<template>
  <div>
    <Get />
    <New />
    <Edit />
  </div>
</template>

<script>
import New from './New'
import Get from './Get'
import Edit from './Edit'
export default {
  components: {
    New,
    Get,
    Edit
  }
}
</script>

<style>

</style>