var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        },
        [_vm._m(0)]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading && _vm.blockStatus == 1,
              expression: "!loading && blockStatus == 1",
            },
          ],
          staticClass: "md-layout-item md-small-size-100",
        },
        [_c("GiftList", { attrs: { promoType: "2" } })],
        1
      ),
      _c("block", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.blockStatus == 0,
            expression: "blockStatus == 0",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "spinner" }, [
      _c("div", { staticClass: "rect1" }),
      _c("div", { staticClass: "rect2" }),
      _c("div", { staticClass: "rect3" }),
      _c("div", { staticClass: "rect4" }),
      _c("div", { staticClass: "rect5" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }