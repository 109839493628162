<template>
    <div class="md-layout">
        <div class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <i class="material-icons">playlist_add</i>
                    </div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"> <br>
                        <span>Bem vindo ao <b>MobCast</b>, serviço onde você poderá publicar seu canal de Podcasts para ser usufruído por ouvintes no aplicativo e em outras plataformas, como Spotify e iTunes. <br><br>
                            - Crie canais <br>
                            - Faça upload dos episódios <br>
                            - O canal com os episódios será publicado automaticamente no aplicativo <br>
                            - Um Feed RSS será gerado para que você possa publicar em outros agregadores de podcast
                        </span>
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <div class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <md-card>
                <md-card-content>
                    <el-tabs v-model="tabActive">
                        <el-tab-pane label="Canais" name="first">
                            <md-button class="md-info" @click="openNewPodcast()"><i class="fas fa-plus"></i> Novo Canal</md-button>
                            <hr style="border: 1px solid #e4e7ed; margin-top:10px;">

                            <ChannelGet />
                        </el-tab-pane>

                        <el-tab-pane label="Episódios" name="second">
                            <div style="display: flex;align-items: baseline;">
                                <md-button class="md-info" @click="openNewEpisode()"><i class="fas fa-plus"></i> Novo Episódio</md-button>
                                <el-select style="padding-left:10px;" v-model="valueChannel" placeholder="Selecione o Canal">
                                    <el-option
                                        v-for="item in listChannels"
                                        :key="item.id"
                                        :label="item.title"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                                <el-input style="padding-left:10px; width:15%;" placeholder="Pesquisar" v-model="searchEp" :disabled="!valueChannel"></el-input>
                            </div>
                            <hr style="border: 1px solid #e4e7ed; margin-top:10px;">

                            <EpisodeGet />

                            <!-- <hr style="border: 1px solid #e4e7ed;"> -->
                        </el-tab-pane>
                    </el-tabs>
                </md-card-content>
            </md-card>
        </div>

        <!-- Channel -->
        <ChannelNew />
        <ChannelEdit />

        <!-- Episodes -->
        <EpisodeNew />
        <EpisodeEdit />
    </div>
</template>

<script>
import { Tabs, TabPane, Select, Option, Input, Pagination } from 'element-ui'
import Swal from 'sweetalert2'
import ChannelGet from './Channel/Get'
import ChannelNew from './Channel/New'
import ChannelEdit from './Channel/Edit'
import EpisodeGet from './Episodes/Get'
import ApiFirebase from '../../../routes/api-firebase'
import EpisodeNew from './Episodes/New'
import EpisodeEdit from './Episodes/Edit'
export default {
    components: {
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
        ChannelGet,
        ChannelNew,
        ChannelEdit,
        EpisodeGet,
        EpisodeNew,
        EpisodeEdit,
        [Select.name]: Select,
        [Option.name]: Option,
        [Input.name]: Input,
        [Pagination.name]: Pagination
    },
    data() {
        return {
            tabActive: 'first',
            listChannels: [],
            valueChannel: '',
            searchEp: ''
        }
    },

    methods: {
        openNewPodcast() {
            this.$root.$emit("openNewPodcast", true)
        },
        openNewEpisode() {
            this.$root.$emit("channels", this.listChannels)
            this.$root.$emit("openNewEpisode", true)
        },
        editEpisode() {
            this.$root.$emit("editEpisode1", true)
        },
        searchEpisode() {
           this.listChannels.indexOf(this.searchEp);
        },
        getChannels() {
            ApiFirebase().get('channels')
                .then((resp) => { this.listChannels = resp.data })
                .catch((err) => { console.error })
        },
        getEpisodesByChannel(id) {
            Swal.showLoading()
            ApiFirebase().get(`channelById/${id}`)
                .then((resp) => { 
                    this.$root.$emit("episodesByChannel", {
                    episodes: resp.data[0].episodes,
                    idChannel: id
                })
                Swal.close()
            })
                .catch((err) => { console.error })
        }
    },
    mounted() {
        let self = this
        self.$root.$on("reloadGet", (payload) => {
            self.getChannels()
        })
        self.$root.$on("listChannels", (payload) => {
            self.listChannels = payload
        })
        self.$root.$on("reloadEpisodes", (payload) => {
            Swal.showLoading()
            self.getEpisodesByChannel(self.valueChannel)
        })
        this.tabActive = 'first'
    },
    watch: {
        searchEp(value) {
            this.searchEpisode()
        },
        valueChannel(id) {
            this.getEpisodesByChannel(id)
        }
    }
}
</script>

<style>
    .el-select__tags {
        top: 58%;
    }
</style>