import jwt_decode from "jwt-decode";

var Mixpanel = require("mixpanel");
var mixpanel = Mixpanel.init("53f8358b762a181427ec5a2697190315");

const mixpanelAdapter = {
    track: (event, dataObject)=> {
        
        const NODE_ENV = process.env.NODE_ENV

        let eventProperties = {
            ...dataObject,
            environment: NODE_ENV
        }

        
        if(localStorage.getItem("SET_APP")){
            let user = jwt_decode(localStorage.getItem("SET_APP")).user[0]
            eventProperties.distinct_id = user.id
            eventProperties.username = user.username
        }

        if(localStorage.getItem("APP_01")){
            let radio = jwt_decode(localStorage.getItem("APP_01")).radio
            eventProperties.radio_key = radio.key
            eventProperties.radio_name = radio.parent_name ? `${radio.parent_name} - ${radio.name}` : radio.name
        }

        if(NODE_ENV!=="production"){
            console.log(`MIXPANEL EVENT ==> ${event} ${JSON.stringify(eventProperties)}`)
        }
        
        mixpanel.track(event, eventProperties)
    }
}
 
export default mixpanelAdapter