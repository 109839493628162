var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Novo Episódio",
            visible: _vm.dialogNewEpisode,
            width: "30%",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogNewEpisode = $event
            },
          },
        },
        [
          _c("el-form", { attrs: { model: _vm.form } }, [
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [_c("span", [_vm._v("Campos com (*) são obrigatórios.")])]
              ),
            ]),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "Atribuir Canal" },
                      model: {
                        value: _vm.form.id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "id", $$v)
                        },
                        expression: "form.id",
                      },
                    },
                    _vm._l(_vm.channels, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.title, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      autocomplete: "off",
                      placeholder: "Título*",
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autocomplete: "off",
                      placeholder: "Descrição*",
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100% !important" },
                    attrs: {
                      type: "datetime",
                      format: "dd-MM-yyyy HH:mm:ss",
                      placeholder:
                        "Data de publicação (momento em que o episódio vai ao ar)",
                    },
                    model: {
                      value: _vm.form.datePub,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "datePub", $$v)
                      },
                      expression: "form.datePub",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                  staticStyle: { "padding-top": "20px" },
                },
                [
                  _c(
                    "span",
                    { ref: "msgImg", staticStyle: { color: "#c1c5cd" } },
                    [
                      _vm._v(
                        " Capa do episódio(Caso não cadastre imagem para o episódio, será usado a mesma imagem do canal). "
                      ),
                    ]
                  ),
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader avatar-gifts",
                      attrs: {
                        action: "#",
                        accept: "image/jpeg, image/png",
                        "auto-upload": false,
                        "show-file-list": false,
                        "on-change": _vm.getFileImage,
                      },
                    },
                    [
                      _vm.imageUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imageUrl },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                  _c("span", { ref: "msgImg" }),
                ],
                1
              ),
            ]),
            _c("br"),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                  staticStyle: { "padding-top": "20px" },
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadDirectory",
                      staticClass: "upload-demo",
                      attrs: {
                        accept: ".mp3, audio/*",
                        action: "#",
                        limit: 1,
                        multiple: false,
                        "auto-upload": false,
                        "on-change": _vm.getAudio,
                      },
                    },
                    [
                      _c("el-button", { attrs: { type: "info" } }, [
                        _vm._v("Arquivo de áudio"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("br"),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [
                  _c("el-checkbox", {
                    attrs: { type: "checkbox", value: false },
                    model: {
                      value: _vm.form.explicit,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "explicit", $$v)
                      },
                      expression: "form.explicit",
                    },
                  }),
                  _vm._v("  "),
                  _c("span", { staticStyle: { color: "#a2a4a7" } }, [
                    _vm._v("Conteúdo explícito"),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogNewEpisode = false
                    },
                  },
                },
                [_vm._v("Cancelar")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.propertyBtnConfirm },
                  on: {
                    click: function ($event) {
                      return _vm.newEpisode()
                    },
                  },
                },
                [_vm._v("Confirmar")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }