<template>
    <div class="md-layout">
        <div v-show="loading"
            class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>
        <div v-show="!loading && blockStatus == 1" class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <i class="material-icons">library_books</i>
                    </div>
                </md-card-header>
                <md-card-content>
                    <el-tabs v-model="activeName" 
                        @tab-click="sendMixPanelEvent(`schedule_tab_filter_clicked`, {
                            day_of_week_filtered: getActiveNameDayOfWeek(activeName)
                        })">
                        <div class="legend" style="text-align:right">
                            <b>Legenda:</b> &nbsp;
                            <i style="color:#ef6b6c" class="fas fa-circle"></i> Finalizado 
                            <i style="color:#4baf51" class="fas fa-circle"></i> Em andamento 
                            <i style="color:#c9cacb" class="fas fa-circle"></i> Próximos
                        </div>
                        <el-tab-pane label="SEGUNDA-FEIRA" name="first">
                            <div class="md-layout">
                                <div
                                    class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-25 md-large-size-20 md-xlarge-size-15">
                                    <md-button class="md-success md-block" @click="emptyScheduleModel();dialogVisibleNewProgram=true; this.msgComponentImage = ''">Adicionar</md-button>
                                </div>
                                <div
                                    class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <md-table v-model="tableDataSegunda" table-header-color="green">
                                        <md-table-row slot="md-table-row" slot-scope="{ item }" :style="(item.status == 2 && item.currentDay === 1?'background-color: lightyellow;':'')">
                                            <md-table-cell md-label="" v-if="item.currentDay > 1" style="width:5px; color:#ef6b6c"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else-if="item.currentDay === 1 && item.status === 1" style="width:5px; color:#ef6b6c"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else-if="item.currentDay === 1 && item.status === 2" style="width:5px; color:#4baf51"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else style="width:5px; color:#c9cacb"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell class="img-schedule" md-label="" style="width:80px;"> <img :src="item.image_url" style="width: 40px; height:40px; border-radius: 5px;"> </md-table-cell>
                                            <md-table-cell md-label="Início">{{ item.start }}</md-table-cell>
                                            <md-table-cell md-label="Fim">{{ item.end }}</md-table-cell>
                                            <md-table-cell md-label="Programa">{{ item.program }}</md-table-cell>
                                            <md-table-cell md-label="Locutor">{{ item.speaker }}</md-table-cell>
                                            <md-table-cell md-label="Ações" style="width:250px">
                                                <div class="container-btn-edit">
                                                    <div class="row-btn-edit">
                                                        <el-button icon="el-icon-edit" circle  @click="getScheduleById(item.id)"></el-button>
                                                    </div>
                                                    <div class="row-btn-edit">
                                                        <el-button icon="el-icon-delete" circle @click="()=>{
                                                            sendMixPanelEvent(`delete_scheduled_popup_opened`)
                                                            deleteSchedule(item.id, item.url)
                                                        }" id="btn-action-times"></el-button>
                                                    </div>
                                                </div>
                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="TERÇA-FEIRA" name="second">
                            <div class="md-layout">
                                <div
                                    class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-25 md-large-size-20 md-xlarge-size-15">
                                    <md-button class="md-success md-block" @click="dialogVisibleNewProgram=true;">Adicionar</md-button>
                                </div>
                                <div
                                    class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <md-table v-model="tableDataTerca" table-header-color="green">
                                        <md-table-row slot="md-table-row" slot-scope="{ item }" :style="(item.status == 2 && item.currentDay === 2?'background-color: lightyellow;':'')">
                                            <md-table-cell md-label="" v-if="item.currentDay > 2" style="width:5px; color:#ef6b6c"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else-if="item.currentDay === 2 && item.status === 1" style="width:5px; color:#ef6b6c"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else-if="item.status === 2 && item.currentDay === 2" style="width:5px; color:#4baf51"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else style="width:5px; color:#c9cacb"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell class="img-schedule" md-label="" style="width:80px;"> <img :src="item.image_url" style="width: 40px; height:40px; border-radius: 5px;"> </md-table-cell>
                                            <md-table-cell md-label="Início">{{ item.start }}</md-table-cell>
                                            <md-table-cell md-label="Fim">{{ item.end }}</md-table-cell>
                                            <md-table-cell md-label="Programa">{{ item.program }}</md-table-cell>
                                            <md-table-cell md-label="Locutor">{{ item.speaker }}</md-table-cell>
                                            <md-table-cell md-label="Ações" style="width:250px">
                                                <div class="container-btn-edit">
                                                    <div class="row-btn-edit">
                                                        <el-button icon="el-icon-edit" circle  @click="getScheduleById(item.id)"></el-button>
                                                    </div>
                                                    <div class="row-btn-edit">
                                                        <el-button icon="el-icon-delete" circle @click="deleteSchedule(item.id, item.url)" id="btn-action-times"></el-button>
                                                    </div>
                                                </div>
                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="QUARTA-FEIRA" name="third">
                            <div class="md-layout">
                                <div
                                    class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-25 md-large-size-20 md-xlarge-size-15">
                                    <md-button class="md-success md-block" @click="dialogVisibleNewProgram=true;">Adicionar</md-button>
                                </div>
                                <div
                                    class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <md-table v-model="tableDataQuarta" table-header-color="green">
                                        <md-table-row slot="md-table-row" slot-scope="{ item }" :style="(item.status == 2 && item.currentDay === 3?'background-color: lightyellow;':'')">
                                            <md-table-cell md-label="" v-if="item.currentDay > 3" style="width:5px; color:#ef6b6c"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else-if="item.currentDay === 3 && item.status === 1" style="width:5px; color:#ef6b6c"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else-if="item.currentDay === 3 && item.status === 2" style="width:5px; color:#4baf51"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else style="width:5px; color:#c9cacb"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell class="img-schedule" md-label="" style="width:80px;"> <img :src="item.image_url" style="width: 40px; height:40px; border-radius: 5px;"> </md-table-cell>
                                            <md-table-cell md-label="Início">{{ item.start }}</md-table-cell>
                                            <md-table-cell md-label="Fim">{{ item.end }}</md-table-cell>
                                            <md-table-cell md-label="Programa">{{ item.program }}</md-table-cell>
                                            <md-table-cell md-label="Locutor">{{ item.speaker }}</md-table-cell>
                                            <md-table-cell md-label="Ações" style="width:250px">
                                                <div class="container-btn-edit">
                                                    <div class="row-btn-edit">
                                                        <el-button icon="el-icon-edit" circle  @click="getScheduleById(item.id)"></el-button>
                                                    </div>
                                                    <div class="row-btn-edit">
                                                        <el-button icon="el-icon-delete" circle @click="deleteSchedule(item.id, item.url)" id="btn-action-times"></el-button>
                                                    </div>
                                                </div>
                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="QUINTA-FEIRA" name="fourth">
                            <div class="md-layout">
                                <div
                                    class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-25 md-large-size-20 md-xlarge-size-15">
                                    <md-button class="md-success md-block" @click="dialogVisibleNewProgram=true;">Adicionar</md-button>
                                </div>
                                <div
                                    class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <md-table v-model="tableDataQuinta" table-header-color="green">
                                        <md-table-row slot="md-table-row" slot-scope="{ item }" :style="(item.status == 2 && item.currentDay === 4?'background-color: lightyellow;':'')">
                                            <md-table-cell md-label="" v-if="item.currentDay > 4" style="width:5px; color:#ef6b6c"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else-if="item.currentDay === 4 && item.status === 1" style="width:5px; color:#ef6b6c"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else-if="item.currentDay === 4 && item.status === 2" style="width:5px; color:#4baf51"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else style="width:5px; color:#c9cacb"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell class="img-schedule" md-label="" style="width:80px;"> <img :src="item.image_url" style="width: 40px; height:40px; border-radius: 5px;"> </md-table-cell>
                                            <md-table-cell md-label="Início">{{ item.start }}</md-table-cell>
                                            <md-table-cell md-label="Fim">{{ item.end }}</md-table-cell>
                                            <md-table-cell md-label="Programa">{{ item.program }}</md-table-cell>
                                            <md-table-cell md-label="Locutor">{{ item.speaker }}</md-table-cell>
                                            <md-table-cell md-label="Ações" style="width:250px">
                                                <div class="container-btn-edit">
                                                    <div class="row-btn-edit">
                                                        <el-button icon="el-icon-edit" circle  @click="getScheduleById(item.id)"></el-button>
                                                    </div>
                                                    <div class="row-btn-edit">
                                                        <el-button icon="el-icon-delete" circle @click="deleteSchedule(item.id, item.url)" id="btn-action-times"></el-button>
                                                    </div>
                                                </div>
                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="SEXTA-FEIRA" name="six">
                            <div class="md-layout">
                                <div
                                    class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-25 md-large-size-20 md-xlarge-size-15">
                                    <md-button class="md-success md-block" @click="dialogVisibleNewProgram=true;">Adicionar</md-button>
                                </div>
                                <div
                                    class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <md-table v-model="tableDataSexta" table-header-color="green">
                                        <md-table-row slot="md-table-row" slot-scope="{ item }" :style="(item.status == 2 && item.currentDay === 5?'background-color: lightyellow;':'')">
                                            <md-table-cell md-label="" v-if="item.currentDay > 5" style="width:5px; color:#ef6b6c"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else-if="item.currentDay === 5 && item.status === 1" style="width:5px; color:#ef6b6c"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else-if="item.currentDay === 5 && item.status === 2" style="width:5px; color:#4baf51"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else style="width:5px; color:#c9cacb"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell class="img-schedule" md-label="" style="width:80px;"> <img :src="item.image_url" style="width: 40px; height:40px; border-radius: 5px;"> </md-table-cell>
                                            <md-table-cell md-label="Início">{{ item.start }}</md-table-cell>
                                            <md-table-cell md-label="Fim">{{ item.end }}</md-table-cell>
                                            <md-table-cell md-label="Programa">{{ item.program }}</md-table-cell>
                                            <md-table-cell md-label="Locutor">{{ item.speaker }}</md-table-cell>
                                            <md-table-cell md-label="Ações" style="width:250px">
                                                <div class="container-btn-edit">
                                                    <div class="row-btn-edit">
                                                        <el-button icon="el-icon-edit" circle  @click="getScheduleById(item.id)"></el-button>
                                                    </div>
                                                    <div class="row-btn-edit">
                                                        <el-button icon="el-icon-delete" circle @click="deleteSchedule(item.id, item.url)" id="btn-action-times"></el-button>
                                                    </div>
                                                </div>
                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="SÁBADO" name="seven">
                            <div class="md-layout">
                                <div
                                    class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-25 md-large-size-20 md-xlarge-size-15">
                                    <md-button class="md-success md-block" @click="dialogVisibleNewProgram=true;">Adicionar</md-button>
                                </div>
                                <div
                                    class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <md-table v-model="tableDataSabado" table-header-color="green">
                                        <md-table-row slot="md-table-row" slot-scope="{ item }" :style="(item.status == 2 && item.currentDay === 6?'background-color: lightyellow;':'')">
                                            <md-table-cell md-label="" v-if="item.currentDay > 6" style="width:5px; color:#ef6b6c"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else-if="item.currentDay === 6 && item.status === 1" style="width:5px; color:#ef6b6c"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else-if="item.currentDay === 6 && item.status === 2" style="width:5px; color:#4baf51"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else style="width:5px; color:#c9cacb"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell class="img-schedule" md-label="" style="width:80px;"> <img :src="item.image_url" style="width: 40px; height:40px; border-radius: 5px;"> </md-table-cell>
                                            <md-table-cell md-label="Início">{{ item.start }}</md-table-cell>
                                            <md-table-cell md-label="Fim">{{ item.end }}</md-table-cell>
                                            <md-table-cell md-label="Programa">{{ item.program }}</md-table-cell>
                                            <md-table-cell md-label="Locutor">{{ item.speaker }}</md-table-cell>
                                            <md-table-cell md-label="Ações" style="width:250px">
                                                <div class="container-btn-edit">
                                                    <div class="row-btn-edit">
                                                        <el-button icon="el-icon-edit" circle  @click="getScheduleById(item.id)"></el-button>
                                                    </div>
                                                    <div class="row-btn-edit">
                                                        <el-button icon="el-icon-delete" circle @click="deleteSchedule(item.id, item.url)" id="btn-action-times"></el-button>
                                                    </div>
                                                </div>
                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="DOMINGO" name="eight">
                            <div class="md-layout">
                                <div
                                    class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-25 md-large-size-20 md-xlarge-size-15">
                                    <md-button class="md-success md-block" @click="dialogVisibleNewProgram=true;">Adicionar</md-button>
                                </div>
                                <div
                                    class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <md-table v-model="tableDataDomingo" table-header-color="green">
                                        <md-table-row slot="md-table-row" slot-scope="{ item }" :style="(item.status == 2 && item.currentDay === 0?'background-color: lightyellow;':'')">
                                            <md-table-cell md-label="" v-if="item.currentDay === 0 && item.status === 1" style="width:5px; color:#ef6b6c"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else-if="item.currentDay === 0 && item.status === 2" style="width:5px; color:#4baf51"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell md-label="" v-else style="width:5px; color:#c9cacb"><i class="fas fa-circle"></i></md-table-cell>
                                            <md-table-cell class="img-schedule" md-label="" style="width:80px;"> <img :src="item.image_url" style="width: 40px; height:40px; border-radius: 5px;"> </md-table-cell>
                                            <md-table-cell md-label="Início">{{ item.start }}</md-table-cell>
                                            <md-table-cell md-label="Fim">{{ item.end }}</md-table-cell>
                                            <md-table-cell md-label="Programa">{{ item.program }}</md-table-cell>
                                            <md-table-cell md-label="Locutor">{{ item.speaker }}</md-table-cell>
                                            <md-table-cell md-label="Ações" style="width:250px">
                                                <div class="container-btn-edit">
                                                    <div class="row-btn-edit">
                                                        <el-button icon="el-icon-edit" circle  @click="getScheduleById(item.id)"></el-button>
                                                    </div>
                                                    <div class="row-btn-edit">
                                                        <el-button icon="el-icon-delete" circle @click="deleteSchedule(item.id, item.url)" id="btn-action-times"></el-button>
                                                    </div>
                                                </div>
                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </md-card-content>
            </md-card>

            <!-- New Promo -->
            <el-dialog title="Novo programa" 
                @open="sendMixPanelEvent(`new_schedule_form_opened`)"
                @close="sendMixPanelEvent(`new_schedule_form_closed`)"
                :visible.sync="dialogVisibleNewProgram">
                <el-tabs v-model="newName">
                    <el-tab-pane label="Programa" name="programa">
                        <div class="md-layout">
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <el-input id="input_name" v-model="schedule.name" placeholder="Nome" style="margin-bottom: 12px;" ></el-input>
                            </div>
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <el-input id="input_broadcaster" v-model="schedule.broadcaster" placeholder="Locutor">
                                </el-input>
                            </div>
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <el-input id="input_description" type="textarea" :rows="6" placeholder="Descrição"
                                    v-model="schedule.description">
                                </el-input>
                            </div>
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-60 md-xlarge-size-60">
                                <el-time-picker style="width:100% !important; margin-top: 12px;" is-range v-model="schedule.time"
                                    range-separator="-" start-placeholder="Horário inicial" end-placeholder="Horário final" format="HH:mm">
                                </el-time-picker>
                                <span class="span-obs">Obs.: O horário final pode limitar o horário inicial, por exemplo, para definir o horário inicial para 14:00hrs, o horário final deve estar no mínimo com 15:00hrs.</span>
                            </div>

                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 check-items">
                                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                                    @change="handleCheckAllChange">Marcar todos</el-checkbox>
                                <div style="margin: 15px 0;"></div>
                                <el-checkbox-group v-model="checkedDays" @change="handlecheckedDaysChange">
                                    <el-checkbox v-for="day in days" :label="day" :key="day">{{day}}</el-checkbox>
                                </el-checkbox-group>
                            </div>
                            <div
                                class="md-layout-item md-size-100 div-img">
                                <el-upload class="avatar-uploader avatar-gifts"
                                    action="#"
                                    accept="image/jpeg,image/gif,image/png"
                                    :auto-upload="false"
                                    :show-file-list="false"
                                    :before-upload="getLoadImage" 
                                    :on-change="handleProgress"
                                    :on-success="handleAvatarSuccess">
                                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <span style="color:red" v-if="msgComponentImage !== ''">{{msgComponentImage}}</span>
                                <!-- <p v-if="loadImage == true">Carregando imagem...</p> -->
                            </div>
                            <div v-if="this.errorsForm.length"
                                class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <p>
                                    <b>Por favor, corrija o(s) seguinte(s) erro(s):</b>
                                    <ul>
                                        <li v-for="error in this.errorsForm" :key="error">{{ error }}</li>
                                    </ul>
                                </p>
                            </div>
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-30 md-xlarge-size-30">
                                <md-button class="md-info md-block" @click="newSchedule()"><i class="fas fa-save"></i>
                                    Salvar</md-button>
                            </div>
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-30 md-xlarge-size-30">
                                <md-button class="md-danger md-block" @click="dialogVisibleNewProgram=false"><i
                                        class="fas fa-times"></i> Cancelar</md-button>
                            </div>
                            <div
                                class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                &nbsp;
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-dialog>

            <!-- Update Promo -->
            <el-dialog title="Atualizar programa" 
                @open="sendMixPanelEvent(`update_schedule_form_opened`)"
                @close="sendMixPanelEvent(`update_schedule_form_closed`)"
                :visible.sync="dialogVisibleViewProgram" style="width:100%">

                <el-tabs v-model="updateName">
                    <el-tab-pane label="Programa" name="programa">
                        <div class="md-layout">
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <el-input  style="margin-bottom: 12px;" id="input_update_name" v-model="scheduleUpdate.name" placeholder="Nome">
                                </el-input>
                            </div>
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <el-input id="input_update_broadcaster" v-model="scheduleUpdate.broadcaster"
                                    placeholder="Locutor"></el-input>
                            </div>
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100" style="margin-bottom: 12px;">
                                <el-input id="input_update_description" type="textarea" :rows="6"
                                    placeholder="Descrição" v-model="scheduleUpdate.description">
                                </el-input>
                            </div>
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-60 md-xlarge-size-60">
                                <el-time-picker style="width:100% !important; margin-botton: 12px;" is-range v-model="scheduleUpdate.time"
                                    range-separator="-" start-placeholder="Horário inicial" end-placeholder="Horário final" format="HH:mm">
                                </el-time-picker>
                                <span class="span-obs">Obs.: O horário final pode limitar o horário inicial, por exemplo, para definir o horário inicial para 14:00hrs, o horário final deve estar no mínimo com 15:00hrs.</span>
                            </div>

                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 check-items">
                                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                                    @change="handleCheckAllChange">Marcar todos</el-checkbox>
                                <div style="margin: 15px 0;"></div>
                                <el-checkbox-group v-model="checkedDays" @change="handlecheckedDaysChange">
                                    <el-checkbox v-for="day in days" :label="day" :key="day">{{day}}</el-checkbox>
                                </el-checkbox-group>
                            </div>
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 div-img">
                                <el-upload class="avatar-uploader avatar-gifts"
                                    action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false"
                                    :before-upload="getLoadImageUpdate" :on-change="handleProgressUpdate"
                                    :on-success="handleAvatarSuccessUpdate">
                                    <img v-if="imageUrlPreview" :src="imageUrlPreview" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <span style="color:red" v-if="msgComponentImage !== ''">{{msgComponentImage}}</span>
                                <!-- <p v-if="loadImageUpdate == true">Carregando imagem...</p> -->
                            </div>
                            <div v-if="errorsEdit.length"
                                class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <p>
                                    <b>Por favor, corrija o(s) seguinte(s) erro(s):</b>
                                    <ul>
                                        <li v-for="error in errorsEdit" :key="error">{{ error }}</li>
                                    </ul>
                                </p>
                            </div>
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-30 md-xlarge-size-30">
                                <md-button class="md-info md-block" @click="updateSchedule()"><i
                                        class="fas fa-save"></i> Atualizar</md-button>
                            </div>
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-30 md-xlarge-size-30">
                                <md-button class="md-danger md-block" @click="dialogVisibleViewProgram=false"><i
                                        class="fas fa-times"></i> Cancelar</md-button>
                            </div>
                            <div
                                class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                &nbsp;
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-dialog>
        </div>
        <block v-show="blockStatus == 0"></block>
    </div>

</template>

<script>
    const daysOptions = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom']
    import Swal from 'sweetalert2'
    import axios from 'axios'
    import models from '../../routes/models.js'
    import formatTimeFromDate from '../../utils/formatTimeFromDate'
    import block from './Block.vue'
    import clearAllCache from '../../utils/events/clearAllCache'
    import services from '../../routes/services'
    import {
        NavTabsCard
    } from '@/components'

    import {
        Dialog,
        Button,
        Input,
        Tabs,
        TabPane,
        Upload,
        Checkbox,
        CheckboxGroup,
        TimePicker,
        TimeSelect
    } from 'element-ui'
    import selectRadio from '../../utils/events/select-radio'
    import jwt_decode from "jwt-decode";
    import mixpanelAdapter from '../../utils/events/mixpanelAdapter'

    export default {
        components: {
            NavTabsCard,
            [Dialog.name]: Dialog,
            [Button.name]: Button,
            [Input.name]: Input,
            [Tabs.name]: Tabs,
            [TabPane.name]: TabPane,
            [Upload.name]: Upload,
            [Checkbox.name]: Checkbox,
            [CheckboxGroup.name]: CheckboxGroup,
            [TimePicker.name]: TimePicker,
            [TimeSelect.name]: TimeSelect,
            block
        },
        data() {
            return {
                value4: [new Date(), new Date()],
                selected: [],
                segunda: [],
                tableDataSegunda: [],
                tableDataTerca: [],
                tableDataQuarta: [],
                tableDataQuinta: [],
                tableDataSexta: [],
                tableDataSabado: [],
                tableDataDomingo: [],
                activeName: 'first',
                newName: 'programa',
                updateName: 'programa',
                dialogVisibleNewProgram: false,
                dialogVisibleViewProgram: false,
                schedule: {
                    name: '',
                    broadcaster: '',
                    description: '',
                    image: '',
                    time: [new Date(2020, 1, 10, 0, 0), new Date(2020, 1, 10, 23, 59)],
                    sunday: 0,
                    monday: 0,
                    tuesday: 0,
                    wednesday: 0,
                    thursday: 0,
                    friday: 0,
                    saturday: 0,
                    userId: jwt_decode(localStorage.getItem('SET_APP')).user[0].id
                },
                scheduleUpdate: {
                    name: '',
                    broadcaster: '',
                    description: '',
                    image: '',
                    time: '',
                    sunday: 0,
                    monday: 0,
                    tuesday: 0,
                    wednesday: 0,
                    thursday: 0,
                    friday: 0,
                    saturday: 0,
                    userId: jwt_decode(localStorage.getItem('SET_APP')).user[0].id
                },
                checkAll: false,
                checkedDays: [],
                days: daysOptions,
                isIndeterminate: true,
                time1: '',
                time2: '',
                appKey: jwt_decode(localStorage.getItem('APP_01')).radio.key,
                loading: false,
                disabledButton: true,
                checkedDelete: [],
                activeId: null,
                blockStatus: 1,
                imageUrl: '',
                loadImage: false,
                loadImageUpdate: false,
                imageUrlPreview: null,
                uploadFile: null,
                touchtime: 0,
                errorsForm: [],
                errorsEdit: [],
                currentDay: 1,
                msgComponentImage: ''
            }
        },
        methods: {
            handleAvatarSuccess(res, file) {
                this.schedule.image = URL.createObjectURL(file.raw);
            },
            handleCheckAllChange(val) {
                this.checkedDays = val ? daysOptions : [];
                this.isIndeterminate = false;
            },
            handlecheckedDaysChange(value) {
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.days.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.days.length;
            },
            getFullSchedule() {
                this.loading = true
                this.currentDay = new Date().getDay()
                models().get(`schedule/getFullSchedule?appKey=${this.appKey}`)
                    .then((resp) => {
                        this.tableDataSegunda = []
                        this.tableDataTerca = []
                        this.tableDataQuarta = []
                        this.tableDataQuinta = []
                        this.tableDataSexta = []
                        this.tableDataSabado = []
                        this.tableDataDomingo = []
                        for (let item of resp.data) {
                            let m = new Date().getMinutes()
                            if(m < 10) {m = '0'+m}

                            let now = new Date().getHours() + '' + m
                            let start = parseInt(item.hora_inicio.substring(0,5).replace(":", ""))
                            let end = parseInt(item.hora_termino.substring(0,5).replace(":", ""))
                            let status = 0
                            if(parseInt(start) < now && parseInt(end) <= parseInt(now)) {
                                status = 1
                            }
                            else if(parseInt(now) >= parseInt(start) && parseInt(now) < parseInt(end)) {
                                status = 2
                            }
                            else{
                                status = 0
                            }
                            if (item.segunda == 1) {
                                this.tableDataSegunda.push({
                                    id: item.id,
                                    start: item.hora_inicio.substring(0, 5),
                                    end: item.hora_termino.substring(0, 5),
                                    program: unescape(item.nome),
                                    image_url: item.image_url ? item.image_url : '../../../public/img/not-found.png',
                                    speaker: item.locutor,
                                    url: item.image_url,
                                    status: status,
                                    currentDay: this.currentDay
                                })
                            }
                            if (item.terca == 1) {
                                let m = new Date().getMinutes()
                                if(m < 10) {m = '0'+m}

                                let now = new Date().getHours() + '' + m
                                let start = parseInt(item.hora_inicio.substring(0,5).replace(":", ""))
                                let end = parseInt(item.hora_termino.substring(0,5).replace(":", ""))
                                let status = 0
                                if(parseInt(start) < now && parseInt(end) < parseInt(now)) {
                                    status = 1
                                }
                                else if(parseInt(now) >= parseInt(start) && parseInt(now) < parseInt(end)) {
                                    status = 2
                                }
                                else{
                                    status = 0
                                }
                                this.tableDataTerca.push({
                                    id: item.id,
                                    start: item.hora_inicio.substring(0, 5),
                                    end: item.hora_termino.substring(0, 5),
                                    program: unescape(item.nome),
                                    image_url: item.image_url ? item.image_url : '../../../public/img/not-found.png',
                                    speaker: item.locutor,
                                    url: item.image_url,
                                    status: status,
                                    currentDay: this.currentDay
                                })
                            }
                            if (item.quarta == 1) {
                                let m = new Date().getMinutes()
                                if(m < 10) {m = '0'+m}

                                let now = new Date().getHours() + '' + m
                                let start = parseInt(item.hora_inicio.substring(0,5).replace(":", ""))
                                let end = parseInt(item.hora_termino.substring(0,5).replace(":", ""))
                                let status = 0
                                if(parseInt(start) < now && parseInt(end) < parseInt(now)) {
                                    status = 1
                                }
                                else if(parseInt(now) >= parseInt(start) && parseInt(now) < parseInt(end)) {
                                    status = 2
                                }
                                else{
                                    status = 0
                                }
                                this.tableDataQuarta.push({
                                    id: item.id,
                                    start: item.hora_inicio.substring(0, 5),
                                    end: item.hora_termino.substring(0, 5),
                                    program: unescape(item.nome),
                                    image_url: item.image_url ? item.image_url : '../../../public/img/not-found.png',
                                    speaker: item.locutor,
                                    url: item.image_url,
                                    status: status,
                                    currentDay: this.currentDay
                                })
                            }
                            if (item.quinta == 1) {
                                let m = new Date().getMinutes()
                                if(m < 10) {m = '0'+m}

                                let now = new Date().getHours() + '' + m
                                let start = parseInt(item.hora_inicio.substring(0,5).replace(":", ""))
                                let end = parseInt(item.hora_termino.substring(0,5).replace(":", ""))
                                let status = 0
                                if(parseInt(start) < now && parseInt(end) < parseInt(now)) {
                                    status = 1
                                }
                                else if(parseInt(now) >= parseInt(start) && parseInt(now) < parseInt(end)) {
                                    status = 2
                                }
                                else{
                                    status = 0
                                }
                                this.tableDataQuinta.push({
                                    id: item.id,
                                    start: item.hora_inicio.substring(0, 5),
                                    end: item.hora_termino.substring(0, 5),
                                    program: unescape(item.nome),
                                    image_url: item.image_url ? item.image_url : '../../../public/img/not-found.png',
                                    speaker: item.locutor,
                                    url: item.image_url,
                                    status: status,
                                    currentDay: this.currentDay
                                })
                            }
                            if (item.sexta == 1) {
                                let m = new Date().getMinutes()
                                if(m < 10) {m = '0'+m}

                                let now = new Date().getHours() + '' + m
                                let start = parseInt(item.hora_inicio.substring(0,5).replace(":", ""))
                                let end = parseInt(item.hora_termino.substring(0,5).replace(":", ""))
                                let status = 0
                                if(parseInt(start) < now && parseInt(end) < parseInt(now)) {
                                    status = 1
                                }
                                else if(parseInt(now) >= parseInt(start) && parseInt(now) < parseInt(end)) {
                                    status = 2
                                }
                                else{
                                    status = 0
                                }
                                this.tableDataSexta.push({
                                    id: item.id,
                                    start: item.hora_inicio.substring(0, 5),
                                    end: item.hora_termino.substring(0, 5),
                                    program: unescape(item.nome),
                                    image_url: item.image_url ? item.image_url : '../../../public/img/not-found.png',
                                    speaker: item.locutor,
                                    url: item.image_url,
                                    status: status,
                                    currentDay: this.currentDay
                                })
                            }
                            if (item.sabado == 1) {
                                let m = new Date().getMinutes()
                                if(m < 10) {m = '0'+m}

                                let now = new Date().getHours() + '' + m
                                let start = parseInt(item.hora_inicio.substring(0,5).replace(":", ""))
                                let end = parseInt(item.hora_termino.substring(0,5).replace(":", ""))
                                let status = 0
                                if(parseInt(start) < now && parseInt(end) < parseInt(now)) {
                                    status = 1
                                }
                                else if(parseInt(now) >= parseInt(start) && parseInt(now) < parseInt(end)) {
                                    status = 2
                                }
                                else{
                                    status = 0
                                }
                                this.tableDataSabado.push({
                                    id: item.id,
                                    start: item.hora_inicio.substring(0, 5),
                                    end: item.hora_termino.substring(0, 5),
                                    program: unescape(item.nome),
                                    image_url: item.image_url ? item.image_url : '../../../public/img/not-found.png',
                                    speaker: item.locutor,
                                    url: item.image_url,
                                    status: status,
                                    currentDay: this.currentDay
                                })
                            }
                            if (item.domingo == 1) {
                                let m = new Date().getMinutes()
                                if(m < 10) {m = '0'+m}

                                let now = new Date().getHours() + '' + m
                                let start = parseInt(item.hora_inicio.substring(0,5).replace(":", ""))
                                let end = parseInt(item.hora_termino.substring(0,5).replace(":", ""))
                                let status = 0
                                if(parseInt(start) < now && parseInt(end) < parseInt(now)) {
                                    status = 1
                                }
                                else if(parseInt(now) >= parseInt(start) && parseInt(now) < parseInt(end)) {
                                    status = 2
                                }
                                else{
                                    status = 0
                                }
                                this.tableDataDomingo.push({
                                    id: item.id,
                                    start: item.hora_inicio.substring(0, 5),
                                    end: item.hora_termino.substring(0, 5),
                                    program: unescape(item.nome),
                                    image_url: item.image_url ? item.image_url : '../../../public/img/not-found.png',
                                    speaker: item.locutor,
                                    url: item.image_url,
                                    status: status,
                                    currentDay: this.currentDay
                                })
                            }
                        }
                        this.switchActiveTable()
                        this.loading = false
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            },
            newSchedule() {
                this.errorsForm = []
                this.validationErrors()

                if (!this.errorsForm.length) {
                    this.dialogVisibleNewProgram = false
                    Swal.fire({
                        title: 'Cadastrando programa...',
                        onOpen: () => {
                            Swal.showLoading()
                            if (this.checkedDays.length > 0) {
                                for (let itens of this.checkedDays) {
                                    if (itens == 'Seg') {
                                        this.schedule.monday = 1
                                    }
                                    if (itens == 'Ter') {
                                        this.schedule.tuesday = 1
                                    }
                                    if (itens == 'Qua') {
                                        this.schedule.wednesday = 1
                                    }
                                    if (itens == 'Qui') {
                                        this.schedule.thursday = 1
                                    }
                                    if (itens == 'Sex') {
                                        this.schedule.friday = 1
                                    }
                                    if (itens == 'Sáb') {
                                        this.schedule.saturday = 1
                                    }
                                    if (itens == 'Dom') {
                                        this.schedule.sunday = 1
                                    }
                                }
                                const startDate = new Date(this.schedule.time[0])
                                const endDate = new Date(this.schedule.time[1])

                                let dataForm = {
                                    appKey: this.appKey,
                                    name: this.schedule.name,
                                    broadcaster: this.schedule.broadcaster,
                                    description: this.schedule.description,
                                    imageUrl: '',
                                    startTime: formatTimeFromDate(startDate),
                                    endTime: formatTimeFromDate(endDate),
                                    sunday: this.schedule.sunday,
                                    monday: this.schedule.monday,
                                    tuesday: this.schedule.tuesday,
                                    wednesday: this.schedule.wednesday,
                                    thursday: this.schedule.thursday,
                                    friday: this.schedule.friday,
                                    saturday: this.schedule.saturday,
                                    userId: jwt_decode(localStorage.getItem('SET_APP')).user[0].id
                                }

                                if (this.uploadFile) {
                                    Promise.resolve(this.checkSchedule(formatTimeFromDate(startDate), formatTimeFromDate(endDate)))
                                        .then((check) => {
                                            if (check.data.length == 0) {
                                                Promise.resolve(this.upload(this.uploadFile))
                                                    .then((upload) => {
                                                        dataForm.imageUrl = upload.secure_url
                                                        models().post('schedule/newSchedule',
                                                                dataForm)
                                                            .then((respSchedule) => {
                                                                const id = respSchedule.data[0][
                                                                    '_insertedId'
                                                                ]
                                                                if (id) {
                                                                    Swal(
                                                                        'Pronto',
                                                                        'Seu programa foi cadastrado com sucesso!',
                                                                        'success'
                                                                    )
                                                                    this.sendMixPanelEvent(`new_schedule_created`)
                                                                    this.deleteCacheApi()
                                                                    this.deleteCacheServer(id)
                                                                    this.emptyScheduleModel()
                                                                    this.getFullSchedule()
                                                                }
                                                            }).catch((e) => {
                                                                Swal(
                                                                    'Erro',
                                                                    'Não foi possível cadastrar a imagem. Por favor, tente novamente.',
                                                                    'error'
                                                                )
                                                                console.log("Error")
                                                                console.log(e)
                                                            })
                                                    })
                                            } else {
                                                Swal.fire({
                                                    title: 'Atenção',
                                                    text: `Horário indisponível. Já existe um programa cadastrado nesse horário.`,
                                                    type: 'warning',
                                                    confirmButtonColor: '#3085d6',
                                                    confirmButtonText: 'Ok'
                                                }).then((result) => {
                                                    if (result.value) {
                                                        this.dialogVisibleNewProgram = true
                                                    }
                                                })
                                            }
                                        })
                                }
                            }
                        }
                    })
                }
            },
            sendMixPanelEvent(eventString, params){
                mixpanelAdapter.track(eventString, {
                    ...params,
                    screen_name: this.$route.name
                })
            },
            getActiveNameDayOfWeek(activeName){
                switch (activeName) {
                    case 'eight':
                        return "Dom"
                    case 'first':
                        return "Seg"
                    case 'second':
                        return "Ter"
                    case 'third':
                        return "Qua"
                    case 'fourth':
                        return "Qui"
                    case 'six':
                        return "Sex"
                    case 'seven':
                        return "Sab"
                }
            },
            async checkSchedule(date1, date2) {
                const sunday = this.schedule.sunday == 0 ? 0 : 1
                const monday = this.schedule.monday == 0 ? 0 : 1
                const tuesday = this.schedule.tuesday == 0 ? 0 : 1
                const wednesday = this.schedule.wednesday == 0 ? 0 : 1
                const thursday = this.schedule.thursday == 0 ? 0 : 1
                const friday = this.schedule.friday == 0 ? 0 : 1
                const saturday = this.schedule.saturday == 0 ? 0 : 1
                return await models().get(
                    `schedule/checkSchedule?appKey=${this.appKey}&startTime=${date1}&endTime=${date2}&sunday=${sunday}&monday=${monday}&tuesday=${tuesday}&wednesday=${wednesday}&thursday=${thursday}&friday=${friday}&saturday=${saturday}`
                    )
            },
            async checkScheduleUpdate(date1, date2) {
                const sunday = this.scheduleUpdate.sunday == 0 ? 0 : 1
                const monday = this.scheduleUpdate.monday == 0 ? 0 : 1
                const tuesday = this.scheduleUpdate.tuesday == 0 ? 0 : 1
                const wednesday = this.scheduleUpdate.wednesday == 0 ? 0 : 1
                const thursday = this.scheduleUpdate.thursday == 0 ? 0 : 1
                const friday = this.scheduleUpdate.friday == 0 ? 0 : 1
                const saturday = this.scheduleUpdate.saturday == 0 ? 0 : 1
                return await models().get(
                    `schedule/checkSchedule?appKey=${this.appKey}&startTime=${date1}&endTime=${date2}&sunday=${sunday}&monday=${monday}&tuesday=${tuesday}&wednesday=${wednesday}&thursday=${thursday}&friday=${friday}&saturday=${saturday}`
                    )
            },
            emptyScheduleModel() {
                this.schedule = {
                    name: '',
                    broadcaster: '',
                    description: '',
                    image: '',
                    time: [new Date(2020, 1, 10, 0, 0), new Date(2020, 1, 10, 23, 59)],
                    startTime: '',
                    endTime: '',
                    sunday: 0,
                    monday: 0,
                    tuesday: 0,
                    wednesday: 0,
                    thursday: 0,
                    friday: 0,
                    saturday: 0,
                    userId: jwt_decode(localStorage.getItem('SET_APP')).user[0].id
                }
                this.imageUrl = ""
                this.uploadFile = null
                this.checkedDays = []
            },
            deleteSchedule(id, url) {
                Swal.fire({
                    title: 'Atenção',
                    html: 'Deseja realmente excluir?',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    type: 'warning'
                }).then((result) => {
                    if (result.value) {
                        Swal.fire({
                            title: 'Excluindo...',
                            onOpen: () => {
                                Swal.showLoading()
                                let dataForm = {
                                    id: id
                                }
                                models().post('schedule/deleteSchedule', dataForm)
                                    .then((resp) => {
                                        // Promise.resolve(this.destroyImageCloud(url))
                                        // .then((resp) => {
                                        Swal.close()
                                        this.deleteCacheServer(id)
                                        this.deleteCacheApi()
                                        this.getFullSchedule()
                                        // })
                                        this.sendMixPanelEvent(`schedule_deleted`)
                                    })
                                    .catch((e) => {
                                        Swal(
                                            'Erro',
                                            'Não foi possível excluir, por favor tente novamente.',
                                            'error'
                                        )
                                        console.log(e)
                                    })

                            }
                        })
                    }
                })
            },
            getScheduleById(id) {
                const dateNow = new Date().toISOString().slice(0, 11)
                this.activeId = id
                this.errorsEdit = []
                Swal.showLoading()
                models().get(`schedule/getScheduleById?id=${id}`)
                    .then((resp) => {
                        //console.log(resp)
                        this.scheduleUpdate = {}
                        this.checkedDays = []
                        if (resp.data) {
                            this.scheduleUpdate = {
                                name: unescape(resp.data.name),
                                broadcaster: resp.data.broadcaster,
                                description: unescape(resp.data.description),
                                image: resp.data.image_url,
                                time: [new Date(dateNow + resp.data.start_time), new Date(dateNow + resp.data
                                    .end_time)],
                                startTime: resp.data.start_time.substring(0, 5),
                                endTime: resp.data.end_time.substring(0, 5),
                                sunday: resp.data.sunday,
                                monday: resp.data.monday,
                                tuesday: resp.data.tuesday,
                                wednesday: resp.data.wednesday,
                                thursday: resp.data.thursday,
                                friday: resp.data.friday,
                                saturday: resp.data.saturday,
                                userId: jwt_decode(localStorage.getItem('SET_APP')).user[0].id
                            }
                            this.imageUrlPreview = resp.data.image_url
                            if (resp.data.sunday == 1) {
                                this.checkedDays.push("Dom")
                            }
                            if (resp.data.monday == 1) {
                                this.checkedDays.push("Seg")
                            }
                            if (resp.data.tuesday == 1) {
                                this.checkedDays.push("Ter")
                            }
                            if (resp.data.wednesday == 1) {
                                this.checkedDays.push("Qua")
                            }
                            if (resp.data.thursday == 1) {
                                this.checkedDays.push("Qui")
                            }
                            if (resp.data.friday == 1) {
                                this.checkedDays.push("Sex")
                            }
                            if (resp.data.saturday == 1) {
                                this.checkedDays.push("Sáb")
                            }
                            Swal.close()
                            this.dialogVisibleViewProgram = true
                            this.validationErrorsEdit()
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            },
            updateSchedule() {
                this.errorsEdit = []
                this.validationErrorsEdit()

                if (!this.errorsEdit.length) {
                    if (this.checkedDays.length > 0) {
                    if (this.checkedDays.includes('Seg')) {
                        this.scheduleUpdate.monday = 1
                    } else {
                        this.scheduleUpdate.monday = 0
                    }
                    if (this.checkedDays.includes('Ter')) {
                        this.scheduleUpdate.tuesday = 1
                    } else {
                        this.scheduleUpdate.tuesday = 0
                    }
                    if (this.checkedDays.includes('Qua')) {
                        this.scheduleUpdate.wednesday = 1
                    } else {
                        this.scheduleUpdate.wednesday = 0
                    }
                    if (this.checkedDays.includes('Qui')) {
                        this.scheduleUpdate.thursday = 1
                    } else {
                        this.scheduleUpdate.thursday = 0
                    }
                    if (this.checkedDays.includes('Sex')) {
                        this.scheduleUpdate.friday = 1
                    } else {
                        this.scheduleUpdate.friday = 0
                    }
                    if (this.checkedDays.includes('Sáb')) {
                        this.scheduleUpdate.saturday = 1
                    } else {
                        this.scheduleUpdate.saturday = 0
                    }
                    if (this.checkedDays.includes('Dom')) {
                        this.scheduleUpdate.sunday = 1
                    } else {
                        this.scheduleUpdate.sunday = 0
                    }
                    this.dialogVisibleViewProgram = false
                    let checkTime = 0;

                    const startDate = new Date(this.scheduleUpdate.time[0])
                    const endDate = new Date(this.scheduleUpdate.time[1])

                    Promise.resolve(this.checkScheduleUpdate(formatTimeFromDate(startDate), formatTimeFromDate(endDate)))
                        .then((resp) => {
                            for (let item of resp.data) {
                                if (item.id === this.activeId) {
                                    checkTime = 1
                                }
                            }
                            if (resp.data.length == 0 || checkTime === 1) {
                                Swal.fire({
                                    title: 'Atualizando',
                                    html: 'Aguarde, enquanto atualizamos o programa...',
                                    onOpen: () => {
                                        Swal.showLoading()
                                        if (this.uploadFile) {
                                            let dataForm = {
                                                name: this.scheduleUpdate.name,
                                                broadcaster: this.scheduleUpdate.broadcaster,
                                                description: this.scheduleUpdate.description,
                                                imageUrl: '',
                                                startTime: new Date(this.scheduleUpdate.time[0])
                                                    .toLocaleTimeString("pt-BR").substring(0,5) + ":00",
                                                endTime: new Date(this.scheduleUpdate.time[1])
                                                    .toLocaleTimeString("pt-BR").substring(0,5) + ":00",
                                                sunday: this.scheduleUpdate.sunday,
                                                monday: this.scheduleUpdate.monday,
                                                tuesday: this.scheduleUpdate.tuesday,
                                                wednesday: this.scheduleUpdate.wednesday,
                                                thursday: this.scheduleUpdate.thursday,
                                                friday: this.scheduleUpdate.friday,
                                                saturday: this.scheduleUpdate.saturday,
                                                userId: jwt_decode(localStorage.getItem('SET_APP')).user[0].id,
                                                id: this.activeId,
                                                appKey: this.appKey
                                            }
                                            Promise.resolve(this.upload(this.uploadFile, this
                                                    .scheduleUpdate.image))
                                                .then((resp) => {
                                                    dataForm.imageUrl = resp.secure_url
                                                    models().post('schedule/updateSchedule',
                                                            dataForm)
                                                        .then((resp) => {
                                                            Swal(
                                                                'Pronto',
                                                                'Seu programa foi atualizado com sucesso!',
                                                                'success'
                                                            )
                                                            
                                                            this.deleteCacheApi()
                                                            this.deleteCacheServer(this.activeId)
                                                            this.emptyScheduleModel()
                                                            this.getFullSchedule()
                                                            this.sendMixPanelEvent(`schedule_updated`)
                                                        })
                                                        .catch((e) => {
                                                            console.log(e)
                                                        })
                                                })
                                        } else {
                                            let dataForm = {
                                                name: this.scheduleUpdate.name,
                                                broadcaster: this.scheduleUpdate.broadcaster,
                                                description: this.scheduleUpdate.description,
                                                imageUrl: this.scheduleUpdate.image,
                                                startTime: new Date(this.scheduleUpdate.time[0])
                                                    .toLocaleTimeString("pt-BR").substring(0,5) + ":00",
                                                endTime: new Date(this.scheduleUpdate.time[1])
                                                    .toLocaleTimeString("pt-BR").substring(0,5) + ":00",
                                                sunday: this.scheduleUpdate.sunday,
                                                monday: this.scheduleUpdate.monday,
                                                tuesday: this.scheduleUpdate.tuesday,
                                                wednesday: this.scheduleUpdate.wednesday,
                                                thursday: this.scheduleUpdate.thursday,
                                                friday: this.scheduleUpdate.friday,
                                                saturday: this.scheduleUpdate.saturday,
                                                userId: jwt_decode(localStorage.getItem('SET_APP')).user[0].id,
                                                id: this.activeId,
                                                appKey: this.appKey
                                            }
                                            models().post('schedule/updateSchedule',
                                                dataForm)
                                            .then((resp) => {
                                                Swal(
                                                    'Pronto',
                                                    'Seu programa foi atualizado com sucesso!',
                                                    'success'
                                                )
                                                this.deleteCacheApi()
                                                this.deleteCacheServer(this.activeId)
                                                this.emptyScheduleModel()
                                                this.getFullSchedule()
                                                this.sendMixPanelEvent(`schedule_updated`)
                                            })
                                            .catch((e) => {
                                                console.log(e)
                                            })
                                        }
                                    }
                                })
                            } else {
                                Swal.fire({
                                    title: 'Atenção',
                                    text: `Horário indisponível. Já existe um programa cadastrado nesse horário.`,
                                    type: 'warning',
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'Ok'
                                }).then((result) => {
                                    if (result.value) {
                                        this.dialogVisibleViewProgram = true
                                    }
                                })
                            }
                        })
                    }
                }
            },
            blockPage() {
                const menu = jwt_decode(localStorage.getItem('APP_02')).services
                let service = null
                for (let obj of menu) {
                    if (obj.subitens === null) {
                        const key = Object.keys(obj.item)[Object.values(obj.item).indexOf(this.$route.path.replace("/",
                            ""))]
                        if (key) {
                            service = obj.item
                        }
                    } else {
                        const key = Object.keys(obj.subitens)[Object.values(obj.subitens).indexOf(this.$route.path
                            .replace("/", ""))]
                        if (key) {
                            service = obj.subitens
                        }
                    }
                }

                if (service) {
                    this.blockStatus = service.has_permission
                } else {
                    this.$router.push("dashboard")
                }
            },
            findObjectByKey(array, key, value) {
                for (var i = 0; i < array.length; i++) {
                    if (array[i][key] === value) {
                        return array[i];
                    }
                }
                return null;
            },
            handleProgress(file) {
                this.msgComponentImage = ''
                if(file.size <= 250000) {
                    if (file.length > 0) {
                        let img = file.pop();
                        this.uploadFile = img.raw
                        this.imageUrl = URL.createObjectURL(img.raw)
                    } else {
                        this.uploadFile = file.raw
                        this.imageUrl = URL.createObjectURL(file.raw)
                    }
                } else {
                    this.msgComponentImage = 'O tamanho da imagem não pode ser maior que 250kb'
                }
            },
            getLoadImage() {
                this.loadImage = true
            },
            handleAvatarSuccessUpdate(res, file) {
                this.uploadFile = file.raw
                this.imageUrlPreview = URL.createObjectURL(file.raw)
            },
            handleProgressUpdate(file) {
                this.msgComponentImage = ''
                if(file.size <= 250000) {
                    if (file.length > 0) {
                        let img = file.pop();
                        this.uploadFile = img.raw
                        this.imageUrlPreview = URL.createObjectURL(img.raw)
                    } else {
                        this.uploadFile = file.raw
                        this.imageUrlPreview = URL.createObjectURL(file.raw)
                    }
                } else {
                    this.msgComponentImage = 'O tamanho da imagem não pode ser maior que 250kb'
                }
            },
            getLoadImageUpdate() {
                this.loadImageUpdate = true
            },
            upload: function (file, updateUrl) {

                const formData = new FormData()
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                if (!updateUrl) {
                    formData.append('key', this.appKey)
                    formData.append('attachments', this.uploadFile)
                } else {
                    const aux = updateUrl.split("/")
                    const newUrl = `${aux[9]}`.split(".")[0]

                    formData.append('key', this.appKey)
                    formData.append('attachments', this.uploadFile)
                    formData.append('public_id', newUrl)
                }
                return services().post('services/uploadCloudImage', formData, config).then(res => {
                    return res.data
                })
            },
            destroyImageCloud: function (destroyUrl) {
                const urlCloud = 'https://server.mobradio.com.br/brokers/cloudinaryDestroyImage'
                const formData = new FormData()
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                const aux = destroyUrl.split("/")
                const newUrl = `${aux[7]}/${aux[8]}/${aux[9]}`.split(".")[0]

                formData.append('public_id', newUrl)

                return services().post('services/cloudinaryDestroyImage', formData, config).then(res => {
                    return res.data
                })
            },
            deleteCacheApi() {
                // Deleta o cache na API EXTERNA
                const formData = {
                    service: "schedule",
                    keyCache: this.appKey
                }

                axios.post("https://api.mobradio.com.br/delete-cache", formData)
                    .then(response => console.log(response.data))
                    .catch(e => console.log(e))
            },
            deleteCacheServer(id) {
                // Deleta o cache na SERVER
                clearAllCache(id, null, "getRadioProgramFeaturedImage")
                clearAllCache(this.appKey, null, "getRadioFullSchedule")
            },
            // VALIDA CAMPOS DO FORMULÁRIO
            validationErrors() {
                const daysElements = document.getElementsByClassName("el-checkbox")
                if (this.schedule.name === '') {
                    this.errorsForm.push('O campo "Nome" é obrigatório.');
                    document.getElementById("input_name").style.borderColor = "#ff0000";
                } else {
                    document.getElementById("input_name").style.borderColor = "";
                }
                if (this.schedule.broadcaster === '') {
                    this.errorsForm.push('O campo "Locutor" é obrigatório.');
                    document.getElementById("input_broadcaster").style.borderColor = "#ff0000";
                } else {
                    document.getElementById("input_broadcaster").style.borderColor = "";
                }
                if (this.schedule.description === '') {
                    this.errorsForm.push('O campo "Descrição" é obrigatório.');
                    document.getElementById("input_description").style.borderColor = "#ff0000";
                } else if (this.schedule.description.length < 3) {
                    this.errorsForm.push('O campo "Descrição" é de no mínimo 3 caracteres.');
                    document.getElementById("input_description").style.borderColor = "#ff0000";
                } else {
                    document.getElementById("input_description").style.borderColor = "";
                }
                if (this.schedule.time === '') {
                    this.errorsForm.push('O campo "Horário inicial" e "Horário final" são obrigatórios.');
                    document.getElementsByClassName("el-range-editor")[0].style.borderColor = "#ff0000";
                }else if(this.schedule.time[0]>this.schedule.time[1]){
                    this.errorsForm.push('O horário de início começa depois do horário final');
                    document.getElementsByClassName("el-range-editor")[0].style.borderColor = "#ff0000";
                }
                
                else {
                    document.getElementsByClassName("el-range-editor")[0].style.borderColor = "";
                }
                if (this.checkedDays.length === 0) {
                    this.errorsForm.push('É necessário marcar pelo menos 1 dia da semana.');
                    for (let i = 0; i < daysElements.length; i++) {
                        document.getElementsByClassName("el-checkbox")[i].style.color = "#ff0000";
                    }
                } else {
                    for (let i = 0; i < daysElements.length; i++) {
                        document.getElementsByClassName("el-checkbox")[i].style.color = "";
                    }
                }
                if (this.uploadFile === null || this.uploadFile === '') {
                    this.errorsForm.push('É necessário inserir uma imagem para o programa.');
                    document.getElementsByClassName("el-upload el-upload--text")[0].style.borderColor = "#ff0000";
                } else {
                    document.getElementsByClassName("el-upload el-upload--text")[0].style.borderColor = "";
                }
            },
            // VALIDA CAMPOS DO FORMULÁRIO EM EDIÇÂO
            validationErrorsEdit() {
                const daysElements = document.getElementsByClassName("el-checkbox")
                if (this.scheduleUpdate.name === '') {
                    this.errorsEdit.push('O campo "Nome" é obrigatório.');
                    document.getElementById("input_update_name").style.borderColor = "#ff0000";
                } else {
                    document.getElementById("input_update_name").style.borderColor = "";
                }
                if (this.scheduleUpdate.broadcaster === '') {
                    this.errorsEdit.push('O campo "Locutor" é obrigatório.');
                    document.getElementById("input_update_broadcaster").style.borderColor = "#ff0000";
                } else {
                    document.getElementById("input_update_broadcaster").style.borderColor = "";
                }
                if (this.scheduleUpdate.description === '') {
                    this.errorsEdit.push('O campo "Descrição" é obrigatório.');
                    document.getElementById("input_update_description").style.borderColor = "#ff0000";
                } else if (this.scheduleUpdate.description.length < 3) {
                    this.errorsEdit.push('O campo "Descrição" é de no mínimo 3 caracteres.');
                    document.getElementById("input_update_description").style.borderColor = "#ff0000";
                } else {
                    document.getElementById("input_update_description").style.borderColor = "";
                }
                if (this.scheduleUpdate.time === '') {
                    this.errorsEdit.push('O campo "Horário inicial" e "Horário final" são obrigatórios.');
                    document.getElementsByClassName("el-range-editor")[0].style.borderColor = "#ff0000";
                }else if(this.scheduleUpdate.time[0]>this.scheduleUpdate.time[1]){
                    this.errorsForm.push('O horário de início começa depois do horário final');
                    document.getElementsByClassName("el-range-editor")[0].style.borderColor = "#ff0000";
                }else {
                    document.getElementsByClassName("el-range-editor")[0].style.borderColor = "";
                }
                if (this.checkedDays.length === 0) {
                    this.errorsEdit.push('É necessário marcar pelo menos 1 dia da semana.');
                    for (let i = 0; i < daysElements.length; i++) {
                        document.getElementsByClassName("el-checkbox")[i].style.color = "#ff0000";
                    }
                } else {
                    for (let i = 0; i < daysElements.length; i++) {
                        document.getElementsByClassName("el-checkbox")[i].style.color = "";
                    }
                }
            },
            switchActiveTable() {
                switch (this.currentDay) {
                    case 0:
                        this.activeName = 'eight'
                        break;
                    case 1:
                        this.activeName = 'first'
                        break;
                    case 2:
                        this.activeName = 'second'
                        break;
                    case 3:
                        this.activeName = 'third'
                        break;
                    case 4:
                        this.activeName = 'fourth'
                        break;
                    case 5:
                        this.activeName = 'six'
                        break;
                    case 6:
                        this.activeName = 'seven'
                        break;
                    default:
                        this.activeName = 'first'
                        break;
                }
            }
        },
        beforeCreate() {
            selectRadio.$on('selectRadio', (payload) => {
                this.appKey = payload.key
                this.blockPage()
            })
        },
        mounted() {
            let self = this
            if (jwt_decode(localStorage.getItem('APP_01'))) {
                self.appKey = jwt_decode(localStorage.getItem('APP_01')).radio.key
                self.getFullSchedule()
                self.blockPage()
            }
        },
        watch: {
            schedule: {
                handler(newValue) {
                    if (newValue.name !== '' &&
                        newValue.broadcaster !== '' &&
                        newValue.description !== '' &&
                        (this.schedule.time !== '' && this.schedule.time !== null) &&
                        this.checkedDays.length > 0 && this.uploadFile !== null) {
                        this.disabledButton = false
                    } else {
                        this.disabledButton = true
                    }
                },
                deep: true
            },
            checkedDays: {
                handler(newValue) {
                    if (newValue.length > 0 &&
                        this.schedule.name !== '' &&
                        this.schedule.broadcaster !== '' &&
                        this.schedule.description !== '' &&
                        (this.schedule.time !== '' && this.schedule.time !== null) &&
                        this.uploadFile !== null) {
                        this.disabledButton = false
                    } else {
                        this.disabledButton = true
                    }
                },
                deep: true
            },
            uploadFile(file) {
                if (file !== null &&
                    this.schedule.name !== '' &&
                    this.schedule.broadcaster !== '' &&
                    this.schedule.description !== '' &&
                    (this.schedule.time !== '' && this.schedule.time !== null) &&
                    this.checkedDays.length > 0
                ) {
                    this.disabledButton = false
                } else {
                    this.disabledButton = true
                }
            },
            appKey(val) {
                this.getFullSchedule()
            }
        }
    }

</script>

<style scoped>
    #pane-programa {
        display: block !important;
    }

    .check-items {
        margin-top: 5%;
    }

    .row-btn-edit {
        width: 8%;
    }

    .div-img {
        text-align: left !important;
    }

    .span-obs {
        font-style: italic;
        font-size: 13px;
        color: #de0d0d;
    }

    @media (min-width: 860px) and (max-width: 960px) {
        .row-btn-edit {
            width: 5% !important;
        }
    }
    @media (min-width: 960px) and (max-width: 1024px) {
        .row-btn-edit {
            width: 50% !important;
        }
    }
    @media (min-width: 1024px)  and (max-width: 1440px) {
        .row-btn-edit {
            width: 25% !important;
        }
    }
    @media (min-width: 1440px){
        .row-btn-edit {
            width: 20% !important;
        }
    }

    @media (max-width: 800px) {

        .el-checkbox,
        .el-checkbox+.el-checkbox {
            margin-left: 0px;
            margin-right: 10px;

        }

        .el-date-editor {
            width: 100% !important;
        }

        .img-schedule {
            display: none
        }
    }

    @media (min-width: 900px) {
        .el-date-editor {
            width: 45% !important;
        }

        .time1 {
            margin-right: 10%;
        }

        .el-dialog {
            width: 70% !important;
        }
    }

    @media (min-width: 1200px) {
        .el-dialog {
            width: 60% !important;
        }
    }

    @media (min-width: 1400px) {
        .el-dialog {
            width: 50% !important;
        }
    }

</style>
