var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        },
        [_vm._m(0)]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading && _vm.blockStatus == 1,
              expression: "!loading && blockStatus == 1",
            },
          ],
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-blue" },
                [
                  _c("div", { staticClass: "card-icon" }, [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("thumbs_up_down"),
                    ]),
                  ]),
                ]
              ),
              _c("md-card-content", [
                _c(
                  "div",
                  {
                    staticClass: "md-layout",
                    staticStyle: { "align-items": "center" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-30 md-xlarge-size-25",
                      },
                      [
                        _c("h4", [
                          _vm._v("Tempo até a próxima atualização: "),
                          _c("b", [
                            _vm._v("0" + _vm._s(_vm.minutes) + ":"),
                            _c(
                              "b",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.seconds < 10,
                                    expression: "seconds < 10",
                                  },
                                ],
                              },
                              [_vm._v(_vm._s(_vm.auxSeconds))]
                            ),
                            _vm._v(_vm._s(_vm.seconds)),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-25 md-xlarge-size-20",
                      },
                      [
                        _c(
                          "md-button",
                          {
                            staticClass: "md-info md-block",
                            on: {
                              click: () => {
                                _vm.sendMixPanelEvent(`tracking_updated`)
                                _vm.updateTracking()
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fas fa-sync-alt" }),
                            _vm._v("   Atualizar"),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout md-alignment-center-left",
                    staticStyle: {
                      "margin-top": "16px",
                      "margin-bottom": "16px",
                    },
                  },
                  [
                    _c(
                      "h4",
                      {
                        staticClass: "md-layout-item md-size-100",
                        staticStyle: {
                          "font-weight": "400",
                          "font-size": "10pt",
                          "font-style": "italic",
                          color: "#999999",
                          margin: "0",
                        },
                      },
                      [
                        _vm._v(
                          " Última atualização às " +
                            _vm._s(
                              this.lastTimeUpdated
                                .toLocaleTimeString("pt-BR")
                                .substring(0, 5)
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading && _vm.blockStatus == 1,
              expression: "!loading && blockStatus == 1",
            },
          ],
          staticClass: "md-layout-item md-small-size-100 md-larg-size-100",
        },
        [
          _vm.info == true
            ? _c(
                "md-card",
                [
                  _c(
                    "md-card-content",
                    [
                      _c("md-table", {
                        attrs: { "table-header-color": "green" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "md-table-row",
                              fn: function ({ item }) {
                                return _c(
                                  "md-table-row",
                                  {},
                                  [
                                    _c(
                                      "md-table-cell",
                                      { attrs: { "md-label": "Título" } },
                                      [_vm._v(_vm._s(item.title))]
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { attrs: { "md-label": "Artista" } },
                                      [_vm._v(_vm._s(item.artist))]
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { attrs: { "md-label": "Curti" } },
                                      [
                                        _vm._v(
                                          _vm._s(item.total_likes) +
                                            " (" +
                                            _vm._s(
                                              parseInt(item.percentual_likes)
                                            ) +
                                            "%)"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { attrs: { "md-label": "Não Curti" } },
                                      [
                                        _vm._v(
                                          _vm._s(item.total_dislikes) +
                                            " (" +
                                            _vm._s(
                                              parseInt(item.percentual_dislikes)
                                            ) +
                                            "%)"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { attrs: { "md-label": "Total" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            parseInt(item.total_likes) +
                                              parseInt(item.total_dislikes)
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          4263070094
                        ),
                        model: {
                          value: _vm.tableData,
                          callback: function ($$v) {
                            _vm.tableData = $$v
                          },
                          expression: "tableData",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.info == false
            ? _c("md-card", { staticStyle: { "text-align": "center" } }, [
                _c("h4", [_vm._v("Sem informações no momento.")]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("block", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.blockStatus == 0,
            expression: "blockStatus == 0",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "spinner" }, [
      _c("div", { staticClass: "rect1" }),
      _c("div", { staticClass: "rect2" }),
      _c("div", { staticClass: "rect3" }),
      _c("div", { staticClass: "rect4" }),
      _c("div", { staticClass: "rect5" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }