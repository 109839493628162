<template>
	<div class="md-layout" id="filter-selector">
		<div
			class="md-layout-item md-size-100"
			style="margin: 0 !important; padding: 0 !important"
		>
			<div class="md-layout">
				<md-card class="md-layout-item md-size-100" style="margin-top: 0">
					<md-card-content>
						<div class="md-layout">
							<div class="md-layout-item md-size-100" style="padding: 0">
								<div class="md-layout">
									<div class="md-layout-item md-size-100" style="padding: 0">
										<slot name="filters"></slot>
									</div>
								</div>
							</div>
							<div class="md-layout-item md-size-100" style="padding: 0">
								<h3 class="title-period">Período</h3>
								<div class="md-layout">
									<template v-for="periodOption in buttonsToDisplay">
										<button
											v-if="periodOption != buttonEnum.CUSTOM"
											:disabled="disableSearch"
											:ref="`${periodOption}-period-option`"
											class="md-layout-item md-xsmall-size-100 md-small-size-20 md-medium-size-20 md-large-size-15 md-xlarge-size-15 period-button"
											:class="{
												'period-button-selected':
													buttonSelected === periodOption,
												'period-button-disabled': disableSearch,
											}"
											@click="()=>{
												setPeriod(periodOption)
												sendMixPanelEvent({
													period: buttonLabels[periodOption]
												})
											}"
										>
											{{ buttonLabels[periodOption] }}
										</button>
										<button
											v-else
											:disabled="disableSearch"
											:ref="`${periodOption}-period-option`"
											style="padding-right: 2px; padding-left: 2px"
											@click="
												() => {
													buttonSelected = buttonEnum.CUSTOM;
												}
											"
											class="md-layout-item md-xsmall-size-100 md-small-size-40 md-medium-size-40 md-large-size-25 md-xlarge-size-25 period-button"
											:class="{
												'period-button-selected':
													buttonSelected === buttonEnum.CUSTOM,
												'period-button-disabled': disableSearch,
												'md-large-size-35 md-xlarge-size-35':
													buttonSelected === buttonEnum.CUSTOM,
											}"
										>
											<span>Personalizado</span>

											<el-date-picker
												v-if="buttonSelected === buttonEnum.CUSTOM"
												@change="handleCustomPeriodChange($event)"
												v-model="period"
												format="dd/MM/yyyy"
												type="daterange"
												style="max-width: 400px; margin: 0 !important"
												range-separator="a"
												unlink-panels
												start-placeholder="Data inicial"
												end-placeholder="Data final"
												class="remove-margin"
											>
											</el-date-picker>
										</button>
									</template>
								</div>
								<h5
									v-if="this.checkDifferenceBetweenDates()"
									ref="period-error-message"
									style="
										color: #dd0000;
										margin: 0;
										margin-top: 10px;
										font-size: 95%;
									"
								>
									O período personalizado não pode ultrapassar 90 dias
								</h5>
							</div>

							<div
								class="md-layout-item md-size-100"
								style="margin-top: 25px; padding: 0"
							>
								<div class="md-layout md-alignment-center-right">
									<h3
										ref="radios-consolidated-counter"
										v-if="this.radio.parent_id && catalogReportAvailable"
										style="margin-right: auto"
									>
										Rádios Consolidadas: {{ radiosConsolidated.length }}
									</h3>
									<div
										style="padding-right: 0"
										class="md-layout-item md-xsmall-size-100 md-small-size-20 md-medium-size-25 md-large-size-15 md-xlarge-size-15"
									>
										<md-button
											v-if="this.radio.parent_id && catalogReportAvailable"
											@click="
												() => {
													this.$emit(
														'showConsolidatePopup',
														this.radiosConsolidated
													);
												}
											"
											class="md-success"
											ref="consolidate-radios-button"
											style="padding-right: 0"
										>
											Consolidar rádios
										</md-button>
									</div>
									<div
										style="padding: 0"
										class="md-layout-item md-xsmall-size-100 md-small-size-25 md-medium-size-20 md-large-size-10 md-xlarge-size-10"
									>
										<md-button
											class="md-info"
											v-if="extraFunction"
											ref="extra-function-button"
											:disabled="disableExtraFunction"
											@click="triggerExtraFunctionEvent()"
										>
											{{ extraFunctionLabel }}
										</md-button>
									</div>
								</div>
							</div>
						</div>
					</md-card-content>
				</md-card>
			</div>
		</div>
		<PopupConsolidateRadios />
	</div>
</template>

<script>
import filters from "./filters";
import selectRadio from "../../../../utils/events/select-radio";
import buttonEnum from "./buttonEnum";
import PopupConsolidateRadios from "./PopupConsolidateRadios.vue";
import jwt_decode from "jwt-decode";
import { CollapseTransition } from "vue2-transitions";
import mixpanelAdapter from "../../../../utils/events/mixpanelAdapter"

const {
	YESTERDAY_PLUS_TODAY,
	LAST_MONTH,
	TODAY,
	YESTERDAY,
	SEVEN_DAYS,
	FIFTEEN_DAYS,
	MONTH_TO_DATE,
	LAST_30_DAYS,
	CUSTOM,
} = buttonEnum;

export default {
	components: {
		CollapseTransition,
		PopupConsolidateRadios,
	},
	props: [
		"disableSearch",
		"catalogReportAvailable",
		"buttonsToDisplay",
		"extraFunction",
		"extraFunctionLabel",
		"disableExtraFunction",
	],
	data() {
		return {
			buttonEnum: buttonEnum,
			buttonSelected: null,
			buttonLabels: {
				[YESTERDAY_PLUS_TODAY]: "Ontem + Hoje",
				[TODAY]: "Hoje",
				[YESTERDAY]: "Ontem",
				[SEVEN_DAYS]: "Últimos 7 dias",
				[FIFTEEN_DAYS]: "Últimos 15 dias",
				[LAST_30_DAYS]: "Últimos 30 dias",
				[MONTH_TO_DATE]: "Este mês",
				[LAST_MONTH]: "Mês anterior",
				[CUSTOM]: "Personalizado...",
			},
			period: [],
			month: null,
			radio: jwt_decode(localStorage.getItem("APP_01")).radio,
			radiosConsolidated: [],
			logo: "",
			btnImprimir: true,
			datePickerOptions: {
				disabledDate: (date) => {
					return date < new Date("2020-1-1");
				},
			},
		};
	},
	methods: {
		handleCustomPeriodChange(data) {
			const startDate = data[0];
			const endDate = data[1];

			this.period[0] = startDate;
			this.period[1] = endDate;

			filters.startDate = startDate;
			filters.endDate = endDate;

			const isValidTimeRange = this.checkDifferenceBetweenDates();

			if (!isValidTimeRange) {
				this.triggerSearchDataEvent();
			}

			this.sendMixPanelEvent({
				period: "Personalizado"
			})
		},
		setPeriod(period) {
			this.period = [];
			switch (period) {
				case buttonEnum.YESTERDAY_PLUS_TODAY:
					this.setPeriodYesterdayPlusToday();
					break;
				case buttonEnum.TODAY:
					this.setPeriodToday();
					break;
				case buttonEnum.YESTERDAY:
					this.setPeriodYesterday();
					break;
				case buttonEnum.SEVEN_DAYS:
					this.setPeriodWeek();
					break;
				case buttonEnum.FIFTEEN_DAYS:
					this.setPeriodFifteenDays();
					break;
				case buttonEnum.MONTH_TO_DATE:
					this.setPeriodMonthToDate();
					break;
				case buttonEnum.LAST_30_DAYS:
					this.setPeriodLast30Days();
					break;
				case buttonEnum.LAST_MONTH:
					this.setPeriodLastMonth();
					break;
			}
			this.buttonSelected = period;
			filters.buttonSelected = period;

			if (this.buttonSelected != buttonEnum.CUSTOM) {
				this.triggerSearchDataEvent();
			}
		},

		sendMixPanelEvent(params){
			mixpanelAdapter.track("filter_selector_period_click", {
				...params,
				screen_name: this.$route.name
			})
		},

		setPeriodYesterdayPlusToday() {
			this.period[0] = new Date(Date.now());
			this.period[1] = new Date(Date.now());
			this.period[0].setDate(new Date(Date.now()).getDate() - 1);
			this.period[0].setHours(0, 0, 0);
			this.period[1].setHours(23, 59, 59);

			filters.startDate = this.period[0];
			filters.endDate = this.period[1];
		},
		setPeriodToday() {
			this.period[0] = new Date(Date.now());
			this.period[1] = new Date(Date.now());
			this.period[0].setHours(0, 0, 0);
			this.period[1].setHours(23, 59, 59);

			filters.startDate = this.period[0];
			filters.endDate = this.period[1];
		},
		setPeriodYesterday() {
			const today = new Date();
			const yesterday = new Date(today);

			yesterday.setDate(today.getDate() - 1);

			this.period[0] = new Date(yesterday);
			this.period[1] = new Date(yesterday);
			this.period[0].setHours(0, 0, 0);
			this.period[1].setHours(23, 59, 59);

			filters.startDate = this.period[0];
			filters.endDate = this.period[1];
		},
		setPeriodWeek() {
			let today = new Date(Date.now());
			const startOfWeek = today.getDate() - 8;
			const endOfWeek = today.getDate() - 1;

			this.period[0] = new Date(Date.now());
			this.period[1] = new Date(Date.now());
			this.period[0].setDate(startOfWeek);
			this.period[1].setDate(endOfWeek);
			this.period[0].setHours(0, 0, 0);
			this.period[1].setHours(23, 59, 59);

			filters.startDate = this.period[0];
			filters.endDate = this.period[1];
		},
		setPeriodFifteenDays() {
			let today = new Date(Date.now());
			const firstDay = today.getDate() - 16;
			const lastDay = today.getDate() - 1;

			this.period[0] = new Date(Date.now());
			this.period[1] = new Date(Date.now());
			this.period[0].setDate(firstDay);
			this.period[1].setDate(lastDay);
			this.period[0].setHours(0, 0, 0);
			this.period[1].setHours(23, 59, 59);

			filters.startDate = this.period[0];
			filters.endDate = this.period[1];
		},
		setPeriodLastMonth() {
			const today = new Date();
			const firstCurrentMonthDay = new Date(
				today.getFullYear(),
				today.getMonth(),
				1
			);
			this.period[0] = new Date(
				firstCurrentMonthDay.getFullYear(),
				firstCurrentMonthDay.getMonth() - 1,
				1
			);
			this.period[1] = new Date(firstCurrentMonthDay.getTime() - 1000);

			filters.startDate = this.period[0];
			filters.endDate = this.period[1];
		},
		setPeriodMonthToDate() {
			var today = new Date();
			this.period[0] = new Date(today.getFullYear(), today.getMonth(), 1);
			this.period[1] = new Date(today.getFullYear(), today.getMonth() + 1, 0);
			this.period[0].setHours(0, 0, 0);
			this.period[1].setHours(23, 59, 59);

			filters.startDate = this.period[0];
			filters.endDate = this.period[1];
		},
		setPeriodLast30Days() {
			let today = new Date(Date.now());
			const firstDay = today.getDate() - 31;
			const lastDay = today.getDate() - 1;

			this.period[0] = new Date(Date.now());
			this.period[1] = new Date(Date.now());
			this.period[0].setDate(firstDay);
			this.period[1].setDate(lastDay);
			this.period[0].setHours(0, 0, 0);
			this.period[1].setHours(23, 59, 59);

			filters.startDate = this.period[0];
			filters.endDate = this.period[1];
		},
		checkDifferenceBetweenDates() {
			const date1 = this.period[0];
			const date2 = this.period[1];

			if (!date1 || !date2) {
				return false;
			}

			const differenceInMilliseconds = Math.abs(date2 - date1);
			const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

			return differenceInDays > 90;
		},
		triggerSearchDataEvent() {
			this.$emit("searchData");
		},
		triggerExtraFunctionEvent() {
			mixpanelAdapter.track("filter_selector_extra_function_click", {
				screen_name: this.$route.name,
				period: this.buttonLabels[this.buttonSelected],
				function_evoked: this.$props.extraFunctionLabel 	
			})
			this.$emit("extraFunction");
		},
	},
	watch: {
		disableSearch: function (val) {
			if (val) {
				this.buttonSelected = null;
				this.period = [];
				filters.buttonSelected = null;
				filters.startDate = null;
				filters.endDate = null;
			}
		},
	},
	mounted() {
		filters.radio = this.radio;
		filters.startDate = null;
		filters.endDate = null;
		filters.buttonSelected = null;

		if (this.radio.parent_id) {
			filters.$options.radiosConsolidated = [
				{
					id: this.radio.id,
					appKey: this.radio.key,
					logo: this.radio.logo,
					name: this.radio.name,
				},
			];

			this.radiosConsolidated = filters.$options.radiosConsolidated;
		}

		this.buttonSelected = this.$props.buttonsToDisplay[0];

		if (!this.$props.disableSearch) {
			this.setPeriod(this.buttonSelected);
		}

		this.$parent.$on("updateFilters", (filters) => {
			this.buttonSelected = filters.buttonSelected;
			this.setPeriod(this.buttonSelected);
		});

		if (jwt_decode(localStorage.getItem("APP_01")).radio) {
			this.appKey = jwt_decode(localStorage.getItem("APP_01")).radio.key;
			this.logo = jwt_decode(localStorage.getItem("APP_01")).radio.logo;
		}

		this.$root.$on("updateConsolidatedRadios", (payload) => {
			this.radiosConsolidated = payload.consolidatedRadios;
		});

		selectRadio.$on("selectRadio", (payload) => {
			this.radio = jwt_decode(localStorage.getItem("APP_01")).radio;
			this.logo = jwt_decode(localStorage.getItem("APP_01")).radio.logo;
			this.period = [filters.startDate, filters.endDate];
			this.buttonSelected = filters.buttonSelected;
			this.radiosConsolidated = [];

			if (this.radio.parent_id) {
				filters.$options.radiosConsolidated = [
					{
						id: this.radio.id,
						appKey: this.radio.key,
						logo: this.radio.logo,
						name: this.radio.name,
					},
				];

				this.radiosConsolidated = filters.$options.radiosConsolidated;
			}
		});
	},
};
</script>
<style>
#filter-selector {
	margin: 0 !important;
	padding: 0 !important;
}

.radio-logo {
	height: 50px;
	width: 50px;
	margin-right: 15px;
	border-radius: 50%;
}

.title-period {
	font-size: 1rem;
	text-align: left;
	margin: 25px 0 10px 0;
	font-weight: 300;
}

.title-report-campaign {
	font-weight: bold !important;
	text-align: center;
	font-size: 170%;
	margin: 24px 0 !important;
}

.period-button {
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: wrap;
	border: none;
	font-family: "Roboto";
	font-size: 1rem;
	border-radius: 0;
	color: #c0c0c0;
	border: none;
	background-color: #fff;
	border: 1px solid #c0c0c0;
	cursor: pointer;
	transition: 0.2s;
	min-height: 2.5rem;
}

.period-button:hover {
	border-color: #009ddb;
	color: #009ddb;
}

.period-button:first-child {
	border-radius: 5px 0 0 5px;
}

.period-button:last-child {
	border-radius: 0 5px 5px 0;
}

.period-button-selected {
	background-color: #009ddb;
	border-color: #009ddb;
	color: #fff !important;
}

.period-button-disabled {
	background-color: rgba(180, 180, 180, 0.151);
	cursor: not-allowed;
}

.period-button-disabled:hover {
	background-color: rgba(180, 180, 180, 0.151);
	color: #c0c0c0;
	border: 1px solid #c0c0c0;
}

.md-button {
	margin-top: 14px !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	width: 100%;
}

.remove-margin {
	margin: 0 !important;
}

.el-input__inner {
	margin-top: 0;
}

@media print {
	#filter-selector {
		display: none;
	}
}

@media (max-width: 600px) {
	.period-button:first-child {
		border-radius: 5px 5px 0 0;
	}

	.period-button:last-child {
		border-radius: 0 0 5px 5px;
	}
}
</style>
