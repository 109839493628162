<template>
	<el-dialog style="padding: 0 !important;" title="" :visible.sync="dialogSelectRadioCompare"
		:before-close="cancelConsolidateRadios">
		<div>
			<div class="md-layout">
				<h4 class="md-layout-item md-size-100" style="text-align: center;">ESCOLHA AS RÁDIOS PARA CONSOLIDAR</h4>
				<collapse-transition>
					<div class="radio-selected" v-show="this.radiosChildrenSelected.length > 0">
						<h4 style="margin: 15px; font-weight:300;">SELECIONADAS</h4>
						<carousel :autoplay="false" :navigationEnabled="true" :perPageCustom="[[300, 1], [768, 3], [1024, 4]]"
							:paginationEnabled="false" :spacePadding="20">
							<slide v-for="radio in this.radiosChildrenSelected" :key="radio.id">
								<button class="remove-radio-compare" v-if="radio.id !== radioDefault.id"
									@click="unselectRadioToConsolidate(radio.id)">
									<i class="fa fa-times"></i>
								</button>
								<h6 style="margin: 10px; text-align: center;" v-else>RÁDIO PADRÃO</h6>
								<radio-catalog-item :logo="radio.logo" :name="radio.name"></radio-catalog-item>
							</slide>
						</carousel>
						<footer class="md-layout">
							<div class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-50 md-large-size-15 md-xlarge-size-15">
								<md-button class="md-danger" @click="unselectAllRadiosToConsolidate()">LIMPAR</md-button>
							</div>
						</footer>
					</div>
				</collapse-transition>
			</div>
			<div class="md-layout" style="margin-top: 1rem;">
				<input type="checkbox" :checked="checkIfAllRadiosAreSelected()" @change="() => {
						if (this.checkIfAllRadiosAreSelected()) {
							this.unselectAllRadiosToConsolidate()
						} else {
							this.selectAllRadiosToConsolidate()
						}
					}" style="width: 1.5rem; height: 1.5rem; cursor: pointer;"> Selecionar todas
			</div>
			<div class="md-layout" style="margin-top: 1rem;">
				<div style="margin: auto;"
					class="md-layout-item md-xsmall-size-50 md-small-size-25 md-medium-size-25 md-large-size-20 md-xlarge-size-20"
					v-for="(radio, index) in this.radiosChildren" :key="radio.id">
					<input type="checkbox" :value="radio" @change="selectRadioToConsolidate(radio.id)"
						style="width: 1.5rem; height: 1.5rem; cursor: pointer;">
					<radio-catalog-item :logo="radio.logo" :name="radio.name"></radio-catalog-item>
				</div>
			</div>

			<footer class="md-layout">
				<div class="md-layout-item md-xsmall-size-100 md-small-size-25 md-medium-size-20 md-large-size-15 md-xlarge-size-15">
					<md-button class="md-success" @click="confirmConsolidateRadios()">
						{{ radiosChildrenSelected.length > 1 ? 'CONSOLIDAR' : 'SALVAR' }}
					</md-button>
				</div>
				<div class="md-layout-item md-xsmall-size-100 md-small-size-25 md-medium-size-20 md-large-size-15 md-xlarge-size-15">
					<md-button @click="cancelConsolidateRadios()" class="md-danger">
						CANCELAR
					</md-button>
				</div>
			</footer>
		</div>
	</el-dialog>
</template>

<script>
import { Dialog } from "element-ui";
import { CollapseTransition } from "vue2-transitions";
import { Carousel, Slide } from "vue-carousel";
import selectRadio from "../../../../utils/events/select-radio";
import RadioCatalogItem from "../../Components/RadioCatalogItem.vue";
import jwt_decode from 'jwt-decode'
import filters from './filters.js'
import models from "../../../../routes/models";
import Swal from "sweetalert2";

export default {
	name: "PopupConsolidateRadios",
	components: {
		CollapseTransition,
		[Dialog.name]: Dialog,
		Carousel,
		Slide,
		RadioCatalogItem
	},
	data() {
		return {
			parentId: null,
			radioDefault: {},
			radiosChildren: [],
			radiosChildrenSelected: [],
			dialogSelectRadioCompare: false
		}
	},
	methods: {
		setData(radiosSelected) {
			Swal.fire({
				html: "Carregando dados...",
				onOpen: () => {
					Swal.showLoading();
					this.parentId = jwt_decode(localStorage.getItem('APP_01')).radio.parent_id
					this.radioDefault = {
						id: jwt_decode(localStorage.getItem('APP_01')).radio.id,
						appKey: jwt_decode(localStorage.getItem('APP_01')).radio.key,
						logo: jwt_decode(localStorage.getItem('APP_01')).radio.logo,
						name: jwt_decode(localStorage.getItem('APP_01')).radio.name
					}
					models()
						.get(`radios/getChildrenRadios/${this.parentId}`)
						.then(res => {
							this.radiosChildrenSelected.push(this.radioDefault)
							this.radiosChildren = res.data.filter(radio => radio.id !== this.radioDefault.id).map(radio => {
								return {
									id: radio.id,
									appKey: radio.key,
									logo: radio.image_logo_url,
									name: radio.name
								};
							});
							this.radiosChildren.forEach(radio => {
								if (radiosSelected.map(radio => radio.id).includes(radio.id)) {
									this.selectRadioToConsolidate(radio.id)
								}
							})
							this.orderRadiosAlphabetically()
							Swal.close()
							this.openPopup()
						}).catch((err) => {
							console.log(err)
							Swal({
								type: 'error',
								text: 'Não foi possível recuperar as rádios catálogo.'
							})
						});
				}
			});

		},

		openPopup() {
			this.dialogSelectRadioCompare = true
		},

		clearDataAndClosePopup() {
			this.radioDefault = {},
				this.radiosChildren = [],
				this.radiosChildrenSelected = [],
				this.dialogSelectRadioCompare = false
		},

		selectRadioToConsolidate(id) {
			const radioSelected = this.radiosChildren.filter(radio => radio.id === id)[0]
			this.radiosChildrenSelected.push(radioSelected)
			this.radiosChildren = this.radiosChildren.filter(radio => radio.id !== id)
		},

		checkIfAllRadiosAreSelected() {
			if (this.radiosChildren.length === 0) {
				return true
			}
			return false
		},

		unselectRadioToConsolidate(id) {
			const radioUnselected = this.radiosChildrenSelected.filter(radio => radio.id === id)[0]
			this.radiosChildren.push(radioUnselected)
			this.radiosChildrenSelected = this.radiosChildrenSelected.filter(radio => radio.id !== id)
		},
		selectAllRadiosToConsolidate() {
			this.radiosChildren.forEach((radio) => {
				this.selectRadioToConsolidate(radio.id)
			})
		},

		unselectAllRadiosToConsolidate() {
			this.radiosChildrenSelected.forEach((radio) => {
				if (radio.id === this.radioDefault.id) {
					return;
				}
				this.unselectRadioToConsolidate(radio.id)
			})
		},
		confirmConsolidateRadios() {
			Swal.fire({
				title: 'Confirmar consolidação de rádios',
				text: 'Tem certeza que dejesa consolidar as rádios selecionadas para o relatório?',
				type: 'question',
				showConfirmButton: true,
				showCancelButton: true,
				confirmButtonText: 'Consolidar',
				cancelButtonText: 'Voltar à seleção'
			}).then(result => {
				const confirmed = result.value
				if (confirmed) {
					this.consolidateRadios()
					this.$root.$emit('updateConsolidatedRadios', {consolidatedRadios: filters.$options.radiosConsolidated})
				}
			})
		},

		consolidateRadios() {
			if (this.radiosChildrenSelected.length === 0) {
				Swal.fire({
					title: 'Rádios não selecionadas',
					text: "Selecione pelo menos uma rádio para fazer a comparação",
					type: 'error'
				})
				return;
			}

			filters.$options.radiosConsolidated =  this.radiosChildrenSelected
			this.$root.$emit('updateConsolidatedRadios', this.radiosConsolidated)

			this.clearDataAndClosePopup()
		},

		cancelConsolidateRadios() {
			this.clearDataAndClosePopup()
		},
		orderRadiosAlphabetically() {
			this.radiosChildren.sort((radio1, radio2) => {
				if (radio1.name < radio2.name) {
					return -1
				}
				if (radio1.name > radio2.name) {
					return 1
				}
				return 0;
			})
		}

	},
	watch: {
		radiosChildrenSelected() {
			this.orderRadiosAlphabetically()
		}
	},
	mounted() {
		this.$parent.$on('showConsolidatePopup', (payload) => {
			this.setData(filters.$options.radiosConsolidated)
		})

		selectRadio.$on('selectRadio', (payload) => {
			this.clearDataAndClosePopup()
		})
	}
}
</script>

<style>
.radio-selected {
	padding: 2rem !important;
	background-color: #e3e3e3;
	width: 100%;
	padding: 20px;
	animation: carousel-toggle-animation 0.2s ease;
}

.radio-selected h5 {
	margin: 10px;
}

.remove-radio-compare {
	position: relative;
	left: 70%;
	width: 2rem;
	height: 2rem;
	border: none;
	border-radius: 50%;
	color: #FFFFFF;
	background-color: #db1e1e;
	cursor: pointer;
}
</style>