<template>
	<div>
		<md-dialog
			@md-opened="sendMixPanelEvent(`mobad_form_opened`)"
			@md-closed="sendMixPanelEvent(`mobad_form_closed`)"
			:md-active.sync="formOpened" :md-click-outside-to-close="false" @md-clicked-outside="closeModal">
			<md-dialog-title>{{
				editionMode ? "Editar MobAd" : "Novo MobAd"
			}}</md-dialog-title>
			<md-dialog-content>
				<md-steppers :md-active-step.sync="activeFormStep" md-alternative md-linear >
					<md-step id="first" md-label="Informações gerais">
						<div class="main-container">
							<div class="md-layout">
								<div
									class="md-layout-item md-xsmall-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
									<md-field :class="!this.mobAd.name && this.errorMissingField
										? 'md-error'
										: ''
										">
										<label>Nome do MobAd</label>
										<md-input v-model="mobAd.name"></md-input>
									</md-field>
									<span v-if="!this.mobAd.name && this.errorMissingField" class="error-span">Preencha
										este campo</span>
								</div>
								<div
									class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
									<el-date-picker
										ref="start-date-picker"
										v-model="mobAd.startDate" 
										@change="checkIfUserHadChangedInput"
										:picker-options="startPickerOptions"
										placeholder="Data de início" type="date" class="md-input"
										style="width:100% !important; margin: 10px 0;" format="dd/MM/yyyy"
										id="input_new_date_start">
									</el-date-picker>
									<span v-if="!this.mobAd.startDate && this.errorMissingField"
										class="error-span">Preencha este campo</span>
								</div>
								<div
									class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
									<el-date-picker
										ref="end-date-picker"
										:disabled="!mobAd.startDate"
										:picker-options="endPickerOptions"
										v-model="mobAd.endDate" 
										@change="checkIfUserHadChangedInput"
										placeholder="Data de término" type="date" class="md-input"
										style="width:100% !important; margin: 10px 0;" format="dd/MM/yyyy"
										id="input_new_date_end">
									</el-date-picker>
									<span v-if="!this.mobAd.endDate && this.errorMissingField"
										class="error-span">Preencha este campo</span>
								</div>
								<div class="md-layout-item md-size-100">
									<span v-if="this.errorInvalidDate" class="error-span">{{ this.errorInvalidDate }}</span>
								</div>
								<div
									class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
									<md-field :class="{
										'md-focused': this.mobAd.startTime,
										'md-error':
											!this.mobAd.startTime && this.errorMissingField,
									}">
										<md-icon>schedule</md-icon>
										<label>Hora de início</label>
										<mdtimepicker class="md-input" 
											:value="this.mobAd.startTime"
											:shown="setMDPickerLanguageToPortuguese" 
											:timeChanged="setStartTime">
										</mdtimepicker>
									</md-field>
									<span v-if="!this.mobAd.startTime && this.errorMissingField"
										class="error-span">Preencha este campo</span>
								</div>
								<div
									class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
									<md-field :class="{
										'md-focused': this.mobAd.endTime,
										'md-error':
											!this.mobAd.endTime && this.errorMissingField,
									}">
										<md-icon>schedule</md-icon>
										<label>Hora de término</label>
										<mdtimepicker class="md-input" :value="this.mobAd.endTime"
											:shown="setMDPickerLanguageToPortuguese" :timeChanged="setEndTime">
										</mdtimepicker>
									</md-field>
									<span v-if="!this.mobAd.endTime && this.errorMissingField"
										class="error-span">Preencha este campo</span>
								</div>
								<div class="md-layout-item md-size-100">
									<span v-if="this.errorInvalidTime" class="error-span">{{ this.errorInvalidTime }}</span>
								</div>
							</div>
						</div>
						<div class="md-layout md-alignment-center-space-between">
							<div
								class="md-layout-item hide-on-xsmall-screen md-small-size-20 md-medium-size-20 md-large-size-20 md-xlarge-size-15">
								<md-button @click="closeModal()" ref="cancel-mobad-button" class="md-danger">Cancelar</md-button>
							</div>
							<div
								class="md-layout-item md-xsmall-size-100 md-small-size-20 md-medium-size-20 md-large-size-20 md-xlarge-size-15">
								<md-button @click="validateGeneralInfo()" ref="next-step-button" class="md-info">Próximo</md-button>
							</div>
							<div
								class="md-layout-item show-on-xsmall-screen md-size-100">
								<md-button @click="closeModal()" class="md-danger">Cancelar</md-button>
							</div>
						</div>
					</md-step>
					<md-step id="second" md-label="Banners">
						<div style="margin-top: 20px; min-height: 350px" class="main-container">
							<div class="md-layout" style="margin: 0; padding: 0">
								<h5 v-if="errorBanners" style="color: red; margin: 10px; padding: 0"
									class="md-layout-item md-size-100">
									{{ errorBanners }}
								</h5>
								<div class="md-layout-item md-size-100" style="padding: 0; margin: 0">
									<div class="md-layout-item md-size-100" style="
											margin: 0;
											padding: 0;
										">
										<div class="md-layout md-alignment-top-center" style="padding: 0; margin: 0">
											<template v-for="banner in listOldBanners">
												<div class="md-layout-item md-size-100">
													<BannerCard
														:key="banner.hash"
														:bannerData="banner" :isOldBanner="true" 
														:id="banner.hash ? banner.hash : banner.id"
														:bannerZonesToSelect="listZones"
														:disableDelete="getTotalBanners() === 1"></BannerCard>
												</div>
											</template>
											<template v-for="banner in listNewBanners">
												<div class="md-layout-item md-size-100">
													<BannerCard
														:key="banner.id"
														:bannerData="banner" :id="banner.id"
														:bannerZonesToSelect="listZones"
														:disableDelete="getTotalBanners() === 1"></BannerCard>
												</div>
											</template>
											<md-button
												ref="add-banner-button"
												class="md-layout-item md-xsmall-size-100 md-small-size-35 md-medium-size-45 md-large-size-40 md-xlarge-size-40 md-success md-block"
												style="margin: auto; margin-bottom: 20px;" @click="addBannerToListBanner()">
											 	Adicionar novo banner
											</md-button>
										</div>
									</div>
									<div class="md-layout md-alignment-center-center"></div>
								</div>
							</div>
							<Spinner v-if="loadingBanners" />
						</div>
						<div class="md-layout md-alignment-center-space-between">
							<div
								class="md-layout-item hide-on-xsmall-screen md-small-size-20 md-medium-size-15 md-large-size-15 md-xlarge-size-15">
								<md-button @click="closeModal()" class="md-danger">
									Cancelar
								</md-button>
							</div>
							<div
								style="padding: 0;"
								class="md-layout-item md-xsmall-size-100 md-small-size-60 md-medium-size-60 md-large-size-45 md-xlarge-size-40">
								<div class="md-layout md-alignment-center-right">
									<div
										style="padding-left: 0;"
										class="md-layout-item md-xsmall-size-100 md-size-40">
										<md-button 
											@click="activeFormStep = 'first'" 
											class="md-outlined" 
											style="color: #1565c0 !important; border-color: #1565c0;">
											Anterior
										</md-button>
									</div>
									<div
										style="padding-left: 0;"
										class="md-layout-item md-xsmall-size-100 md-size-40">
										<md-button ref="create-mobad-button" v-if="!editionMode" @click="newMobAd()" class="md-info">
											Finalizar
										</md-button>
										<md-button 
											ref="update-mobad-button" 
											v-else @click="updateMobAd()" 
											class="md-info">
											Finalizar
										</md-button>
									</div>
								</div>
							</div>
							<div
								class="md-layout-item show-on-xsmall-screen md-size-100">
								<md-button @click="closeModal()" class="md-danger">
									Cancelar
								</md-button>
							</div>
						</div>
					</md-step>
				</md-steppers>
			</md-dialog-content>
		</md-dialog>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import Spinner from "../../../components/Icons/Spinner.vue";
import jwt_decode from "jwt-decode";
import apiSingleton from "../../../routes/apiSingleton";
import selectRadio from "../../../utils/events/select-radio.js";
import uploadCloudinary from "../../../utils/events/uploadCloudinary.js";
import BannerCard from "./BannerCard.vue";
import mixpanelAdapter from "../../../utils/events/mixpanelAdapter";

export default {
	name: "mobAdForm",
	components: {
		BannerCard,
		Spinner
	},
	data() {
		return {
			radio: jwt_decode(localStorage.getItem("APP_01")).radio,
			user: jwt_decode(localStorage.getItem("SET_APP")).user[0],
			activeFormStep: "first",
			editionMode: false,
			userChangedOriginalInfo: false,
			formOpened: false,
			mobAd: {
				id: null,
				name: "",
				deliveryLimit: "",
				startDate: "",
				endDate: "",
				startTime: "",
				endTime: "",
				active: 1
			},
			newBanner: {
				name: "",
				type: "",
				url: "",
				position: ""
			},
			loadingBanners: false,
			errorBanners: null,
			errorMissingField: false,
			errorInvalidDate: null,
			errorInvalidTime: null,
			listZones: [],
			listOldBanners: [],
			listNewBanners: [],
			fileImage: "",
			fileString: "",
			errors: [],
			fileMessageError: ""
		}
	},
	methods: {
		setMDPickerLanguageToPortuguese() {
			var buttonsCancel = document.getElementsByClassName(
				"mdtp__button cancel"
			);
			var buttonsConfirm = document.getElementsByClassName("mdtp__button ok");
			var buttonsClear = document.getElementsByClassName(
				"mdtp__button clear-btn"
			);

			Array.from(buttonsCancel).forEach(
				(button) => (button.innerHTML = "CANCELAR")
			);
			Array.from(buttonsConfirm).forEach(
				(button) => (button.innerHTML = "CONFIRMAR")
			);
			Array.from(buttonsClear).forEach(
				(button) => (button.innerHTML = "LIMPAR")
			);
		},
		setStartTime(newTime) {
			this.checkIfUserHadChangedInput()
			this.mobAd.startTime = newTime.value;
		},
		setEndTime(newTime) {
			this.checkIfUserHadChangedInput()
			this.mobAd.endTime = newTime.value;
		},
		checkIfUserHadChangedInput() {
			if (this.editionMode) {
				this.userChangedOriginalInfo = true
			}
		},
		validateGeneralInfo() {
			if (this.editionMode && !this.userChangedOriginalInfo) {
				this.activeFormStep = "second";
				return
			}
			this.errorMissingField = false;

			const allFieldsAreFilled =
				this.mobAd.name &&
				this.mobAd.startDate &&
				this.mobAd.endDate &&
				this.mobAd.startTime &&
				this.mobAd.endTime;

			if (!allFieldsAreFilled) {
				this.errorMissingField = true;
				return;
			}

			this.validateDates()
			this.validateHours()

			if (this.errorInvalidDate || this.errorInvalidTime) {
				return
			}

			this.activeFormStep = "second";
		},
		validateDates() {
			this.errorInvalidDate = null;

			const today = new Date(Date.now())
			const startDate = this.mobAd.startDate
			const endDate = this.mobAd.endDate

			today.setHours(0, 0, 0)
			startDate.setHours(0, 0, 0)
			endDate.setHours(0, 0, 0)
			today.setMilliseconds(0)
			startDate.setMilliseconds(0)
			endDate.setMilliseconds(0, 0, 0)

			if (startDate.getTime() < today.getTime()) {
				this.errorInvalidDate = "Não se pode iniciar umo MobAd anterior a hoje";
			}
			else if (startDate.getTime() > endDate.getTime()) {
				this.errorInvalidDate = "A data de término é anterior à data de início";
			}
		},

		validateHours() {
			this.errorInvalidTime = null;

			const today = new Date(Date.now());
			const currentTimeInSeconds = today.getHours() * 3600 + today.getMinutes() * 60

			const startDate = this.mobAd.startDate
			const endDate = this.mobAd.endDate

			const startHours = parseInt(this.mobAd.startTime.split(":")[0])
			const startMinutes = parseInt(this.mobAd.startTime.split(":")[1])
			const startTimeInSeconds = startHours * 3600 + startMinutes * 60

			const endHours = parseInt(this.mobAd.endTime.split(":")[0])
			const endMinutes = parseInt(this.mobAd.endTime.split(":")[1])
			const endTimeInSeconds = endHours * 3600 + endMinutes * 60

			startDate.setHours(0, 0, 0)
			endDate.setHours(0, 0, 0)
			today.setHours(0, 0, 0)

			startDate.setMilliseconds(0)
			endDate.setMilliseconds(0)
			today.setMilliseconds(0)

			if (
				startDate.getTime() === today.getTime() &&
				startTimeInSeconds < currentTimeInSeconds
			) {
				this.errorInvalidTime = "Não se pode criar umo MobAd com o horário de início anterior ao de agora"
			}
			if (
				startDate.getTime() === endDate.getTime() &&
				endTimeInSeconds < startTimeInSeconds
			) {
				this.errorInvalidTime = "Não se pode criar umo MobAd com o horário de término anterior ao de início"
			}
		},
		previousStep() {
			this.activeStep--;
		},
		closeModal() {
			Swal.fire({
				title: "Atenção",
				text: "Você está prestes a cancelar o preenchimento do formulário e todas as informações serão perdidas. Deseja continuar?",
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Sim",
				cancelButtonText: "Não",
			}).then((result) => {
				if (result.value) {
					this.clearFormMobAd();
					this.clearFormBanner();
					this.fileImage = "";
					this.fileString = "";
					this.listNewBanners = [];
					this.listOldBanners = [];
					this.formOpened = false;
				}
			});
		},
		getZones() {
			apiSingleton
				.get(`mobads/getZones?appKey=${this.radio.key}`)
				.then((response) => {
					this.listZones = response.data;
				})
				.catch((error) => console.log(error));
		},

		addBannerToListBanner() {
			const lastBanner = this.listNewBanners[this.listNewBanners.length - 1];
			this.listNewBanners.push({
				id: lastBanner ? lastBanner.id + 1 : 0,
				file: "",
				image: "",
				name: "",
				type: "",
				url: "",
				idZone: "",
				errorEmptyField: false,
			});

			this.fileImage = "";
			this.fileString = "";
			this.clearFormBanner();
		},
		removeBannerFromList(id) {
			const totalBanners = this.getTotalBanners();
			if (totalBanners === 1) {
				this.errorBanners =
					"O MobAd deve ter no mínimo um banner para ser cadastrado ou editado";
				return;
			}
			this.listNewBanners = this.listNewBanners.filter(
				(banner) => banner.id !== id
			);
		},

		showBannerPopup(banner) {
			Swal.fire({
				title: "Visualização de banner",
				html: `
					<h3>Nome: ${banner.name}</h3>
					<h5>${banner.position}</h5>
					<div style="background-color: #e3e3e3">
						<img src=${banner.image} width=300>
					</div>
				`,
				showCloseButton: true,
			});
		},
		clearFormMobAd() {
			this.errorMissingField = false;
			this.activeFormStep = 'first'
			this.errorInvalidDate = null
			this.errorInvalidTime = null
			this.mobAd.name = "";
			this.mobAd.deliveryLimit = "";
			this.mobAd.startDate = "";
			this.mobAd.endDate = "";
			this.mobAd.startTime = "";
			this.mobAd.endTime = "";
			this.mobAd.active = 1;
			this.fileImage = "";
			this.fileString = "";
		},
		clearFormBanner() {
			this.newBanner.name = "";
			this.newBanner.url = "";
			this.newBanner.position = "";
			this.fileImage = "";
			this.fileString = "";
			this.$root.$emit("clearUploadImage");
			this.$root.$emit("clearFileImage");
		},

		getTotalBanners() {
			const totalBanners = this.listNewBanners.length + this.listOldBanners.length;
			return totalBanners;
		},

		validateNewBanners() {
			let allBannersAreValid = true;
			for (let banner of [...this.listNewBanners, ...this.listOldBanners]) {
				if (
					this.checkIfExistsEmptyBannerField(banner) &&
					!this.checkIfAllBannerFieldsAreEmpty(banner)
				) {
					banner.errorEmptyField = true;
					allBannersAreValid = false;
				}
			}
			return allBannersAreValid;
		},

		checkIfAllBannersAreEmpty() {
			let allBannersAreEmpty = true;
			for (let banner of [...this.listNewBanners, ...this.listOldBanners]) {
				if (!this.checkIfAllBannerFieldsAreEmpty(banner)) {
					allBannersAreEmpty = false;
					break;
				}
			}
			return allBannersAreEmpty;
		},
		newMobAd() {
			if (this.checkIfAllBannersAreEmpty()) {
				this.errorBanners =
					"É necessário preencher pelo menos um banner para que o MobAd seja cadastrado.";
				return;
			}
			if (!this.validateNewBanners()) {
				this.errorBanners =
					"Existe algum banner com dados não preenchidos. Verifique antes de prosseguir.";
				return;
			}
			const totalBanners = this.getTotalBanners();
			if (totalBanners === 0) {
				this.errorBanners =
					"O MobAd deve ter no mínimo um banner para ser cadastrado ou editado";
				return;
			}
			this.formOpened = false;
			this.loadRequest();
			this.mobAd.appKey = this.radio.key;

			const mobAdStartDate = this.prepareDateToMySQL(this.mobAd.startDate).date
			const mobAdEndDate = this.prepareDateToMySQL(this.mobAd.endDate).date

			let formData = {
				appKey: this.radio.key,
				name: this.mobAd.name,
				deliveryLimit: this.mobAd.deliveryLimit
					? this.mobAd.deliveryLimit
					: 0,
				startDate: mobAdStartDate,
				endDate: mobAdEndDate,
				startTime: this.mobAd.startTime,
				endTime: this.mobAd.endTime,
				active: 1
			};
			apiSingleton
				.post("mobads/newMobAd", formData)
				.then((mobAd) => {
					this.handleBanners(mobAd.data.last_id).then(()=>{
						const mobAdStartDate = this.mobAd.startDate
						const mobAdEndDate = this.mobAd.endDate
						this.clearFormBanner()
						this.clearFormMobAd()
						this.listNewBanners = [];
						this.listOldBanners = [];
						this.sendMixPanelEvent('mobad_created')
						this.$root.$emit("reloadMobAd", { id: mobAd.data.last_id, start_date: mobAdStartDate, end_date: mobAdEndDate })
					})
				})
				.catch((error) => console.log(error));
		},
		updateMobAd() {
			if (this.checkIfAllBannersAreEmpty()) {
				this.errorBanners =
					"É necessário preencher pelo menos um banner para que o MobAd seja editado.";
				return;
			}

			if (!this.validateNewBanners()) {
				this.errorBanners =
					"Existe algum banner com dados não preenchidos. Verifique antes de prosseguir.";
				return;
			}

			const totalBanners = this.getTotalBanners();
			if (totalBanners === 0) {
				this.errorBanners =
					"O MobAd deve ter no mínimo um banner para ser cadastrado ou editado";
				return;
			}

			this.formOpened = false;
			this.loadRequest();
			this.mobAd.appKey = this.radio.key;
			let formData = {
				name: this.mobAd.name,
				deliveryLimit: this.mobAd.deliveryLimit
					? this.mobAd.deliveryLimit
					: 0,
				startDate: this.prepareDateToMySQL(this.mobAd.startDate).date,
				endDate: this.prepareDateToMySQL(this.mobAd.endDate).date,
				startTime: this.mobAd.startTime,
				endTime: this.mobAd.endTime,
				active: 1,
				id: this.mobAd.id,
			};
			apiSingleton
				.post("mobads/updateMobAd", formData)
				.then(() => {
					const mobAdStartDate = this.mobAd.startDate
					const mobAdEndDate = this.mobAd.endDate
					this.handleBanners(this.mobAd.id).then(()=>{
						this.formOpened = false;
						this.clearFormMobAd();
						this.listOldBanners = [];
						this.listNewBanners = [];
						this.$root.$emit("reloadMobAd", { id: this.mobAd.id, start_date: mobAdStartDate, end_date: mobAdEndDate});
						this.sendMixPanelEvent('mobad_updated')
						this.closeRequest();
					})
				})
				.catch((error) => {
					console.log(error);
					Swal({
						title: "Ocorreu um erro",
						html: "Não foi possível editar o MobAd.",
						type: "error",
					});
				});
		},
		setBannerToUpdate(payload) {
			let banner = this.listOldBanners.find(b => b.hash === payload.hash);
			banner.isUpdated = true
		},
		async updateBanners() {
			const bannersToUpdate = this.listOldBanners.filter(banner => banner.isUpdated === true)

			bannersToUpdate.forEach(async banner => {
				let url_image

				if (banner.file) {
					try {
						url_image = (await uploadCloudinary(this.radio.key, banner.file)).secure_url;
					}catch {
						return
					}
				}else{
					url_image = banner.image
				}

				const {hash, name, idZone, url} = banner
				await apiSingleton.post('/mobads/updateBanner', {
					hash,
					name,
					url_image,
					zone_id: idZone,
					url_click: url
				})
			})
		},
		async handleBanners(idMobAd) {
			for (let item of this.listNewBanners) {
				const zone = item.idZone;

				let url_image = null;
				if (item.file) {
					try {
						url_image = (await uploadCloudinary(this.radio.key, item.file))
							.secure_url;
					} catch {
						this.closeRequest();
						return;
					}
				}

				await this.createNewBanner(
					idMobAd,
					1,
					zone,
					item.name,
					url_image,
					item.url
				)
					.catch((error) => {
						console.log(error);
					});
			}
			await this.updateBanners();

			this.clearFormMobAd();
			this.clearFormBanner();

			this.listNewBanners = [];
			this.listOldBanners = [];

			Swal.close();
		},
		async createNewBanner(
			idMobAd,
			idBannerType,
			idZone,
			name,
			urlImage,
			urlClickImage
		) {
			let formData = {
				idMobAd: idMobAd,
				idBannerType: idBannerType,
				idZone: idZone,
				name: name,
				urlImage: urlImage,
				urlImageClick: urlClickImage,
			};

			return apiSingleton.post("mobads/newBanner", formData);
		},
		deleteOldBanner(banner) {
			const totalBanners = this.getTotalBanners();
			if (totalBanners === 1) {
				this.errorBanners =
					"O MobAd deve ter no mínimo um banner para ser cadastrado ou editado. Se deseja substituir este banner, adicione outro primeiro";
				return;
			}
			apiSingleton
				.post("mobads/deleteBanner", { hash: banner.hash })
				.then((response) => {
					let index = this.listOldBanners.findIndex(b => b.hash === banner.hash);
					this.listOldBanners.splice(index, 1);
					this.userActionLog(banner, 2);
					return response.data;
				})
				.catch(() => {
					Swal({
						title: "Ops!",
						html: "Houve um erro ao deletar este banner de campanha",
						type: "error",
					});
					banner.deleting = false;
				});
		},
		getBanner(idMobAd) {
			this.loadingBanners = false;
			apiSingleton
				.get(`mobads/getBanners?idMobAd=${idMobAd}`)
				.then((banners) => {
					for (let item of banners.data) {
						this.listOldBanners.push({
							idBanner: item.id,
							idMobAd: item.mobAd_id,
							idBannerType: item.banner_type_id,
							idZone: item.zone_id,
							image: item.url_image,
							name: item.name,
							type: item.type,
							url: item.url_image_click,
							position: item.zone,
							hash: item.hash ? item.hash : "",
							isUpdated: false,
							errorEmptyField: false
						});
					}
					this.loadingBanners = false;
				})
				.catch((err) => {
					console.log(err);
					Swal(
						"Erro ao buscar banners",
						"Houve um erro ao recuperar os banners. Caso o erro persista, entre em contato com o suporte",
						"error"
					);
					this.formOpened = false;
					this.clearFormMobAd();
					this.clearFormBanner();
				});
		},
		checkIfExistsEmptyBannerField(banner) {
			return (!banner.file && !banner.image) || !banner.name || !banner.url || !banner.idZone;
		},
		checkIfAllBannerFieldsAreEmpty(banner) {
			return (!banner.file || !banner.image) && !banner.name && !banner.url && !banner.idZone;
		},
		loadRequest() {
			Swal({
				title: "Aguarde",
				html: "Estamos cadastrando as informações do MobAd...",
				type: "info",
				onOpen: () => {
					Swal.showLoading();
				},
			});
		},
		closeRequest() {
			Swal.close();
		},
		userActionLog(actionToLog, type) {
			const json_log = {
				radio_key: this.radio.key,
				user_id: this.user.id,
				user_username: this.user.username,
				user_first_name: this.user.firstname,
				user_lastname: this.user.lastname,
				user_email: this.user.email,
				radio_data: JSON.stringify(actionToLog).replace(/['"]+/g, "'"),
				type: type,
				user_agent: navigator.userAgent,
			};
			apiSingleton.post("radios/userActionLog", json_log);
		},
		prepareDateToMySQL(dateString) {

			let dt = dateString;
			const auxStart =
				dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
			return { date: auxStart };
		},
		changeBannerArea() {
			this.newBanner.type = "";
			this.fileMessageError = "";
		},
		sendMixPanelEvent(eventString, params){
			mixpanelAdapter.track(eventString, {
				...params,
				screen_name: this.$route.name
			})
		}
	},
	computed: {
		startPickerOptions() {
			if(!this.mobAd.startDate){
				this.mobAd.endDate = null
			}
			return {
				disabledDate: time => {
					const today = new Date()
					today.setHours(0,0,0)
					today.setMilliseconds(0)

					return time.getTime() < today.getTime();
				},
			};
		},
		endPickerOptions() {
			return {
				disabledDate: time => {
					const startDate = this.mobAd.startDate

					if(!startDate){
						return time
					}
					startDate.setHours(0,0,0)
					startDate.setMilliseconds(0)

					return time.getTime() < startDate.getTime();
				},
			};
		}
	},
	mounted() {
		selectRadio.$on("selectRadio", () => {
			this.radio = jwt_decode(localStorage.getItem("APP_01")).radio;
			this.getZones();
		});
		this.getZones();
		this.$root.$on("imageDeleted", () => {
			this.fileImage = "";
			this.fileString = "";
		});

		this.$root.$on("openNewMobAd", () => {
			this.activeStep = 0;
			this.editionMode = false;
			this.formOpened = true;
			this.errorBanners = null;
			this.listNewBanners.push({
				file: "",
				id: 0,
				url_image: "",
				name: "",
				idZone: "",
				type: "",
				url: "",
				errorEmptyField: false,
			});
			this.userChangedOriginalInfo = false
		});
		this.$root.$on("openEditMobAd", (payload) => {
			this.activeStep = 0;
			this.editionMode = true;
			this.getBanner(payload.id);
			this.mobAd.id = payload.id;
			this.mobAd.name = payload.name;
			this.mobAd.deliveryLimit = payload.deliveryLimit
				? payload.deliveryLimit
				: 0;
			this.mobAd.startDate = new Date(payload.start_date);
			this.mobAd.endDate = new Date(payload.end_date);
			this.mobAd.startTime = payload.start_time.substring(0, 5);
			this.mobAd.endTime = payload.end_time.substring(0, 5);
			this.formOpened = true;
			this.errorBanners = null;
			this.blockFields = false;
			this.userChangedOriginalInfo = false
		});

		this.$root.$on("fileImage", (string, file) => {
			this.fileString = string;
			this.fileImage = file[0];
			this.fileMessageError = "";
		});

		this.$root.$on("clearFileImage", () => {
			this.fileImage = "";
		});

		this.$root.$on("fileSizeUpload", (payload) => {
			if (payload.type === 0) {
				this.fileMessageError = "A imagem deve ter um tamanho máximo de 150kb";
				this.fileImage = "";
			} else if (payload.type === 1 && payload.dimensions !== "xundefined") {
				this.fileMessageError = `As dimensões pra esse tipo de imagem devem estar entre ${payload.dimensions} pixels`;
				this.fileImage = "";
			} else if (payload.type === 1) {
				this.fileMessageError = `Selecione a área do dispositivo`;
				this.fileImage = "";
			} else {
				this.fileMessageError = "";
			}
		});

		this.$root.$on("removeBannerFromList", (payload) => {
			this.removeBannerFromList(payload.id);
		});

		this.$root.$on("updateBannerName", (payload) => {
			this.listNewBanners.filter((banner) => banner.id === payload.id)[0].name =
				payload.bannerName;
		});

		this.$root.$on("updateBannerURL", (payload) => {
			this.listNewBanners.filter((banner) => banner.id === payload.id)[0].url =
				payload.bannerURL;
		});

		this.$root.$on("updateBannerZoneId", (payload) => {
			this.listNewBanners.filter(
				(banner) => banner.id === payload.id
			)[0].position = payload.bannerZoneId;
		});

		this.$root.$on("updateBannerImage", (payload) => {
			this.listNewBanners.filter((banner) => banner.id === payload.id)[0].file =
				payload.file;
		});

		this.$root.$on("deleteOldBanner", (payload) => {
			this.deleteOldBanner(payload);
		});

		this.$root.$on("setBannerToUpdate", (payload) => {
			this.setBannerToUpdate(payload)
		})
	}
};
</script>

<style>
.md-outlined {
	border: 1px solid;
	background-color: #ffff !important;
	font-weight: 600 !important;
	box-shadow: none;
}

.md-outlined:hover{
	background: #fffb !important;
}

.md-steppers-navigation,
.md-steppers-navigation .md-button {
	box-shadow: none;
}

.md-steppers-navigation .md-ripple {
	background-color: #fff;
}

.md-stepper-number {
	background-color: #1565c0 !important;
}

button[disabled="disabled"] .md-stepper-number {
	background-color: #0000008a !important;
}

.md-field.md-focused .md-icon {
	color: #1565c0 !important;
}

.md-dialog {
	max-width: 1200px;
	margin: auto;
}

.error-span {
	font-size: 10px;
	color: #f44336;
}

.url-redirect-box {
	white-space: nowrap;
	width: 180px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.banner-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	column-gap: 10px;
	row-gap: 5px;
	margin-bottom: 2rem;
}

.thead-banners {
	display: block;
	text-align: left;
}

.tbody-banners {
	display: block;
	width: 100% !important;
	overflow-y: scroll;
	height: 250px;
}

.table-banners {
	margin-top: 100px;
}

.table-banners th,
.table-banners td {
	padding: 5px;
	width: 36%;
}

@media (max-width: 1919px){
	.main-container { 
		min-height: 200px;
	}
}

@media (min-width: 1919px){
	.main-container { 
		min-height: 350px
	}
}
@media (min-width: 599px) {
	.show-on-xsmall-screen {
		display: none;
	}

	.hide-on-xsmall-screen {
		display: block;
	}
}

@media (max-width: 599px) {
	.show-on-xsmall-screen {
		display: block;
	}

	.hide-on-xsmall-screen {
		display: none;
	}
}
</style>