<template>
    <md-toolbar v-if="$route.name" md-elevation="0" class="md-transparent" :class="{'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute}">
        <div class="md-toolbar-row">
            <div class="md-toolbar-section-start">
                <h2>{{$route.name}} </h2>
            </div>
            <div class="md-toolbar-section-end">
                <md-button class="md-just-icon md-round md-simple md-toolbar-toggle" :class="{toggled: $sidebar.showSidebar}" @click="toggleSidebar">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </md-button>

            </div>
        </div>
        <div v-if="msgAlertPagarMe" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 statusPagarMe print-hide">
            <span class="pagar-link" v-html="msgAlertPagarMe"></span>
        </div>
    </md-toolbar>
    <md-toolbar  md-elevation="0" class="md-transparent" v-else>
        <div class="md-toolbar-row">
            <div class="md-toolbar-section-start">
                <div class="md-layout">
                    <div class="md-layout-item md-size-20" style="padding-left: 0; padding-top: 1rem">
                        <a @click="$router.go(-1)" style="cursor: pointer">
                            <h5 style="display: inline-block;">Voltar para a página anterior</h5>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </md-toolbar>
</template>

<script>
    export default {
        data() {
            return {
                statusPagarMe: JSON.parse(localStorage.getItem('statusPagarMe')),
                msgAlertPagarMe: ''
            }
        },
        methods: {
            logout() {
                sessionStorage.clear()
                this.$session.destroy()
                this.$router.push('login')
            },
            goBack(){
                this.$router.go(-1)
            },
            toggleSidebar() {
                this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
            },
            minimizeSidebar() {
                if (this.$sidebar) {
                    this.$sidebar.toggleMinimize()
                }
            },
            checkPagarMe() {
                if(this.statusPagarMe) {
                    if(this.statusPagarMe.status === false) {
                        this.msgAlertPagarMe = `<b>Atenção - </b>
                                                Seu <a href="/my-plan/plans">plano <i class="material-icons">open_in_new</i></a> está no período de vigência que vai até <b>${this.statusPagarMe.data}</b>. Após esse período você voltará para as funcionalidades básicas.`
                    }
                }
            }
        },
        mounted() {
            this.checkPagarMe()
        }
    }
</script>

<style>
    .statusPagarMe {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid gold;
        border-radius: 10px;
        background-color: gold;
    }

    .alter-size{
        width: 10rem;
    }

    .pagar-link>a {
        color: black !important
    }
    .pagar-link>a:visited {
        color: black
    }
    .pagar-link>a>i {
        font-size: 20px;
    }

    @media (min-width: 960px) {
        .statusPagarMe {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            border: 1px solid gold;
            border-radius: 10px;
            background-color: gold;
        }
    }
</style>
