import axios from 'axios';

let serverBaseURL = ""

if (process.env.NODE_ENV === "development" || 
    process.env.NODE_ENV === "test"){
    serverBaseURL = "http://localhost:3030"
}else if( process.env.NODE_ENV === "homolog") {
    serverBaseURL = "https://nodejs-server-homolog-kk43m3jaoq-uc.a.run.app"

} else if (process.env.NODE_ENV === "stage") {
    serverBaseURL = "https://server.stage.mobradio.com.br"

} else {
    serverBaseURL = "https://server.mobradio.com.br"
}

function clearRadioCache(id, key) {
    MasterRadio(key);
    ParentRadio(id);
}

function MasterRadio(key) {
    if(key) {
        let formData = {
            token: key,
            service: "getRadioJson"
        }
        axios.post(`${serverBaseURL}/brokers/delete-cache/`, formData)
        .then(() => {
            console.log("Clear getRadioJson");

            let formData = {
                token: key,
                service: "getStreamPlatformsBlocked"
            }

            axios.post(`${serverBaseURL}/brokers/delete-cache/`, formData)
            .then(() => {
                console.log("Clear getStreamPlatformsBlocked");
            }).catch((error) => {
                console.log("Error clear cache");
                return error;
            })
        })
        .catch((error) => {
            console.log("Error clear cache");
            return error;
        })
    }
}

function ParentRadio(id) {
    if(id) {
        axios.get(`${serverBaseURL}/brokers/clearRadioCache/` + id)
        .then((resp) => {
            console.log("Clear All Cache");
            return resp.data;
        })
        .catch((error) => {
            console.log("Error clear cache");
            return error;
        });
    }
}

export default clearRadioCache;