import models from '../../routes/models'

function destroyImageFromCloudinary(url) {
    try {
        const public_id = url.split("/")[9].split(".")[0]
        const data = {
            cloud_name: this.radio.cloud_name,
            cloud_key: this.radio.cloud_key,
            cloud_secret: this.radio.cloud_secret,
            key: this.radio.key,
            public_id: `${public_id}`
        }
        models().post('services/destroyImage', data)
            .then(() => console.log("Imagem apagada do cloudinary"))
            .catch(() => console.log("Falha ao remover imagem do cloudinary"))
    } catch (error) {
        console.log("Não foi possível gerar o id publico para remover a imagem")
    }
}

export default destroyImageFromCloudinary