var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    this.switch === 1 &&
    this.expiration_date >
      new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
      ? _c("div", [_c("PopupInfo")], 1)
      : this.switch === 2 &&
        this.expiration_date >
          new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
      ? _c("div", [_c("PopupSuccess")], 1)
      : this.switch === 3 &&
        this.expiration_date >
          new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
      ? _c("div", [_c("PopupWarning")], 1)
      : this.switch === 4 &&
        this.expiration_date >
          new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
      ? _c("div", [_c("PopupDanger")], 1)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }