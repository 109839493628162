<template>
	<div class="md-layout">
		<div v-show="blockStatus == 1" class="md-layout-item md-small-size-100">
			<FilterSelector
				@searchData="musicPlayed()"
				:filterForReport="false"
				:buttonsToDisplay="[
					buttonEnum.TODAY,
					buttonEnum.SEVEN_DAYS,
					buttonEnum.MONTH_TO_DATE,
					buttonEnum.CUSTOM,
				]"
			>
				<template #filters>
					<div class="md-layout">
						<h3
							class="md-layout-item md-size-100"
							style="
								font-size: 1rem;
								text-align: left;
								margin: 25px 0 10px 0;
								padding: 0;
								font-weight: 100;
							"
						>
							Digite sua busca
						</h3>
						<el-input
							class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-20 md-xlarge-size-20"
							style="margin: 0; padding: 0"
							label="Busca"
							type="text"
							v-model="searchTitle"
						>
						</el-input>
					</div>
				</template>
			</FilterSelector>
		</div>
		<div
			v-show="loading"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
		>
			<Spinner />
		</div>
		<div
			v-show="!loading"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
		>
			<md-card v-if="tableData.length > 0">
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						class="paginated-table table-striped table-hover"
					>
						<md-table-toolbar>
							<md-field>
								<label for="pages">Por página</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item"
									>
										{{ item }}
									</md-option>
								</md-select>
							</md-field>
						</md-table-toolbar>

						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell md-label="Título">{{ item.title }}</md-table-cell>
							<md-table-cell md-label="Artista">{{
								item.artist
							}}</md-table-cell>
							<!-- <md-table-cell md-label="Álbum">{{ item.album }}</md-table-cell> -->
							<md-table-cell md-label="Total" md-sort-by="total">{{
								item.total
							}}</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ from + 1 }} à {{ to }} de {{ total }} itens
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total"
					>
					</pagination>
				</md-card-actions>
			</md-card>
			<md-card style="text-align: center" v-show="msgError">
				<md-card-content>
					<label for="">Sem informações pra serem exibidas.</label>
				</md-card-content>
			</md-card>
		</div>
		<block v-show="blockStatus == 0"></block>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { Pagination } from "@/components";
import Fuse from "fuse.js";
import models from "../../routes/models.js";
import selectRadio from "../../utils/events/select-radio.js";

import FilterSelector from "./Components/FilterSelector/FilterSelector.vue";
import filters from "./Components/FilterSelector/filters";
import buttonEnum from "./Components/FilterSelector/buttonEnum";
import Spinner from "../../components/Icons/Spinner.vue";

import block from "./Block.vue";
import jwt_decode from "jwt-decode";
import { DatePicker } from "element-ui";
export default {
	components: {
		Pagination,
		block,
		[DatePicker.name]: DatePicker,
		Spinner,
		FilterSelector,
	},
	computed: {
		queriedData() {
			let result = this.tableData;
			if (this.searchedData.length > 0) {
				result = this.searchedData;
			}
			return result.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0
				? this.searchedData.length
				: this.tableData.length;
		},
	},
	data() {
		return {
			buttonEnum: buttonEnum,
			searchTitle: "",
			time1: null,
			time2: null,
			appKey: jwt_decode(localStorage.getItem("APP_01")).radio.key,
			currentSort: "total",
			currentSortOrder: "desc",
			pagination: {
				perPage: 50,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50],
				total: 0,
			},
			searchQuery: "",
			propsToSearch: ["Título", "artist", "age"],
			tableData: [],
			searchedData: [],
			fuseSearch: null,
			widthPicker: 100 + "%",
			loading: false,
			blockStatus: 1,
			disabledSearch: true,
			msgError: false,
			timerSearch: null,
		};
	},
	methods: {
		getMusicPlayed() {
			// let dateStart = new Date()
			// let dateEnd = new Date()
			// dateStart.setDate(dateStart.getDate()-30)
			// this.time1 = dateStart.getFullYear() + '-' + (dateStart.getMonth()+1) + '-' + dateStart.getDate() + ' 00:00:00'
			// this.time2 = dateEnd.getFullYear() + '-' + (dateEnd.getMonth()+1) + '-' + dateEnd.getDate() + ' 23:59:59'

			this.musicPlayed();
		},
		async musicPlayed() {
			this.loading = true;
			const formatDateStart = new Date(filters.startDate);
			const formatDateEnd = new Date(filters.endDate);

			let start =
				formatDateStart.getFullYear() +
				"-" +
				(formatDateStart.getMonth() + 1) +
				"-" +
				formatDateStart.getDate() +
				" 00:00:00";
			let end =
				formatDateEnd.getFullYear() +
				"-" +
				(formatDateEnd.getMonth() + 1) +
				"-" +
				formatDateEnd.getDate() +
				" 23:59:59";

			await models()
				.get(
					`music/getMusicPlayed?appKey=${this.appKey}&search=${
						this.searchTitle ? this.searchTitle : ""
					}&startDate=${start}&endDate=${end}&offset=0&rows=999999999`
				)
				.then((resp) => {
					if (resp.data.length == 0) {
						this.msgError = true;
					} else {
						this.msgError = false;
					}
					this.tableData = resp.data;
					this.loading = false;
				})
				.catch((e) => {
					Swal(
						"Erro",
						"Tivemos uma falha ao buscar as músicas tocadas, por favor tente novamente.",
						"error"
					);
					console.log(e);
				});
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return b[sortBy] - a[sortBy];
				}
				return a[sortBy] - b[sortBy];
			});
		},
		blockPage() {
			const menu = jwt_decode(localStorage.getItem("APP_02")).services;
			let service = null;
			for (let obj of menu) {
				if (obj.subitens === null) {
					const key = Object.keys(obj.item)[
						Object.values(obj.item).indexOf(this.$route.path.replace("/", ""))
					];
					if (key) {
						service = obj.item;
					}
				} else {
					const key = Object.keys(obj.subitens)[
						Object.values(obj.subitens).indexOf(
							this.$route.path.replace("/", "")
						)
					];
					if (key) {
						service = obj.subitens;
					}
				}
			}

			if (service) {
				this.blockStatus = service.has_permission;
			} else {
				this.$router.push("dashboard");
			}
		},
		findObjectByKey(array, key, value) {
			for (var i = 0; i < array.length; i++) {
				if (array[i][key] === value) {
					return array[i];
				}
			}
			return null;
		},
		enterClicked() {
			if (this.disabledSearch == false) {
				this.getMusicPlayed();
			}
		},
	},
	mounted() {
		if (jwt_decode(localStorage.getItem("APP_01")).radio) {
			this.appKey = jwt_decode(localStorage.getItem("APP_01")).radio.key;
			this.blockPage();
		}
		selectRadio.$on("selectRadio", (payload) => {
			this.appKey = payload.key;
			this.blockPage();
			this.musicPlayed();
		});
		this.fuseSearch = new Fuse(this.tableData, {
			keys: ["title", "artist"],
			threshold: 0.3,
		});
		document.addEventListener("keyup", function (e) {
			if (e.keyCode == 13) {
				if (this.disabledSearch == false) {
					this.getMusicPlayed();
				}
			}
		});
	},
	watch: {
		searchQuery(value) {
			let result = this.tableData;
			if (value !== "") {
				result = this.fuseSearch.search(this.searchQuery);
			}
			this.searchedData = result;
		},
		time1: function () {
			if (this.time1 !== null && this.time2 !== null) {
				this.disabledSearch = false;
			} else {
				this.disabledSearch = true;
			}
		},
		time2: function () {
			if (this.time1 !== null && this.time2 !== null) {
				this.disabledSearch = false;
			} else {
				this.disabledSearch = true;
			}
		},
		searchTitle() {
			clearTimeout(this.timerSearch);

			this.timerSearch = setTimeout(() => {
				this.musicPlayed();
			}, 1000);
		},
	},
};
</script>
<style scoped></style>
