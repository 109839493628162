<template>
	<div>
		<div>
			<!-- PAINEL DE BUSCAS -->
			<div
				class="md-layout-item md-size-100"
				style="margin: 0 !important; padding: 0 !important"
			>
				<FilterSelector
					:filterForReport="false"
					:buttonsToDisplay="[
						buttonEnum.TODAY,
						buttonEnum.SEVEN_DAYS,
						buttonEnum.MONTH_TO_DATE,
						buttonEnum.CUSTOM,
					]"
					@searchData="searchGifts()"
					@extraFunction="newGift()"
					:extraFunction="true"
					:extraFunctionLabel="'Novo'"
				>
				</FilterSelector>
			</div>
			<div class="md-layout-item md-size-100" v-show="this.loading">
				<Spinner />
			</div>

			<template v-show="!this.loading">
				<!-- PAINEL DE RESULTADOS -->
				<md-card v-if="tableGifts.length > 0">
					<md-card-header class="md-card-header-icon md-card-header-green">
						<div class="card-icon">
							<md-icon>assignment</md-icon>
						</div>
						<div class="legend" style="text-align: right">
							<b>Legenda:</b>
							<i style="color: #ef6b6c" class="fas fa-circle"></i>Expirado
							<i style="color: #4baf51" class="fas fa-circle"></i>Aberto
							<i style="color: #469ddb" class="fas fa-circle"></i>Finalizado
							<i style="color: #c9cacb" class="fas fa-circle"></i>Próximos
						</div>
					</md-card-header>
					<md-card-content style="padding-bottom: 0">
						<md-table
							:value="queriedData"
							class="paginated-table table-striped table-hover"
						>
							<md-table-toolbar>
								<md-field>
									<label for="pages">Por página</label>
									<md-select v-model="pagination.perPage" name="pages">
										<md-option
											v-for="item in pagination.perPageOptions"
											:key="item"
											:label="item"
											:value="item"
										>
											{{ item }}
										</md-option>
									</md-select>
								</md-field>
							</md-table-toolbar>

							<md-table-row
								slot="md-table-row"
								slot-scope="{ item }"
								:style="
									item.current == 1 ? 'background-color: lightyellow;' : ''
								"
							>
								<md-table-cell
									md-label=""
									v-if="item.is_closed == 1"
									style="width: 5px; color: #469ddb"
									><i class="fas fa-circle"></i
								></md-table-cell>
								<md-table-cell
									md-label=""
									v-else-if="item.expired == 1"
									style="width: 5px; color: #ef6b6c !important"
									><i class="fas fa-circle"></i
								></md-table-cell>
								<md-table-cell
									md-label=""
									v-else-if="item.current == 1"
									style="width: 5px; color: #4baf51"
									><i class="fas fa-circle"></i
								></md-table-cell>
								<md-table-cell
									md-label=""
									v-else
									style="width: 5px; color: #c9cacb !important"
									><i class="fas fa-circle"></i
								></md-table-cell>
								<md-table-cell md-label="" style="width: 25px">
									<img
										:src="item.image_url"
										style="width: 40px; height: 40px; border-radius: 5px"
									/>
								</md-table-cell>
								<md-table-cell md-label="Título" md-sort-by="name">
									{{ item.name }}</md-table-cell
								>
								<md-table-cell md-label="Descrição">{{
									item.description
								}}</md-table-cell>
								<template>
									<md-table-cell md-label="Período">{{
										item.date
									}}</md-table-cell>
								</template>

								<md-table-cell md-label="Ações">
									<div class="container-btn-edit">
										<div
											class="row-btn-edit-general"
											v-if="user.level === 0 || (item.is_closed == 0 && item.opened == 1)"
										>
											<el-tooltip
												effect="dark"
												content="Editar promoção"
												placement="top"
											>
												<el-button
													icon="el-icon-edit"
													circle
													@click="openGift(item.id)"
												></el-button>
											</el-tooltip>
										</div>
										<div
											class="row-btn-edit-general"
											v-if="item.is_closed == 0 && item.current == 1"
										>
											<el-tooltip
												effect="dark"
												content="Visualizar"
												placement="top"
											>
												<el-button
													icon="el-icon-view"
													circle
													@click="openGiftView(item.id)"
												></el-button>
											</el-tooltip>
										</div>
										<div
											class="row-btn-edit-general"
											v-if="item.is_closed == 0 && item.expired == 1"
										>
											<el-tooltip
												effect="dark"
												content="Visualizar"
												placement="top"
											>
												<el-button
													icon="el-icon-view"
													circle
													@click="openGiftView(item.id)"
												></el-button>
											</el-tooltip>
										</div>
										<div
											class="row-btn-edit-general"
											v-if="item.is_closed == 1"
										>
											<el-tooltip
												effect="dark"
												content="Visualizar"
												placement="top"
											>
												<el-button
													icon="el-icon-view"
													circle
													@click="openGiftView(item.id)"
												></el-button>
											</el-tooltip>
										</div>
										<div class="row-btn-edit-general">
											<el-tooltip
												effect="dark"
												content="Ver participantes"
												placement="top"
											>
												<el-button circle @click="openSort(item.id)">
													<i
														class="fa fa-users"
														style="width: 100%; height: 100%"
													></i>
												</el-button>
											</el-tooltip>
										</div>
										<div
											class="row-btn-edit-general"
											v-if="user.level === 0 || (item.is_closed == 0 && item.opened == 1)"
										>
											<el-tooltip
												effect="dark"
												content="Deletar promoção"
												placement="top"
											>
												<el-button
													icon="el-icon-delete"
													circle
													@click="deleteGift(item.id, item.image_url)"
												></el-button>
											</el-tooltip>
										</div>
									</div>
								</md-table-cell>
							</md-table-row>
						</md-table>
					</md-card-content>
					<md-card-actions md-alignment="space-between">
						<div class="">
							<p class="card-category">
								{{ from + pageStart }} à {{ to }} de {{ total }} itens
							</p>
						</div>
						<pagination
							class="pagination-no-border pagination-success"
							v-model="pagination.currentPage"
							:per-page="pagination.perPage"
							:total="total"
						>
						</pagination>
					</md-card-actions>
				</md-card>

				<!-- PAINEL DE RESULTADOS VAZIOS -->
				<md-card style="text-align: center" v-show="msgError && !this.loading">
					<md-card-content>
						<label for="">Sem informações pra serem exibidas.</label>
					</md-card-content>
				</md-card>
			</template>

			<!--<Edit />-->
			<GiftForm :promoType="promoType" />
			<TableOfParticipants />
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import models from "../../../routes/models";
import { Button, DatePicker, Tooltip } from "element-ui";
import { Pagination } from "@/components";
import selectRadio from "../../../utils/events/select-radio";
import jwt_decode from "jwt-decode";
import GiftForm from "./GiftForm.vue";
import Spinner from "../../../components/Icons/Spinner.vue";
import TableOfParticipants from "./TableOfParticipants.vue";
import buttonEnum from "../Components/FilterSelector/buttonEnum";
import filters from "../Components/FilterSelector/filters.js";
import FilterSelector from "../Components/FilterSelector/FilterSelector.vue";
import mixpanelAdapter from "../../../utils/events/mixpanelAdapter";
import moment from "moment-timezone";

import destroyImageFromCloudinary from "../../../utils/events/destroyImageFromCloudinary";

async function deletePromo(id, url) {
	const URL = "gift/deleteGifts";
	const formData = { ids: id };
	return await models().post(URL, formData);
}
// *** END FUNCTIONS *** //

export default {
	name: "get-gift",
	components: {
		[DatePicker.name]: DatePicker,
		[Button.name]: Button,
		[Tooltip.name]: Tooltip,
		Pagination,
		GiftForm,
		TableOfParticipants,
		FilterSelector,
		Spinner,
	},
	props: ["promoType"],
	data() {
		return {
			buttonEnum: buttonEnum,
			user: jwt_decode(localStorage.getItem("SET_APP")).user[0],
			radio: jwt_decode(localStorage.getItem("APP_01")).radio,
			pageSize: 10,
			currentPageStart: 0,
			time1: new Date(),
			time2: new Date(),
			msgError: false,
			searchedData: "",
			pagination: {
				perPage: 50,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50],
				total: 0,
			},
			tableListeners: [],
			tableGifts: [],
			pageStart: 0,
			loading: false,
		};
	},
	methods: {
		searchGifts() {
			this.loading = true;
			this.tableGifts = [];
			this.getGifts()
				.then((response) => {
					if (response.status === 200 && response.data.length > 0) {
						this.msgError = false;
						for (let item of response.data) {
							let expired = 0;
							let current = 0;
							let opened = 0;
							const currentDate = moment();
							let itemStartDate = moment(item.start_date);
							let itemEndDate = moment(item.end_date);

							let itemStartDateFormatted =
								itemStartDate.format("DD/MM/YYYY HH:mm");
							let itemEndDateFormatted = itemEndDate.format("DD/MM/YYYY HH:mm");

							if (currentDate.isAfter(itemEndDate)) {
								expired = 1;
							} else if (
								currentDate.isSameOrAfter(itemStartDate) &&
								!currentDate.isAfter(itemEndDate)
							) {
								current = 1;
							} else if (!currentDate.isAfter(itemStartDate)) {
								opened = 1;
							}

							this.tableGifts.push({
								id: item.id,
								name: decodeURIComponent(item.name),
								description: decodeURIComponent(item.description),
								start_date: itemStartDateFormatted,
								end_date: itemEndDateFormatted,
								image_url: item.image_url,
								is_closed: item.is_closed,
								expired: expired,
								opened: opened,
								current: current,
								gift_promo_type: item.gift_promo_type,
								date: `${itemStartDateFormatted} - ${itemEndDateFormatted}`,
								question: item.question? decodeURIComponent(item.question) : ""
							});
						}
						this.pageStart = 1;
					} else {
						this.pageStart = 0;
						this.msgError = true;
					}
				})
				.catch((err) => {
					Swal(
						"Erro",
						"Não foi possível buscar o(s) prêmio(s) da hora. Por favor entre em contato com nosso suporte.",
						"error"
					);
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		async getGifts() {
			let dateStart = new Date(filters.startDate);
			let dateEnd = new Date(filters.endDate);
			let aux1 =
				dateStart.getFullYear() +
				"-" +
				(dateStart.getMonth() + 1) +
				"-" +
				dateStart.getDate();
			let aux2 =
				dateEnd.getFullYear() +
				"-" +
				(dateEnd.getMonth() + 1) +
				"-" +
				dateEnd.getDate();

			const URL = `gift/getGifts?appKey=${this.radio.key}&type=${
				this.$props.promoType
			}&startDate=${aux1}&endDate=${aux2}&offset=${
				this.pagination.perPage
			}&rows=${0}&is_closed=0`;
			return await models()
				.get(URL)
				.then((response) => {
					return response;
				})
				.catch((err) => {
					return err;
				});
		},

		newGift() {
			this.$emit("openGiftForm", {
				mode: "creation",
			});
			this.sendMixPanelEvent(`new_gift_form_opened`)
		},
		openGift(id) {
			this.$emit("openGiftForm", {
				mode: "edition",
				id: id,
			});
			this.sendMixPanelEvent(`edit_gift_form_opened`)
		},
		openGiftView(id) {
			this.$emit("openGiftForm", {
				mode: "view",
				id: id,
			});
			this.sendMixPanelEvent(`view_gift_form_opened`)
		},
		openSort(id) {
			let gift = this.tableGifts.filter(gift=>gift.id===id)[0]
			let data = { id: id, promoType: gift.gift_promo_type, giftPromoQuestion: gift.question};
			this.$emit("showSortGifts", data);
			this.sendMixPanelEvent(`gift_participants_form_opened`)
		},
		deleteGift(id, image_url) {
			Swal({
				title: "Apagar promoção?",
				text: "Clique em confirmar para excluir a promoção.",
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				cancelButtonText: "Cancelar",
				confirmButtonText: "Confirmar",
			}).then((result) => {
				if (result.value) {
					Promise.resolve(destroyImageFromCloudinary(image_url, this.radio.key))
						.then((response_cloudinary) => {
							Promise.resolve(deletePromo(id, image_url))
								.then((resp) => {
									mixpanelAdapter.track(`${this.$route.name} - Deletou ${this.$props.promoType==="1"?'prêmio da hora':'promoção'}`)

									this.searchGifts();
								})
								.catch((err) => {
									Swal(
										"Erro",
										"Não foi  excluir a Promoção, por favor, entre em contato com nosso suporte",
										"error"
									);
									console.log(err);
								});
						})
						.catch((err) => {
							Swal(
								"Erro",
								"Não foi possível excluir a imagem vinculada a promoção, por favor, entre em contato com nosso suporte",
								"error"
							);
							console.log(err);
						});
				}
			});
		},
		getStyle(start, end) {
			let from = new Date(start);
			let to = new Date(end);
			let dateCheck = new Date();

			if (
				dateCheck.getTime() <= to.getTime() &&
				dateCheck.getTime() >= from.getTime()
			) {
				return "background-color: lightyellow;";
			}
		},
		fixViewDate(auxTime) {
			let auxHour = auxTime.split(" ");
			if (auxHour[1] === "23:59") auxHour[1] = "24:00";
			return `${auxHour[0]} ${auxHour[1]}`;
		},

		sendMixPanelEvent(eventString, params){
			mixpanelAdapter.track(eventString, {
				...params,
				screen_name: this.$route.name
			})
		}
	},
	computed: {
		queriedData() {
			let result = this.tableGifts;
			if (this.searchedData.length > 0) {
				result = this.searchedData;
			}
			return result.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0
				? this.searchedData.length
				: this.tableGifts.length;
		},
		sortedParticipantes() {
			return this.tableListeners.filter((row, index) => {
				let start = (this.currentPage - 1) * this.pageSize;
				let end = this.currentPage * this.pageSize;
				if (index >= start && index < end) return true;
				this.totalPage = Math.ceil(this.tableListeners.length / 10);
			});
		},
	},
	mounted() {
		selectRadio.$on("selectRadio", (payload) => {
			this.radio = payload;
			this.searchGifts(payload);
		});
		this.$root.$on("getGifts", (payload) => {
			this.searchGifts(payload);
		});
	},
};
</script>

<style>
.center-datepicker {
	margin-top: -8px;
}

.row-btn-edit-gift {
	width: 20%;
}

.paginated-table .md-table-head-label {
	font-weight: 800 !important;
}

@media (min-width: 860px) and (max-width: 960px) {
	.row-btn-edit-gift {
		width: 5% !important;
	}
}

@media (min-width: 960px) and (max-width: 1024px) {
	.row-btn-edit-gift {
		width: 50% !important;
	}
}

@media (min-width: 1024px) and (max-width: 1440px) {
	.row-btn-edit-gift {
		width: 25% !important;
	}
}

@media (min-width: 1440px) {
	.row-btn-edit-gift {
		width: 20% !important;
	}
}
</style>
