var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$route.name
    ? _c(
        "md-toolbar",
        {
          staticClass: "md-transparent",
          class: {
            "md-toolbar-absolute md-white md-fixed-top":
              _vm.$route.meta.navbarAbsolute,
          },
          attrs: { "md-elevation": "0" },
        },
        [
          _c("div", { staticClass: "md-toolbar-row" }, [
            _c("div", { staticClass: "md-toolbar-section-start" }, [
              _c("h2", [_vm._v(_vm._s(_vm.$route.name) + " ")]),
            ]),
            _c(
              "div",
              { staticClass: "md-toolbar-section-end" },
              [
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-just-icon md-round md-simple md-toolbar-toggle",
                    class: { toggled: _vm.$sidebar.showSidebar },
                    on: { click: _vm.toggleSidebar },
                  },
                  [
                    _c("span", { staticClass: "icon-bar" }),
                    _c("span", { staticClass: "icon-bar" }),
                    _c("span", { staticClass: "icon-bar" }),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm.msgAlertPagarMe
            ? _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 statusPagarMe print-hide",
                },
                [
                  _c("span", {
                    staticClass: "pagar-link",
                    domProps: { innerHTML: _vm._s(_vm.msgAlertPagarMe) },
                  }),
                ]
              )
            : _vm._e(),
        ]
      )
    : _c(
        "md-toolbar",
        { staticClass: "md-transparent", attrs: { "md-elevation": "0" } },
        [
          _c("div", { staticClass: "md-toolbar-row" }, [
            _c("div", { staticClass: "md-toolbar-section-start" }, [
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-20",
                    staticStyle: { "padding-left": "0", "padding-top": "1rem" },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [
                        _c("h5", { staticStyle: { display: "inline-block" } }, [
                          _vm._v("Voltar para a página anterior"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }