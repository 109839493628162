var render = function render(_c, _vm) {
  return _c("header", { staticClass: "main-header" }, [
    _c("img", {
      staticClass: "mobradio-logo",
      attrs: { src: require("../assets/logos/mobradio-white.png") },
    }),
    _c("h1", { staticClass: "radio-name" }, [
      _vm._v(_vm._s(_vm.props.RadioName)),
    ]),
    _c("img", {
      staticClass: "radio-logo",
      attrs: { src: _vm.props.RadioLogo },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }