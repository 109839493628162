var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-card",
    { staticStyle: { "margin-top": "0" } },
    [
      _c("md-content", [
        _c(
          "div",
          {
            staticClass: "md-layout md-alignment-top-center",
            staticStyle: { background: "#e3e3e3" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-30 md-xlarge-size-20",
                staticStyle: { position: "relative" },
              },
              [
                _c(
                  "div",
                  { staticClass: "md-layout md-alignment-center-center" },
                  [
                    _c("img", {
                      staticClass: "image-award",
                      attrs: {
                        src: this.awardImage
                          ? this.awardImage
                          : this.errorImage,
                      },
                    }),
                    this.errorUpload
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#FF0909",
                              "font-size": "90%",
                              "text-align": "center",
                            },
                          },
                          [_vm._v(" O tamanho da imagem ultrapassa 200kB ")]
                        )
                      : _vm._e(),
                    !this.$props.viewOnly
                      ? [
                          _c(
                            "label",
                            {
                              staticClass: "button-upload",
                              attrs: { for: "upload_" + this.$props.index },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    this.awardImage
                                      ? "Editar imagem"
                                      : "Adicionar Imagem"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("input", {
                            staticStyle: { display: "none" },
                            attrs: {
                              id: "upload_" + this.$props.index,
                              type: "file",
                              accept: "image/*",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.loadFile($event)
                              },
                            },
                          }),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-xsmall-size-100 md-small-size-65 md-medium-size-65 md-large-size-60 md-xlarge-size-75",
              },
              [
                _c(
                  "div",
                  { staticClass: "md-layout md-layout md-alignment-top-left" },
                  [
                    _c("div", { staticClass: "md-layout-item md-size-100" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout md-alignment-top-space-between",
                        },
                        [
                          _c(
                            "h6",
                            {
                              staticClass: "md-layout-item md-size-100",
                              staticStyle: { margin: "12px 0 0 0" },
                            },
                            [_vm._v(" Nome do prêmio ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-xsmall-size-80 md-small-size-90 md-medium-size-90 md-large-size-90 md-xlarge-size-75",
                            },
                            [
                              _c("el-input", {
                                staticStyle: { margin: "0 !important" },
                                attrs: {
                                  autosize: "",
                                  type: "textarea",
                                  disabled: !this.editionMode,
                                  resize: !this.editionMode
                                    ? "none"
                                    : "vertical",
                                },
                                model: {
                                  value: _vm.awardName,
                                  callback: function ($$v) {
                                    _vm.awardName = $$v
                                  },
                                  expression: "awardName",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  style:
                                    _vm.awardName.length > 150 ||
                                    _vm.awardName.length === 0
                                      ? "color: #FF0606"
                                      : "color: #707070",
                                },
                                [
                                  _vm._v(
                                    " (" +
                                      _vm._s(this.awardName.length) +
                                      "/150) "
                                  ),
                                  this.awardName.length > 150
                                    ? _c("span", [
                                        _vm._v(
                                          "O nome do prêmio passa de 150 caracteres."
                                        ),
                                      ])
                                    : this.awardName.length === 0
                                    ? _c("span", [
                                        _vm._v(
                                          "O nome do prêmio não pode ser vazio."
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          !this.editionMode && !this.$props.viewOnly
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-xsmall-size-15 md-small-size-10 md-medium-size-10 md-large-size-10 md-xlarge-size-10",
                                  staticStyle: {
                                    "margin-top": "15px",
                                    padding: "0 !important",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    staticStyle: { margin: "0 !important" },
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-edit",
                                      circle: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.enableEditionMode()
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : !this.$props.viewOnly
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-xsmall-size-15 md-small-size-10 md-medium-size-10 md-large-size-10 md-xlarge-size-10",
                                  staticStyle: {
                                    "margin-top": "15px",
                                    padding: "0 !important",
                                  },
                                },
                                [
                                  this.awardName.length <= 150 &&
                                  this.awardName.length !== 0
                                    ? _c("el-button", {
                                        staticStyle: { margin: "0 !important" },
                                        attrs: {
                                          type: "success",
                                          icon: "el-icon-check",
                                          circle: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.confirmNameChange()
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "md-layout-item md-size-100" }, [
                      _c("div", { staticClass: "md-layout" }, [
                        !this.$props.viewOnly
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-70 md-large-size-70",
                                staticStyle: {
                                  "padding-left": "0 !important",
                                  "padding-right": "0 !important",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout md-alignment-center-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "md-layout-item" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              margin: "10px 0",
                                            },
                                            attrs: {
                                              type: "danger",
                                              icon: "el-icon-delete",
                                              round: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeAwardFromList()
                                              },
                                            },
                                          },
                                          [_vm._v("Remover")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }