<template>
  <div>
    <el-dialog title="Nova área" :visible.sync="openNewZone" :before-close="closeModal" :close-on-click-modal="false" :center="true" :lock-scroll="false">
        <el-steps :active="activeStep" finish-status="success">
          <!-- <el-step title="Cadastrar"></el-step> -->
      </el-steps>
      <div v-show="activeStep === 0" style="margin-top:20px;" class="main-container">
        <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
          <div class="md-layout divDescription">
              <span class="info-span">
                  Olá, aqui você irá preencher as informações para cadastrar uma nova área no dispositivo
              </span>
          </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
              <el-input v-model="zone.name" placeholder="Nome da área*"></el-input>
            </div>
            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
              <el-input v-model="zone.description" type="text-area" placeholder="Descrição"></el-input>
            </div>
            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
              <el-input v-model="zone.width" placeholder="Width*"></el-input>
            </div>
            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
              <el-input v-model="zone.height" placeholder="Heigth*"></el-input>
            </div>
            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
              <el-input v-model="zone.banner_rotation_time" placeholder="Tempo de rotação do banner (em segundos)*"></el-input>
            </div>
          </div>
          <div v-if="errors.length" class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
              <p>
                  <b>Por favor, corrija os campos conforme solicitado:</b>
                  <ul>
                    <li style="color: red" v-for="error in errors" :key="error">{{ error }}</li>
                  </ul>
              </p>
          </div>
          <div class="md-layout" style="margin-top: 2rem;">
            <div class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-80 md-large-size-80 md-xlarge-size-80">
              <el-button type="success" style="margin-top: 12px;" @click="nextStep(1)">Cadastrar</el-button>
            </div>
          </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { Dialog, Button, Input, Tabs, TabPane, Upload, Step, Steps, TimeSelect, DatePicker, Select, Option } from 'element-ui'
import jwt_decode from "jwt-decode"
import models from '../../../../routes/models';
export default {
  name: 'New',
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Input.name]: Input,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Upload.name]: Upload,
    [TimeSelect.name]: TimeSelect,
    [DatePicker.name]: DatePicker,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      radio: jwt_decode(localStorage.getItem('APP_01')).radio,
      openNewZone: false,
      activeStep: 0,
      zone: {
        name: '',
        description: '',
        width: '',
        height: '',
        banner_rotation_time: ''
      },
      errors: []
    }
  },
  methods: {
    nextStep() {
      switch (this.activeStep) {
        case 0:
          this.validationFields(0)
          if(this.errors.length === 0) this.newZone()
          break;
        default:
          break;
      }
    },
    closeModal() {
        Swal.fire({
            title: 'Atenção',
            text: "Você está prestes a cancelar o preenchimento do formulário e todas as informações serão perdidas. Deseja continuar?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if(result.value){
              this.clearFormZone()
              this.activeStep = 0
              this.openNewZone = false
            }
          });
    },
    clearFormZone() {
        this.zone.name = '',
        this.zone.description = '',
        this.zone.width = '',
        this.zone.height = '',
        this.zone.banner_rotation_time = ''
    },
    validationFields(step) {
      this.errors = []
      if(step === 0) {
        if(this.zone.name === '') this.errors.push('O campo Nome da área deve ser preenchido')
        if(this.zone.width === '') this.errors.push('O campo Width deve ser preenchido')
        if(this.zone.height === '') this.errors.push('O campo Height deve ser preenchido')
        if(this.zone.banner_rotation_time === '') this.errors.push('O campo Tempo de rotação do banner deve ser preenchido')
      }
    },
    newZone() {
      this.openNewZone = false
      this.loadRequest()
      let formData = {
        appKey: this.radio.key,
        name: this.zone.name,
        description: this.zone.description ? this.zone.description : '',
        width: this.zone.width,
        height: this.zone.height,
        bannerRotationTime: this.zone.banner_rotation_time
      }

      models().post('mobads/newZone', formData)
        .then(() => {
          this.$root.$emit('reloadGetZone')
          Swal(
            'Pronto',
            'Nova área cadastrada com sucesso!',
            'success'
          )
        })
    },
    loadRequest() {
        Swal({
            title: "Aguarde",
            html: "Estamos cadastrando as informações da área...",
            type: "info",
            onOpen: () => {
              Swal.showLoading()
            }
        })
    }
  },
  mounted() {
    this.$root.$on('openNewZone', () => {
      this.activeStep = 0
      this.openNewZone = true
    })
  }
}
</script>

<style scoped>
  .main-container {
    min-height: 300px;
  }
</style>
