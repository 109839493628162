const ServiceBlock = {
    checkPage () {
        let service_name = []
        const services = JSON.parse(localStorage.getItem('radio-services'))
        for(let item of services) {
            service_name.push({
                name: item.service_name.trim(),
                url: item.service_url.trim(),
                has_permission: item.has_permission
            })
        }
        return service_name
    }
}

export default ServiceBlock