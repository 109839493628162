var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-card",
    {
      staticClass: "md-card-login",
      class: { "md-card-hidden": _vm.cardHidden },
    },
    [
      _c(
        "md-card-header",
        { class: _vm.getClass(_vm.headerColor) },
        [
          _vm._t("title"),
          _c("div", { staticClass: "social-line" }, [_vm._t("buttons")], 2),
        ],
        2
      ),
      _c("md-card-content", [_vm._t("description"), _vm._t("inputs")], 2),
      _c("md-card-actions", [_vm._t("footer")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }