<template functional>
	<footer class="final-footer">
		<img src="../assets/logos/mobradio-white.png"/>
		<h5>Fonte de dados: MobRadio Analytics</h5>
	</footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style>

	@import url("../assets/background/background-header.css");

	.final-footer {
		position: absolute;
		bottom: 0;
		background-image: var(--background-footer);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 9.6rem;
		width: 100%;
		border: none;
	}
	.final-footer img {
		width: 178px;
		margin: 3.5rem 0 10px 0;
	}
	.final-footer h5 {
		font-size: 10px;
		color: #fff !important;
		margin: 0;
	}
</style>