<template>
    <div class="md-layout">
        <div v-show="loading"
            class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>
        <div v-show="!loading && blockStatus == 1" class="md-layout-item md-small-size-100">
            <GiftList promoType="1"/>
        </div>
        <block v-show="blockStatus == 0"></block>
    </div>
</template>

<script>
import block from './Block.vue'
import GiftList from './Gifts/GiftList.vue'
import jwt_decode from "jwt-decode"

export default {
    components: {
    GiftList,
    block,
    GiftList
},
    data() {
        return {
            appKey: jwt_decode(localStorage.getItem('APP_01')).radio.key,
            loading: false,
            blockStatus: 1,
        }
    },
    methods: {
        blockPage() {
            const menu = jwt_decode(localStorage.getItem('APP_02')).services
            let service = null
            for (let obj of menu) {
                if (obj.subitens === null) {
                    const key = Object.keys(obj.item)[Object.values(obj.item).indexOf(this.$route.path.replace("/",
                        ""))]
                    if (key) {
                        service = obj.item
                    }
                } else {
                    const key = Object.keys(obj.subitens)[Object.values(obj.subitens).indexOf(this.$route.path
                        .replace("/", ""))]
                    if (key) {
                        service = obj.subitens
                    }
                }
            }

            if (service) {
                this.blockStatus = service.has_permission
            } else {
                this.$router.push("dashboard")
            }
        },
    },
    mounted() {
        let self = this
        if (jwt_decode(localStorage.getItem('APP_01'))) {
            self.appKey = jwt_decode(localStorage.getItem('APP_01')).radio.key
            self.blockPage()
        }
    },
}

</script>
