<template>
	<div>
		<el-dialog :visible.sync="showDialog"
			width="80%" 
			class="hide-on-print">
			<div class="md-layout">
				<div v-show="loading"
					class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
					<Spinner />
				</div>
				<div class="md-layout" v-show="!loading">
					<div class="md-layout-item md-size-85 principal">
						<h2 style="text-align: left; margin: 0; font-weight: 500">
							{{ this.selectedCampaing.name }}
						</h2>
						<h5 style="margin-top: 12px; margin-left: 12px">
							<em>
								{{ selectedCampaing.start_date.toLocaleDateString("pt-BR") }}
							</em>
							até
							<em>
								{{ selectedCampaing.end_date.toLocaleDateString("pt-BR") }}
							</em>
						</h5>
					</div>
					<div v-show="this.averageAdsStatistics.length > 0"
						class="md-layout-item md-size-100 principal"
						style="margin: 0 auto;">
						<md-card>
							<md-card-header>
								<h3 style="text-align: center" class="title-report-campaign">
									Estatísticas gerais
								</h3>
							</md-card-header>
							<md-card-content>
								<div class="md-layout">
									<div 
										class="md-layout-item md-xsmall-size-100 md-size-20"
										style="text-align: center">
										<h4 class="title-gerenal-statistics">Visualizações totais</h4>
										<h3 class="data-general-statistics">{{ this.totalViews | formatNumber }}</h3>
									</div>
									<div
										class="md-layout-item md-xsmall-size-100 md-size-20" 
										style="text-align: center">
										<h4 class="title-gerenal-statistics">Cliques totais</h4>
										<h3 class="data-general-statistics">{{ this.totalClicks | formatNumber }}</h3>
									</div>
									<div
										class="md-layout-item md-xsmall-size-100 md-size-20" 
										style="text-align: center">
										<h4 class="title-gerenal-statistics">*CTR</h4>
										<h3 class="data-general-statistics">{{ this.totalCTR }}%</h3>
									</div>
									<div
										class="md-layout-item md-xsmall-size-100 md-size-20" 
										style="text-align: center">
										<h4 class="title-gerenal-statistics">Visualizações únicas</h4>
										<h3 class="data-general-statistics">{{ this.totalUniqueViews | formatNumber }}</h3>
									</div>
									<div
										class="md-layout-item md-xsmall-size-100 md-size-20" 
										style="text-align: center">
										<h4 class="title-gerenal-statistics">Cliques únicos</h4>
										<h3 class="data-general-statistics">{{ this.totalUniqueClicks | formatNumber }}</h3>
									</div>
								</div>
							</md-card-content>
							<md-card-footer>
								<h5 style="
										font-size: 15px;
										text-align: left;
										padding: 1.5rem;
										margin: 0;
										color: #495161;
									">
									<strong>*CTR: </strong><i>Click-through rate</i>, traduzindo,
									taxa de cliques. Divisão da quantidade de cliques pela de
									visualizações.
								</h5>
							</md-card-footer>
						</md-card>
					</div>
					<div v-show="this.averageAdsStatistics.length > 0"
						class="md-layout-item md-size-100 principal"
						style="margin: 0 auto">
						<md-card style="height: 85%;">
							<md-card-header>
								<h3 style="text-align: center;" class="title-report-campaign">
									Estatísticas por plataforma
								</h3>
							</md-card-header>
							<md-card-content style="padding-left: 0; padding-right: 0; overflow-x: auto; overflow-y: hidden;">
								<table class="table-mobads">
									<thead style="text-align: left;">
										<th></th>
										<th>Visualizações</th>
										<th>Cliques</th>
										<th>CTR</th>
										<th>Visualizações únicas</th>
										<th>Cliques únicos</th>
									</thead>
									<tbody>
										<tr v-for="item in this.statisticsPerPlatform" :key="item" class="tr_tables">
											<td class="td_tables">{{ item.os }}</td>
											<td class="td_tables">{{ item.views | formatNumber }}</td>
											<td class="td_tables">
												{{ item.clicks | formatNumber }}
											</td>
											<td class="td_tables">{{ item.ctr }}</td>
											<td class="td_tables">{{ item.unique_views }}</td>
											<td class="td_tables">{{ item.unique_clicks }}</td>
										</tr>
									</tbody>
								</table>
							</md-card-content>
						</md-card>
					</div>
					<div v-show="this.averageAdsStatistics.length > 0"
						class="md-layout-item md-size-100 principal"
						style="margin: 0 auto;">
						<md-card style="height: 85%;">
							<md-card-header>
								<h3 style="text-align: center;" class="title-report-campaign">
									Estatísticas por área do dispositivo
								</h3>
							</md-card-header>
							<md-card-content style="padding-left: 0; padding-right: 0; overflow-x: auto;">
								<table class="table-mobads">
									<thead style="text-align: left;">
										<th></th>
										<th>Visualizações</th>
										<th>Cliques</th>
										<th>CTR</th>
										<th>Visualizações únicas</th>
										<th>Cliques únicos</th>
									</thead>
									<tbody>
										<tr v-for="item in this.statisticsPerZone" :key="item" class="tr_tables">
											<td class="td_tables">{{ item.zone_name}}</td>
											<td class="td_tables">{{ item.views | formatNumber }}</td>
											<td class="td_tables">{{ item.clicks | formatNumber }}</td>
											<td class="td_tables">{{ item.ctr }}</td>
											<td class="td_tables">{{ item.unique_views | formatNumber}}</td>
											<td class="td_tables">{{ item.unique_clicks | formatNumber}}</td>
										</tr>
									</tbody>
								</table>
							</md-card-content>
						</md-card>
					</div>
					<div v-show="this.averageAdsStatistics.length > 0"
						class="md-layout-item md-size-100 principal">
						<md-card>
							<md-card-header class="md-card-header-icon md-card-header-blue">
								<h3 class="title-report-campaign">Estatísticas por banner</h3>
							</md-card-header>
							<md-card-content style="padding-left: 0; padding-right: 0; overflow-x: auto;">
								<div class="md-layout">
									<div class="md-layout-item md-size-100" style="margin: auto">
										<form id="platform-filter">
											<fieldset>
												<h5 class="title-report-campaign subtitle-for-platform-filter" style="
														margin: 0 !important;
														margin-bottom: 16px !important;
													">
													Filtrar por plataforma:
												</h5>

												<el-checkbox-group 
													@change="sendMixPanelEvent(`mobad_report_platform_filter_used`)"
													:min="1"
													v-model="platformsSelectedByFilter">
													<el-checkbox :label="0" class="platform-mobad-checkbox">
														<div class="label-checkbox">
															<img class="icon-checkbox"
																src="../../../../public/img/icons/android-icon.svg" />
															<span>Android</span>
														</div>
													</el-checkbox>
													<el-checkbox :label="1" class="platform-mobad-checkbox">
														<div class="label-checkbox">
															<img class="icon-checkbox"
																src="../../../../public/img/icons/apple-icon.svg" />
															<span>iOS</span>
														</div>
													</el-checkbox>
													<el-checkbox :label="2" class="platform-mobad-checkbox">
														<div class="label-checkbox">
															<img class="icon-checkbox"
																src="../../../../public/img/icons/web-icon.svg" />
															<span>Web</span>
														</div>
													</el-checkbox>
													<el-checkbox :label="3" class="platform-mobad-checkbox">
														<div class="label-checkbox">
															<img class="icon-checkbox"
																src="../../../../public/img/icons/question-mark.svg" />
															<span>Não definido</span>
														</div>
													</el-checkbox>
												</el-checkbox-group>
											</fieldset>
										</form>
									</div>
								</div>
								<template v-if="this.bannersFilteredPerPlatform.length !== 0">
									<table class="table-mobads">
										<thead style="text-align: left">
											<th>Banner</th>
											<th>Área do dispositivo</th>
											<th>Visualizações</th>
											<th>Cliques</th>
											<th>CTR</th>
											<th>Visualizações únicas</th>
											<th>Cliques únicos</th>
										</thead>
										<tbody>
											<tr class="tr_tables" v-for="(item, index) in this.bannersFilteredPerPlatform"
												:key="index">
												<td class="td_tables">{{ item.name }}</td>
												<td class="td_tables">{{ item.zone }}</td>
												<td class="td_tables">{{ item.views | formatNumber }}</td>
												<td class="td_tables">{{ item.clicks | formatNumber }}</td>
												<td class="td_tables">{{ item.ctr }}</td>
												<td class="td_tables">{{ item.unique_views | formatNumber }}</td>
												<td class="td_tables">{{ item.unique_clicks | formatNumber }}</td>
											</tr>
										</tbody>
									</table>
								</template>
								<template v-else>
									<div class="md-layout-item md-alignment-center-center">
										<div class="md-layout-item md-size-100" style="padding: 0;">
											<h5 style="text-align: left;">Sem informações pra serem exibidas.</h5>
										</div>
									</div>
								</template>
							</md-card-content>
						</md-card>
					</div>
					<div
						class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 print-hide">
						<md-card style="text-align: center" v-show="msgError">
							<md-card-content>
								<label for="">Sem informações pra serem exibidas.</label>
							</md-card-content>
						</md-card>
					</div>
					<div class="md-layout-item md-size-100 principal">
						<div class="md-layout md-alignment-center-right">
							<md-button @click="printReport()" v-if="this.averageAdsStatistics.length > 0"
								class="md-layout-item md-size-15 md-xsmall-size-30 md-info">
								Imprimir
							</md-button>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
		<TemplateReportMobAd
			id="report-campaign" 
			:radioName="nameRadioReport" 
			:radioLogo="this.radio.logo"
			:mobAdName="this.selectedCampaing.name" 
			:periodStart="this.selectedCampaing.start_date.toLocaleDateString('pt-BR')" 
			:periodEnd="this.selectedCampaing.end_date.toLocaleDateString('pt-BR')" 
			:data="{
				totalClicks: this.totalClicks,
				totalViews: this.totalViews,
				totalCTR: this.totalCTR,
				totalUniqueClicks: this.totalUniqueClicks,
				totalUniqueViews: this.totalUniqueViews,
				statisticsPerPlatform: this.statisticsPerPlatform,
				statisticsPerZone: this.statisticsPerZone,
				platformsSelected: this.mapPlatformsToPrintReport(),
				statisticsPerBanner: this.bannersFilteredPerPlatform,
			}" />
	</div>
</template>

<script>
import TemplateReportMobAd from "../ReportTemplates/TemplateReportMobAd.vue";
import Spinner from "../../../components/Icons/Spinner";

import models from "../../../routes/models.js";
import selectRadio from "../../../utils/events/select-radio.js";
import jwt_decode from "jwt-decode";
import mixpanelAdapter from "../../../utils/events/mixpanelAdapter";

export default {
	name: "ReportPopup",
	components: {
		Spinner,
		TemplateReportMobAd
	},
	data() {
		return {
			showDialog: true,
			loading: false,
			radio: jwt_decode(localStorage.getItem("APP_01")).radio,
			nameRadioReport: jwt_decode(localStorage.getItem("APP_01")).radio.name,
			logo: "",
			disabledButton: true,
			btnImprimir: true,
			msgError: false,
			periodStart: null,
			periodEnd: null,
			listCampaigns: [],
			selectedCampaing: null,
			averageAdsStatistics: [],
			bannersFilteredPerPlatform: [],
			allPlatformsAreChecked: true,
			platformsSelectedByFilter: [0, 1, 2, 3],
			totalViews: 0,
			totalClicks: 0,
			totalCTR: 0,
			totalUniqueClicks: 0,
			totalUniqueViews: 0,
			statisticsPerPlatform: [],
			statisticsPerZone: [],
			time1: null,
			time2: null,
			datepicker: null,
		};
	},
	methods: {
		printReport() {
			this.sendMixPanelEvent(`mobad_report_printed`)
			window.print();
		},
		sendMixPanelEvent(eventString){
			mixpanelAdapter.track(eventString, {
				screen_name: this.$route.name
			})
		},
		mapPlatformsToPrintReport() {
			const possiblePlatforms = [
				{
					order: 0,
					name: "Android",
					icon: require('../../../../public/img/icons/android-icon.svg')
				},
				{
					order: 1,
					name: "iOS",
					icon: require('../../../../public/img/icons/apple-icon.svg')
				},
				{
					order: 2,
					name: "Web",
					icon: require('../../../../public/img/icons/web-icon.svg')
				},
				{
					order: 3,
					name: "Não definido",
					icon: require('../../../../public/img/icons/question-mark.svg')
				}
			]
			let platformsMapped = []
			for (let platform of this.platformsSelectedByFilter) {
				platformsMapped.push(possiblePlatforms[platform])
			}

			platformsMapped.sort((a, b) => a.order - b.order)

			return platformsMapped
		},
		async getMobAdById() {
			this.listCampaigns = [];
			await models()
				.get(`mobads/getMobAdById/${this.$route.params.id}`)
				.then((response) => {
					this.selectedCampaing = response.data[0];
				})
				.catch((error) => console.log(error));
		},
		async getBannerByZones(campaignId) {
			return models().get(
				`report/getBannerByZones?campaignId=${campaignId}&appKey=${this.radio.key}`
			);
		},
		async getAverageAdsStatisticsPerDay(campaignId, startDate, endDate) {
			return models().get(
				`report/getAverageAdsStatisticsPerDay?campaignId=${campaignId}&startDate=${startDate} 00:00:00&endDate=${endDate} 23:59:59`
			);
		},
		generateReport() {
			this.loading = true;
			this.msgError = false;
			Promise.all([
				this.getAverageAdsStatisticsPerDay(
					this.selectedCampaing.id,
					this.formatDateToMYSQL(this.selectedCampaing.start_date),
					this.formatDateToMYSQL(this.selectedCampaing.end_date)
				),
			]).then((response) => {
				if (response[0].data.length > 0) {
					this.averageAdsStatistics = response[0].data;

					this.calculateGeneralStatistics();
					this.calculateStatisticsPerPlatform();
					this.calculateStatisticsPerDeviceZone();
					this.calculateBannerDataPerPlatform();
				} else {
					this.averageAdsStatistics = [];
					this.bannerByZones = [];
					this.msgError = true;
				}
				this.loading = false;
				this.btnImprimir = false;
			});
		},
		calculateGeneralStatistics() {
			const totals = this.averageAdsStatistics.reduce(
				(acc, banner) => {
					acc.totalClicks += banner.clicks;
					acc.totalViews += banner.total;
					acc.totalUniqueViews += banner.unique_users;
					acc.totalUniqueClicks += banner.unique_users_click;
					return acc;
				},
				{ totalClicks: 0, totalViews: 0, totalUniqueViews: 0, totalUniqueClicks: 0 }
			);

			this.totalClicks = totals.totalClicks;
			this.totalViews = totals.totalViews;
			this.totalUniqueViews = totals.totalUniqueViews;
			this.totalUniqueClicks = totals.totalUniqueClicks;

			this.totalCTR = this.totalViews !== 0
				? ((this.totalClicks * 100) / this.totalViews).toFixed(2)
				: "0.00";
		},
		calculateStatisticsPerPlatform() {
			const osMapping = {
				0: "Android",
				1: "iOS",
				2: "Web",
				3: "Não Definido"
			};

			this.statisticsPerPlatform = Object.values(osMapping).map((platformName) => {
				const platformKey = Object.keys(osMapping).find((key) => osMapping[key] === platformName);

				const filteredBanners = this.averageAdsStatistics.filter((banner) => banner.os == platformKey);

				const clicks = filteredBanners.reduce((sum, banner) => sum + banner.clicks, 0);
				const views = filteredBanners.reduce((sum, banner) => sum + banner.total, 0);
				const uniqueClicks = filteredBanners.reduce((sum, banner) => sum + banner.unique_users_click, 0);
				const uniqueViews = filteredBanners.reduce((sum, banner) => sum + banner.unique_users, 0);
				const ctr = views !== 0 ? ((clicks * 100) / views).toFixed(2) + "%" : "0%";

				return { os: platformName, views, clicks, ctr, unique_views: uniqueViews, unique_clicks: uniqueClicks };
			});
		},
		calculateStatisticsPerDeviceZone() {
			const groupedStatistics = {};

			this.averageAdsStatistics.forEach((banner) => {
				const zoneName = banner.zone_name;

				if (!groupedStatistics[zoneName]) {
					groupedStatistics[zoneName] = {
						zone_name: zoneName,
						views: 0,
						clicks: 0,
						unique_views: 0,
						unique_clicks: 0,
						ctr: "0%"
					};
				}

				groupedStatistics[zoneName].views += banner.total;
				groupedStatistics[zoneName].clicks += banner.clicks;
				groupedStatistics[zoneName].unique_views += banner.unique_users;
				groupedStatistics[zoneName].unique_clicks += banner.unique_users_click;
			});

			this.statisticsPerZone = Object.values(groupedStatistics).map((zone) => {
				if (zone.views !== 0) {
					zone.ctr = ((zone.clicks * 100) / zone.views).toFixed(2) + "%";
				}
				return zone;
			});
		},
		calculateBannerDataPerPlatform() {
			this.bannersFilteredPerPlatform = [];
			for (let banner of this.averageAdsStatistics) {
				if (this.platformsSelectedByFilter.includes(banner.os)) {
					let bannerFiltered = this.getFilteredBannerByNameAndZone(
						banner.banner_name,
						banner.zone_name
					);
					if (!bannerFiltered) {
						let bannerObject = {
							name: banner.banner_name,
							zone: banner.zone_name,
							views: 0,
							clicks: 0,
							ctr: 0,
							unique_views: 0,
							unique_clicks: 0
						};
						this.addNewBannerToListOfFilteredBanners(bannerObject);
						bannerFiltered = this.getFilteredBannerByNameAndZone(
							banner.banner_name,
							banner.zone_name
						);
					}
					bannerFiltered.views += banner.total
					bannerFiltered.clicks += banner.clicks
					bannerFiltered.unique_views += banner.unique_users
					bannerFiltered.unique_clicks += banner.unique_users_click
					bannerFiltered.ctr = (bannerFiltered.clicks * 100/ bannerFiltered.views).toFixed(2) + "%"
				}
			}
			this.bannersFilteredPerPlatform.sort((a, b) => {
				if (a.name < b.name) {
					return -1
				}
				if (a.name > b.name) {
					return 1
				}
				return 0
			})
		},
		getFilteredBannerByNameAndZone(name, zone) {
			let bannerFinded = null
			for (let banner of this.bannersFilteredPerPlatform) {
				if (banner.name === name && banner.zone === zone) {
					bannerFinded = banner
					break
				}
			}
			return bannerFinded
		},
		addNewBannerToListOfFilteredBanners(banner) {
			this.bannersFilteredPerPlatform.push(banner)
		},
		formatDateToMYSQL(dateString) {
			const date = new Date(dateString);
			return (
				date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
			);
		},
		formatPeriod(selectedDate) {
			const d = new Date(selectedDate);
			let day = d.getDate();
			let month = d.getMonth() + 1;
			if (day < 10) {
				day = `0${day}`;
			}
			if (month < 10) {
				month = `0${month}`;
			}
			return day + "/" + month + "/" + d.getFullYear();
		},
	},
	mounted() {
		this.$root.$on("openReportMobAdPopup", (payload) => {
			payload.start_date = new Date(payload.start_date);
			payload.end_date = new Date(payload.end_date);

			this.showDialog = true;
			this.selectedCampaing = payload;
			this.generateReport();
		});

		selectRadio.$on("selectRadio", (payload) => {
			this.selectedCampaing = null;
			this.time1 = null;
			this.time2 = null;
			this.dataSetOS = [];
			this.listCampaigns = [];
			this.averageAdsStatistics = [];
			this.bannerByZones = [];
			this.averageAdsStatistics = [];
			this.radio = jwt_decode(localStorage.getItem("APP_01")).radio;
			this.nameRadioReport = payload.name;
			this.logo = payload.logo;
			this.btnImprimir = true;
			this.msgError = false;
			this.logo = jwt_decode(localStorage.getItem("APP_01")).radio.logo;
		});
	},
	watch: {
		platformsSelectedByFilter: function (val) {
			if (val.length === 4) {
				this.allPlatformsAreChecked = true;
			} else {
				this.allPlatformsAreChecked = false;
			}
			this.calculateBannerDataPerPlatform();
		},
	},
};
</script>

<style>
#platform-filter fieldset {
	border-radius: 5px;
	border: 1px solid #dadada;
	padding: 14px;
	margin: 0;
	margin-bottom: 24px;
}

.platform-mobad-checkbox .el-checkbox__input {
	margin-top: -8px;
}

.label-checkbox {
	display: flex;
	align-items: flex-start;
}

.label-checkbox span {
	font-weight: bold !important;
	color: #495161;
}

.custom-checkbox-group {
	border: 1px solid #dadada;
	border-radius: 5px;
	padding: 14px;
}

.table-mobads tr:nth-child(odd) td {
	background: #fff;
}

.table-mobads tr:nth-child(even) td {
	background: #dcf5ff46;
}

.title-report-campaign {
	font-weight: bold !important;
	text-align: center;
	margin: 24px 0 !important;
	color: #495161;
}

.subtitle-for-platform-filter {
	font-size: 150% !important;
	text-align: left !important;
}

#platform-filter .icon-checkbox {
	display: inline-block;
	height: 15px;
	width: 15px;
	margin: 0 8px 0 0;
}

.table-mobads td,
.table-mobads th {
	padding: 10px;
	text-align: center;
}

.table-mobads th {
	font-weight: 500;
}

#report-campaign {
	position: absolute;
	top: -9999px;
	left: -9999px;
	visibility: hidden;
}

.title-gerenal-statistics {
	height: 2rem;
	font-weight: 300;
}

.data-general-statistics {
	color: #495161;
	font-weight: 500;
}

@media print {
	#report-campaign {
		position: static;
		top: 0;
		left: 0;
		z-index: 9999;
		width: 100%;
		height: 100%;
		visibility: visible;
	}

	.hide-on-print {
		display: none;
	}

	.v-modal {
		display: none;
	}
}

@media (max-width: 959px) {
	.add-padding-on-small-screen {
		padding-bottom: 14px;
	}

	.title-gerenal-statistics {
		height: 2.5rem;
		font-size: 120%;
	}
}

@media (max-width: 600px) {
	.title-gerenal-statistics {
		height: 1.5rem;
		font-size: 120%;
	}
}

</style>
