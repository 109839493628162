<template>
    <div class="md-layout">
        <div v-show="loading"
            class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>
        <div v-show="!loading && blockStatus == 1" class="md-layout-item md-small-size-100">
            <GiftList promoType="2"/>
        </div>

        <block v-show="blockStatus == 0"></block>
    </div>

</template>


<script>
import block from './Block.vue'
import GiftList from './Gifts/GiftList.vue'
import jwt_decode from "jwt-decode"

export default {
    components: {
        GiftList,
        block
    },
    data() {
        return {
            appKey: jwt_decode(localStorage.getItem('APP_01')).radio.key,
            loading: false,
            blockStatus: 1,
        }
    },
    methods: {
        blockPage() {
            const menu = jwt_decode(localStorage.getItem('APP_02')).services
            let service = null
            for (let obj of menu) {
                if (obj.subitens === null) {
                    const key = Object.keys(obj.item)[Object.values(obj.item).indexOf(this.$route.path.replace("/",
                        ""))]
                    if (key) {
                        service = obj.item
                    }
                } else {
                    const key = Object.keys(obj.subitens)[Object.values(obj.subitens).indexOf(this.$route.path
                        .replace("/", ""))]
                    if (key) {
                        service = obj.subitens
                    }
                }
            }

            if (service) {
                this.blockStatus = service.has_permission
            } else {
                this.$router.push("dashboard")
            }
        },
    },
    mounted() {
        let self = this
        if (jwt_decode(localStorage.getItem('APP_01'))) {
            self.appKey = jwt_decode(localStorage.getItem('APP_01')).radio.key
            self.blockPage()
        }
    },
}

</script>

<style>
    h3 {
        text-align: center;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 178px;
        height: 178px;
        margin-top: 15px;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .div-avatar {
        text-align: center;
    }

    .content-tab {
        padding: 10px;
    }

    .div-premio {
        margin-top: -8px;
    }

    .div-btn-plus {
        margin-top: 8px;
    }

    .md-table-cell-selection {
        width: 66px !important;
    }

    .dialog-gifts {
        min-height: 70vh !important;
    }

    .div-remove {
        display: flex;
        align-items: baseline;
    }

    .btn-remove {
        margin-left: 30px;
    }

    .table-custom {
        border-spacing: 0;
        margin-bottom: 50px;
    }

    .table-custom tbody tr td {
        height: 60px;
    }

    .table-custom thead {
        text-align: left;
    }

    .table-custom thead tr {
        height: 50px;
        color: #74bf72;
    }

    .tbl-participantes {
        overflow: auto;
        position: relative;
    }

    .tbl-participantes p {
        position: absolute;
        bottom: 0;
    }

    p>button {
        margin-right: 10px;
        height: 25px;
        border-radius: 10px;
    }

    p>button:hover {
        cursor: pointer;
    }

    tbody .row_custom td {
        border-top: 1px solid;
        border-top-color: #e1e1e1;
    }

    .msg-error {
        border: 1px solid red;
        border-radius: 10px;
        color: red;
        text-align: center;
    }

    .datePickerError>input {
        color: red !important;
    }

    .container-btn-edit {
        display: flex;
    }

    .row-btn-edit {
        width: 15%;
    }

    /* MEDIA QUERIES */
    @media (max-width: 960px) {
        .div-img {
            text-align: center;
        }

        .tbl-participantes {
            overflow: auto;
            position: unset;
        }

        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            /* width: 268px; */
            /* height: 200px; */
            margin-top: 15px;
        }

        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }

        /* .el-tabs__content {
            min-height: 930px;
        } */

        button.md-button.md-warning.md-block.md-theme-default {
            min-width: 100%;
            min-height: 35px;
        }

        #pane-promocao {
            min-height: 1080px;
        }

        .row-btn-edit {
            margin-right: 50%;
        }

        .btns-edit {
            height: 28px !important;
            min-width: 28px !important;
            width: 28px !important;
        }
    }

    @media (max-width: 320px) {
        .div-img {
            text-align: center;
        }

        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 218px;
            height: 200px;
            margin-top: 15px;
        }
    }

    @media (min-width: 321px) and (max-width: 360px) {
        .div-img {
            text-align: center;
        }

        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 254px;
            height: 200px;
            margin-top: 15px;
        }
    }

    @media (min-width: 361px) and (max-width: 375px) {
        .div-img {
            text-align: center;
        }

        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 254px;
            height: 200px;
            margin-top: 15px;
        }
    }

    @media (min-width: 376px) and (max-width: 414px) {
        .div-img {
            text-align: center;
        }

        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 300px;
            height: 200px;
            margin-top: 15px;
        }
    }

</style>
