var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-card",
    { staticClass: "md-card-global-sales" },
    [
      _c(
        "md-card-header",
        {
          staticClass: "md-card-header-icon",
          class: _vm.getClass(_vm.headerColor),
        },
        [_vm._t("header")],
        2
      ),
      _c("md-card-content", [_vm._t("content")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }