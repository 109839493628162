var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "div",
          {
            staticClass: "md-layout-item md-size-100",
            staticStyle: { margin: "0 !important", padding: "0 !important" },
          },
          [
            _c("FilterSelector", {
              attrs: {
                filterForReport: false,
                buttonsToDisplay: [
                  _vm.buttonEnum.TODAY,
                  _vm.buttonEnum.SEVEN_DAYS,
                  _vm.buttonEnum.MONTH_TO_DATE,
                  _vm.buttonEnum.CUSTOM,
                ],
                extraFunction: true,
                extraFunctionLabel: "Novo",
              },
              on: {
                searchData: function ($event) {
                  return _vm.searchGifts()
                },
                extraFunction: function ($event) {
                  return _vm.newGift()
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: this.loading,
                expression: "this.loading",
              },
            ],
            staticClass: "md-layout-item md-size-100",
          },
          [_c("Spinner")],
          1
        ),
        [
          _vm.tableGifts.length > 0
            ? _c(
                "md-card",
                [
                  _c(
                    "md-card-header",
                    { staticClass: "md-card-header-icon md-card-header-green" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-icon" },
                        [_c("md-icon", [_vm._v("assignment")])],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "legend",
                          staticStyle: { "text-align": "right" },
                        },
                        [
                          _c("b", [_vm._v("Legenda:")]),
                          _c("i", {
                            staticClass: "fas fa-circle",
                            staticStyle: { color: "#ef6b6c" },
                          }),
                          _vm._v("Expirado "),
                          _c("i", {
                            staticClass: "fas fa-circle",
                            staticStyle: { color: "#4baf51" },
                          }),
                          _vm._v("Aberto "),
                          _c("i", {
                            staticClass: "fas fa-circle",
                            staticStyle: { color: "#469ddb" },
                          }),
                          _vm._v("Finalizado "),
                          _c("i", {
                            staticClass: "fas fa-circle",
                            staticStyle: { color: "#c9cacb" },
                          }),
                          _vm._v("Próximos "),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "md-card-content",
                    { staticStyle: { "padding-bottom": "0" } },
                    [
                      _c(
                        "md-table",
                        {
                          staticClass:
                            "paginated-table table-striped table-hover",
                          attrs: { value: _vm.queriedData },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "md-table-row",
                                fn: function ({ item }) {
                                  return _c(
                                    "md-table-row",
                                    {
                                      style:
                                        item.current == 1
                                          ? "background-color: lightyellow;"
                                          : "",
                                    },
                                    [
                                      item.is_closed == 1
                                        ? _c(
                                            "md-table-cell",
                                            {
                                              staticStyle: {
                                                width: "5px",
                                                color: "#469ddb",
                                              },
                                              attrs: { "md-label": "" },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-circle",
                                              }),
                                            ]
                                          )
                                        : item.expired == 1
                                        ? _c(
                                            "md-table-cell",
                                            {
                                              staticStyle: {
                                                width: "5px",
                                                color: "#ef6b6c !important",
                                              },
                                              attrs: { "md-label": "" },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-circle",
                                              }),
                                            ]
                                          )
                                        : item.current == 1
                                        ? _c(
                                            "md-table-cell",
                                            {
                                              staticStyle: {
                                                width: "5px",
                                                color: "#4baf51",
                                              },
                                              attrs: { "md-label": "" },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-circle",
                                              }),
                                            ]
                                          )
                                        : _c(
                                            "md-table-cell",
                                            {
                                              staticStyle: {
                                                width: "5px",
                                                color: "#c9cacb !important",
                                              },
                                              attrs: { "md-label": "" },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-circle",
                                              }),
                                            ]
                                          ),
                                      _c(
                                        "md-table-cell",
                                        {
                                          staticStyle: { width: "25px" },
                                          attrs: { "md-label": "" },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "40px",
                                              height: "40px",
                                              "border-radius": "5px",
                                            },
                                            attrs: { src: item.image_url },
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "md-table-cell",
                                        {
                                          attrs: {
                                            "md-label": "Título",
                                            "md-sort-by": "name",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.name))]
                                      ),
                                      _c(
                                        "md-table-cell",
                                        { attrs: { "md-label": "Descrição" } },
                                        [_vm._v(_vm._s(item.description))]
                                      ),
                                      [
                                        _c(
                                          "md-table-cell",
                                          { attrs: { "md-label": "Período" } },
                                          [_vm._v(_vm._s(item.date))]
                                        ),
                                      ],
                                      _c(
                                        "md-table-cell",
                                        { attrs: { "md-label": "Ações" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "container-btn-edit",
                                            },
                                            [
                                              _vm.user.level === 0 ||
                                              (item.is_closed == 0 &&
                                                item.opened == 1)
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "row-btn-edit-general",
                                                    },
                                                    [
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              "Editar promoção",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c("el-button", {
                                                            attrs: {
                                                              icon: "el-icon-edit",
                                                              circle: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openGift(
                                                                  item.id
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.is_closed == 0 &&
                                              item.current == 1
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "row-btn-edit-general",
                                                    },
                                                    [
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              "Visualizar",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c("el-button", {
                                                            attrs: {
                                                              icon: "el-icon-view",
                                                              circle: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openGiftView(
                                                                  item.id
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.is_closed == 0 &&
                                              item.expired == 1
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "row-btn-edit-general",
                                                    },
                                                    [
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              "Visualizar",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c("el-button", {
                                                            attrs: {
                                                              icon: "el-icon-view",
                                                              circle: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openGiftView(
                                                                  item.id
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.is_closed == 1
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "row-btn-edit-general",
                                                    },
                                                    [
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              "Visualizar",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c("el-button", {
                                                            attrs: {
                                                              icon: "el-icon-view",
                                                              circle: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openGiftView(
                                                                  item.id
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "row-btn-edit-general",
                                                },
                                                [
                                                  _c(
                                                    "el-tooltip",
                                                    {
                                                      attrs: {
                                                        effect: "dark",
                                                        content:
                                                          "Ver participantes",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: { circle: "" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openSort(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fa fa-users",
                                                            staticStyle: {
                                                              width: "100%",
                                                              height: "100%",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.user.level === 0 ||
                                              (item.is_closed == 0 &&
                                                item.opened == 1)
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "row-btn-edit-general",
                                                    },
                                                    [
                                                      _c(
                                                        "el-tooltip",
                                                        {
                                                          attrs: {
                                                            effect: "dark",
                                                            content:
                                                              "Deletar promoção",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _c("el-button", {
                                                            attrs: {
                                                              icon: "el-icon-delete",
                                                              circle: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteGift(
                                                                  item.id,
                                                                  item.image_url
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            2132680763
                          ),
                        },
                        [
                          _c(
                            "md-table-toolbar",
                            [
                              _c(
                                "md-field",
                                [
                                  _c("label", { attrs: { for: "pages" } }, [
                                    _vm._v("Por página"),
                                  ]),
                                  _c(
                                    "md-select",
                                    {
                                      attrs: { name: "pages" },
                                      model: {
                                        value: _vm.pagination.perPage,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.pagination,
                                            "perPage",
                                            $$v
                                          )
                                        },
                                        expression: "pagination.perPage",
                                      },
                                    },
                                    _vm._l(
                                      _vm.pagination.perPageOptions,
                                      function (item) {
                                        return _c(
                                          "md-option",
                                          {
                                            key: item,
                                            attrs: { label: item, value: item },
                                          },
                                          [_vm._v(" " + _vm._s(item) + " ")]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "md-card-actions",
                    { attrs: { "md-alignment": "space-between" } },
                    [
                      _c("div", {}, [
                        _c("p", { staticClass: "card-category" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.from + _vm.pageStart) +
                              " à " +
                              _vm._s(_vm.to) +
                              " de " +
                              _vm._s(_vm.total) +
                              " itens "
                          ),
                        ]),
                      ]),
                      _c("pagination", {
                        staticClass: "pagination-no-border pagination-success",
                        attrs: {
                          "per-page": _vm.pagination.perPage,
                          total: _vm.total,
                        },
                        model: {
                          value: _vm.pagination.currentPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.pagination, "currentPage", $$v)
                          },
                          expression: "pagination.currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "md-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.msgError && !this.loading,
                  expression: "msgError && !this.loading",
                },
              ],
              staticStyle: { "text-align": "center" },
            },
            [
              _c("md-card-content", [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Sem informações pra serem exibidas."),
                ]),
              ]),
            ],
            1
          ),
        ],
        _c("GiftForm", { attrs: { promoType: _vm.promoType } }),
        _c("TableOfParticipants"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }