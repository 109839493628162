<template>
    <div class="md-layout">
        <div v-show="!loading" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>
        <div v-show="loading && blockStatus == 1" class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <i class="material-icons">notification_important</i>
                    </div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout-item md-xsmall-size-100 md-small-size-75 md-medium-size-75 md-large-size-80 md-xlarge-size-90">
                        <md-field> 
                            <label>Digite o nome da música</label>
                            <md-input type="text" v-model="search" v-on:keyup.enter="onEnterClick"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-xsmall-size-100 md-small-size-25 md-medium-size-25 md-large-size-20 md-xlarge-size-10"> 
                        <md-button class="md-success md-block" @click="searchArtist()" :disabled="disabled"><i class="fas fa-search"></i> Buscar</md-button>
                    </div>
                </md-card-content>
            </md-card>

            <md-card v-if="dataArtist != null" v-for="(item, index) in dataArtist" :key="index" class="panel-media">
                <div class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-20 md-large-size-20 md-xlarge-size-20"> 
                    <md-card-media class="media-card">
                        <img v-if="item.image_url != null" :src="item.image_url" alt="People">
                        <img v-else src="../../assets/artist.jpg" alt="People">
                    </md-card-media>
                </div>    

                <div class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-20 md-large-size-20 md-xlarge-size-20 card-info card-flex"> 
                    <md-card-header class="header-card">
                        <div class="md-title">{{item.title}}</div>
                        <div class="md-subhead">Artista: {{item.artist}}</div>
                    </md-card-header>
                </div>
                <div class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-20 md-large-size-20 md-xlarge-size-20 options card-flex"> 
                    <md-card-header class="header-card">
                        <md-button class="md-info md-block" @click="getAlert(item.title, item.artist)"> Buscar pedidos</md-button>
                    </md-card-header> 
                </div>
            </md-card>
        </div>
        <block v-show="blockStatus == 0"></block>
    </div>
</template>
<script>    
    import Swal from 'sweetalert2'
    import models from '../../routes/models.js'
    import services from '../../routes/services.js'
    import selectRadio from '../../utils/events/select-radio.js'
    import mixpanelAdapter from '../../utils/events/mixpanelAdapter'
    import block from './Block.vue'
    import jwt_decode from "jwt-decode"
    export default {
        components: {
            block
        },
        data() {
            return {
                radio_current: jwt_decode(localStorage.getItem('APP_01')).radio,
                search: null,
                dataArtist: null,
                ids: [],
                tokens: [],
                loading: true,
                route: '',
                blockStatus: 1,
                disabled: true
            }
        },
        methods: {
            searchArtist() {
                if(this.search != null && this.radio_current.key != null) {
                    this.loading = false
                    Promise.resolve(this.getMusic())
                    .then(() => {
                        this.loading = true
                    })
                    .catch((e) => {
                        console.log(e)
                    })  
                }
            },

            //Busca pela música
            async getMusic() {
                 this.dataArtist = null
                await
                    models().get(`music/searchRequestedSongs?appKey=${this.radio_current.key}&searchArtist=${this.search}`)
                    .then((resp) => {
                        this.dataArtist = resp.data
                        this.loading = true
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            },

            //Busca número de pedidos da música encontrada
            getAlert(music, artist) {
                this.ids = []
                this.tokens = []
                let count = null
                Swal.fire({
                    title: "Buscando pedidos",
                    onOpen: () => {
                        Swal.showLoading()
                            models().get(`music/getRequestsTokensBySongs?appKey=${this.radio_current.key}&musics=${music}&artist=${artist}&groupId=2`)
                            .then((resp) => {
                                if(resp.data.length <= 0) {
                                    Swal.fire({
                                        title: `Pedidos musicais`,
                                        html: `Nenhum pedido musical foi encontrado pra essa música.`,
                                    })
                                } else {
                                    for (let item of resp.data) {
                                        this.tokens.push(item.device_token)
                                        this.ids.push(item.id_requested_song)
                                    }
                                    let current = null
                                    let count = 0
                                    for(let item of this.tokens) {
                                        if(item !== current) {
                                            current = item
                                            count =  count + 1
                                        } else {
                                            count = 1
                                        }
                                    }

                                    let txtTitle = 'Pedido musical';
                                    let txtHeader = 'Foi encontrado';
                                    let txtPedido = 'pedido';
                                    let txtPessoa = 'pessoa';
                                    if (count > 1) {
                                        txtPessoa = 'pessoas';
                                    }

                                    if (resp.data.length > 1) {
                                        txtHeader = 'Foram encontrados';
                                        txtTitle = 'Pedidos musicais';
                                        txtPedido = 'pedidos';
                                    }

                                    Swal.fire({
                                        title: txtTitle,
                                        html: `<b>${txtHeader}:</b><br>
                                                <ul style='display: inline-block; padding-left:0px !important; text-align:left;'>
                                                    <li>${resp.data.length} ${txtPedido}</li>
                                                    <li>${count} ${txtPessoa}</li>
                                                </ul>`,
                                        confirmButtonText: 'Enviar',
                                        showCancelButton: true,
                                        cancelButtonText: 'Cancelar'
                                    })
                                    .then((result) => {
                                        if (result.value) {
                                            this.registerAlert(music, artist)
                                            this.sendMixPanelEvent("musical_alert_emited")
                                        }else{
                                            this.sendMixPanelEvent("musical_alert_canceled")
                                        }
                                    })
                                }
                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    }
                })
            },

            //Atualiza status do alerta musical
            async updateAlert(id) {
                let formData = {
                    ids: id
                }
                await 
                    models().post(`music/updateStatusRequestedSong`, formData)
                    .then((resp) => {
                        // console.log(resp)
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            },
            
            //Cadastrar o alerta musical
            async registerAlert(music, artist){
                const typeId = 2
                const title = "Alerta Musical"
                const message = `Você pediu a música ${music} de ${artist}. Se liga na ${this.radio_current.name} que sua música já vai tocar!`
                const sender = jwt_decode(localStorage.getItem('SET_APP')).user[0].email
                const status = "Enviado"
                let date = new Date()
                let time = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds()
                date = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate() + ' ' + time
                await 
                    models().get(`music/sendMusicAlert?push_notification_type_id=${typeId}&app_key=${this.radio_current.key}&title=${title}&message=${message}&date=${date}&sender=${sender}&status=${status}`)
                    .then((resp) => {
                        if(resp.data) {
                            this.sendAlertMusical(this.tokens, this.ids, title, message, resp.data[0].service_account)
                        }
                    })
                    .catch((e) => {
                        Swal(
                            'Falha',
                            'Erro ao cadastrar notificação. Verifique se a mesma já existe.',
                            'error'
                        )
                        console.log(e)
                    })
            },

            sendAlertMusical(tokens, ids, title, message, serviceAccount) {
                const serverKey = serviceAccount ? serviceAccount: this.radio_current.server_key;
                
                Swal.fire({
                    title: "Enviando alertas...",
                    onOpen: () => {
                        Swal.showLoading()
                        services().post('services/alertMusical', {
                            serverKey: serverKey,
                            tokens: tokens,
                            ids: ids,
                            title: title,
                            message: message
                        })
                        .then((resp) => {
                            if(resp) {
                                this.updateAlert(ids.join(', '))
                                Swal(
                                    'Pronto',
                                    'Os alertas foram enviados com sucesso!',
                                    'success'
                                )
                                this.ids = []
                                this.tokens = []
                                this.getMusic()
                            }
                        })
                        .catch((e) => {
                            Swal(
                                'Erro',
                                'Tivemos uma falha ao enviar os alertas, por favor, tente novamente.',
                                'error'
                            )
                            console.log(e)
                        })
                    }
                })
            },

            blockPage() {
                const menu = jwt_decode(localStorage.getItem('APP_02')).services
                let service = null
                for(let obj of menu) {
                    if(obj.subitens === null) {
                        const key = Object.keys(obj.item)[Object.values(obj.item).indexOf(this.$route.path.replace("/", ""))]
                        if(key) {
                            service = obj.item
                        }
                    } else {
                        const key = Object.keys(obj.subitens)[Object.values(obj.subitens).indexOf(this.$route.path.replace("/", ""))]
                        if(key) {
                            service = obj.subitens
                        }
                    }
                }
                
                if(service) {
                    this.blockStatus = service.has_permission
                } else {
                    this.$router.push("dashboard")
                }
            },
            findObjectByKey(array, key, value) {
                for (var i = 0; i < array.length; i++) {
                    if (array[i][key] === value) {
                        return array[i];
                    }
                }
                return null;
            },

            onEnterClick() {
                if(this.disabled == false) {
                    this.searchArtist()
                }
            },
            sendMixPanelEvent(eventString){
                mixpanelAdapter.track(eventString, {
                    screen_name: this.$route.name
                })
            }
        },
        mounted() {
            if(localStorage.getItem('APP_01')) {
                this.radio_current.key = jwt_decode(localStorage.getItem('APP_01')).radio.key
                this.blockPage()
            }
            selectRadio.$on('selectRadio', (payload) => {
                this.radio_current.key = payload.key
                this.radio_current.serverKey = payload.server_key
                this.blockPage()
            })
        },
        watch: {
            search: function (param) { 
                if(param.length >= 2) {
                    this.disabled = false
                } else {
                    this.disabled = true
                }
            }
        }
    }
</script>
<style scoped>
    .panel-media {
        cursor: pointer;
    }
    .media-card{
        /* padding: 10px; */
    }

    @media (min-width: 600px) {
        img {
            max-width: 128px;
            max-height: 128px;
        }
        .panel-media {
            padding: 10px;
            flex-direction: row;
        }
        .md-card-content {
            display: flex;
        }
    }
    @media (max-width: 960px) {
        .media-card{
            margin: auto;
            width: 60%;
            padding: 10px;
        }
        .card-info {
            display: contents;
            text-align: center;
        }
    }
    @media (min-width: 600px) {
        .card-flex {
            display: flex !important;
            align-items: center !important;
        }
    }
</style>