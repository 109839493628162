<template>
    <div>
        <div
            class="row-channel"
            v-for="(channel, key) in dataChannels"
            :key="key"
        >
            <div
                class="row-child"
                style="width: 17%;display: flex;justify-content: center;"
            >
                <img
                    style="width:200px;border-radius: 10px;"
                    :src="channel.image"
                    alt
                />
            </div>
            <div class="row-child" style="width:50%">
                <h4 style="font-weight: 400;margin: auto;">
                    {{ channel.title }}
                </h4>
                <br />
                {{ channel.episodes.length }} Episódio(s)
                <br />
                <md-button
                    class="md-success md-sm"
                    @click="copyToClipBoard(channel.id)"
                    :value="channel.id"
                >
                    <i class="fas fa-rss"></i> Feed RSS </md-button
                >&nbsp;
                <md-button
                    class="md-warning md-sm"
                    @click="editChannel(channel.id)"
                >
                    <i class="fas fa-edit"></i> Editar </md-button
                >&nbsp;
                <md-button
                    class="md-danger md-sm"
                    @click="deleteChannels(channel.id)"
                >
                    <i class="fas fa-trash"></i> Excluir
                </md-button>
                <br />

                <div style="margin-top: 25px;">
                    <span
                        style="margin-top: 50px;font-style: italic;"
                        class="pub-date"
                        >Criado em:
                        {{
                            new Date(channel.created).toLocaleString("pt-BR", {
                                timeZone: "America/Belem"
                            })
                        }}</span
                    >
                </div>
            </div>
        </div>

        <div v-show="this.dataChannels.length <= 0" class="row-channel">
            <span>Nenhum canal foi cadastrado.</span>
        </div>
    </div>
</template>

<script>
import { Tabs, TabPane, Select, Option, Input, Pagination } from "element-ui";
import ApiFirebase from "../../../../routes/api-firebase";
import Swal from "sweetalert2";
import ChannelEdit from "./Edit";
import swal from "sweetalert2";
import jwt_decode from "jwt-decode"

function windowCopyClipboard() {
    window.Clipboard = (function(window, document, navigator) {
        var textArea, copy;

        function isOS() {
            return navigator.userAgent.match(/ipad|iphone/i);
        }

        function createTextArea(text) {
            textArea = document.createElement("textArea");
            textArea.value = text;
            document.body.appendChild(textArea);
        }

        function selectText() {
            var range, selection;

            if (isOS()) {
                range = document.createRange();
                range.selectNodeContents(textArea);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                textArea.setSelectionRange(0, 999999);
            } else {
                textArea.select();
            }
        }

        function copyToClipboard() {
            document.execCommand("copy");
            document.body.removeChild(textArea);
        }

        copy = function(text) {
            createTextArea(text);
            selectText();
            copyToClipboard();
        };

        return {
            copy: copy
        };
    })(window, document, navigator);
}

export default {
    components: {
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
        ChannelEdit,
        [Select.name]: Select,
        [Option.name]: Option,
        [Input.name]: Input,
        [Pagination.name]: Pagination
    },
    data() {
        return {
            radio: jwt_decode(localStorage.getItem('APP_01')).radio,
            dataChannels: []
        };
    },
    methods: {
        getChannels() {
            swal.showLoading();
            ApiFirebase()
                .get(`channels/${this.radio.key}`)
                .then(response => {
                    this.dataChannels = response.data.sort(function(a,b){
                        return new Date(b.created) - new Date(a.created);
                    });
                    this.$root.$emit("listChannels", response.data);
                    swal.close();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        deleteChannels(id) {
            let self = this;
            Swal.fire({
                title: "Apagar Canal",
                text:
                    "Deseja mesmo fazer isso? O canal e todos os episódios serão excluídos e o processo não poderá ser revertido",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: "Cancelar",
                confirmButtonText: "Confirmar"
            }).then(result => {
                if (result.value) {
                    Swal.showLoading();
                    ApiFirebase()
                        .post("deleteChannel", { id: id })
                        .then(resp => {
                            Swal(
                                "Pronto",
                                "O canal foi apagado com sucesso!",
                                "success"
                            );
                            self.$root.$emit("reloadGet", true);
                        })
                        .catch(err => console.log(err));
                }
            });
        },
        editChannel(id) {
            this.$root.$emit("editChannel", { show: true, id: id });
        },
        copyToClipBoard(value) {
            Clipboard.copy(
                `https://us-central1-api-mobradio.cloudfunctions.net/app/getFeedRSS/${value}`
            );
            Swal({
                type: "success",
                title: "Copiado para área de transferência",
                showConfirmButton: false,
                timer: 2500
            });
        }
    },
    mounted() {
        this.getChannels();
        let self = this;
        self.$root.$on("reloadGet", payload => {
            self.getChannels();
        });

        windowCopyClipboard();
    }
};
</script>

<style lang="css">
.row-channel {
    display: flex;
    border-bottom: 1px solid #e4e7ed;
    margin-bottom: 20px;
    padding: 10px;
}
</style>
