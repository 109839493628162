var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-card",
    { staticClass: "hide-on-print", attrs: { "md-with-hover": "" } },
    [
      _c("md-card-header", [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
            staticStyle: { "margin-top": "1rem" },
          },
          [
            _vm.mobAd.expired === 1
              ? [
                  _c("i", {
                    staticClass: "fas fa-circle",
                    staticStyle: { color: "#ef6b6c" },
                  }),
                  _vm._v("  "),
                  _c("span", { staticStyle: { color: "#ef6b6c" } }, [
                    _vm._v("FINALIZADA"),
                  ]),
                ]
              : _vm.mobAd.current === 1
              ? [
                  _c("i", {
                    staticClass: "fas fa-circle",
                    staticStyle: { color: "#4baf51" },
                  }),
                  _vm._v("  "),
                  _c("span", { staticStyle: { color: "#4baf51" } }, [
                    _vm._v("ABERTA"),
                  ]),
                ]
              : _vm.mobAd.opened === 0
              ? [
                  _c("i", {
                    staticClass: "fas fa-circle",
                    staticStyle: { color: "#c9cacb" },
                  }),
                  _vm._v("  "),
                  _c("span", { staticStyle: { color: "#c9cacb" } }, [
                    _vm._v("PRÓXIMA"),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c("md-card-content", [
        _c("div", { staticClass: "md-layout" }, [
          _c("div", { staticClass: "md-layout-item md-size-100" }, [
            _c("h3", { staticClass: "mobAd-name-title" }, [
              _vm._v(_vm._s(_vm.mobAd.name)),
            ]),
            _c("em", [
              _vm._v(
                _vm._s(
                  new Date(_vm.mobAd.start_date).toLocaleDateString("pt-BR")
                ) +
                  " às " +
                  _vm._s(_vm.mobAd.start_time.slice(0, -3))
              ),
            ]),
            _c("span", [_vm._v(" até ")]),
            _c("em", [
              _vm._v(
                _vm._s(
                  new Date(_vm.mobAd.end_date).toLocaleDateString("pt-BR")
                ) +
                  " às " +
                  _vm._s(_vm.mobAd.end_time.slice(0, -3))
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "md-layout-item md-size-100",
              staticStyle: { margin: "1rem 0" },
            },
            [
              _c(
                "div",
                { staticClass: "md-layout md-alignment-center-space-between" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-size-50 md-xsmall-size-100",
                      staticStyle: { padding: "0" },
                    },
                    [
                      _c(
                        "h5",
                        {
                          staticStyle: { display: "inline-block", margin: "0" },
                        },
                        [_vm._v("Status: ")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "md-layout-item md-size-50",
                      staticStyle: { "padding-right": "0" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "md-layout md-alignment-center-right" },
                        [
                          _vm.mobAd.active
                            ? _c("span", { staticClass: "label-status" }, [
                                _vm._v("ATIVADO"),
                              ])
                            : _c("span", { staticClass: "label-status" }, [
                                _vm._v("DESATIVADO"),
                              ]),
                          _c("el-switch", {
                            ref: "switch-status",
                            staticClass: "md-primary",
                            attrs: { disabled: _vm.mobAd.expired === 1 },
                            on: {
                              change: function ($event) {
                                return _vm.switchStatus()
                              },
                            },
                            model: {
                              value: _vm.mobAd.active,
                              callback: function ($$v) {
                                _vm.$set(_vm.mobAd, "active", $$v)
                              },
                              expression: "mobAd.active",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-size-100" },
            [
              _c(
                "md-button",
                {
                  ref: "edit-button",
                  staticClass: "md-info md-simple md-block outline",
                  attrs: { disabled: _vm.mobAd.expired === 1 },
                  on: {
                    click: function ($event) {
                      return _vm.editCampaign()
                    },
                  },
                },
                [_c("span", [_vm._v("Editar")])]
              ),
              _c(
                "md-button",
                {
                  ref: "banners-button",
                  staticClass: "md-info md-simple md-block outline",
                  on: {
                    click: function ($event) {
                      return _vm.previewCampaign()
                    },
                  },
                },
                [_vm._v("Ver banners")]
              ),
              _c(
                "md-button",
                {
                  ref: "report-button",
                  staticClass: "md-info md-block",
                  on: {
                    click: function ($event) {
                      return _vm.getMobAdReport()
                    },
                  },
                },
                [_vm._v("Ver Relatório")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }