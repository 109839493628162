<template>
	<ReportLayout 
		:radioName="radioName" 
		:radioLogo="radioLogo" 
		reportName="Relatório de MobAd" 
		:sublabel="mobAdName"
		:periodStart="periodStart" 
		:periodEnd="periodEnd" 
		:numberOfPages="2">
		<template v-slot:page-1>
			<div class="md-layout-item md-size-100" 
				style="margin: 0 auto;">
				<ReportCard
					:title="'Estatísticas gerais'" 
					:labels="['Visualizações', 'Cliques', '*CTR', 'Visualizações únicas', 'Cliques únicos']"
					:keys="['totalViews', 'totalClicks', 'totalCTR', 'totalUniqueViews', 'totalUniqueClicks']" 
					:cardData="[{
						totalViews: $options.filters.formatNumber(data.totalViews),
						totalClicks: $options.filters.formatNumber(data.totalClicks),
						totalCTR: `${data.totalCTR}%`,
						totalUniqueViews: $options.filters.formatNumber(data.totalUniqueViews),
						totalUniqueClicks: $options.filters.formatNumber(data.totalUniqueClicks),
					}]" 
					:dataDisplay="'big-columns'">
					<template v-slot:report-subtitle-footer>
						<strong>*CTR: </strong>
						<i>Click-through rate</i>,
						traduzindo, taxa de cliques. Divisão da quantidade
						de cliques pela de visualizações.
					</template>
				</ReportCard>
			</div>
			<div class="md-layout-item md-size-100" style="margin: 0 auto;">
				<ReportCard 
					:title="'Estatísticas por plataforma'"
					:labels="['Plataforma', 'Visualizações', 'Cliques', 'CTR', 'Visualizações únicas', 'Cliques únicos']"
					:keys="['os', 'views', 'clicks', 'ctr', 'unique_views', 'unique_clicks']"
					:cardData="data.statisticsPerPlatform"
					:dataDisplay="'short-table'">
				</ReportCard>
			</div>
		</template>
		<template v-slot:page-2>
			<ReportCard 
				:title="'Estatísticas por área de dispositivo'" 
				:labels="['Área', 'Visualizações', 'Cliques', 'CTR', 'Visualizações únicas', 'Cliques únicos']"
				:keys="['zone_name', 'views', 'clicks', 'ctr', 'unique_views', 'unique_clicks']" 
				:cardData="data.statisticsPerZone"
				:dataDisplay="'long-table'">
			</ReportCard>
			<ReportCard 
				:title="'Estatísticas por banner'" 
				:labels="['Nome', 'Área', 'Visualizações', 'Cliques', 'CTR', 'Visualizações únicas', 'Cliques únicos']"
				:keys="['name', 'zone', 'views', 'clicks', 'ctr', 'unique_views', 'unique_clicks']" 
				:cardData="data.statisticsPerBanner"
				:dataDisplay="'long-table'">
				<template v-slot:report-subtitle-header>
					<div class="md-layout">
						<p style="padding: 0; margin:0; text-align: left;" class="md-layout-item md-size-100">
							Plataformas analisadas:
						</p>
						<div style="padding: 0; margin:0; text-align: left; margin-bottom: 14px;"
							class="md-layout-item md-size-100">
							<template v-for="(platform) in data.platformsSelected">
								<p class="platform-label">
									<img :src="platform.icon">
									<span>{{ platform.name }}</span>
								</p>
							</template>
						</div>
					</div>
				</template>
			</ReportCard>
		</template>
	</ReportLayout>
</template>

<script>
import Vue from "vue";
import ReportLayout from "./partials/ReportLayout.vue";
import ReportCard from "./partials/ReportCard.vue";
import numeral from "numeral";
numeral.locale("pt-br");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

export default {
	name: "TemplateReportMobAd",
	components: {
		ReportLayout,
		ReportCard
	},
	props: [
		"radioName",
		"radioLogo",
		"mobAdName",
		"periodStart",
		"periodEnd",
		"data"
	]
};
</script>

<style>

.platform-label {
	text-align: left;
	margin-right: 20px;
	display: inline-block;
}

.platform-label img {
	height: 15px;
	width: 15px;
	margin-right: 5px;
	margin-top: -5px;
}

.platform-label span {
	font-size: 100%;
	white-space: nowrap;
}

</style>