var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "md-layout", staticStyle: { display: "contents" } },
      [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
          },
          [
            _c("div", { staticClass: "spinner" }, [
              _c("div", { staticClass: "rect1" }),
              _c("div", { staticClass: "rect2" }),
              _c("div", { staticClass: "rect3" }),
              _c("div", { staticClass: "rect4" }),
              _c("div", { staticClass: "rect5" }),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }