var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        },
        [_vm._m(0)]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading && _vm.blockStatus == 1,
              expression: "!loading && blockStatus == 1",
            },
          ],
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size100",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size100",
            },
            [
              _c(
                "md-card",
                [
                  _c("md-card-header", [
                    _c(
                      "div",
                      { staticClass: "card-icon" },
                      [_c("md-icon", [_vm._v("input")])],
                      1
                    ),
                  ]),
                  _c("md-card-content", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                      },
                      [
                        _c(
                          "md-field",
                          [
                            _c("label", { attrs: { for: "period" } }, [
                              _vm._v("Escolher período"),
                            ]),
                            _c(
                              "md-select",
                              {
                                model: {
                                  value: _vm.period,
                                  callback: function ($$v) {
                                    _vm.period = $$v
                                  },
                                  expression: "period",
                                },
                              },
                              [
                                _c("md-option", { attrs: { value: "0" } }, [
                                  _vm._v("Alcance do dia"),
                                ]),
                                _c("md-option", { attrs: { value: "1" } }, [
                                  _vm._v("Alcance da última hora"),
                                ]),
                                _c("md-option", { attrs: { value: "2" } }, [
                                  _vm._v("Alcance das últimas 4 horas"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.info,
                  expression: "info",
                },
              ],
              staticClass:
                "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100",
            },
            [
              _c(
                "md-card",
                [
                  _c(
                    "md-card-header",
                    {
                      staticClass:
                        "md-card-header md-card-header-blue md-card-header-icon",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "card-icon" },
                        [_c("md-icon", [_vm._v("timeline")])],
                        1
                      ),
                    ]
                  ),
                  _c("md-card-content", [
                    _c("canvas", { attrs: { id: "getGraphicPerMinute" } }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "md-layout" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.info,
                    expression: "info",
                  },
                ],
                staticClass:
                  "md-layout-item md-small-size-100 md-large-size-50 md-xlarge-size-50",
              },
              [
                _c(
                  "md-card",
                  [
                    _c("md-card-header", [
                      _c(
                        "div",
                        { staticClass: "card-icon card-red" },
                        [_c("md-icon", [_vm._v("access_time")])],
                        1
                      ),
                      _c("h4", [_vm._v("Últimos 20 minutos")]),
                    ]),
                    _c(
                      "md-card-content",
                      { staticClass: "heigthTable" },
                      [
                        _c("md-table", {
                          ref: "tableLastMinutes",
                          attrs: { "table-header-color": "green" },
                          scopedSlots: _vm._u([
                            {
                              key: "md-table-row",
                              fn: function ({ item }) {
                                return _c(
                                  "md-table-row",
                                  {},
                                  [
                                    _c(
                                      "md-table-cell",
                                      { attrs: { "md-label": "Hora" } },
                                      [_vm._v(_vm._s(item.hour))]
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { attrs: { "md-label": "Ouvintes" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatNumber")(
                                              item.listeners
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { attrs: { "md-label": "Tune In" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatNumber")(item.tunein)
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { attrs: { "md-label": "Tune Out" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatNumber")(item.tuneout)
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              },
                            },
                          ]),
                          model: {
                            value: _vm.tableLastMinutes,
                            callback: function ($$v) {
                              _vm.tableLastMinutes = $$v
                            },
                            expression: "tableLastMinutes",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.info,
                    expression: "info",
                  },
                ],
                staticClass:
                  "md-layout-item md-small-size-100 md-large-size-50 md-xlarge-size-50",
              },
              [
                _c(
                  "md-card",
                  [
                    _c("md-card-header", [
                      _c(
                        "div",
                        { staticClass: "card-icon card-darkorange" },
                        [_c("md-icon", [_vm._v("access_time")])],
                        1
                      ),
                      _c("h4", [_vm._v("Alcance médio por hora")]),
                    ]),
                    _c(
                      "md-card-content",
                      { staticClass: "heigthTable" },
                      [
                        _c("md-table", {
                          attrs: { "table-header-color": "green" },
                          scopedSlots: _vm._u([
                            {
                              key: "md-table-row",
                              fn: function ({ item }) {
                                return _c(
                                  "md-table-row",
                                  {},
                                  [
                                    _c(
                                      "md-table-cell",
                                      { attrs: { "md-label": "Hora" } },
                                      [_vm._v(_vm._s(item.hora))]
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { attrs: { "md-label": "Ouvintes/Min" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatNumber")(
                                              item.total_listeners
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { attrs: { "md-label": "Tune In/Min" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatNumber")(
                                              item.total_tunein
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "md-table-cell",
                                      { attrs: { "md-label": "Tune Out/Min" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatNumber")(
                                              item.total_tuneout
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              },
                            },
                          ]),
                          model: {
                            value: _vm.tableDataAverage,
                            callback: function ($$v) {
                              _vm.tableDataAverage = $$v
                            },
                            expression: "tableDataAverage",
                          },
                        }),
                        _c("h4", [_vm._v("Hora com maior alcance médio")]),
                        _c(
                          "md-table",
                          { attrs: { "table-header-color": "green" } },
                          [
                            _c(
                              "md-table-row",
                              {
                                attrs: { slot: "md-table-row" },
                                slot: "md-table-row",
                              },
                              [
                                _c(
                                  "md-table-cell",
                                  { attrs: { "md-label": "Horário" } },
                                  [
                                    _c("b", [_vm._v("Horário: ")]),
                                    _vm._v(_vm._s(_vm.hourMax)),
                                  ]
                                ),
                                _c(
                                  "md-table-cell",
                                  { attrs: { "md-label": "Ouvintes/Min" } },
                                  [
                                    _c("b", [_vm._v("Ouvintes/Min: ")]),
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatNumber")(_vm.listenerMax)
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.info,
                  expression: "!info",
                },
              ],
              staticClass:
                "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100",
            },
            [
              _c(
                "md-card",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100",
                  staticStyle: { "text-align": "center" },
                },
                [_c("h4", [_vm._v("Sem informações no momento.")])]
              ),
            ],
            1
          ),
        ]
      ),
      _c("block", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.blockStatus == 0,
            expression: "blockStatus == 0",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "spinner" }, [
      _c("div", { staticClass: "rect1" }),
      _c("div", { staticClass: "rect2" }),
      _c("div", { staticClass: "rect3" }),
      _c("div", { staticClass: "rect4" }),
      _c("div", { staticClass: "rect5" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }