var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-card",
    [
      _c("md-card-content", [
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "div",
            {
              staticClass:
                "md-layout-item md-xsmall-size-100 md-small-size-60 md-medium-size-60 md-large-size-60 md-xlarge-size-60 align-center",
            },
            [
              _c(
                "md-field",
                [
                  _c("label", [_vm._v("Nome do banner")]),
                  _c("md-input", {
                    attrs: { disabled: _vm.isOldBanner },
                    model: {
                      value: _vm.bannerData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.bannerData, "name", $$v)
                      },
                      expression: "bannerData.name",
                    },
                  }),
                ],
                1
              ),
              _vm.bannerData.errorEmptyField && !_vm.bannerData.name
                ? _c("span", { staticClass: "error-span" }, [
                    _vm._v("Preencha este campo"),
                  ])
                : _vm._e(),
              _c(
                "md-field",
                [
                  _c("label", [_vm._v("URL do banner")]),
                  _c("md-input", {
                    attrs: { disabled: _vm.isOldBanner },
                    model: {
                      value: _vm.bannerData.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.bannerData, "url", $$v)
                      },
                      expression: "bannerData.url",
                    },
                  }),
                ],
                1
              ),
              _vm.bannerData.errorEmptyField && !_vm.bannerData.url
                ? _c("span", { staticClass: "error-span" }, [
                    _vm._v("Preencha este campo"),
                  ])
                : _vm._e(),
              _c(
                "md-field",
                [
                  _c("label", [_vm._v("Área do banner")]),
                  _c(
                    "md-select",
                    {
                      attrs: { disabled: _vm.isOldBanner },
                      model: {
                        value: _vm.bannerData.idZone,
                        callback: function ($$v) {
                          _vm.$set(_vm.bannerData, "idZone", $$v)
                        },
                        expression: "bannerData.idZone",
                      },
                    },
                    _vm._l(_vm.bannerZonesToSelect, function (zone) {
                      return _c(
                        "md-option",
                        { key: zone.id, attrs: { value: zone.id } },
                        [
                          _vm._v(
                            " " + _vm._s(`${zone.id} - ${zone.name}`) + " "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.bannerData.errorEmptyField && !_vm.bannerData.idZone
                ? _c("span", { staticClass: "error-span" }, [
                    _vm._v("Preencha este campo"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.bannerData.idZone
            ? _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xsmall-size-100 md-small-size-40 md-medium-size-40 md-large-size-40 md-xlarge-size-40 align-center",
                  staticStyle: { padding: "15px", "text-align": "center" },
                },
                [
                  _vm.bannerData.image || _vm.bannerData.file
                    ? [
                        _c("img", {
                          staticStyle: {
                            "max-height": "130px",
                            width: "auto",
                            "object-fit": "contain",
                          },
                          attrs: { src: _vm.bannerData.image },
                        }),
                      ]
                    : [
                        _c("img", {
                          staticStyle: {
                            "max-height": "130px",
                            "object-fit": "contain",
                          },
                          attrs: {
                            src: require("../../../assets/image-award.png"),
                          },
                        }),
                      ],
                  _c("p", [
                    _vm.bannerData.errorEmptyField &&
                    !(_vm.bannerData.image || _vm.bannerData.file)
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "red",
                              "font-style": "italic",
                              display: "block",
                            },
                          },
                          [_vm._v("(Selecione uma imagem)")]
                        )
                      : _vm._e(),
                    _vm._v(" Dimensões da imagem: "),
                    _c("span", { staticStyle: { "font-style": "italic" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.bannerZonesToSelect.filter(
                              (zone) => zone.id === _vm.bannerData.idZone
                            )[0].width
                          ) +
                          "px x " +
                          _vm._s(
                            _vm.bannerZonesToSelect.filter(
                              (zone) => zone.id === _vm.bannerData.idZone
                            )[0].height
                          ) +
                          "px "
                      ),
                    ]),
                  ]),
                  _vm.errorUpload
                    ? _c("p", { staticStyle: { color: "red" } }, [
                        _vm._v(" " + _vm._s(_vm.errorUpload) + " "),
                      ])
                    : _vm._e(),
                  _c("input", {
                    ref: "imageUploaded",
                    staticStyle: { display: "none" },
                    attrs: {
                      id: "upload_" + this.$props.id,
                      type: "file",
                      accept: "image/*",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.loadFileAndCheckResolution($event)
                      },
                    },
                  }),
                ],
                2
              )
            : _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xsmall-size-100 md-small-size-40 md-large-size-40 md-xlarge-size-40 align-center",
                },
                [
                  _c("h4", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(
                      "Selecione a área do banner para escolher uma imagem"
                    ),
                  ]),
                ]
              ),
          _c("div", { staticClass: "md-layout" }, [
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item hide-on-xsmall-screen md-small-size-60 md-medium-size-60 md-large-size-60 md-xlarge-size-60 md-xsmall-hide",
              },
              [
                !_vm.disableDelete
                  ? _c(
                      "md-button",
                      {
                        staticClass: "md-danger",
                        on: {
                          click: function ($event) {
                            return _vm.deleteBanner()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-trash" }),
                        _vm._v(" Apagar banner "),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-xsmall-size-100 md-small-size-40 md-medium-size-40 md-large-size-40 md-xlarge-size-40",
              },
              [
                _vm.bannerData.idZone && !_vm.isOldBanner
                  ? _c(
                      "label",
                      {
                        staticClass: "md-button md-info",
                        attrs: { for: "upload_" + this.$props.id },
                      },
                      [
                        _c("div", { staticClass: "md-ripple" }, [
                          !_vm.bannerData.file
                            ? _c("i", { staticClass: "fa fa-plus" })
                            : _c("i", { staticClass: "fa fa-edit" }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.bannerData.file
                                  ? "Editar imagem"
                                  : "Adicionar Imagem"
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "md-layout-item md-xsmall-size-100 show-on-xsmall-screen",
              },
              [
                !_vm.disableDelete
                  ? _c(
                      "md-button",
                      {
                        staticClass: "md-danger",
                        on: {
                          click: function ($event) {
                            return _vm.deleteBanner()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-trash" }),
                        _vm._v(" Apagar banner "),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }