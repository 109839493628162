<template>
    <div class="md-layout">
        <div v-show="loading" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>

        <div v-if="infoPlan[0]" class="md-layout">
            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>important_devices</md-icon>
                        </div>
                        <h4 class="title"> Informações do Plano</h4>
                    </md-card-header>
                    <md-card-content id="contentPlan">
                        <div class="md-layout">
                            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <h2>{{infoPlan[0].plan.name}}</h2>
                                </div>
                            </div>
                            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <label>
                                        <b>ID da Inscrição</b>: {{infoPlan[0].id}}</label>
                                </div>
                            </div>
                            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <label>
                                        <b>Início do plano</b>: {{infoPlan[0].date_created}}</label>
                                </div>
                            </div>
                            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <label>
                                        <b>Valor do Plano</b>: R${{infoPlan[0].plan.amount}}</label>
                                </div>
                            </div>
                            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <label>
                                        <b>Status</b>: {{infoPlan[0].status}}</label>
                                </div>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>

                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>payment</md-icon>
                        </div>
                        <h4 class="title"> Informações de Pagamento</h4>
                    </md-card-header>
                    <md-card-content>
                        <div class="md-layout">
                            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <label>
                                        <b>Método</b>: {{infoPlan[0].payment_method}}</label>
                                </div>
                            </div>
                            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <label>
                                        <b>Bandeira</b>: {{infoPlan[0].card.brand}}</label>
                                </div>
                            </div>
                            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <label>
                                        <b>Últimos dígitos</b>: {{infoPlan[0].card.last_digits}}</label>
                                </div>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>

            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>clear_all</md-icon>
                        </div>
                        <h4 class="title"> Últimas faturas</h4>
                    </md-card-header>
                    <md-card-content style="min-height:240px;">
                        <div class="md-layout" v-for="(item, key) of infoPlan" :key="key">
                            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <label>
                                        <b>Valor pago</b>: R$199,99</label>
                                </div>
                            </div>
                            <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                    <label>
                                        <b>Data</b>: {{item.date_updated}}</label>
                                </div>
                            </div>
                        </div>

                    </md-card-content>
                </md-card>
            </div>
        </div>

        <div v-if="!loading && !plan" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                <md-card>
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>https</md-icon>
                        </div>
                        <h4 class="title"> Informações do Plano</h4>
                    </md-card-header>
                    <md-card-content>
                        <div class="md-layout" style="text-align:center">
                            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                                <h2>Plano Grátis</h2>
                                <p>Atualmente você contempla o plano grátis. Faço upgrade para o Plano Premium e tenha muito
                                    mais!</p>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-medium-size-33 md-large-size-33 md-xlarge-size-33" v-for="(item, key) in allPlans" :key="key">
                    <pricing-card icon-color="icon-info">
                        <md-icon slot="icon">important_devices</md-icon>
                        <h3 slot="title" class="title"> {{item.name}}
                            <br>
                            <span class="price">{{item.amount }}</span>
                        </h3>
                        <md-button slot="footer" class="md-info md-round" @click="choicePlan(item.id)">Escolher plano</md-button>
                    </pricing-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Vue from 'vue'
    import selectRadio from '../../../utils/events/select-radio.js'
    import Swal from 'sweetalert2'
    import axios from 'axios';
    import models from '../../../routes/models.js'
    import { Dialog, Button, Tabs, TabPane, Input } from 'element-ui'
    import { StatsCard } from '@/components'
    import { PricingCard, ChartCard } from '@/components'
    import { SlideYDownTransition } from 'vue2-transitions'
    const numeral = require('numeral')
    const locale = require('numeral/locales')
    numeral.locale('pt-br')
    Vue.filter("formatMoney", function (value) {
        return numeral(value).format('$ 0,0[.]00'); // displaying other groupings/separators is possible, look at the docs
    })

    export default {
        components: {
            ChartCard,
            StatsCard,
            PricingCard,
            SlideYDownTransition,
            [Button.name]: Button,
            [Dialog.name]: Dialog,
            [Tabs.name]: Tabs,
            [TabPane.name]: TabPane,
            [Input.name]: Input
        },
        data() {
            return {
                allPlans: [],
                key: JSON.parse(localStorage.getItem('radio-default')).key,
                loading: true,
                plan: JSON.parse(localStorage.getItem('user')).pagarme,
                updatePlan: false,
                infoPlan: [],
                card_number: '',
                card_holder_name: '',
                card_expiration_month: '',
                card_expiration_year: '',
                card_cvv: '',
                card_cpf_cnpj: '',
                cloudURL: '',

                touched: {
                    card_number: false,
                    card_holder_name: false,
                    card_expiration_month: false,
                    card_nucard_expiration_yearmber: false,
                    card_cvv: false,
                    card_cpf_cnpj: false
                },

                modelValidations: {
                    card_number: {
                        required: true,
                        min: 16
                    },
                    card_holder_name: {
                        required: true,
                        min: 5
                    },
                    card_expiration_month: {
                        required: true,
                        min: 2
                    },
                    card_expiration_year: {
                        required: true,
                        min: 4
                    },
                    card_cvv: {
                        required: true,
                        min: 3
                    },
                    card_cpf_cnpj: {
                        required: true,
                        min: 11
                    }
                }
            }
        },
        methods: {
            getAllPlans(){
                axios.get(`https://api.pagar.me/1/plans?count=10&page=1&api_key=ak_test_o6pNlMnzryy2DnrpqaIkG4iqy6ex5S`).then((resp) => {
                    this.allPlans = resp.data
                    this.loading = false
                })
                .catch((e) => {
                    console.log(e)
                    Swal(
                        'Falha',
                        'Encontramos um problema ao buscar os planos, por favor, tente novamente',
                        'error'
                    )
                })
            },
            getInfoPlan(id) {
                axios.get(
                        `https://api.pagar.me/1/subscriptions?plan_id=&id=${id}&api_key=ak_test_o6pNlMnzryy2DnrpqaIkG4iqy6ex5S`
                    ).then((resp) => {
                        let auxDateCreated = new Date(resp.data[0].date_created)
                        auxDateCreated = auxDateCreated.toLocaleDateString("pt-BR")

                        let auxDateEnd = new Date(resp.data[0].current_period_end)
                        auxDateEnd = auxDateEnd.toLocaleDateString("pt-BR")

                        const dateUpdate = this.convertDateToLocalString(resp.data[0].date_updated)
                        const aux = []

                        for(let item of resp.data) {
                            if(item.status == "paid"){item.status = "Ativo"}
                            if(item.payment_method == "credit_card"){item.payment_method = "Cartão de crédito"}
                            item.date_updated = dateUpdate
                            item.date_created = auxDateCreated
                            item.date_end = auxDateEnd
                            aux.push(item)
                        }
                        this.infoPlan = aux
                        this.loading = false
                    })
                    .catch((e) => {
                        console.log(e)
                        Swal(
                            'Falha',
                            'Tivemos problemas ao buscar as informações do plano, por favor tente novamente.',
                            'error'
                        )
                    })
            },
            getDataCard() {
                let card = {}
                card.card_holder_name = this.card_holder_name
                card.card_expiration_date = this.card_expiration_month + '/' + this.card_expiration_year
                card.card_number = this.card_number
                card.card_cvv = this.card_cvv

                const cardValidations = pagarme.validate({card: card})

                if(!cardValidations.card.card_number) {
                    let txt = "Número do cartão inválido."
                    this.getSnackBar(txt)
                }
                if(!cardValidations.card.card_holder_name) {
                    let txt = "Nome do portador inválido."
                    this.getSnackBar(txt)
                }
                if(!cardValidations.card.card_expiration_month) {
                    let txt = "Mês de expiração inválido."
                    this.getSnackBar(txt)
                }
                if(!cardValidations.card.card_expiration_year) {
                    let txt = "Ano de expiração inválido."
                    this.getSnackBar(txt)
                }
                if(!cardValidations.card.card_cvv) {
                    let txt = "Código de segurança inválido."
                    this.getSnackBar(txt)
                }

                const planId = JSON.parse(sessionStorage.getItem('plans')).id
                const str = this.card_expiration_year.substring(2, 4)
                const email = JSON.parse(sessionStorage.getItem('user')).email
                const customerName = JSON.parse(sessionStorage.getItem('user')).firstname + ' ' + JSON.parse(sessionStorage.getItem('user')).lastname
                const formData = {
                api_key: 'ak_test_o6pNlMnzryy2DnrpqaIkG4iqy6ex5S',
                plan_id: planId,
                    card_number: card.card_number,
                    card_holder_name: card.card_holder_name.toUpperCase(),
                    card_expiration_date: this.card_expiration_month + str,
                    card_cvv: card.card_cvv,
                    customer: {
                    name: customerName,
                    email: email,
                    // phone: {
                    //   number: JSON.parse(sessionStorage.getItem('user')).phone
                    // },
                    document_number: this.card_cpf_cnpj
                    }
                }
                axios.post("https://api.pagar.me/1/subscriptions", formData).then((resp) => {
                    models().put("users/updateUserPagarMe", {"email": email, "pagarmeId": resp.data.id}).then(() => {
                        Swal.fire({
                        title: "Pronto!",
                        html: `Seu cadastro e assinatura foram gerados com sucesso! Em breve você receberá uma confirmação no email registrado: <b>${JSON.parse(sessionStorage.getItem('user')).email}</b>`,
                        type: "success"
                        })
                        this.$router.push('/login')
                    })
                })
            },
            choicePlan(type) {
                Swal.fire({
                    title: "Aguarde",
                    html: "Buscando informações....",
                    onOpen: () => {
                        Swal.showLoading()
                    }
                })
                for(let obj of this.allPlans) {
                    if(type == obj.id) {
                        models().get(`services/getServicesPlan?pagarmeId=${obj.id}`).then((resp) => {
                            sessionStorage.setItem('services-plan', JSON.stringify(resp.data))
                            sessionStorage.setItem('plans', JSON.stringify(obj))
                            Swal.close()
                            this.$router.push('upgrade-plan')
                        })
                        .catch((e) => {
                            console.log(e)
                            Swal(
                                'Falha',
                                'Não foi possível buscar as informações do plano, por favor, tente novamente.',
                                'error'
                            )
                        })
                    }
                }
            },
            convertDateToLocalString(date) {
                let newDate = new Date(date);
                let day = newDate.getDate();
                let month = newDate.getMonth() + 1;
                let fullYear = newDate.getFullYear();
                let hour = newDate.getHours();
                let minutes = newDate.getMinutes();

                if (day < 10) {
                    day = "0" + day
                }
                if (month < 10) {
                    month = "0" + month
                }
                if (hour < 10) {
                    hour = "0" + hour
                }
                if (minutes < 10) {
                    minutes = "0" + minutes
                }

                return day + "/" + month + "/" + fullYear + " " + hour + ":" + minutes;
            },
            cancelPlan() {
                Swal.fire({
                    title: 'Atenção',
                    text: "Deseja mesmo cancelar seu plano atual? Esta ação não pode ser desfeita.",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim, estou certo disso!',
                    cancelButtonText: 'Não'
                    }).then((result) => {
                    if (result.value) {
                            axios.post(`https://api.pagar.me/1/subscriptions/${this.plan}/cancel?api_key=ak_test_o6pNlMnzryy2DnrpqaIkG4iqy6ex5S`).then(() => {
                                models().put("users/upgradePlan", {
                                    "email": JSON.parse(localStorage.getItem('user')).email,
                                    "pagarmeId": 0,
                                    "key": JSON.parse(localStorage.getItem('radio-default')).key,
                                    "profile_id": 227,
                                    
                                }).then(() => {
                                    Swal.fire({
                                        title: "Pronto!",
                                        html: `Seu plano foi cancelado com sucesso! Em breve você receberá uma confirmação no email registrado: <b>${JSON.parse(localStorage.getItem('user')).email}</b>`,
                                        type: "success"
                                    })
                                    localStorage.clear()
                                    sessionStorage.clear()
                                    this.$router.push('/login')
                                })
                            })
                        }
                    })
                
            }
        },
        mounted() {
            this.getAllPlans()
            let vm = this
            if (this.plan) {
                this.getInfoPlan(this.plan)
            }
        },
        watch: {
            plan(newKey) {
                this.getInfoPlan(newKey)
            }
        }
    }

</script>

<style>
    .spinner {
        margin: 100px auto;
        width: 100%;
        height: 40px;
        text-align: center;
        font-size: 10px;
    }

    .spinner>div {
        background-color: #333;
        height: 50px;
        margin-right: 5px;
        width: 6px;
        display: inline-block;
        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .spinner .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .spinner .rect3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }

    .spinner .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .spinner .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

    @-webkit-keyframes sk-stretchdelay {

        0%,
        40%,
        100% {
            -webkit-transform: scaleY(0.4)
        }

        20% {
            -webkit-transform: scaleY(1.0)
        }
    }

    @keyframes sk-stretchdelay {

        0%,
        40%,
        100% {
            transform: scaleY(0.4);
            -webkit-transform: scaleY(0.4);
        }

        20% {
            transform: scaleY(1.0);
            -webkit-transform: scaleY(1.0);
        }
    }

    .text-center {
        text-align: center;
    }

    .btn-plan:visited {
        color: #a1a1a1;
    }

    .btn-plan2:visited {
        color: #fff;
    }

    .price {
        font-size: 15px;
    }

</style>
