<template>
  <div class="episodes">
    <div
      class="row-episodes"
      style="display: flex; flex-wrap: wrap"
      v-for="(episode, index) in dataEpisodes"
      :key="index"
    >
      <div
        class="row-child"
        style="width: 17%; display: flex; justify-content: center"
      >
        <img style="width: 200px; border-radius: 10px" :src="episode.image" />
      </div>
      <div class="row-child" style="width: 60%">
        <h4 style="font-weight: 400; margin: auto">{{ episode.title }}</h4>

        <audio
          :id="'audio_' + index"
          controls="controls"
          style="width: 100%; min-height: 60px"
        >
          <source :src="episode.audio" />
          Your browser does not support the audio element.
        </audio>

        <div style="margin-top: 5px">
          <md-button class="md-warning md-sm" @click="editEpisode(episode)">
            <i class="fas fa-edit"></i> Editar </md-button
          >&nbsp;
          <md-button class="md-danger md-sm" @click="deleteEpisode(episode)">
            <i class="fas fa-trash"></i> Excluir
          </md-button>
          <br />
        </div>

        <div style="margin-top: 25px;">
          <span style="margin-top: 50px; font-style: italic" class="pub-date"
            >Publicado em:
            {{
              new Date(episode.created).toLocaleString("pt-BR", {
                timeZone: "America/Belem",
              })
            }}</span
          >
          <span
            style="margin-top: 0px; font-style: italic; float: right;"
            class="pub-date"
            >id: {{ episode.id }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tabs, TabPane, Select, Option, Input, Pagination } from "element-ui";
import ApiFirebase from "../../../../routes/api-firebase";
import Swal from "sweetalert2";
import EpisodeEdit from "./Edit";
import jwt_decode from "jwt-decode";

export default {
  components: {
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    EpisodeEdit,
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      dataEpisodes: [],
      idChannel: null,
    };
  },
  methods: {
    getEpisodes() {
      this.$root.$on("episodesByChannel", (payload) => {
        this.dataEpisodes = payload.episodes.sort(function (a, b) {
          return new Date(b.created) - new Date(a.created);
        });
        this.idChannel = payload.idChannel;
        Swal.close();
      });
    },
    deleteChannels(id) {
      let self = this;
      Swal.fire({
        title: "Apagar Canal",
        text: "Deseja mesmo fazer isso? O canal e todos os episódios serão excluídos e o processo não poderá ser revertido",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
      }).then((result) => {
        if (result.value) {
          Swal.showLoading();
          ApiFirebase()
            .post("deleteChannel", { id: id })
            .then((resp) => {
              Swal("Pronto", "O canal foi apagado com sucesso!", "success");
              self.$root.$emit("reloadGet", true);
            })
            .catch((err) => console.log(err));
        }
      });
    },
    editEpisode(episode) {
      this.$root.$emit("editEpisode", {
        idEpisode: episode.id,
        idChannel: this.idChannel,
        show: true,
      });
    },
    deleteEpisode(episode) {
      this.$root.$emit("deleteEpisode", {
        episode: episode,
        idChannel: this.idChannel,
      });
    },
  },
  mounted() {
    this.getEpisodes();
    let self = this;
    self.$root.$on("reloadGetEpisode", (payload) => {
      self.getEpisodes();
    });
    self.$root.$on("updateListDelete", (payload) => {
      let index = self.dataEpisodes.findIndex((item) => item.id == payload.id);
      if (index !== -1) {
        self.dataEpisodes.splice(index, 1);
      }
    });
  },
  watch: {
    dataEpisodes() {
      window.setTimeout(() => {
        for (let index in this.dataEpisodes) {
          let audio = document.getElementById("audio_" + index);
          audio.load();
        }
      }, 200);
    },
  },
};
</script>

<style>
.row-episodes {
  display: flex;
  border-bottom: 1px solid #e4e7ed;
  margin-bottom: 20px;
  padding: 10px;
}
</style>