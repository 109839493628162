<template>
    <div class="md-layout">
        <el-dialog
            title="Editar Canal"
            :visible.sync="dialogEditChannel"
            width="30%"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
        >
            <el-form :model="form">
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <span>Campos com (*) são obrigatórios.</span>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-input
                            type="text"
                            v-model="form.title"
                            autocomplete="off"
                            placeholder="Título*"
                        ></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-input
                            type="textarea"
                            v-model="form.description"
                            autocomplete="off"
                            placeholder="Descrição*"
                        ></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-select
                            v-model="form.language"
                            placeholder="Idioma*"
                            style="width:100%;"
                        >
                            <el-option value="DE">Alemão</el-option>
                            <el-option value="ZH">Chinês (Mandarin)</el-option>
                            <el-option value="ES">Espanhol</el-option>
                            <el-option value="FR">Francês</el-option>
                            <el-option value="EN">Inglês</el-option>
                            <el-option value="IT">Italiano</el-option>
                            <el-option value="JA">Japonês</el-option>
                            <el-option value="PT">Português</el-option>
                            <el-option value="RU">Russo</el-option>
                        </el-select>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-input
                            type="email"
                            v-model="form.email"
                            autocomplete="off"
                            placeholder="Email*"
                        ></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-input
                            type="text"
                            v-model="form.author"
                            autocomplete="off"
                            placeholder="Autor*"
                        ></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-input
                            type="text"
                            v-model="form.copyright"
                            autocomplete="off"
                            placeholder="Direito Autoral*"
                        ></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-select
                            v-model="form.category"
                            multiple
                            :multiple-limit="3"
                            placeholder="Selecione até 3 categorias"
                            style="width:100%;"
                        >
                            <el-option-group
                                v-for="group in options"
                                :key="group.label"
                                :label="group.label"
                            >
                                <el-option
                                    v-for="item in group.options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-option-group>
                        </el-select>
                        <!-- <el-input type="text" v-model="form.category" autocomplete="off" placeholder="Categorias"></el-input> -->
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        style="padding-top:20px;"
                        class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
                    >
                        <span style="color:#c1c5cd;" ref="msgImg"
                            >Capa do podcast (1400x1400 px)</span
                        >
                        <el-upload
                            class="avatar-uploader avatar-gifts"
                            action="#"
                            accept="image/jpeg,image/png"
                            :auto-upload="false"
                            :show-file-list="false"
                            :on-change="getFileImage"
                        >
                            <img
                                v-if="urlPreviewImage"
                                :src="urlPreviewImage"
                                class="avatar"
                            />
                            <i
                                v-else
                                class="el-icon-plus avatar-uploader-icon"
                            ></i>
                        </el-upload>
                        <span ref="msgImg"></span>
                    </div>
                </div>
                <br />
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-checkbox
                            type="checkbox"
                            v-model="form.explicit"
                        ></el-checkbox>
                        &nbsp;
                        <span style="color:#a2a4a7;">Conteúdo explícito</span>
                    </div>
                </div>
                <br />
                <div class="md-layout" style="color: #a5a7aa;">
                    <div
                        style="border: 1px dotted"
                        class="md-layout-item md-xs-size-90 md-small-size-90 md-medium-size-90 md-large-size-90 md-xlarge-size-90"
                    >
                        Publicado em:
                        {{
                            new Date(this.created).toLocaleString("pt-BR", {
                                timeZone: "America/Belem"
                            })
                        }}
                        <br />
                        Última atualização:
                        {{
                            new Date(this.updated).toLocaleString("pt-BR", {
                                timeZone: "America/Belem"
                            })
                        }}
                        <br />
                        Episódios: {{ this.episodesLenght }} <br />
                        Feed RSS:
                        https://us-central1-api-mobradio.cloudfunctions.net/app/getFeedRSS/{{
                            this.idEdit
                        }}
                    </div>
                </div>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogEditChannel = false"
                    >Cancelar</el-button
                >
                <el-button
                    type="primary"
                    @click="updateChanel()"
                    :disabled="propertyBtnConfirm"
                    >Confirmar</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
    Dialog,
    Button,
    Form,
    Input,
    Select,
    Option,
    Upload,
    Checkbox,
    Tabs,
    TabPane,
    OptionGroup
} from "element-ui";
import Swal from "sweetalert2";
import categoryChannel from "./category";
import ApiFirebase from "../../../../routes/api-firebase";
import firebase from "firebase/app";
import Axios from "axios";
import services from '../../../../routes/services'
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import jwt_decode from "jwt-decode"

async function uploadCloudinary(key, file, url_image, service) {
    const URL = `https://api.painel.mobradio.com.br/services/${service}`;
    const formData = new FormData();
    const config = {
        headers: {
            "content-type": "multipart/form-data"
        }
    };
    if (!url_image) {
        formData.append("key", key);
        formData.append("attachments", file);
    } else {
        const aux = url_image.split("/");
        const newUrl = `${aux[9]}`.split(".")[0];

        formData.append("key", key);
        formData.append("attachments", file);
        formData.append("public_id", newUrl);
    }
    return await services().post(`services/${service}`, formData, config);
}

export default {
    components: {
        [Dialog.name]: Dialog,
        [Button.name]: Button,
        [Form.name]: Form,
        [Input.name]: Input,
        [Select.name]: Select,
        [Option.name]: Option,
        [Upload.name]: Upload,
        [Checkbox.name]: Checkbox,
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
        [OptionGroup.name]: OptionGroup
    },
    data() {
        return {
            radio: jwt_decode(localStorage.getItem('APP_01')).radio,
            dialogEditChannel: false,
            form: {
                title: "",
                description: "",
                language: "",
                email: "",
                author: "",
                category: [],
                copyright: "",
                explicit: false,
                image: "",
                type: [],
                resource: "",
                desc: ""
            },
            created: "",
            updated: "",
            episodesLenght: 0,
            value: [],
            options: categoryChannel,
            file: null,
            urlPreviewImage: null,
            imagePublicId: null,
            propertyBtnConfirm: false,
            idEdit: "",
            fileImage: null
        };
    },

    methods: {
        openNewEpisode() {
            this.dialogEditChannel = true;
        },
        getFileImage(file) {
            let vm = this;
            let span = this.$refs.msgImg;
            let img = new Image();
            this.file = file;
            this.urlPreviewImage = URL.createObjectURL(file.raw);
            this.fileImage = file;
            img.onload = function() {
                if(img.width < 1400 || img.height < 1400) {
                    span.textContent = "A imagem não atende os requisitos minimos de resolução (1400 x 1400 pixels)."
                    span.style.color = "#ff0000"
                    vm.propertyBtnConfirm = true
                } else {
                    span.textContent = ""
                    vm.propertyBtnConfirm = false
                }
            };
            img.src = this.urlPreviewImage;
        },
        getEdit(id, show) {
            this.idEdit = id;
            Swal.showLoading();
            ApiFirebase()
                .get(`channelById/${id}`)
                .then(resp => {
                    (this.form.title = resp.data[0].title),
                        (this.form.description = resp.data[0].description),
                        (this.form.language = resp.data[0].language),
                        (this.form.email = resp.data[0].email),
                        (this.form.author = resp.data[0].author),
                        (this.form.image = resp.data[0].image),
                        (this.form.category = resp.data[0].category),
                        (this.form.copyright = resp.data[0].copyright),
                        (this.form.explicit = resp.data[0].explicit),
                        (this.form.episodes = resp.data[0].episodes),
                        (this.urlPreviewImage = resp.data[0].image),
                        (this.imagePublicId = resp.data[0].image),
                        (this.created = resp.data[0].created),
                        (this.updated = resp.data[0].updated),
                        (this.episodesLenght = resp.data[0].episodes.length);
                    Swal.close();
                    this.dialogEditChannel = show;
                })
                .catch(err => {
                    console.log(err);
                });
        },
        updateChanel(id) {
            this.dialogEditChannel = false;
            // ATUALIZA COM NOVA IMAGEM
            if (this.file) {
                Swal({
                    title: "Atualizando canal...",
                    onOpen: () => {
                        Swal.showLoading();
                        uploadCloudinary(
                            this.radio.key,
                            this.file.raw,
                            this.imagePublicId,
                            "uploadCloudImage"
                        ).then(response => {
                            let formUpdate = {
                                id: this.idEdit,
                                title: this.form.title,
                                description: this.form.description,
                                language: this.form.language,
                                email: this.form.email,
                                author: this.form.author,
                                episodes: this.form.episodes
                                    ? this.form.episodes
                                    : [],
                                image: response.data.secure_url,
                                copyright: this.form.copyright,
                                category: this.form.category,
                                explicit: this.form.explicit,
                                key: this.radio.key,
                                updated: new Date().toLocaleString("pt-BR", {
                                    timeZone: "America/Belem"
                                })
                            };
                            ApiFirebase()
                                .post("channelUpdate", formUpdate)
                                .then(resp => {
                                    Swal(
                                        "Pronto",
                                        "Canal atualizado com sucesso!",
                                        "success"
                                    );
                                    this.$root.$emit("reloadGet", true);
                                })
                                .catch(() => {
                                    Swal(
                                        "Erro",
                                        "Não foi possível atualizar o canal, por favor, tente novamente.",
                                        "error"
                                    );
                                });
                        });
                    }
                });
            }
            // ATUALIZA COM A MESMA IMAGEM
            else {
                let formUpdate = {
                    id: this.idEdit,
                    title: this.form.title,
                    description: this.form.description,
                    language: this.form.language,
                    email: this.form.email,
                    author: this.form.author,
                    episodes: this.form.episodes ? this.form.episodes : [],
                    image: this.urlPreviewImage,
                    copyright: this.form.copyright,
                    category: this.form.category,
                    explicit: this.form.explicit,
                    key: this.radio.key
                };
                Swal({
                    title: "Atualizando canal...",
                    onOpen: () => {
                        Swal.showLoading();
                        ApiFirebase()
                            .post("channelUpdate", formUpdate)
                            .then(resp => {
                                Swal(
                                    "Pronto",
                                    "Canal atualizado com sucesso!",
                                    "success"
                                );
                                this.$root.$emit("reloadGet", true);
                            })
                            .catch(() => {
                                Swal(
                                    "Erro",
                                    "Não foi possível atualizar o canal, por favor, tente novamente.",
                                    "error"
                                );
                            });
                    }
                });
            }
        },
        async uploadFirebase(folder) {
            const storage = firebase.storage().ref(`Podcasts/${folder}/`);
            const file = this.fileImage;
            const name = this.radio.key + "_" + file.name;
            const metadata = {
                contentType: file.raw.type
            };
            const task = storage.child(name).put(file.raw, metadata);
            return await task
                .then(snapshot => snapshot.ref.getDownloadURL())
                .then(url => {
                    return url;
                })
                .catch(console.error);
        }
    },
    mounted() {
        let self = this;
        self.$root.$on("editChannel", payload => {
            self.getEdit(payload.id, payload.show);
        });
    }
};
</script>

<style>
.el-dialog__header {
    font-weight: 400;
}
.el-dialog__body {
    font-weight: 400;
}
.el-dialog__footer {
    text-align: left;
    padding: 10px 35px 20px;
}
</style>
