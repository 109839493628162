<template>
  <div class="md-layout">
    <div v-show="blockStatus == 1" class="md-layout-item md-small-size-100 principal">
        <div class="md-layout-item md-small-size-100" style="padding: 0;">
            <FilterSelector
                @searchData="filter()"
                :filterForReport="false"
                :buttonsToDisplay="[
					buttonEnum.TODAY,
					buttonEnum.SEVEN_DAYS,
					buttonEnum.MONTH_TO_DATE,
					buttonEnum.CUSTOM
				]"
                @extraFunction="dialogVisible = true"
                :extraFunction="true"
                :extraFunctionLabel="'NOVO'"
            >
            </FilterSelector>
        </div>
        <div v-show="loading" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <Spinner/>
        </div>
        <div v-show="!loading">
            <md-card v-if="this.tableData.length > 0">
                <md-card-header class="md-card-header-icon md-card-header-green">
                    <div class="card-icon">
                        <md-icon>assignment</md-icon>
                    </div>
                    <div class="div-periodo">
                        <span>{{periodo}}</span>
                    </div>
                </md-card-header>
                <md-card-content>
                    <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
                        <md-table-toolbar>
                        <md-field>
                            <label for="pages">Por página</label>
                            <md-select v-model="pagination.perPage" name="pages">
                            <md-option
                                v-for="item in pagination.perPageOptions"
                                :key="item"
                                :label="item"
                                :value="item">
                                {{ item }}
                            </md-option>
                            </md-select>
                        </md-field>

                        </md-table-toolbar>

                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="Título">{{ item.title }}</md-table-cell>
                            <md-table-cell md-label="Artista" >{{ item.artist }}</md-table-cell>
                            <md-table-cell md-label="Total" md-sort-by="total">{{ item.total }}</md-table-cell>
                            </md-table-row>
                    </md-table>
                </md-card-content>
                <md-card-actions md-alignment="space-between">
                    <div class="">
                        <p class="card-category">{{from + 1}} à {{to}} de {{total}} itens</p>
                    </div>
                    <pagination class="pagination-no-border pagination-success"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="total">
                    </pagination>
                </md-card-actions>
            </md-card>
            <md-card style="text-align: center" v-else-if="errorSearch">
				<md-card-content>
					<label for="">Sem informações pra serem exibidas.</label>
				</md-card-content>
			</md-card>
        </div>
    </div>

    <!-- IMPRESSÃO -->
    <div class="titleLeft impressao">
        <img src="../../../public/img/logo-mobradio-black.png" alt="">
        <h1>Relatório de Pedido Musical</h1>
    </div>
    <div class="titleRight impressao">
        <b class="nameRadio">{{nameRadioReport ? nameRadioReport : "MobRadio"}}</b> &nbsp;
        <span><img class="logoRadio" :src="logo" alt=""></span>
    </div>
    <div class="impressao periodo">
        <span>Período: {{periodStart}} até: {{periodEnd}} </span>
    </div>
    <div class="contentBody impressao">
        <div class="divTable">
            <div style="margin-top:25px;margin-bottom:25px;"><span style="font-size: 18px;"><b>Total Pedidos</b></span></div>
                <div class="md-content md-table md-theme-default" table-header-color="blue">
                    <div class="md-content md-table-content md-scrollbar md-theme-default">
                        <table>
                            <thead>
                                <tr>
                                    <th class="md-table-head">
                                        <div class="md-table-head-container md-ripple md-disabled">
                                            <div class="md-table-head-label">Título</div> 
                                        </div>
                                    </th>
                                    <th class="md-table-head">
                                        <div class="md-table-head-container md-ripple md-disabled">
                                            <div class="md-table-head-label">Artista</div> 
                                        </div>
                                    </th>
                                    <th class="md-table-head">
                                        <div class="md-table-head-container md-ripple md-disabled">
                                            <div class="md-table-head-label">Total</div> 
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tfoot class="report-footer">
                                <tr>
                                    <td colspan="6">
                                    Fonte de dados: MobRadio Analytics &nbsp; <img class="footerIcon" src="../../../public/img/favicon.png">
                                    </td>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr class="md-table-row" v-for="(item, index) in tableData" :key="index">
                                    <td class="md-table-cell">
                                        <div class="md-table-cell-container">{{item.title}}</div>
                                    </td>
                                    <td class="md-table-cell">
                                        <div class="md-table-cell-container">{{item.artist}}</div>
                                    </td>
                                    <td class="md-table-cell">
                                        <div class="md-table-cell-container">{{item.total}}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table> 
                    </div>
                </div>
            </div>
    </div>
    
    <el-dialog
        title="Novo pedido"
        :visible.sync="dialogVisible">
        <div class="md-layout-item md-size-100 md-small-size-100">
            <el-input id="input_music" placeholder="Nome da música" v-model="music"></el-input>
            <el-input id="input_artist" placeholder="Nome do artista" style="margin-top: 15px;" v-model="artist"></el-input>
            <el-input id="input_nameListener" placeholder="Nome do ouvinte" style="margin-top: 15px;" v-model="nameListener"></el-input>
            <el-input id="input_emailListener" placeholder="Email do ouvinte" style="margin-top: 15px;" v-model="emailListener"></el-input>
            <el-input id="input_phoneListener" placeholder="Telefone do ouvinte" style="margin-top: 15px;" v-model="phoneListener"></el-input>
        </div>
        <div v-if="this.errorsForm.length" class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <p>
                <b>Por favor, corrija o(s) seguinte(s) erro(s):</b>
                <ul>
                <li v-for="error in this.errorsForm" :key="error">{{ error }}</li>
                </ul>
            </p>
        </div>
        <span slot="footer" class="dialog-footer">
            <div class="md-layout">
                <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-button class="md-default md-block" @click="dialogVisible = false">Cancelar</md-button>
                </div>
                <div class="md-layout-item md-size-50 md-small-size-100">
                    <md-button class="md-success md-block" @click="newRequestedSongs"><i class="fas fa-check"></i> &nbsp; Enviar</md-button>
                </div>
            </div>
        </span>
    </el-dialog>
    <block v-show="blockStatus == 0"></block>
  </div>
</template>

<script>
    import Swal from 'sweetalert2'
    import models from '../../routes/models.js'   
    import {Pagination} from '@/components'
    import selectRadio from '../../utils/events/select-radio.js'
    import block from './Block.vue'
    import jwt_decode from "jwt-decode"
    import {
        Dialog,
        Button,
        Tabs,
        TabPane,
        Input,
        DatePicker
    } from 'element-ui'

    import filters from './Components/FilterSelector/filters'
    import FilterSelector from './Components/FilterSelector/FilterSelector.vue'
    import buttonEnum from './Components/FilterSelector/buttonEnum'
    import Spinner from '../../components/Icons/Spinner.vue'

    export default {
        components: {
            [Button.name]: Button,
            [Dialog.name]: Dialog,
            [Tabs.name]: Tabs,
            [TabPane.name]: TabPane,
            [Input.name]: Input,
            [DatePicker.name]: DatePicker,
            Pagination,
            block,
            FilterSelector,
            Spinner
        },
        computed: {
            queriedData () {
                let result = this.tableData;
                if(this.searchedData.length > 0){
                    result = this.searchedData;
                }
                return result.slice(this.from, this.to)
            },
            to () {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from () {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total () {
                return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
            },
            validateForm () {
                return [this.music, this.artist, this.nameListener, this.emailListener, this.phoneListener].join()
            }
        },
        data() {
            return {
                buttonEnum: buttonEnum,
                appKey: jwt_decode(localStorage.getItem('APP_01')).radio.key,
                nameRadioReport: jwt_decode(localStorage.getItem('APP_01')).radio.name,
                logo: jwt_decode(localStorage.getItem('APP_01')).radio.logo,
                tableData: [],
                selectedClose1: null,
                selectedClose2: null,
                time1: null,
                time2: null,
                widthPicker: 100 + "%",
                dialogVisible: false,
                music: '',
                artist: '',
                nameListener: '',
                emailListener: '',
                phoneListener: '',
                currentSort: 'desc',
                currentSortOrder: 'asc',
                pagination: {
                    perPage: 50,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                searchQuery: '',
                propsToSearch: ['Título', 'artist', 'age'],
                tableData: [],
                searchedData: [],
                fuseSearch: null,
                loading: false,
                blockStatus: 1,
                valideForm: true,
                periodo: 'Hoje',
                disabledButton: true,
                disabledButton1: false,
                disabledButton2: false,
                disabledButton3: false,
                disabledButton4: false,
                disabledButton5: false,
                periodStart: null,
                periodEnd: null,
                errorsForm: [],
                errorSearch: false
            }
        },
        methods: {
            filter() {
                this.errorSearch = false
                this.loading = true
                this.tableData = []
                this.disabledButton1 = false,
                this.disabledButton2 = false,
                this.disabledButton3 = false,
                this.disabledButton4 = false,
                this.disabledButton5 = false
                let dtStart = null
                let dtEnd = null
                let per1 = new Date(filters.startDate)
                let per2 = new Date(filters.endDate)
                dtStart = per1.getFullYear() + '-' + (per1.getMonth()+1) + '-' + per1.getDate() + ' 00:00:00'
                dtEnd = per2.getFullYear() + '-' + (per2.getMonth()+1) + '-' + per2.getDate() + ' 23:59:59'
                this.periodo = 'Período customizado'

                models().get(`music/getRequestedSongs?appKey=${this.appKey}&startDate=${dtStart}&endDate=${dtEnd}&offset=0&rows=999999999`)
                .then((resp) => {
                    this.tableData = resp.data
                    if(this.tableData.length === 0){
                        this.errorSearch = true
                    }
                })
                .catch((e) => {
                    console.log(e)
                })
                .finally(()=>{
                    this.loading = false
                })
            },
            customSort (value) {
                return value.sort((a, b) => {
                    const sortBy = this.currentSort
                    if (this.currentSortOrder === 'desc') {
                        return a[sortBy] > b[sortBy]
                    }
                    return b[sortBy] > a[sortBy]
                })
            },
            blockPage() {
                const menu = jwt_decode(localStorage.getItem('APP_02')).services
                let service = null
                for(let obj of menu) {
                    if(obj.subitens === null) {
                        const key = Object.keys(obj.item)[Object.values(obj.item).indexOf(this.$route.path.replace("/", ""))]
                        if(key) {
                            service = obj.item
                        }
                    } else {
                        const key = Object.keys(obj.subitens)[Object.values(obj.subitens).indexOf(this.$route.path.replace("/", ""))]
                        if(key) {
                            service = obj.subitens
                        }
                    }
                }
                
                if(service) {
                    this.blockStatus = service.has_permission
                } else {
                    this.$router.push("dashboard")
                }
            },
            findObjectByKey(array, key, value) {
                for (var i = 0; i < array.length; i++) {
                    if (array[i][key] === value) {
                        return array[i];
                    }
                }
                return null;
            },
            newRequestedSongs() {
                this.errorsForm = []
                this.validationErrors()
                if(!this.errorsForm.length) {
                    this.dialogVisible = false
                    Swal.fire({
                        title: "Aguarde",
                        html: "Estamos cadastrando o novo pedido musical.",
                        onOpen: () => {
                            Swal.showLoading()
                        }
                    })
                    let dataForm = {
                        appKey: this.appKey,
                        title: this.music,
                        artist: this.artist,
                        listener: this.nameListener,
                        email: this.emailListener,
                        phone: this.phoneListener
                    }
                    models().post("music/newRequestedSongs", dataForm).then((resp) => {
                        Swal(
                            'Pronto',
                            'Pedido cadastrado com sucesso!',
                            'success'
                        )
                        this.music = ""
                        this.artist = ""
                        this.nameListener = ""
                        this.emailListener = ""
                        this.phoneListener = ""
                        this.filter()
                    })
                    .catch((e) => {
                        console.log(e)
                        Swal(
                            'Erro',
                            'Não foi possível cadastrar um novo pedido musical, por favor, tente novamente.',
                            'error'
                        )
                    })
                }
            },
            setNewDate(id) {
                this.tableData = []
                this.loading = true
                this.disabledButton1 = false,
                this.disabledButton2 = false,
                this.disabledButton3 = false,
                this.disabledButton4 = false,
                this.disabledButton5 = false
                let dtStart = null
                let dtEnd = null
                let aux1 = new Date()
                let aux2 = new Date()

                if(id == 0) {
                    //Pega a data do datepicker e altera o formato pra ser enviado pro mysql
                    let per1 = new Date(this.time1)
                    let per2 = new Date(this.time2)
                    
                    dtStart = per1.getFullYear() + '-' + (per1.getMonth()+1) + '-' + per1.getDate() + ' 00:00:00'
                    dtEnd = per2.getFullYear() + '-' + (per2.getMonth()+1) + '-' + per2.getDate() + ' 23:59:59'

                    this.periodo = 'Período customizado'
                    // this.currentSort = 'total'
                }
                if(id == 1) {
                    dtStart = aux1.getFullYear() + '-' + (aux1.getMonth()+1) + '-' + aux1.getDate() + ' 00:00:00'
                    dtEnd = aux2.getFullYear() + '-' + (aux2.getMonth()+1) + '-' + aux2.getDate() + ' 23:59:59'
                    this.disabledButton = false
                    this.disabledButton1 = true
                    this.periodo = 'Hoje'
                    this.time1 = aux1.getFullYear() + '-' + (aux1.getMonth()+1) + '-' + aux1.getDate()
                    this.time2 = aux2.getFullYear() + '-' + (aux2.getMonth()+1) + '-' + aux2.getDate()
                    // this.currentSort = 'total'
                }
                if(id == 2) {
                    aux1.setDate(aux1.getDate()-1)
                    aux2.setDate(aux2.getDate()-1)
                    dtStart = aux1.getFullYear() + '-' + (aux1.getMonth()+1) + '-' + aux1.getDate() + ' 00:00:00'
                    dtEnd = aux2.getFullYear() + '-' + (aux2.getMonth()+1) + '-' + aux2.getDate() + ' 23:59:59'
                    this.disabledButton = false
                    this.disabledButton2 = true
                    this.periodo = 'Ontem'
                    this.time1 = aux1.getFullYear() + '-' + (aux1.getMonth()+1) + '-' + aux1.getDate()
                    this.time2 = aux2.getFullYear() + '-' + (aux2.getMonth()+1) + '-' + aux2.getDate()
                    // this.currentSort = 'total'
                }
                if(id == 3) {
                    aux1.setDate(aux1.getDate()-7)
                    dtStart = aux1.getFullYear() + '-' + (aux1.getMonth()+1) + '-' + aux1.getDate() + ' 00:00:00'
                    dtEnd = aux2.getFullYear() + '-' + (aux2.getMonth()+1) + '-' + aux2.getDate() + ' 23:59:59'
                    this.disabledButton = false
                    this.disabledButton3 = true
                    this.periodo = 'Últimos 7 dias'
                    this.time1 = aux1.getFullYear() + '-' + (aux1.getMonth()+1) + '-' + aux1.getDate()
                    this.time2 = aux2.getFullYear() + '-' + (aux2.getMonth()+1) + '-' + aux2.getDate()
                    // this.currentSort = 'total'
                }
                if(id == 4) {
                    aux1.setDate(aux1.getDate()-30)
                    dtStart = aux1.getFullYear() + '-' + (aux1.getMonth()+1) + '-' + aux1.getDate() + ' 00:00:00'
                    dtEnd = aux2.getFullYear() + '-' + (aux2.getMonth()+1) + '-' + aux2.getDate() + ' 23:59:59'
                    this.disabledButton = false
                    this.disabledButton4 = true
                    this.periodo = 'Últimos 30 dias'
                    this.time1 = aux1.getFullYear() + '-' + (aux1.getMonth()+1) + '-' + aux1.getDate()
                    this.time2 = aux2.getFullYear() + '-' + (aux2.getMonth()+1) + '-' + aux2.getDate()
                    // this.currentSort = 'total'
                }
                if(id == 5) {
                    aux1.setDate(aux1.getDate()-1)
                    dtStart = aux1.getFullYear() + '-' + (aux1.getMonth()+1) + '-' + aux1.getDate() + ' 00:00:00'
                    dtEnd = aux2.getFullYear() + '-' + (aux2.getMonth()+1) + '-' + aux2.getDate() + ' 23:59:59'
                    this.disabledButton = false
                    this.disabledButton5 = true
                    this.periodo = 'Ontem + Hoje'
                    this.time1 = aux1.getFullYear() + '-' + (aux1.getMonth()+1) + '-' + aux1.getDate()
                    this.time2 = aux2.getFullYear() + '-' + (aux2.getMonth()+1) + '-' + aux2.getDate()
                    // this.currentSort = 'total'
                }
                models().get(`music/getRequestedSongs?appKey=${this.appKey}&startDate=${dtStart}&endDate=${dtEnd}&offset=0&rows=999999999`)
                .then((resp) => {
                    this.tableData = resp.data
                    this.loading = false
                })
                .catch((e) => {
                    Swal(
                        'Erro',
                        'Falha ao buscar as informações, por favor tente novamente.',
                        'error'
                    )
                    console.log(e)
                })
            },
            print() {
                this.periodStart = new Date(this.time1).toLocaleDateString("pt-BR")
                this.periodEnd = new Date(this.time2).toLocaleDateString("pt-BR")
                setTimeout(() => {
                    window.print()
                }, 200)
            },
            validationErrors() {
                if(this.music === '') {
                    this.errorsForm.push('O campo "Nome da música" é obrigatório.');
                    document.getElementById("input_music").style.borderColor = "#ff0000";
                } else {
                    document.getElementById("input_music").style.borderColor = "";
                }
                if(this.artist === '') {
                    this.errorsForm.push('O campo "Nome do artista" é obrigatório.');
                    document.getElementById("input_artist").style.borderColor = "#ff0000";
                } else {
                    document.getElementById("input_artist").style.borderColor = "";
                }
                if(this.nameListener === '') {
                    this.errorsForm.push('O campo "Nome do ouvinte" é obrigatório.');
                    document.getElementById("input_nameListener").style.borderColor = "#ff0000";
                } else {
                    document.getElementById("input_nameListener").style.borderColor = "";
                }
                if(this.emailListener === '') {
                    this.errorsForm.push('O campo "Email do ouvinte" é obrigatório.');
                    document.getElementById("input_emailListener").style.borderColor = "#ff0000";
                } else {
                    document.getElementById("input_emailListener").style.borderColor = "";
                }
                if(this.phoneListener === '') {
                    this.errorsForm.push('O campo "Telefone do ouvinte" é obrigatório.');
                    document.getElementById("input_phoneListener").style.borderColor = "#ff0000";
                } else {
                    document.getElementById("input_phoneListener").style.borderColor = "";
                }
            }
        },
        mounted() {
            if(localStorage.getItem('radio-default')) {
                this.appKey = jwt_decode(localStorage.getItem('APP_01')).radio.key
                this.blockPage()
            }
            selectRadio.$on('selectRadio', (payload) => {
                this.appKey = payload.key
                this.tableData = []
                this.disabledButton = false
                this.disabledButton1 = false
                this.disabledButton2 = false
                this.disabledButton3 = false
                this.disabledButton4 = false
                this.disabledButton5 = false
                this.nameRadioReport = jwt_decode(localStorage.getItem('APP_01')).radio.name,
                this.logo = jwt_decode(localStorage.getItem('APP_01')).radio.logo,
                this.blockPage()
                this.filter()
            })
            document.addEventListener("keyup", function(e) {
               if(e.keyCode == 13) {
                   if(this.disabledButton == false) {
                        this.filter()
                    }
               }
            })
        },
        watch: {
            searchQuery(value){
                let result = this.tableData;
                if (value !== '') {
                    result = this.fuseSearch.search(this.searchQuery)
                }
                this.searchedData = result;
            },
            validateForm() {
                if(this.music != '' && this.artist != "" && this.nameListener != '' && this.emailListener != '' && this.phoneListener != '') {
                    this.valideForm = false
                } else {
                    this.valideForm = true
                }
            },
            time1: function() {          
                if (this.time1 !== null && this.time2 !== null) {
                    this.disabledButton = false
                } else {
                    this.disabledButton = true
                }
            },
            time2: function() {
                if (this.time1 !== null && this.time2 !== null) {
                    this.disabledButton = false
                } else {
                    this.disabledButton = true
                }
            },
        }
    }
</script>
<style scoped>
    .div-periodo {
        float: right;
        padding-top: 20px;
        font-size: 15px;
        font-weight: 500;
        font-style: italic;
        font-variant: all-small-caps;
    }

    .principal {
        display: block;
    }

    .impressao {
        display: none;
    }

    .datepicker-center {
        margin-top: -16px;
    }

    @page {
        size: auto;
        margin: 10mm 10mm 10mm 10mm;
    }

    @media print {
        .impressao {
            display: block;
            margin-top: -30px;
        }
        tr {
            page-break-inside: avoid;
        }
        table.report-container {
            page-break-after:always !important;
        }
        thead.report-header {
            display:table-header-group !important;
        }
        tfoot.report-footer {
            display:table-footer-group !important;
            text-align: center;
            font-size: 12pt;
        }
        canvas {
            height: 300px !important;
            padding: 10px;
            border: 1px solid #d9d9d9;
            border-radius: 0px;
            box-shadow: 0px 0px 0px 0px;
            background-color: #ffffff;
            margin-top: -30px;
            margin-bottom: -40px;
        }
        img {
            width: 160px;
            height: 160px;
        }

        h1 {
            font-size: 18pt;
        }

        span {
            font-size: 12pt;
        }

        footer {
            text-align: center;
            font-size: 12pt;
        }

        .principal {
            display: none;
        }

        .periodo {
            margin-top: 10px;
            width: 100%;
        }

        .md-toolbar-section-start {
            display: none;
        }

        .titleLeft {
            width: 50%;
        }
        .titleLeft>img {
            width: 100%;
            height: 40px;
        }

        .titleRight {
            float:right; 
            width: 50%;
            text-align: right;
        }

        .nameRadio {
            font-size: 18pt;
        }

        .logoRadio {
            width: 48px;
            height: 48px;
            border-radius: 25px;
            margin-top: -10px;
        }

        .contentBody {
            width: 100%;
            margin-top: 50px;
        }

        .divTable {
            border: 1px solid #d2d2d2;
            padding: 10px;
            margin-bottom: 10px;
            width: 100%;
        }

        .card-icon {
            display: none;
        }

        .footerIcon {
            width: 16px;
            height: 16px;
            margin-top: -5px;
        }

        .container-graph {
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
        }

        .md-card.main-rodape.md-theme-default {
            margin-top: -300px;
        }
    }
</style>