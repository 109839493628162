var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c("SwitchPopup"),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "md-layout", staticStyle: { display: "contents" } },
            [_vm._m(0)]
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-15 md-xlarge-size-15",
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "align-items": "center",
                "justify-content": "center",
              },
            },
            [
              _c("img", {
                staticClass: "logo-radio",
                attrs: { src: _vm.radio_current.logo, alt: "Logo da rádio" },
              }),
              _c("h4", { staticStyle: { "font-weight": "light" } }, [
                _vm._v(_vm._s(_vm.radio_current.name)),
              ]),
            ]
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "md-layout-item md-xsmall-size-100 md-medium-size-100 md-large-size-80 md-xlarge-size-70",
            },
            [
              _c(
                "md-card",
                [
                  _c("md-card-header", [
                    _c(
                      "h4",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "margin-bottom": "10px",
                        },
                      },
                      [_vm._v(" Sessões e usuários únicos ")]
                    ),
                  ]),
                  _c(
                    "md-card-content",
                    { staticClass: "remove-margin-and-padding" },
                    [
                      _c(
                        "div",
                        { staticClass: "custom-div-card-content md-layout" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-xsmall-size-100 md-small-size-40 md-medium-size-45 md-large-size-30 md-xlarge-size-30",
                              staticStyle: { margin: "auto" },
                            },
                            [
                              _c(
                                "h4",
                                { staticStyle: { "text-align": "center" } },
                                [_vm._v("Hoje")]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-around",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "category",
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v(" Sessões iniciadas ")]
                                    ),
                                    _c("h3", { staticClass: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-100",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  this.sessionsToday
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "15px" },
                                        },
                                        [_vm._v(" ")]
                                      ),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "category",
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v(" Usuários únicos ")]
                                    ),
                                    _c("h3", { staticClass: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  this.uniqueUsersToday
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-xsmall-size-100 md-small-size-40 md-medium-size-45 md-large-size-30 md-xlarge-size-30 div-border",
                              staticStyle: { margin: "auto" },
                            },
                            [
                              _c(
                                "h4",
                                { staticStyle: { "text-align": "center" } },
                                [_vm._v("Ontem")]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-around",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "category",
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v(" Sessões iniciadas ")]
                                    ),
                                    _c("h3", { staticClass: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  this.sessionsYesterday
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      this.percentSessionsYesterday > 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "text-success",
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-caret-up",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    this
                                                      .percentSessionsYesterday
                                                  )
                                                ),
                                              ]),
                                              _vm._v("% "),
                                            ]
                                          )
                                        : this.percentSessionsYesterday < 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "text-danger",
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-caret-down custom-icon-content",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    this
                                                      .percentSessionsYesterday
                                                  )
                                                ),
                                              ]),
                                              _vm._v("% "),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                                "white-space": "nowrap",
                                              },
                                            },
                                            [_vm._v("Sem percentual")]
                                          ),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "category",
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v(" Usuários únicos ")]
                                    ),
                                    _c("h3", { staticClass: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  this.uniqueUsersYesterday
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      this.percentUniqueUsersYesterday > 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "text-success",
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-caret-up",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    this
                                                      .percentUniqueUsersYesterday
                                                  )
                                                ),
                                              ]),
                                              _vm._v("% "),
                                            ]
                                          )
                                        : this.percentUniqueUsersYesterday < 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "text-danger",
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-caret-down custom-icon-content",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    this
                                                      .percentUniqueUsersYesterday
                                                  )
                                                ),
                                              ]),
                                              _vm._v("% "),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                                "white-space": "nowrap",
                                              },
                                            },
                                            [_vm._v("Sem percentual")]
                                          ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-xsmall-size-100 md-small-size-70 md-medium-size-70 md-large-size-30 md-xlarge-size-30",
                              staticStyle: { margin: "auto" },
                            },
                            [
                              _c(
                                "h4",
                                { staticStyle: { "text-align": "center" } },
                                [_vm._v("Mês")]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-around",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "category",
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v(" Sessões iniciadas ")]
                                    ),
                                    _c("h3", { staticClass: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-30 md-large-size-100 md-xlarge-size-100",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  this.sessionsMonth
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      this.percentSessionsMonth > 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "text-success",
                                              staticStyle: {
                                                "font-size": "15px",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-caret-up",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    this.percentSessionsMonth
                                                  )
                                                ),
                                              ]),
                                              _vm._v("% "),
                                            ]
                                          )
                                        : this.percentSessionsMonth < 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "text-danger",
                                              staticStyle: {
                                                "font-size": "15px",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-caret-down custom-icon-content",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    this.percentSessionsMonth
                                                  )
                                                ),
                                              ]),
                                              _vm._v("% "),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "15px",
                                                "white-space": "nowrap",
                                              },
                                            },
                                            [_vm._v("Sem percentual")]
                                          ),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "category",
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [_vm._v(" Média de usuários únicos ")]
                                    ),
                                    _c("h3", { staticClass: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatNumber")(
                                                  this.uniqueUsersMonth
                                                )
                                              ) +
                                              "/dia "
                                          ),
                                        ]
                                      ),
                                      this.percentUniqueUsersMonth > 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "text-success",
                                              staticStyle: {
                                                "font-size": "15px",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-caret-up",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    this.percentUniqueUsersMonth
                                                  )
                                                ),
                                              ]),
                                              _vm._v("% "),
                                            ]
                                          )
                                        : this.percentUniqueUsersMonth < 0
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "text-danger",
                                              staticStyle: {
                                                "font-size": "15px",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-caret-down custom-icon-content",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    this.percentUniqueUsersMonth
                                                  )
                                                ),
                                              ]),
                                              _vm._v("% "),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "15px",
                                                "white-space": "nowrap",
                                              },
                                            },
                                            [_vm._v("Sem percentual")]
                                          ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "md-card-footer",
                    { staticClass: "remove-margin-and-padding" },
                    [
                      _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "router-link",
                            {
                              staticStyle: {
                                display: "block",
                                margin: "2.5rem",
                                "font-size": "1rem",
                                color: "#4caf50",
                              },
                              attrs: { to: "/report-sessions" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.sendMixPanelEvent(
                                    `dashboard_button_report_clicked`
                                  )
                                },
                              },
                            },
                            [_vm._v("VER RELATÓRIO ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "md-card-actions md-alignment-left" },
                    [
                      _c("div", { staticClass: "stats" }, [
                        _c(
                          "i",
                          {
                            staticClass:
                              "md-icon md-icon-font md-theme-default",
                          },
                          [_vm._v("access_time")]
                        ),
                        _vm._v(
                          " Última atualização (" +
                            _vm._s(
                              new Date()
                                .toLocaleTimeString("pt-BR")
                                .substring(0, 2)
                            ) +
                            "h) "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-95 md-xlarge-size-85",
              staticStyle: { "margin-left": "10px" },
            },
            [
              _c(
                "md-card",
                [
                  _c("md-card-header", [
                    _c(
                      "h4",
                      {
                        staticStyle: {
                          margin: "10px 0 0 5px !important",
                          "text-align": "left",
                        },
                      },
                      [_vm._v(" Sessões iniciadas nos últimos 7 dias ")]
                    ),
                  ]),
                  _c(
                    "md-card-content",
                    { staticClass: "md-content-dashboard" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.arrAccessRadio.length === 0,
                              expression: "this.arrAccessRadio.length === 0",
                            },
                          ],
                          staticClass: "error-message",
                        },
                        [
                          _c("h3", [
                            _vm._v(
                              " Sem informações disponíveis. Aguarde até que a base de dados seja alimentada. "
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.arrAccessRadio.length !== 0,
                              expression: "this.arrAccessRadio.length !== 0",
                            },
                          ],
                          staticClass: "md-layout",
                        },
                        [
                          _c("div", {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-200 md-xlarge-size-200 row-icon-dashboard platform-data-container",
                            attrs: { id: "chart-sessions-per-platform" },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-25 md-xlarge-size-25",
                              staticStyle: {
                                "background-color": "#e9e9e9",
                                padding: "2rem",
                                margin: "1rem",
                                height: "70%",
                                "border-radius": "1rem",
                              },
                            },
                            [
                              _c("div", { staticClass: "md-layout" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-30 row-icon-dashboard",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fab fa-android",
                                      staticStyle: { color: "#5BB643" },
                                      attrs: { title: "Android" },
                                    }),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatNumber")(
                                            this.totalAndroid
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-30 row-icon-dashboard",
                                    staticStyle: {
                                      "border-left": "1px solid #e3e3e3",
                                      "border-right": "1px solid #e3e3e3",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fab fa-apple",
                                      staticStyle: { color: "#000000" },
                                      attrs: { title: "iOS" },
                                    }),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatNumber")(this.totalIos)
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-30 row-icon-dashboard",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-desktop",
                                      staticStyle: { color: "#FFA100" },
                                      attrs: { title: "Web" },
                                    }),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatNumber")(this.totalWeb)
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-30 row-icon-dashboard",
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { width: "38px" },
                                      attrs: {
                                        src: require("../../../public/img/alexa_icon.png"),
                                        alt: "alexa_icon",
                                      },
                                    }),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatNumber")(
                                            this.totalAlexa
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-30 row-icon-dashboard",
                                    staticStyle: {
                                      "border-left": "1px solid #e3e3e3",
                                      "border-right": "1px solid #e3e3e3",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-hdd",
                                      staticStyle: { color: "#61FFD1" },
                                      attrs: { title: "Apple TV" },
                                    }),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatNumber")(
                                            this.totalAppleTV
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "md-layout-item md-size-30 row-icon-dashboard",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "font-weight": "bold",
                                          margin: "0",
                                        },
                                      },
                                      [_vm._v("TOTAL")]
                                    ),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatNumber")(
                                            this.totalSessions
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
      },
      [
        _c("div", { staticClass: "spinner" }, [
          _c("div", { staticClass: "rect1" }),
          _c("div", { staticClass: "rect2" }),
          _c("div", { staticClass: "rect3" }),
          _c("div", { staticClass: "rect4" }),
          _c("div", { staticClass: "rect5" }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }