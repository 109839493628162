<template>
  <div>
    <MobAdSearcher class="hide-on-print"/>
    <MobAdForm />
    <ViewInfo />
    <ReportMobAdsPopup />
  </div>
</template>

<script>
import MobAdSearcher from './MobAdSearcher.vue'
import MobAdForm from './MobAdForm.vue'
import ViewInfo from './ViewInfo.vue'
import ReportMobAdsPopup from './ReportMobAdsPopup.vue'

export default {
  components: {
    MobAdSearcher,
    MobAdForm,
    ViewInfo,
    ReportMobAdsPopup
  }
}
</script>

<style>

</style>