<template>
  <md-card md-with-hover class="hide-on-print">
    <md-card-header>
      <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100" style="margin-top: 1rem;">
        <template v-if="mobAd.expired===1">
            <i style="color:#ef6b6c" class="fas fa-circle"></i>&nbsp;
            <span style="color:#ef6b6c">FINALIZADA</span>
        </template>
        <template v-else-if="mobAd.current===1">
            <i style="color:#4baf51" class="fas fa-circle"></i>&nbsp;
            <span style="color:#4baf51">ABERTA</span>
        </template>
        <template v-else-if="mobAd.opened===0">
            <i style="color:#c9cacb" class="fas fa-circle"></i>&nbsp;
            <span style="color:#c9cacb">PRÓXIMA</span>
        </template>
      </div>
    </md-card-header>
    <md-card-content>
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <h3 class="mobAd-name-title">{{mobAd.name}}</h3>
          <em>{{new Date(mobAd.start_date).toLocaleDateString('pt-BR')}} às {{mobAd.start_time.slice(0, -3)}}</em>
          <span> até </span>         
          <em>{{new Date(mobAd.end_date).toLocaleDateString('pt-BR')}} às {{mobAd.end_time.slice(0, -3)}}</em>
        </div>
        <div class="md-layout-item md-size-100" style="margin: 1rem 0;">
          <div class="md-layout md-alignment-center-space-between">
            <div class="md-layout-item md-size-50 md-xsmall-size-100" style="padding: 0;">
              <h5 style="display: inline-block; margin: 0;">Status:&nbsp;</h5>
            </div>
            <div class="md-layout-item md-size-50" style="padding-right: 0;">
              <div class="md-layout md-alignment-center-right">
                <span class="label-status" v-if="mobAd.active">ATIVADO</span>
                <span class="label-status" v-else>DESATIVADO</span>
                <el-switch 
                  ref="switch-status"
                  v-model="mobAd.active" 
                  @change="switchStatus()" 
                  :disabled="mobAd.expired===1" 
                  class="md-primary"></el-switch>
              </div>
            </div>
          </div>
        </div>
        <div class="md-layout-item md-size-100">
          <md-button class="md-info md-simple md-block outline" @click="editCampaign()" 
              :disabled="mobAd.expired===1"
              ref="edit-button">
              <span>Editar</span>
          </md-button>
          <md-button class="md-info md-simple md-block outline" @click="previewCampaign()" ref="banners-button">Ver banners</md-button>
          <md-button class="md-info md-block" @click="getMobAdReport()" ref="report-button">Ver Relatório</md-button>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script scoped>
import { Switch } from 'element-ui'
import mixpanelAdapter from '../../../utils/events/mixpanelAdapter'

export default {
  name: 'CardCampaign',
  components: {
    [Switch.name]: Switch,
  },
  props: ['mobAd'],
  methods: {
    editCampaign(){
      mixpanelAdapter.track(`edit_mobad_form_openened`, {
        screen_name: this.$props.name
      })
      this.$root.$emit('openEditMobAd', this.$props.mobAd)
    },
    previewCampaign(){
      this.$root.$emit('openPreviewCampaign', this.$props.mobAd)
      mixpanelAdapter.track(`mobad_banner_list_form_openened`, {
        screen_name: this.$props.name
      })
    },
    switchStatus(){ 
      this.$root.$emit('switchStatus', this.$props.mobAd)
    },
    getMobAdReport(){
      this.$root.$emit('openReportMobAdPopup', this.$props.mobAd)
      mixpanelAdapter.track(`mobad_report_generated`, {
        screen_name: this.$props.name
      })
    }
  }
}
</script>
<style>
.outline {
	border: 1px solid #009ddb;
}

.outline span{
  color: #009ddb;
}

.outline:disabled{
  border: 1px solid #afafaf;
}

.outline:disabled span {
  color: #afafaf;
}

.label-status {
  font-weight: 500;
  font-size: 90%;
  display: inline-block;
  margin-right: 10px;
}

.mobAd-name-title{
  text-align: left;
  font-weight: 500;
  margin-top: 0;
}

</style>