var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "episodes" },
    _vm._l(_vm.dataEpisodes, function (episode, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "row-episodes",
          staticStyle: { display: "flex", "flex-wrap": "wrap" },
        },
        [
          _c(
            "div",
            {
              staticClass: "row-child",
              staticStyle: {
                width: "17%",
                display: "flex",
                "justify-content": "center",
              },
            },
            [
              _c("img", {
                staticStyle: { width: "200px", "border-radius": "10px" },
                attrs: { src: episode.image },
              }),
            ]
          ),
          _c(
            "div",
            { staticClass: "row-child", staticStyle: { width: "60%" } },
            [
              _c(
                "h4",
                { staticStyle: { "font-weight": "400", margin: "auto" } },
                [_vm._v(_vm._s(episode.title))]
              ),
              _c(
                "audio",
                {
                  staticStyle: { width: "100%", "min-height": "60px" },
                  attrs: { id: "audio_" + index, controls: "controls" },
                },
                [
                  _c("source", { attrs: { src: episode.audio } }),
                  _vm._v(" Your browser does not support the audio element. "),
                ]
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "5px" } },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-warning md-sm",
                      on: {
                        click: function ($event) {
                          return _vm.editEpisode(episode)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fas fa-edit" }),
                      _vm._v(" Editar "),
                    ]
                  ),
                  _vm._v("  "),
                  _c(
                    "md-button",
                    {
                      staticClass: "md-danger md-sm",
                      on: {
                        click: function ($event) {
                          return _vm.deleteEpisode(episode)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fas fa-trash" }),
                      _vm._v(" Excluir "),
                    ]
                  ),
                  _c("br"),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-top": "25px" } }, [
                _c(
                  "span",
                  {
                    staticClass: "pub-date",
                    staticStyle: {
                      "margin-top": "50px",
                      "font-style": "italic",
                    },
                  },
                  [
                    _vm._v(
                      "Publicado em: " +
                        _vm._s(
                          new Date(episode.created).toLocaleString("pt-BR", {
                            timeZone: "America/Belem",
                          })
                        )
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "pub-date",
                    staticStyle: {
                      "margin-top": "0px",
                      "font-style": "italic",
                      float: "right",
                    },
                  },
                  [_vm._v("id: " + _vm._s(episode.id))]
                ),
              ]),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }