var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-100",
      attrs: { id: "configuration-new-user" },
    },
    [
      _c(
        "md-card",
        [
          _c(
            "md-card-header",
            { staticClass: "md-card-header-icon md-card-header-blue" },
            [
              _c(
                "div",
                { staticClass: "card-icon card" },
                [_c("md-icon", [_vm._v("group")])],
                1
              ),
              _c("h4", { staticClass: "title" }, [_vm._v("Usuários")]),
            ]
          ),
          _c("md-card-content", [
            _c(
              "div",
              [
                _c(
                  "md-table",
                  {
                    attrs: {
                      "md-sort": "name",
                      "md-sort-order": "asc",
                      "md-card": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "md-table-row",
                        fn: function ({ item }) {
                          return _c(
                            "md-table-row",
                            {},
                            [
                              _c(
                                "md-table-cell",
                                {
                                  attrs: {
                                    "md-label": "Usuário",
                                    "md-sort-by": "username",
                                  },
                                },
                                [_vm._v(_vm._s(item.username))]
                              ),
                              _c(
                                "md-table-cell",
                                {
                                  attrs: {
                                    "md-label": "Nome",
                                    "md-sort-by": "firstname",
                                  },
                                },
                                [_vm._v(_vm._s(item.firstname))]
                              ),
                              _c(
                                "md-table-cell",
                                {
                                  attrs: {
                                    "md-label": "Sobrenome",
                                    "md-sort-by": "lastname",
                                  },
                                },
                                [_vm._v(_vm._s(item.lastname))]
                              ),
                              _c(
                                "md-table-cell",
                                {
                                  attrs: {
                                    "md-label": "Email",
                                    "md-sort-by": "email",
                                  },
                                },
                                [_vm._v(_vm._s(item.email))]
                              ),
                              _c(
                                "md-table-cell",
                                { attrs: { "md-label": "Telefone" } },
                                [_vm._v(_vm._s(item.mobile))]
                              ),
                              _c(
                                "md-table-cell",
                                { attrs: { "md-label": "Nível" } },
                                [_vm._v(_vm._s(item.level.name))]
                              ),
                              _c(
                                "md-table-cell",
                                { attrs: { "md-label": "Email Semanal" } },
                                [
                                  _c(
                                    "md-checkbox",
                                    {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: item.weekly_email,
                                        callback: function ($$v) {
                                          _vm.$set(item, "weekly_email", $$v)
                                        },
                                        expression: "item.weekly_email",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.weekly_email ? "Sim" : "Não"
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "md-table-cell",
                                { attrs: { "md-label": "Ações" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "container-btn-edit" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "row-btn-edit" },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-edit",
                                              circle: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editUser(item)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "row-btn-edit" },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-delete",
                                              circle: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteUser(item.id)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                    model: {
                      value: _vm.usersDataTable,
                      callback: function ($$v) {
                        _vm.usersDataTable = $$v
                      },
                      expression: "usersDataTable",
                    },
                  },
                  [_c("md-table-toolbar")],
                  1
                ),
              ],
              1
            ),
            _c("h5", [_vm._v("Adicionar")]),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "Buscar usuário",
                        "remote-method": _vm.remoteMethod,
                        loading: _vm.loading,
                      },
                      model: {
                        value: _vm.selectUser,
                        callback: function ($$v) {
                          _vm.selectUser = $$v
                        },
                        expression: "selectUser",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Usuário")]),
                      _c("md-input", {
                        model: {
                          value: _vm.user.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "username", $$v)
                          },
                          expression: "user.username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Nome")]),
                      _c("md-input", {
                        model: {
                          value: _vm.user.firstname,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "firstname", $$v)
                          },
                          expression: "user.firstname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Sobrenome")]),
                      _c("md-input", {
                        model: {
                          value: _vm.user.lastname,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "lastname", $$v)
                          },
                          expression: "user.lastname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Email")]),
                      _c("md-input", {
                        attrs: { type: "email" },
                        model: {
                          value: _vm.user.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "email", $$v)
                          },
                          expression: "user.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Telefone")]),
                      _c("md-input", {
                        attrs: { type: "number" },
                        model: {
                          value: _vm.user.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "mobile", $$v)
                          },
                          expression: "user.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Nível")]),
                      _c(
                        "md-select",
                        {
                          attrs: { type: "select" },
                          model: {
                            value: _vm.user.level,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "level", $$v)
                            },
                            expression: "user.level",
                          },
                        },
                        [
                          _c("md-option", { attrs: { value: "0" } }, [
                            _vm._v("Administrador"),
                          ]),
                          _c("md-option", { attrs: { value: "1" } }, [
                            _vm._v("Gerente MobRadio"),
                          ]),
                          _c("md-option", { attrs: { value: "2" } }, [
                            _vm._v("Usuário do Painel"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-25 md-medium-size-20 md-large-size-20 md-xlarge-size-20",
                },
                [
                  _c(
                    "md-button",
                    {
                      staticClass: "md-warning md-block",
                      on: {
                        click: function ($event) {
                          return _vm.newUser()
                        },
                      },
                    },
                    [_vm._v(" Adicionar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("EditUser"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }