var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box-message", attrs: { id: "box-popup" } }, [
    _c(
      "div",
      {
        staticClass: "alert alert-warning alert-with-icon",
        attrs: { "data-notify": "container" },
      },
      [
        _c(
          "button",
          {
            staticClass: "close",
            attrs: { type: "button", "aria-hidden": "true" },
            on: {
              click: function ($event) {
                return _vm.closePopup()
              },
            },
          },
          [_vm._v("×")]
        ),
        _c(
          "i",
          { staticClass: "material-icons", attrs: { "data-notify": "icon" } },
          [_vm._v("warning")]
        ),
        _c("h3", { staticClass: "title-popup" }, [_vm._v(_vm._s(_vm.title))]),
        _c("span", { attrs: { "data-notify": "message" } }, [
          _vm._v(_vm._s(_vm.message)),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }