<template>
	<div class="md-layout">
		<div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
			<FilterSelector @searchData="searchMusicCollection()" :buttonsToDisplay="[
					buttonEnum.TODAY,
					buttonEnum.SEVEN_DAYS,
					buttonEnum.MONTH_TO_DATE,
					buttonEnum.CUSTOM,
				]"
				:extraFunction="true"
				@extraFunction="printReport()"
				:extraFunctionLabel="'IMPRIMIR'"
				:disableExtraFunction="this.tableCollection.length === 0"
			>
				<template #filters>
					<div class="md-layout-item md-small-size-100 md-large-size-20 md-xlarge-size-20 print-hide"
						style="padding: 0">
						<h3 class="md-layout-item md-size-100" style="
								font-size: 1rem;
								text-align: left;
								margin: 25px 0 10px 0;
								padding: 0;
								font-weight: 100;
							">
							Música
						</h3>
						<span id="load-data" class="load-data"
							><img src="@/assets/loading.gif"
						/></span>
						<v-autocomplete
							:items="items"
							:auto-select-one-item="false"
							:get-label="getLabel"
							:min-len="3"
							@update-items="updateItems"
							@item-selected="setMusic"
							@change="inputSearch"
							:component-item="template"
							:placeholder="'Digite o nome da música'"
						></v-autocomplete>
					</div>
				</template>
			</FilterSelector>
		</div>

		<div v-show="loading"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
			<Spinner />
		</div>
		<div
			v-show="!loading && blockStatus == 1 && tableCollection.length > 0" 
			class="md-layout-item md-size-100 print-hide">
			<md-card>
				<md-card-header>
					<h3 class="main-title" >{{`${artistSelect.title} - ${artistSelect.artist}`}}</h3>
				</md-card-header>
			</md-card>
		</div>
		<div v-show="!loading && blockStatus == 1 && tableCollection.length > 0"
			class="md-layout-item md-size-30 print-hide" style="max-height: 450px;">
			<ReportCardTableView 
				title="Estatísticas gerais" 
				dataDisplay="short-table" 
				:keys="['label', 'data']"
				:cardData="[
					{
						label: 'Total de likes',
						data: this.totalLikes,
					},
					{
						label: 'Total de dislikes',
						data: this.totalDislikes,
					},
					{
						label: 'Total de votos',
						data: this.totalVotes,
					},
					{
						label: 'Total de reproduções',
						data: this.totalPlayed,
					},
					{
						label: 'Total de pedidos',
						data: this.totalRequests,
					}
				]">
					
			</ReportCardTableView>
		</div>
		<div v-show="!loading && blockStatus == 1 && tableCollection.length > 0"
			class="md-layout-item md-size-70 print-hide">
			<ReportCardTableView 
				title="Extrato por hora"
				dataDisplay="long-table"
				:labels="['Hora', 'Likes', 'Dislikes', 'votos', 'Reproduções', 'Pedidos']" 
				:keys="[
					'hour',
					'total_likes',
					'total_dislikes',
					'total_votes',
					'total_played',
					'total_requests',
				]" 
				:cardData="this.tableCollection"
			/>
		</div>
		<block v-show="blockStatus == 0"></block>

		<div class="md-layout-item md-size-100">
			<md-card style="text-align: center" v-show="msgError">
				<md-card-content>
					<label for="">Sem informações pra serem exibidas.</label>
				</md-card-content>
			</md-card>
		</div>

		<!-- PRINT PAGE-->
		<div class="print">
			<TemplateReportExtractMusical
				:radioName="nameRadioReport" 
				:radioLogo="this.radio.logo"
				:title="this.artistSelect.title"
				:artist="this.artistSelect.artist"
				:periodStart="this.startDate.toLocaleDateString('pt-BR')" 
				:periodEnd="this.endDate.toLocaleDateString('pt-BR')" 
				:data="{
					totalLikes: this.totalLikes,
					totalDislikes: this.totalDislikes,
					totalVotes: this.totalVotes,
					totalPlayed: this.totalPlayed,
					totalRequests: this.totalRequests,
					collectionPerHour: this.tableCollection
				}"
			/>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import models from "../../routes/models.js";
import selectRadio from "../../utils/events/select-radio.js";
import block from "./Block.vue";
import ItemTemplate from "../../components/template-autocomplete/extract-musical..vue";
import jwt_decode from "jwt-decode";
import { DatePicker } from "element-ui";
import FilterSelector from "./Components/FilterSelector/FilterSelector.vue";
import Spinner from "../../components/Icons/Spinner.vue";
import filters from "./Components/FilterSelector/filters";
import buttonEnum from "./Components/FilterSelector/buttonEnum";
import Logger from "../../utils/Logger.js";
import formatDateToSQL from "../../utils/formatDateToSQL.js";
import ReportCardTableView from "./Components/ReportComponents/ReportCardTableView.vue";
import TemplateReportExtractMusical from "./ReportTemplates/TemplateReportExtractMusical.vue";
import mixpanelAdapter from "../../utils/events/mixpanelAdapter.js";

export default {
	components: {
		block,
		[DatePicker.name]: DatePicker,
		FilterSelector,
		Spinner,
		ReportCardTableView,
		TemplateReportExtractMusical
	},
	data() {
		return {
			buttonEnum: buttonEnum,
			radio: jwt_decode(localStorage.getItem("APP_01")).radio,
			nameRadioReport: jwt_decode(localStorage.getItem("APP_01")).radio.name,
			appKey: jwt_decode(localStorage.getItem("APP_01")).radio.key,
			startDate: new Date(),
			endDate: new Date(),
			source: "",
			musics: [],
			search: "",
			time1: null,
			time2: null,
			chips: [],
			loading: false,
			tableInfo: [],
			tableCollection: [],
			totalRequests: 0,
			totalLikes: 0,
			totalDislikes: 0,
			totalVotes: 0,
			totalPlayed: 0,
			timeout: null,
			disabledButton: true,
			blockStatus: 1,
			loadArtist: false,
			artistSelect: {
				artist: "",
				title: ""
			},
			item: "",
			items: [],
			template: ItemTemplate,
			periodStart: "",
			periodEnd: "",
			RadioLocalStorage: "",
			inputVal: "",
			msgError: false,
			currentSort: "hour",
			currentSortOrder: "desc",
		};
	},
	methods: {
		searchMusicCollection() {
			const stringMusic = this.artistSelect.title
			const stringArtist = this.artistSelect.artist

			if(!stringMusic || !stringArtist || !filters.startDate || !filters.endDate){
				return
			}

			this.loading = true
			this.tableInfo = []

			this.totalLikes = 0
			this.totalDislikes = 0
			this.totalRequests = 0
			this.totalVotes = 0
			this.totalPlayed = 0

			this.msgError = false
			this.startDate = filters.startDate
			this.endDate = filters.endDate

			let dateStartFormatted = formatDateToSQL(filters.startDate) + " 00:00:00";
			let dateEndFormatted = formatDateToSQL(filters.endDate) + " 23:59:59";


			Promise.resolve(
				this.getMusicCollectionByDate(dateStartFormatted, dateEndFormatted, stringMusic, stringArtist)
			)
				.then((resp) => {
					if(resp.data.length === 0){
						this.msgError = true
					}

					this.tableInfo.push({
						music: stringMusic,
						artist: stringArtist,
					})

					this.tableCollection = resp.data.map((element)=>{
						const hour = element.hour
						const hourFormatted = `${hour.padStart(2, '0')}`

						delete element["hour"]
						element = {
							hour: `${hourFormatted}:00 - ${hourFormatted}:59`,
							total_votes: element.total_likes + element.total_dislikes,
							...element
						}

						return element
					})

					this.tableCollection.forEach(item => {
						this.totalLikes += item.total_likes
						this.totalDislikes += item.total_dislikes
						this.totalRequests += item.total_requests
						this.totalVotes += item.total_likes + item.total_dislikes
						this.totalPlayed += item.total_played
					});
				})
				.catch((err) => {
					Swal.fire(
						"Ops!",
						"Houve algum erro ao recuperar o extrato musical. Caso o erro persiste, entre em contato com o suporte.",
						"error"
					);
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		setMusic(obj) {
			if (obj.artist && obj.artist !== "Sem resultados.") {
				this.inputVal = obj.artist;
				this.artistSelect = obj;
				this.tableInfo = [];
				this.tableInfo.push({
					music: obj.title,
					artist: obj.artist,
				});

				if(filters.startDate && filters.endDate){
					this.searchMusicCollection()
				}
			}
		},
		sendMixPanelEvent(eventString){
            mixpanelAdapter.track(eventString, {
                screen_name: this.$route.name
            })
        },
		printReport() {
			this.sendMixPanelEvent(`musical_extract_report_printed`)
			window.print();
		},
		async searchSongs(searchTerm) {
			this.loadArtist = true;
			return await models().get(
				`music/searchSong?appKey=${this.appKey}&searchTerm=${searchTerm}`
			);
		},

		async getMusicCollectionByDate(dateStart, dateEnd, music, artist){
			return await models().get(
				`music/getMusicCollectionByDate?appKey=${this.appKey}&startDate=${dateStart}&endDate=${dateEnd}&music=${music}&artist=${artist}`
			);
		},

		blockPage() {
			const menu = jwt_decode(localStorage.getItem("APP_02")).services;
			let service = null;
			for (let obj of menu) {
				if (obj.subitens === null) {
					const key = Object.keys(obj.item)[
						Object.values(obj.item).indexOf(this.$route.path.replace("/", ""))
					];
					if (key) {
						service = obj.item;
					}
				} else {
					const key = Object.keys(obj.subitens)[
						Object.values(obj.subitens).indexOf(
							this.$route.path.replace("/", "")
						)
					];
					if (key) {
						service = obj.subitens;
					}
				}
			}

			if (service) {
				this.blockStatus = service.has_permission;
			} else {
				this.$router.push("dashboard");
			}
		},
		findObjectByKey(array, key, value) {
			for (var i = 0; i < array.length; i++) {
				if (array[i][key] === value) {
					return array[i];
				}
			}
			return null;
		},
		print() {
			window.print();
		},
		selectedMusic(val) {
			if (item.artist && item.artist !== "Sem resultados.") {
				this.chips = [];
				this.setMusic(val);
			}
		},
		formattedDisplay(result) {
			return result.title + " - " + result.artist;
		},
		getLabel(item) {
			if (item.artist && item.artist !== "Sem resultados.")
				return item.title + " - " + item.artist;
		},
		updateItems(text) {
			if (text.length < 3) {
				return;
			}
			document.getElementById("load-data").style.display = "block";
			models()
				.get(`/music/searchSong?appKey=${this.appKey}&searchTerm=${text}`)
				.then((resp) => {
					if (resp.data.length == 0) {
						this.items = [{ album: "", artist: "Sem resultados.", title: "" }];
						document.getElementById("load-data").style.display = "none";
					} else {
						document.getElementById("load-data").style.display = "none";
						this.items = resp.data;
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		inputSearch(txt) {
			this.inputVal = txt;
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;

				if (this.currentSortOrder === "desc") {
					return a[sortBy] - b[sortBy];
				}

				return b[sortBy] - a[sortBy];
			});
		},
	},
	mounted() {
		if (jwt_decode(localStorage.getItem("APP_01")).radio) {
			this.RadioLocalStorage = jwt_decode(localStorage.getItem("APP_01")).radio;
			this.appKey = this.RadioLocalStorage.key;
			this.source = `https://api.painel.mobradio.com.br/music/searchSong?appKey=${this.appKey}&searchTerm=`;
			this.blockPage();
			this.loadArtist = false;
		}
		selectRadio.$on("selectRadio", (payload) => {
			this.appKey = payload.key;
			this.tableCollection = [];
			this.items = [];
			this.msgError = false;
			this.RadioLocalStorage = jwt_decode(localStorage.getItem("APP_01")).radio;
			this.source = `https://api.painel.mobradio.com.br/music/searchSong?appKey=${this.appKey}&searchTerm=`;
			this.blockPage();
			this.searchMusicCollection();
		});
	},
	watch: {
		selectedMusic: function (val) {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				if (val.toUpperCase() !== "" && val.length >= 2) {
					this.disabledButton = false;
					Promise.resolve(this.searchSongs(val.toUpperCase())).then((resp) => {
						this.loadArtist = false;
						if (resp.data[0]) {
							if (
								!this.musics.includes(
									resp.data[0].title + " - " + resp.data[0].artist
								)
							) {
								this.musics.push(
									resp.data[0].title + " - " + resp.data[0].artist
								);
							}
						}
					});
				} else {
					this.disabledButton = true;
				}
			}, 200);
		},
		inputVal() {
			if (this.time1 !== null && this.time2 !== null && this.inputVal !== "") {
				this.disabledButton = false;
			} else {
				this.disabledButton = true;
			}
			this.periodStart = new Date(this.time1).toLocaleDateString("pt-BR");
		},
		time1() {
			if (this.time1 !== null && this.time2 !== null && this.inputVal !== "") {
				this.disabledButton = false;
			} else {
				this.disabledButton = true;
			}
			this.periodStart = new Date(this.time1).toLocaleDateString("pt-BR");
		},
		time2() {
			if (this.time1 !== null && this.time2 !== null && this.inputVal !== "") {
				this.disabledButton = false;
			} else {
				this.disabledButton = true;
			}
			this.periodEnd = new Date(this.time2).toLocaleDateString("pt-BR");
		},
	},
};
</script>

<style lang="scss">
.shake-on-error .md-duplicated {
	animation-name: shake;
	animation-duration: 0.5s;
}
.md-chip .md-ripple {
	font-size: 1em;
}
.md-field > button {
	display: none;
}
.md-card-footer {
	margin-left: 0px !important;
}

.autocomplete__box {
	border-top: 0 !important;
	border-right: 0 !important;
	border-left: 0 !important;
	border-radius: 0 !important;
}

.autocomplete__box > img {
	width: 18px;
}

.autocomplete--clear img {
	width: 12px;
}

.autocomplete__no-results {
	display: none !important;
}

.print {
	display: none;
}

.main-title{
    font-weight: bold !important;
	text-align: center;
	font-size: 170%;
	margin: 24px 0 !important;
}

@page {
	margin-top: 10mm 10mm 10mm 10mm;
}

@media print {
	.print-hide {
		display: none;
	}

	.print {
		display: block;
	}

	body {
		margin: 0;
		padding: 0;
		font-size: 8pt;
	}
	img {
		width: 160px;
		height: 160px;
	}
	h1 {
		font-size: 18pt;
	}
	.md-table-head-label {
		font-weight: 800;
	}
	span {
		font-size: 12pt;
	}
	footer {
		text-align: center;
		font-size: 12pt;
	}
	.md-toolbar-section-start {
		display: none;
	}
	.titleRight {
		float: right;
		margin-top: -80px;
	}
	.nameRadio {
		font-size: 18pt;
	}
	.logoRadio {
		width: 48px;
		height: 48px;
		border-radius: 25px;
		margin-top: -10px;
	}
	.contentBody {
		margin-top: 20px;
	}
	.divTable {
		border: 1px solid #d2d2d2;
		padding: 10px;
		margin-bottom: 20px;
	}
	.footerIcon {
		width: 16px;
		height: 16px;
		margin-top: -5px;
	}
}

@media (min-width: 960px) {
	.cards-footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: -30px;
	}
}

@keyframes shake {
	0% {
		transform: translate(15px);
	}
	20% {
		transform: translate(-15px);
	}
	40% {
		transform: translate(7px);
	}
	60% {
		transform: translate(-7px);
	}
	80% {
		transform: translate(3px);
	}
	100% {
		transform: translate(0px);
	}
}
</style>
