<template>
	<div class="md-layout">
		<div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
			style="display:flex;">
			<div class="md-layout-item md-small-size-100 md-medium-size-15 md-large-size-15 md-xlarge-size-15">
				<md-button @click="dialogNewPost = true" class="md-block md-info" style="margin-top:25px;">
					Novo Popup
				</md-button>
			</div>
		</div>

		<div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
			<div class="md-layout-item md-size-100">
				<div class="md-card md-theme-default">
					<div class="md-card-header md-card-header-icon md-card-header-green">
						<div class="card-icon"><i
								class="md-icon md-icon-font md-theme-default">assignment</i></div>
						<h4 class="title">Popups</h4>
					</div>
					<div class="md-card-content">
						<div class="md-content md-table md-theme-default">
							<div class="md-content md-table-content md-scrollbar md-theme-default">
								<table>
									<thead>
										<tr>
											<th class="md-table-head">
												<div class="md-table-head-container md-ripple md-disabled">
													<div class="md-table-head-label">Status</div>
												</div>
											</th><th class="md-table-head">
												<div class="md-table-head-container md-ripple md-disabled">
													<div class="md-table-head-label">Nome</div>
												</div>
											</th>
											<th class="md-table-head">
												<div class="md-table-head-container md-ripple md-disabled">
													<div class="md-table-head-label">Tipo</div>
												</div>
											</th>
                                            <th class="md-table-head">
												<div class="md-table-head-container md-ripple md-disabled">
													<div class="md-table-head-label">Iniciado em</div>
												</div>
											</th>
                                            <th class="md-table-head">
												<div class="md-table-head-container md-ripple md-disabled">
													<div class="md-table-head-label">Duração/Validade</div>
												</div>
											</th>
											<th class="md-table-head">
												<div class="md-table-head-container md-ripple md-disabled">
													<div class="md-table-head-label">Ações</div>
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in popups" :key="index" class="md-table-row" :style="(item.status === 1?'background-color: #ffffe0;':'')">
											<td class="md-table-cell">
												<div class="md-table-cell-container" v-if="item.status == 1 && item.expiration_date > new Date().toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'})" style="width:5px; color:#4baf51"><i class="fas fa-circle"></i></div>
												<div class="md-table-cell-container" v-if="item.status == 1 && item.expiration_date < new Date().toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'})" style="width:5px; color:#ef6b6c"><i class="fas fa-circle"></i></div>
												<div class="md-table-cell-container" v-if="item.status == 0" style="width:5px; color:#c9cacb"><i class="fas fa-circle"></i></div>
											</td>
											<td class="md-table-cell">
												<div class="md-table-cell-container">{{item.title}}</div>
											</td>
											<td class="md-table-cell">
												<div class="md-table-cell-container">{{(item.type === 1) ? 'Informação' : (item.type === 2) ? 'Atualização' : (item.type === 3) ? 'Alerta' : (item.type === 4) ? 'Manutenção' : ''}}</div>
											</td>
                                            <td class="md-table-cell">
												<div class="md-table-cell-container">{{item.created}}</div>
											</td>
											<td class="md-table-cell">
												<div class="md-table-cell-container">{{item.expiration_date}}</div>
											</td>
											<td class="md-table-cell text-right">
												<div class="md-table-cell-container" style="display:flex">
                                                    <div>
                                                        <button type="button"
                                                            class="md-button md-just-icon md-theme-default md-success" style="margin-right:5px;">
                                                            <div class="md-ripple">
                                                                <div class="md-button-content"><i
                                                                        class="md-icon md-icon-font md-theme-default" @click="editPopup(item)">edit</i>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button type="button"
														class="md-button md-just-icon md-theme-default md-danger" @click="deletePopup(item.id)">
														<div class="md-ripple">
															<div class="md-button-content"><i
																	class="md-icon md-icon-font md-theme-default">close</i>
															</div>
														</div>
													</button>
                                                    </div>
                                                </div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<!---->
						</div>
					</div>
				</div>
			</div>
		</div>

		<el-dialog
            title="Novo Popup"
            :visible.sync="dialogNewPost"
            width="30%"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
        >
            <el-form :model="form">
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-80 md-large-size-80 md-xlarge-size-80"
                    >
                        <el-input
                            type="text"
                            v-model="form.title"
                            autocomplete="off"
                            placeholder="Título"
                        ></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-80 md-large-size-80 md-xlarge-size-80"
                    >
                        <el-input
                            type="textarea"
                            v-model="form.message"
                            autocomplete="off"
                            placeholder="Mensagem"
                        ></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-80 md-large-size-80 md-xlarge-size-80"
                    >
                        <el-select
                            v-model="form.type"
                            placeholder="Tipo"
                            style="width:100%;"
                        >
                            <el-option value="Informação">Informação <i style="color: #23a5d8" class="fas fa-circle"></i></el-option>
                            <el-option value="Atualização">Atualização <i style="color: #51ab55" class="fas fa-circle"></i></el-option>
                            <el-option value="Alerta">Alerta <i style="color: #ffa500" class="fas fa-circle"></i></el-option>
                            <el-option value="Manutenção">Manutenção <i style="color: #ff0000" class="fas fa-circle"></i></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-80 md-large-size-80 md-xlarge-size-80"
                    >
                        <el-date-picker
							v-model="form.expiration_date"
							type="datetime"
							format="dd-MM-yyyy HH:mm:ss"
							placeholder="Data de expiração"
							style="width:100% !important;"
						></el-date-picker>
                    </div>
                </div>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button
                    type="primary"
                    @click="newPopup()"
                    >Confirmar</el-button
                >
				<el-button @click="dialogNewPost = false">Cancelar</el-button>
            </span>
        </el-dialog>

		<PopupEdit />
	</div>	
</template>

<script>
import {
  Dialog,
  Button,
  Form,
  Input,
  Select,
  Option,
  Upload,
  Checkbox,
  Tabs,
  TabPane,
  OptionGroup,
  DatePicker
} from "element-ui";
import models from '../../../routes/models'
import PopupEdit from "./PopupEdit"
import swal from 'sweetalert2';
	export default {
		components: {
			[Dialog.name]: Dialog,
			[Button.name]: Button,
			[Form.name]: Form,
			[Input.name]: Input,
			[Select.name]: Select,
			[Option.name]: Option,
			[Upload.name]: Upload,
			[Checkbox.name]: Checkbox,
			[Tabs.name]: Tabs,
			[TabPane.name]: TabPane,
			[OptionGroup.name]: OptionGroup,
			[DatePicker.name]: DatePicker,
			PopupEdit
		},
		data() {
			return {
				popups: [],
				form: {
					title: '',
					message: '',
					type: '',
					expiration_date: ''
				},
				dialogNewPost: false,
			}
		},
		methods: {
			getPopups() {
				models().get("/popups/getPopupWarning")
					.then((popups) => {
						this.popups = popups.data
					})
					.catch((error) => console.log(error))
			},
			newPopup() {
				if(this.form.title !== "" && this.form.message !== "" && this.form.type !== "" && this.form.expiration_date !== "") {
					models().post("/popups/newPopup", this.form)
						.then(() => {
							this.form.title = ""
							this.form.message = ""
							this.form.type = ""
							this.form.expiration_date = ""
							this.dialogNewPost = false
							this.getPopups()
						})
						.catch((error) => console.log(error))
				}
			},
			editPopup(element) {
				this.$emit("openPopupEdit", element)
			},
			deletePopup(id) {
				swal.fire({
                    title: 'Atenção',
                    text: "Deseja mesmo excluir o popup de aviso?",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Confirmar'
                }).then((result) => {
                    if (result.value) {
						models().delete(`/popups/deletePopup?id=${id}`)
							.then(() => {
								swal(
									'',
									'Popup deletado!',
									'success'
								)
								this.getPopups()
							})
							.catch((error) => console.log(error))
					}})
			}
		},
		mounted() {
			this.getPopups()
			this.$root.$on("reloadPopupPage", () => {
				this.getPopups()
			})
		}
	}
</script>

<style>
	@media (max-width: 960px) {
		.el-dialog {
			width: 100% !important;
		}
	}
</style>
