var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.blockStatus == 1,
              expression: "blockStatus == 1",
            },
          ],
          staticClass: "md-layout-item md-small-size-100 principal",
        },
        [
          _c("FilterSelector", {
            attrs: {
              buttonsToDisplay: [
                _vm.buttonEnum.TODAY,
                _vm.buttonEnum.YESTERDAY_PLUS_TODAY,
                _vm.buttonEnum.SEVEN_DAYS,
                _vm.buttonEnum.LAST_30_DAYS,
                _vm.buttonEnum.LAST_MONTH,
                _vm.buttonEnum.CUSTOM,
              ],
              extraFunction: true,
              extraFunctionLabel: "VER RELATÓRIO",
              disableExtraFunction: _vm.tableData.length === 0,
            },
            on: {
              searchData: function ($event) {
                return _vm.setNewDate()
              },
              extraFunction: function ($event) {
                return _vm.redirectToReport()
              },
            },
            scopedSlots: _vm._u([
              {
                key: "filters",
                fn: function () {
                  return [
                    _c("div", { staticClass: "md-layout" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-large-size-20 md-xlarge-size-20",
                          staticStyle: { padding: "0" },
                        },
                        [
                          _c(
                            "h3",
                            {
                              staticClass: "md-layout-item md-size-100",
                              staticStyle: {
                                "font-size": "1rem",
                                "text-align": "left",
                                margin: "25px 0 10px 0",
                                padding: "0",
                                "font-weight": "100",
                              },
                            },
                            [_vm._v(" Artista (opcional) ")]
                          ),
                          _c("el-input", {
                            attrs: { placeholder: "Digite o nome do artista" },
                            model: {
                              value: _vm.artist,
                              callback: function ($$v) {
                                _vm.artist = $$v
                              },
                              expression: "artist",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-large-size-20 md-xlarge-size-20",
                        },
                        [
                          _c(
                            "h3",
                            {
                              staticClass: "md-layout-item md-size-100",
                              staticStyle: {
                                "font-size": "1rem",
                                "text-align": "left",
                                margin: "25px 0 10px 0",
                                padding: "0",
                                "font-weight": "100",
                              },
                            },
                            [_vm._v(" Música (opcional) ")]
                          ),
                          _c("el-input", {
                            attrs: { placeholder: "Digite o nome da música" },
                            model: {
                              value: _vm.title,
                              callback: function ($$v) {
                                _vm.title = $$v
                              },
                              expression: "title",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        },
        [_c("Spinner")],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        },
        [
          this.tableData.length > 0
            ? _c(
                "md-card",
                [
                  _c(
                    "md-card-header",
                    { staticClass: "md-card-header-icon md-card-header-green" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-icon" },
                        [_c("md-icon", [_vm._v("assignment")])],
                        1
                      ),
                      _c("div", { staticClass: "div-periodo" }, [
                        _c("span", [_vm._v(_vm._s(_vm.periodo))]),
                      ]),
                    ]
                  ),
                  _c(
                    "md-card-content",
                    [
                      _c(
                        "md-table",
                        {
                          staticClass:
                            "paginated-table table-striped table-hover",
                          attrs: {
                            value: _vm.queriedData,
                            "md-sort": _vm.currentSort,
                            "md-sort-order": _vm.currentSortOrder,
                          },
                          on: {
                            "update:mdSort": function ($event) {
                              _vm.currentSort = $event
                            },
                            "update:md-sort": function ($event) {
                              _vm.currentSort = $event
                            },
                            "update:mdSortOrder": function ($event) {
                              _vm.currentSortOrder = $event
                            },
                            "update:md-sort-order": function ($event) {
                              _vm.currentSortOrder = $event
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "md-table-row",
                                fn: function ({ item }) {
                                  return _c(
                                    "md-table-row",
                                    { attrs: { height: "10" } },
                                    [
                                      _c(
                                        "md-table-cell",
                                        { attrs: { "md-label": "Título" } },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                      _c(
                                        "md-table-cell",
                                        { attrs: { "md-label": "Artista" } },
                                        [_vm._v(_vm._s(item.artist))]
                                      ),
                                      _c(
                                        "md-table-cell",
                                        {
                                          attrs: {
                                            "md-label": "Curti",
                                            "md-sort-by": "percentual_likes",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatNumber")(
                                                item.total_likes
                                              )
                                            ) +
                                              " (" +
                                              _vm._s(
                                                Math.round(
                                                  item.percentual_likes
                                                )
                                              ) +
                                              "%)"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "md-table-cell",
                                        {
                                          attrs: {
                                            "md-label": "Não curti",
                                            "md-sort-by": "percentual_dislikes",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatNumber")(
                                                item.total_dislikes
                                              )
                                            ) +
                                              " (" +
                                              _vm._s(
                                                Math.round(
                                                  item.percentual_dislikes
                                                )
                                              ) +
                                              "%)"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "md-table-cell",
                                        {
                                          attrs: {
                                            "md-label": "Total",
                                            "md-sort-by": "total",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatNumber")(item.total)
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            4228675841
                          ),
                        },
                        [
                          _c(
                            "md-table-toolbar",
                            [
                              _c(
                                "md-field",
                                [
                                  _c("label", { attrs: { for: "pages" } }, [
                                    _vm._v("Por página"),
                                  ]),
                                  _c(
                                    "md-select",
                                    {
                                      attrs: { name: "pages" },
                                      model: {
                                        value: _vm.pagination.perPage,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.pagination,
                                            "perPage",
                                            $$v
                                          )
                                        },
                                        expression: "pagination.perPage",
                                      },
                                    },
                                    _vm._l(
                                      _vm.pagination.perPageOptions,
                                      function (item) {
                                        return _c(
                                          "md-option",
                                          {
                                            key: item,
                                            attrs: { label: item, value: item },
                                          },
                                          [_vm._v(" " + _vm._s(item) + " ")]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "md-card-actions",
                    { attrs: { "md-alignment": "space-between" } },
                    [
                      _c("div", {}, [
                        _c("p", { staticClass: "card-category" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.from + 1) +
                              " à " +
                              _vm._s(_vm.to) +
                              " de " +
                              _vm._s(_vm.total) +
                              " itens "
                          ),
                        ]),
                      ]),
                      _c("pagination", {
                        staticClass: "pagination-no-border pagination-success",
                        attrs: {
                          "per-page": _vm.pagination.perPage,
                          total: _vm.total,
                        },
                        model: {
                          value: _vm.pagination.currentPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.pagination, "currentPage", $$v)
                          },
                          expression: "pagination.currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "md-card",
                { staticStyle: { "text-align": "center" } },
                [
                  _c("md-card-content", [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v("Sem informações pra serem exibidas."),
                    ]),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
      _vm._m(0),
      _c("div", { staticClass: "titleRight impressao" }, [
        _c("b", { staticClass: "nameRadio" }, [
          _vm._v(
            _vm._s(_vm.nameRadioReport ? _vm.nameRadioReport : "MobRadio")
          ),
        ]),
        _vm._v("   "),
        _c("span", [
          _c("img", {
            staticClass: "logoRadio",
            attrs: { src: _vm.logo, alt: "" },
          }),
        ]),
      ]),
      _c("div", { staticClass: "impressao periodo" }, [
        _c("span", [
          _vm._v(
            "Período: " +
              _vm._s(_vm.periodStart) +
              " até: " +
              _vm._s(_vm.periodEnd) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticStyle: { width: "100%" } }, [_vm._v(" ")]),
      _c("div", { staticClass: "contentBody impressao" }, [
        _c("div", { staticClass: "divTable" }, [
          _c("h4", [_vm._v("Votação")]),
          _c(
            "div",
            {
              staticClass: "md-content md-table md-theme-default",
              attrs: { "table-header-color": "blue" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "md-content md-table-content md-scrollbar md-theme-default",
                },
                [
                  _c("table", [
                    _vm._m(1),
                    _vm._m(2),
                    _c(
                      "tbody",
                      _vm._l(this.tableData, function (item, index) {
                        return _c(
                          "tr",
                          { key: index, staticClass: "md-table-row" },
                          [
                            _c("td", { staticClass: "md-table-cell" }, [
                              _c(
                                "div",
                                { staticClass: "md-table-cell-container" },
                                [_vm._v(_vm._s(item.title))]
                              ),
                            ]),
                            _c("td", { staticClass: "md-table-cell" }, [
                              _c(
                                "div",
                                { staticClass: "md-table-cell-container" },
                                [_vm._v(_vm._s(item.artist))]
                              ),
                            ]),
                            _c("td", { staticClass: "md-table-cell" }, [
                              _c(
                                "div",
                                { staticClass: "md-table-cell-container" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatNumber")(item.total_likes)
                                      ) +
                                      " (" +
                                      _vm._s(
                                        Math.round(item.percentual_likes)
                                      ) +
                                      "%) "
                                  ),
                                ]
                              ),
                            ]),
                            _c("td", { staticClass: "md-table-cell" }, [
                              _c(
                                "div",
                                { staticClass: "md-table-cell-container" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatNumber")(
                                          item.total_dislikes
                                        )
                                      ) +
                                      " (" +
                                      _vm._s(
                                        Math.round(item.percentual_dislikes)
                                      ) +
                                      "%) "
                                  ),
                                ]
                              ),
                            ]),
                            _c("td", { staticClass: "md-table-cell" }, [
                              _c(
                                "div",
                                { staticClass: "md-table-cell-container" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatNumber")(item.total)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _c("block", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.blockStatus == 0,
            expression: "blockStatus == 0",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titleLeft impressao" }, [
      _c("img", {
        attrs: {
          src: require("../../../public/img/logo-mobradio-black.png"),
          alt: "",
        },
      }),
      _c("h1", [_vm._v("Relatório de Votação Musical")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "md-table-head", attrs: { width: "40" } }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Título"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head", attrs: { width: "40" } }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Artista"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head", attrs: { width: "40" } }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Curti"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head", attrs: { width: "40" } }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Não Curti"),
              ]),
            ]
          ),
        ]),
        _c("th", { staticClass: "md-table-head", attrs: { width: "40" } }, [
          _c(
            "div",
            { staticClass: "md-table-head-container md-ripple md-disabled" },
            [
              _c("div", { staticClass: "md-table-head-label" }, [
                _vm._v("Total"),
              ]),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tfoot", { staticClass: "report-footer" }, [
      _c("tr", [
        _c("td", { attrs: { colspan: "6" } }, [
          _vm._v(" Fonte de dados: MobRadio Analytics   "),
          _c("img", {
            staticClass: "footerIcon",
            attrs: { src: require("../../../public/img/favicon.png") },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }