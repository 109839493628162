<template>
	<div class="md-layout">
		<div
			v-show="loading"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
		>
			<Spinner />
		</div>
		<div
			v-show="!loading && blockStatus == 1"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
		>
			<FilterSelector
				@searchData="buttonSearch()"
				:buttonsToDisplay="[
					buttonEnum.TODAY,
					buttonEnum.SEVEN_DAYS,
					buttonEnum.MONTH_TO_DATE,
					buttonEnum.CUSTOM,
				]"
				:extraFunction="true"
				@extraFunction="printReport()"
				:extraFunctionLabel="'IMPRIMIR'"
				:disableExtraFunction="disabledButton"
			>
				<template #filters>
					<div
						class="md-layout-item md-small-size-100 md-large-size-20 md-xlarge-size-20 print-hide"
						style="padding: 0"
					>
						<h3
							class="md-layout-item md-size-100"
							style="
								font-size: 1rem;
								text-align: left;
								margin: 25px 0 10px 0;
								padding: 0;
								font-weight: 100;
							"
						>
							Música
						</h3>
						<span id="load-data" class="load-data"
							><img src="@/assets/loading.gif"
						/></span>
						<v-autocomplete
							:items="items"
							:auto-select-one-item="false"
							:get-label="getLabel"
							:min-len="3"
							@update-items="updateItems"
							@item-selected="setMusic"
							@change="inputSearch"
							:component-item="template"
							:placeholder="'Digite o nome da música'"
						></v-autocomplete>
					</div>
				</template>
			</FilterSelector>
		</div>

		<div
			v-show="!loading && blockStatus == 1 && tableVoted.length > 0"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
		>
			<md-card class="print-hide" style="z-index: 0">
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<i class="material-icons">info</i>
					</div>
					<h4>Informações da música</h4>
				</md-card-header>
				<md-card-content>
					<div
						class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
					>
						<md-table v-model="tableInfo" table-header-color="green">
							<md-table-row slot="md-table-row" slot-scope="{ item }">
								<md-table-cell md-label="Música">{{
									item.music
								}}</md-table-cell>
								<md-table-cell md-label="Artista">{{
									item.artist
								}}</md-table-cell>
							</md-table-row>
						</md-table>
					</div>
				</md-card-content>
			</md-card>
		</div>
		<div
			v-show="!loading && blockStatus == 1 && tableVoted.length > 0"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 print-hide"
		>
			<md-card style="z-index: 0">
				<md-card-header
					class="md-card-header-icon md-card-header-blue print-hide"
				>
					<div class="card-icon">
						<i class="material-icons">thumbs_up_down</i>
					</div>
					<h4>Votação</h4>
				</md-card-header>
				<md-card-content>
					<div
						class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
					>
						<md-table
							v-model="tableVoted"
							table-header-color="green"
							:md-sort.sync="currentSort"
							:md-sort-order.sync="currentSortOrder"
							:md-sort-fn="customSort"
						>
							<md-table-row slot="md-table-row" slot-scope="{ item }">
								<md-table-cell md-label="Horário" md-sort-by="hour">{{
									item.hour
								}}</md-table-cell>
								<md-table-cell md-label="Curti">{{ item.like }}</md-table-cell>
								<md-table-cell md-label="Não curti">{{
									item.dislike
								}}</md-table-cell>
								<md-table-cell md-label="Total Votação" md-sort-by="total">{{
									item.total
								}}</md-table-cell>
							</md-table-row>
						</md-table>
					</div>
				</md-card-content>
			</md-card>
		</div>
		<div
			v-show="!loading && blockStatus == 1 && tableVoted.length > 0"
			class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50 print-hide"
		>
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-danger">
					<div class="card-icon print-hide">
						<i class="material-icons">call_made</i>
					</div>
					<h4>Pedidos</h4>
				</md-card-header>
				<md-card-content>
					<span style="padding-left: 20px">{{ requests }}</span>
				</md-card-content>
			</md-card>
		</div>
		<div
			v-show="!loading && blockStatus == 1 && tableVoted.length > 0"
			class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50 print-hide"
		>
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-warning">
					<div class="card-icon print-hide">
						<i class="material-icons">play_circle_filled</i>
					</div>
					<h4>Execuções</h4>
				</md-card-header>
				<md-card-content>
					<span style="padding-left: 20px">{{ execution }}</span>
				</md-card-content>
			</md-card>
		</div>
		<block v-show="blockStatus == 0"></block>

		<md-card style="text-align: center" v-show="msgError">
			<md-card-content>
				<label for="">Sem informações pra serem exibidas.</label>
			</md-card-content>
		</md-card>

		<!-- PRINT PAGE-->
		<div class="md-layout print">
			<div>
				<img src="./../../../public/img/logo-mobradio-black.png" alt="" />
				<h1>Extrato por artista</h1>
			</div>
			<div class="titleRight">
				<b class="nameRadio">{{
					RadioLocalStorage.name ? RadioLocalStorage.name : "MobRadio"
				}}</b>
				&nbsp;
				<img class="logoRadio" :src="RadioLocalStorage.logo" alt="" />
			</div>
			<div>
				<span>Período: {{ periodStart }} até: {{ periodEnd }} </span>
			</div>
			<div class="contentBody">
				<div class="divTable">
					<md-table v-model="tableInfo" table-header-color="blue">
						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell md-label="Música">{{ item.music }}</md-table-cell>
							<md-table-cell md-label="Artista">{{
								item.artist
							}}</md-table-cell>
						</md-table-row>
					</md-table>
				</div>
				<div class="divTable">
					<md-table v-model="tableVoted" table-header-color="blue">
						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell md-label="Horário">{{ item.hour }}</md-table-cell>
							<md-table-cell md-label="Curti">{{ item.like }}</md-table-cell>
							<md-table-cell md-label="Não curti">{{
								item.dislike
							}}</md-table-cell>
							<md-table-cell md-label="Total Votação">{{
								item.total
							}}</md-table-cell>
						</md-table-row>
					</md-table>
				</div>
				<div class="divTable">
					<md-table table-header-color="blue">
						<md-table-row slot="md-table-row">
							<md-table-head>Pedidos</md-table-head>
							<md-table-head>Execuções</md-table-head>
						</md-table-row>
						<md-table-row slot="md-table-row">
							<md-table-cell>{{ requests }}</md-table-cell>
							<md-table-cell>{{ execution }}</md-table-cell>
						</md-table-row>
					</md-table>
				</div>
			</div>

			<footer v-if="this.tableInfo.length > 0">
				Fonte de dados: MobRadio Analytics &nbsp;
				<img class="footerIcon" src="./../../../public/img/favicon.png" />
			</footer>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import models from "../../routes/models.js";
import selectRadio from "../../utils/events/select-radio.js";
import block from "./Block.vue";
import ItemTemplate from "../../components/template-autocomplete/extract-musical..vue";
import jwt_decode from "jwt-decode";
import { DatePicker } from "element-ui";
import FilterSelector from "./Components/FilterSelector/FilterSelector.vue";
import Spinner from "../../components/Icons/Spinner.vue";
import filters from "./Components/FilterSelector/filters";
import buttonEnum from "./Components/FilterSelector/buttonEnum";

export default {
	components: {
		block,
		[DatePicker.name]: DatePicker,
		FilterSelector,
		Spinner,
	},
	data() {
		return {
			buttonEnum: buttonEnum,
			appKey: jwt_decode(localStorage.getItem("APP_01")).radio.key,
			source: "",
			musics: [],
			search: "",
			time1: null,
			time2: null,
			chips: [],
			loading: false,
			employees: [],
			tableInfo: [],
			tableRq: [],
			tablePlay: [],
			tableVoted: [],
			timeout: null,
			requests: "---",
			execution: "---",
			disabledButton: true,
			blockStatus: 1,
			loadArtist: false,
			artistSelect: null,
			item: "",
			items: [],
			template: ItemTemplate,
			periodStart: "",
			periodEnd: "",
			RadioLocalStorage: "",
			inputVal: "",
			msgError: false,
			currentSort: "hour",
			currentSortOrder: "desc",
		};
	},
	methods: {
		beginDate() {
			let dateStart = new Date();
			let dateEnd = new Date();
			this.time1 =
				dateStart.getFullYear() +
				"-" +
				(dateStart.getMonth() + 1) +
				"-" +
				dateStart.getDate() +
				" 00:00:00";
			this.time2 =
				dateEnd.getFullYear() +
				"-" +
				(dateEnd.getMonth() + 1) +
				"-" +
				dateEnd.getDate() +
				" 23:59:59";
		},
		buttonSearch() {
			const stringMusic = this.artistSelect.title;
			const stringArtist = this.artistSelect.artist;

			this.loading = true;
			this.tableInfo = [];
			let dateStart = filters.startDate;
			let dateEnd = filters.endDate;
			dateStart =
				dateStart.getFullYear() +
				"-" +
				(dateStart.getMonth() + 1) +
				"-" +
				dateStart.getDate() +
				" 00:00:00";
			dateEnd =
				dateEnd.getFullYear() +
				"-" +
				(dateEnd.getMonth() + 1) +
				"-" +
				dateEnd.getDate() +
				" 23:59:59";
			Promise.all([
				this.getVoted(dateStart, dateEnd, stringMusic),
				this.getExecution(dateStart, dateEnd, stringMusic),
				this.getRequests(dateStart, dateEnd, stringMusic),
			])
				.then(([resp1, resp2, resp3]) => {
					let like_per1 = 0,
						dislike_per1 = 0,
						like_per2 = 0,
						dislike_per2 = 0,
						like_per3 = 0,
						dislike_per3 = 0,
						like_per4 = 0,
						dislike_per4 = 0,
						like_per5 = 0,
						dislike_per5 = 0,
						like_per6 = 0,
						dislike_per6 = 0,
						like_per7 = 0,
						dislike_per7 = 0;
					for (let i of resp1.data) {
						if (parseInt(i.hour) >= 0 && parseInt(i.hour) <= 6) {
							like_per1 += parseInt(i.total_likes);
							dislike_per1 += parseInt(i.total_dislikes);
						}
						if (parseInt(i.hour) > 6 && parseInt(i.hour) <= 9) {
							like_per2 += parseInt(i.total_likes);
							dislike_per2 += parseInt(i.total_dislikes);
						}
						if (parseInt(i.hour) > 9 && parseInt(i.hour) <= 12) {
							like_per3 += parseInt(i.total_likes);
							dislike_per3 += parseInt(i.total_dislikes);
						}
						if (parseInt(i.hour) > 12 && parseInt(i.hour) <= 14) {
							like_per4 += parseInt(i.total_likes);
							dislike_per4 += parseInt(i.total_dislikes);
						}
						if (parseInt(i.hour) > 14 && parseInt(i.hour) <= 17) {
							like_per5 += parseInt(i.total_likes);
							dislike_per5 += parseInt(i.total_dislikes);
						}
						if (parseInt(i.hour) > 17 && parseInt(i.hour) <= 20) {
							like_per6 += parseInt(i.total_likes);
							dislike_per6 += parseInt(i.total_dislikes);
						}
						if (parseInt(i.hour) > 20 && parseInt(i.hour) <= 24) {
							like_per7 += parseInt(i.total_likes);
							dislike_per7 += parseInt(i.total_dislikes);
						}
					}
					this.tableVoted = [];
					this.tableVoted.push(
						{
							hour: "00:00 - 05:59",
							like: `${like_per1} (${
								Math.round((like_per1 * 100) / (like_per1 + dislike_per1))
									? Math.round((like_per1 * 100) / (like_per1 + dislike_per1))
									: 0
							}%)`,
							dislike: `${dislike_per1} (${
								Math.round((dislike_per1 * 100) / (like_per1 + dislike_per1))
									? Math.round(
											(dislike_per1 * 100) / (like_per1 + dislike_per1)
									  )
									: 0
							}%)`,
							total: like_per1 + dislike_per1,
						},
						{
							hour: "06:00 - 08:59",
							like: `${like_per2} (${
								Math.round((like_per2 * 100) / (like_per2 + dislike_per2))
									? Math.round((like_per2 * 100) / (like_per2 + dislike_per2))
									: 0
							}%)`,
							dislike: `${dislike_per2} (${
								Math.round((dislike_per2 * 100) / (like_per2 + dislike_per2))
									? Math.round(
											(dislike_per2 * 100) / (like_per2 + dislike_per2)
									  )
									: 0
							}%)`,
							total: like_per2 + dislike_per2,
						},
						{
							hour: "09:00 - 11:59",
							like: `${like_per3} (${
								Math.round((like_per3 * 100) / (like_per3 + dislike_per3))
									? Math.round((like_per3 * 100) / (like_per3 + dislike_per3))
									: 0
							}%)`,
							dislike: `${dislike_per3} (${
								Math.round((dislike_per3 * 100) / (like_per3 + dislike_per3))
									? Math.round(
											(dislike_per3 * 100) / (like_per3 + dislike_per3)
									  )
									: 0
							}%)`,
							total: like_per3 + dislike_per3,
						},
						{
							hour: "12:00 - 13:59",
							like: `${like_per4} (${
								Math.round((like_per4 * 100) / (like_per4 + dislike_per4))
									? Math.round((like_per4 * 100) / (like_per4 + dislike_per4))
									: 0
							}%)`,
							dislike: `${dislike_per4} (${
								Math.round((dislike_per4 * 100) / (like_per4 + dislike_per4))
									? Math.round(
											(dislike_per4 * 100) / (like_per4 + dislike_per4)
									  )
									: 0
							}%)`,
							total: like_per4 + dislike_per4,
						},
						{
							hour: "14:00 - 16:59",
							like: `${like_per5} (${
								Math.round((like_per5 * 100) / (like_per5 + dislike_per5))
									? Math.round((like_per5 * 100) / (like_per5 + dislike_per5))
									: 0
							}%)`,
							dislike: `${dislike_per5} (${
								Math.round((dislike_per5 * 100) / (like_per5 + dislike_per5))
									? Math.round(
											(dislike_per5 * 100) / (like_per5 + dislike_per5)
									  )
									: 0
							}%)`,
							total: like_per5 + dislike_per5,
						},
						{
							hour: "17:00 - 19:59",
							like: `${like_per6} (${
								Math.round((like_per6 * 100) / (like_per6 + dislike_per6))
									? Math.round((like_per6 * 100) / (like_per6 + dislike_per6))
									: 0
							}%)`,
							dislike: `${dislike_per6} (${
								Math.round((dislike_per6 * 100) / (like_per6 + dislike_per6))
									? Math.round(
											(dislike_per6 * 100) / (like_per6 + dislike_per6)
									  )
									: 0
							}%)`,
							total: like_per6 + dislike_per6,
						},
						{
							hour: "20:00 - 23:59",
							like: `${like_per7} (${
								Math.round((like_per7 * 100) / (like_per7 + dislike_per7))
									? Math.round((like_per7 * 100) / (like_per7 + dislike_per7))
									: 0
							}%)`,
							dislike: `${dislike_per7} (${
								Math.round((dislike_per7 * 100) / (like_per7 + dislike_per7))
									? Math.round(
											(dislike_per7 * 100) / (like_per7 + dislike_per7)
									  )
									: 0
							}%)`,
							total: like_per7 + dislike_per7,
						}
					);
					if (resp2.data.length > 0) {
						this.execution = resp2.data[0].total;
					} else {
						this.execution = "0";
					}
					if (resp3.data.length > 0) {
						this.requests = resp3.data[0].total;
					} else {
						this.requests = "0";
					}
					this.tableInfo.push({
						music: stringMusic,
						artist: stringArtist,
					});
					Swal.close();
				})
				.catch((err) => {
					Swal.fire(
						"Ops!",
						"Houve algum erro ao recuperar o extrato musical. Caso o erro persiste, entre em contato com o suporte.",
						"error"
					);
					console.log(err);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		setMusic(obj) {
			if (obj.artist && obj.artist !== "Sem resultados.") {
				this.inputVal = obj.artist;
				this.artistSelect = obj;
				this.tableInfo = [];
				this.tableInfo.push({
					music: obj.title,
					artist: obj.artist,
				});
			}
		},
		async searchSongs(searchTerm) {
			this.loadArtist = true;
			return await models().get(
				`music/searchSong?appKey=${this.appKey}&searchTerm=${searchTerm}`
			);
		},
		async getVoted(dateStart, dateEnd, title) {
			return await models().get(
				`music/getVoted?appKey=${this.appKey}&startDate=${dateStart}&endDate=${dateEnd}&title=${title}`
			);
		},
		async getExecution(dateStart, dateEnd, title) {
			return await models().get(
				`music/getExecution?appKey=${this.appKey}&startDate=${dateStart}&endDate=${dateEnd}&title=${title}`
			);
		},
		async getRequests(dateStart, dateEnd, title) {
			return await models().get(
				`music/getRequests?appKey=${this.appKey}&startDate=${dateStart}&endDate=${dateEnd}&title=${title}`
			);
		},
		blockPage() {
			const menu = jwt_decode(localStorage.getItem("APP_02")).services;
			let service = null;
			for (let obj of menu) {
				if (obj.subitens === null) {
					const key = Object.keys(obj.item)[
						Object.values(obj.item).indexOf(this.$route.path.replace("/", ""))
					];
					if (key) {
						service = obj.item;
					}
				} else {
					const key = Object.keys(obj.subitens)[
						Object.values(obj.subitens).indexOf(
							this.$route.path.replace("/", "")
						)
					];
					if (key) {
						service = obj.subitens;
					}
				}
			}

			if (service) {
				this.blockStatus = service.has_permission;
			} else {
				this.$router.push("dashboard");
			}
		},
		findObjectByKey(array, key, value) {
			for (var i = 0; i < array.length; i++) {
				if (array[i][key] === value) {
					return array[i];
				}
			}
			return null;
		},
		print() {
			window.print();
		},
		selectedMusic(val) {
			if (item.artist && item.artist !== "Sem resultados.") {
				this.chips = [];
				this.setMusic(val);
			}
		},
		formattedDisplay(result) {
			return result.title + " - " + result.artist;
		},
		getLabel(item) {
			if (item.artist && item.artist !== "Sem resultados.")
				return item.title + " - " + item.artist;
		},
		updateItems(text) {
			if (text.length < 3) {
				return;
			}
			document.getElementById("load-data").style.display = "block";
			models()
				.get(`/music/searchSong?appKey=${this.appKey}&searchTerm=${text}`)
				.then((resp) => {
					if (resp.data.length == 0) {
						this.items = [{ album: "", artist: "Sem resultados.", title: "" }];
						document.getElementById("load-data").style.display = "none";
					} else {
						document.getElementById("load-data").style.display = "none";
						this.items = resp.data;
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		inputSearch(txt) {
			this.inputVal = txt;
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;

				if (this.currentSortOrder === "desc") {
					return a[sortBy] - b[sortBy];
				}

				return b[sortBy] - a[sortBy];
			});
		},
	},
	mounted() {
		if (jwt_decode(localStorage.getItem("APP_01")).radio) {
			this.RadioLocalStorage = jwt_decode(localStorage.getItem("APP_01")).radio;
			this.appKey = this.RadioLocalStorage.key;
			this.source = `https://api.painel.mobradio.com.br/music/searchSong?appKey=${this.appKey}&searchTerm=`;
			this.blockPage();
			this.loadArtist = false;
		}
		selectRadio.$on("selectRadio", (payload) => {
			this.appKey = payload.key;
			this.tableVoted = [];
			this.items = [];
			this.msgError = false;
			this.RadioLocalStorage = jwt_decode(localStorage.getItem("APP_01")).radio;
			this.source = `https://api.painel.mobradio.com.br/music/searchSong?appKey=${this.appKey}&searchTerm=`;
			this.blockPage();
			this.buttonSearch();
		});
	},
	watch: {
		selectedMusic: function (val) {
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				if (val.toUpperCase() !== "" && val.length >= 2) {
					this.disabledButton = false;
					Promise.resolve(this.searchSongs(val.toUpperCase())).then((resp) => {
						this.loadArtist = false;
						if (resp.data[0]) {
							if (
								!this.musics.includes(
									resp.data[0].title + " - " + resp.data[0].artist
								)
							) {
								this.musics.push(
									resp.data[0].title + " - " + resp.data[0].artist
								);
							}
						}
					});
				} else {
					this.disabledButton = true;
				}
			}, 200);
		},
		inputVal() {
			if (this.time1 !== null && this.time2 !== null && this.inputVal !== "") {
				this.disabledButton = false;
			} else {
				this.disabledButton = true;
			}
			this.periodStart = new Date(this.time1).toLocaleDateString("pt-BR");
		},
		time1() {
			if (this.time1 !== null && this.time2 !== null && this.inputVal !== "") {
				this.disabledButton = false;
			} else {
				this.disabledButton = true;
			}
			this.periodStart = new Date(this.time1).toLocaleDateString("pt-BR");
		},
		time2() {
			if (this.time1 !== null && this.time2 !== null && this.inputVal !== "") {
				this.disabledButton = false;
			} else {
				this.disabledButton = true;
			}
			this.periodEnd = new Date(this.time2).toLocaleDateString("pt-BR");
		},
	},
};
</script>

<style lang="scss">
.shake-on-error .md-duplicated {
	animation-name: shake;
	animation-duration: 0.5s;
}
.md-chip .md-ripple {
	font-size: 1em;
}
.md-field > button {
	display: none;
}
.md-card-footer {
	margin-left: 0px !important;
}

.autocomplete__box {
	border-top: 0 !important;
	border-right: 0 !important;
	border-left: 0 !important;
	border-radius: 0 !important;
}

.autocomplete__box > img {
	width: 18px;
}

.autocomplete--clear img {
	width: 12px;
}

.autocomplete__no-results {
	display: none !important;
}

.print {
	display: none;
}
@page {
	margin-top: 10mm 10mm 10mm 10mm;
}

@media print {
	.print-hide {
		display: none;
	}

	.print {
		display: block;
	}

	body {
		margin: 0;
		padding: 0;
		font-size: 8pt;
	}
	img {
		width: 160px;
		height: 160px;
	}
	h1 {
		font-size: 18pt;
	}
	.md-table-head-label {
		font-weight: 800;
	}
	span {
		font-size: 12pt;
	}
	footer {
		text-align: center;
		font-size: 12pt;
	}
	.md-toolbar-section-start {
		display: none;
	}
	.titleRight {
		float: right;
		margin-top: -80px;
	}
	.nameRadio {
		font-size: 18pt;
	}
	.logoRadio {
		width: 48px;
		height: 48px;
		border-radius: 25px;
		margin-top: -10px;
	}
	.contentBody {
		margin-top: 20px;
	}
	.divTable {
		border: 1px solid #d2d2d2;
		padding: 10px;
		margin-bottom: 20px;
	}
	.footerIcon {
		width: 16px;
		height: 16px;
		margin-top: -5px;
	}
}

@media (min-width: 960px) {
	.cards-footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: -30px;
	}
}

@keyframes shake {
	0% {
		transform: translate(15px);
	}
	20% {
		transform: translate(-15px);
	}
	40% {
		transform: translate(7px);
	}
	60% {
		transform: translate(-7px);
	}
	80% {
		transform: translate(3px);
	}
	100% {
		transform: translate(0px);
	}
}
</style>
