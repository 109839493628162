<template>
    <div>
        <el-dialog :title="`Formulário de ${promoType==='1'?'Prêmio da hora':'Promoção'}`"
            :visible.sync="dialogVisibleNewPromo" 
            :before-close="closeModal" :close-on-click-modal="false"
            :center="true" :lock-scroll="false">

            <el-steps :active="activeStep" finish-status="success">
                <el-step title="Geral"></el-step>
                <el-step title="Período"></el-step>
                <el-step title="Regulamento"></el-step>
                <el-step title="Prêmios"></el-step>
                <el-step title="Validação"></el-step>
            </el-steps>

            <div v-show="activeStep === 0" class="main-gift-container ">
                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                    <div class="md-layout divDescription">
                        <span class="info-span">
                            Olá, aqui você irá preencher os campos que descreverão {{promoType==='1'?'o prêmio da hora':'a promoção'}}
                        </span>
                    </div>
                </div>
                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                    <el-input :placeholder="`Nome ${promoType==='1'?'do prêmio da hora':'da promoção'}`" v-model="gift.title" :disabled="disabledInputs"></el-input>
                </div>
                <div 
                    v-if="promoType==='2'"
                    class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                    <el-input
                        :disabled="disabledInputs"
                        style="margin-top: 15px;"
                        placeholder="Pergunta do concurso cultural" v-model="gift.question"></el-input>
                </div>
                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                    <el-input type="textarea" rows="5" 
                        :placeholder="`Descrição ${promoType==='1'?'do prêmio da hora':'da promoção'}`"
                        v-model="gift.description"
                        :disabled="disabledInputs"
                        ></el-input>
                </div>

                <div
                    class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-70 md-large-size-60 md-xlarge-size-60 div-img">
                    <span style="color:#c8cbd2">{{promoType==='1'?'Imagem do prêmio da hora':'Imagem da promoção'}}</span>
                    <el-upload class="avatar-uploader avatar-gifts" action="#" accept="image/jpeg,image/gif,image/png"
                        :auto-upload="false" 
                        :show-file-list="false" 
                        :on-change="validateImageSizeAndResolution" 
                        :disabled="disabledInputs"
                        >
                        
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span style="color:red" v-if="msgComponentImage !== ''">{{ msgComponentImage }}</span>
                </div>

                <div
                    class="md-layout-item md-size-90 md-small-size-95 md-xlarge-size-95 container-bottom-actions">
                    <el-button type="danger" style="margin-top: 12px;" @click="closeModal()">{{disabledInputs?'Cancelar':'Fechar'}}</el-button>
                    <el-button
                        ref="next-button"
                        type="primary" 
                        style="margin-top: 12px;" 
                        @click="validateInputAndGoToNextStep()"
                    >Próximo</el-button>
                </div>
                <div class="md-layout">
                    <div v-if="errorFields.length"
                        class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                        <p>
                            <b>Os campos abaixo são obrigatórios e não foram preenchidos:</b>
                        <ul>
                            <li v-for="error in errorFields" :key="error">{{ error }}</li>
                        </ul>
                        </p>
                    </div>
                </div>
            </div>

            <div v-show="activeStep === 1" class="main-gift-container ">
                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                    <div class="md-layout divDescription">
                        <span class="info-span">
                            Aqui você irá definir o período em que {{promoType==='1'?'o Prêmio da hora':'a Promoção'}} ficará ativ{{promoType==='1'?'o':'a'}} no aplicativo
                        </span>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        :class="promoType==='1'?'md-size-100':'md-size-50'"
                        class="md-layout-item md-xsmall-size-100">
                        <el-date-picker v-model="gift.startDate" type="date" placeholder="Data de início"
                            style="width:100% !important; margin-bottom: 15px;" format="dd/MM/yyyy" id="input_new_date_start"
                            :disabled="disabledInputs"
                            :picker-options="{
                                start: '00:00',
                                step: '01:00',
                                end: '23:00',
                            }"
                            >
                        </el-date-picker>
                    </div>
                    <div
                        v-if="promoType==='2'"
                        :class="promoType==='1'?'md-size-100':'md-size-50'"
                        class="md-layout-item md-size-50 md-xsmall-size-100">
                        <el-date-picker v-model="gift.endDate" type="date" placeholder="Data de término"
                            style="width:100% !important; margin-bottom: 15px;" format="dd/MM/yyyy" id="input_new_date_start"
                            :disabled="disabledInputs"
                            :picker-options="{
                                start: '00:00',
                                step: '01:00',
                                end: '23:00',
                            }"
                            >
                        </el-date-picker>
                    </div>
                </div>

                <div class="md-layout">
                    <div
                        class="md-layout-item md-size-50 md-xsmall-size-100">
                        <el-time-picker 
                            style="width:100% !important; margin-bottom: 15px !important;"
                            v-model="gift.startTime" 
                            :picker-options="{
                                selectableRange: '00:00:00 - 23:59:00'
                            }"
                            placeholder="Hora de início"
                            format="HH:mm"
                            :disabled="disabledInputs">
                        </el-time-picker>
                    </div>
                    <div
                        class="md-layout-item md-size-50 md-xsmall-size-100">
                        <el-time-picker 
                            style="width:100% !important; margin-bottom: 15px !important;"
                            v-model="gift.endTime" 
                            :picker-options="{
                                selectableRange: '00:00:00 - 23:59:00'
                            }"
                            placeholder="Hora de término"
                            format="HH:mm"
                            :disabled="disabledInputs">
                        </el-time-picker>
                    </div>
                </div>

                <div
                    class="md-layout-item md-size-90 md-small-size-95 md-xlarge-size-95 container-bottom-actions">
                    <el-button type="default" style="margin-top: 12px;" @click="backToPreviousStep()">Voltar</el-button>
                    <el-button type="primary" style="margin-top: 12px;" @click="validateInputAndGoToNextStep()">Próximo</el-button>
                </div>
                <div class="md-layout">
                    <div v-if="errorFields.length"
                        class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                        <p>
                            <b>Houveram alguns erros ao preencher os dados:</b>
                        <ul>
                            <li v-for="error in errorFields" :key="error">{{ error }}</li>
                        </ul>
                        </p>
                    </div>
                </div>
            </div>

            <div v-show="activeStep === 2" class="main-gift-container ">
                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                    <div class="md-layout divDescription">
                        <span class="info-span">
                            Nessa etapa é importante fazer o uso de um bom regulamento, com um texto revisado, pois é uma
                            medida que traz segurança para a rádio e para o ouvinte.
                        </span>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                        <el-input 
                            id="input_terms" type="textarea" :rows="15" v-model="gift.termsOfUse"
                            placeholder="Descreva o regulamento da promoção" :disabled="disabledInputs"></el-input>
                    </div>
                </div>

                <div
                    class="md-layout-item md-x-small-70 md-size-90 md-small-size-95 md-xlarge-size-95 container-bottom-actions">
                    <el-button type="default" style="margin-top: 12px;" @click="backToPreviousStep()">Voltar</el-button>
                    <el-button type="primary" style="margin-top: 12px;" @click="validateInputAndGoToNextStep()">Próximo</el-button>
                </div>
                <div class="md-layout">
                    <div v-if="errorFields.length"
                        class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                        <p>
                            <b>Os campos abaixo são obrigatórios e não foram preenchidos:</b>
                        <ul>
                            <li v-for="error in errorFields" :key="error">{{ error }}</li>
                        </ul>
                        </p>
                    </div>
                </div>
            </div>

            <div v-show="activeStep === 3" class="main-gift-container ">
                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                    <div class="md-layout divDescription">
                        <span class="info-span">
                            Nessa etapa você irá incluir os prêmios da promoção.
                        </span>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-medium-size-80 md-large-size-60 md-xlarge-size-60">
                        <el-input 
                            :disabled="disabledInputs"
                            autosize type="textarea" placeholder="Nome do Prêmio" v-model="award" ></el-input>
                        <span :style="award.length > 150 ? 'color: #FF0606' : 'color: #707070'">
                            ({{ award.length }}/150) <span v-if="award.length > 150">O nome do prêmio passa de 150 caracteres</span>
                        </span>

                    </div>
                    <div class="md-layout-item md-small-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20"
                        style="margin-top:2px;">
                        <el-button type="primary" style="margin-top: 12px;" @click="addGift()"
                            :disabled="award.length > 150 || disabledInputs">+</el-button>
                    </div>
                </div>


                <draggable v-model="rowsGift" v-bind="dragOptions"
                    class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
                    style="padding-top:20px;">
                    <transition-group type="transition" name="flip-list" @start="isDragging = true"
                        @end="isDragging = false">
                        <card-award v-for="(element, index) in rowsGift" 
                            :key="element.award" 
                            :awardNameDefault="element.award"
                            :awardImageDefault="element.imageSrc" 
                            :index="index" 
                            :viewOnly="disabledInputs"
                        ></card-award>
                    </transition-group>
                </draggable>

                <div
                    class="md-layout-item md-size-90 md-small-size-95 md-xlarge-size-95 container-bottom-actions">
                    <el-button type="default" style="margin-top: 12px;" @click="backToPreviousStep()">Voltar</el-button>
                    <el-button type="primary" style="margin-top: 12px;" @click="validateInputAndGoToNextStep()">Próximo</el-button>
                </div>
                <div class="md-layout">
                    <div v-if="errorFields.length"
                        class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                        <p>
                            <b>Os campos abaixo são obrigatórios e não foram preenchidos:</b>
                        <ul>
                            <li v-for="error in errorFields" :key="error">{{ error }}</li>
                        </ul>
                        </p>
                    </div>
                </div>
            </div>

            <div v-show="activeStep === 4" style="margin-top:20px;" class="main-validation-container">
                <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                    <div class="md-layout divDescription">
                        <span class="info-span">
                            Pronto, agora que você já inseriu todas as informações da promoção, é hora de revisar e garantir
                            que tudo esteja correto.
                        </span>
                    </div>
                </div>
                <el-tabs v-model="activeTableValidation" @tab-click="handleClick" style="padding: 0 15px;">
                    <el-tab-pane label="Informações Gerais" name="first">
                        <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
                            style="margin-bottom:15px;">
                            <span style="color:#c8cbd2">Nome {{promoType==='1'?'do prêmio da hora':'da promoção'}}</span>
                            <el-input v-model="gift.title"
                                disabled></el-input>
                        </div>

                        <div 
                            v-if="promoType==='2'"
                            style="margin-bottom:15px;"
                            class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                            <span style="color:#c8cbd2">Pergunta do concurso cultural</span>
                            <el-input
                                aria-disabled=""
                                disabled
                                placeholder="Pergunta do concurso cultural" v-model="gift.question"></el-input>
                        </div>

                        <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
                            style="margin-bottom:15px;">
                            <span style="color:#c8cbd2">Descrição da promoção</span>
                            <el-input style="margin-top:-15px" type="textarea" rows="5" placeholder="Descrição da promoção"
                                v-model="gift.description" disabled></el-input>
                        </div>
                        
                        <div class="md-layout">
                            <span
                                class="md-layout-item md-size-100" 
                                style="color:#c8cbd2;">Período da promoção</span>
                            <div
                                class="md-layout-item md-xsmall-size-100 md-medium-size-50"
                                :class="promoType==='2'?'md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50':'md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100'">
                                <el-date-picker disabled v-model="gift.startDate" type="date" placeholder="Data inicial"
                                    style="width:100% !important; margin: 7px 0;" format="dd/MM/yyyy" id="input_new_date_start">
                                </el-date-picker>
                            </div>
                            <div
                                v-if="promoType==='2'"
                                class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                                <el-date-picker disabled v-model="gift.endDate" type="date" placeholder="Data inicial"
                                    style="width:100% !important; margin: 7px 0;" format="dd/MM/yyyy" id="input_new_date_start">
                                </el-date-picker>
                            </div>
                        </div>

                        <div class="md-layout">
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                                <el-time-picker
                                style="width:100% !important; margin: 7px 0;"
                                disabled
                                v-model="gift.startTime"
                                value-format="HH:mm"
                                format="HH:mm"
                                placeholder="Horário inicial">
                                </el-time-picker>
                            </div>
                            <div
                                class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                                <el-time-picker
                                    disabled 
                                    style="width:100% !important; margin: 7px 0;"
                                    v-model="gift.endTime"
                                    value-format="HH:mm"
                                    format="HH:mm"
                                    placeholder="Horário final">
                                </el-time-picker>
                            </div>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
                            style="margin-top:10px;">
                            <span style="color:#c8cbd2">Imagem {{promoType==='1'?'do prêmio da hora':'da promoção'}}</span>
                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Prêmios" name="second">
                        <md-table style="padding:15px;" :value="rowsGift" class="paginated-table table-striped table-hover">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell v-if="item.imageSrc" md-label="Imagem"><img width="64px;"
                                        :src="item.imageSrc" /></md-table-cell>
                                <md-table-cell v-if="!item.imageSrc" md-label="Imagem"><img width="64px;"
                                        src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" /></md-table-cell>
                                <md-table-cell md-label="Nome do Prêmio">{{ item.award }}</md-table-cell>
                            </md-table-row>
                        </md-table>
                    </el-tab-pane>
                    <el-tab-pane label="Regulamento" name="third">
                        <div
                            class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                            <el-input 
                                disabled id="input_terms" type="textarea" :rows="15" v-model="gift.termsOfUse"
                                placeholder="Descreva o regulamento da promoção"></el-input>
                        </div>
                    </el-tab-pane>
                </el-tabs>

                <div v-show="gift.startDate && gift.startTime && gift.endTime" style="margin-top:20px;"
                    class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                    <span style="color:red; font-size:14px;" class="info-span">
                        Atenção: É importante verificar e ter certeza do período que está definindo. Uma vez que a promoção
                        iniciar não será possível realizar nenhuma alteração!
                    </span>
                </div>
                <div
                    class="md-layout-item md-size-90 md-small-size-95 md-xlarge-size-95 container-bottom-actions">
                    <el-button type="default" style="margin-top: 12px;" @click="backToPreviousStep()">Voltar</el-button>
                    <template v-if="!disabledInputs">
                        <el-button v-if="!editionMode" type="success" style="margin-top: 12px;" @click="createNewGift()">Finalizar</el-button>
                        <el-button v-else type="success" style="margin-top: 12px;" @click="updateGift()">Finalizar</el-button>
                    </template>
                    <el-button v-else type="danger" style="margin-top: 12px;" @click="closeModal()">Fechar</el-button>

                </div>
                <div class="md-layout">
                    <div v-if="errorFields.length"
                        class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                        <p>
                            <b>Os campos abaixo são obrigatórios e não foram preenchidos:</b>
                        <ul>
                            <li v-for="error in errorFields" :key="error">{{ error }}</li>
                        </ul>
                        </p>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import selectRadio from '../../../utils/events/select-radio'
import uploadCloudinary from '../../../utils/events/uploadCloudinary'
import destroyImageFromCloudinary from '../../../utils/events/destroyImageFromCloudinary'
import clearAllCache from '../../../utils/events/clearAllCache'
import { Dialog, Button, Input, Tabs, TabPane, Upload, Step, Steps, TimeSelect, DatePicker } from 'element-ui'
import Swal from 'sweetalert2'
import models from '../../../routes/models'
import jwt_decode from "jwt-decode"
import draggable from 'vuedraggable'
import CardAward from '../Components/CardAward.vue'
import moment from 'moment-timezone'
import mixpanelAdapter from '../../../utils/events/mixpanelAdapter'

async function deleteAllGifts(id) {
    const URL = 'gift/removeAllGifts'
    let dataForm = {id: id}
    return await models().put(URL, dataForm)
}

async function insertGiftsByAppKey(key, title, url, giftId) {
    let dataForm = { appKey: key, title: title, image_logo_url: url, promoId: giftId }
    return await models().post('gift/insertGifstByAppKey', dataForm)
}

function loadRequest(title, html) {
    Swal({
        title: title,
        html: html,
        type: "info",
        onOpen: () => {
            Swal.showLoading()
        }
    })
}

function closeRequest() {
    Swal.close()
}

function swalMessageError(title, msg) {
    Swal(
        `${title}`,
        `${msg}`,
        'error'
    )
}

export default {
    components: {
        [Dialog.name]: Dialog,
        [Button.name]: Button,
        [Input.name]: Input,
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
        [Upload.name]: Upload,
        [Step.name]: Step,
        [Steps.name]: Steps,
        [TimeSelect.name]: TimeSelect,
        [DatePicker.name]: DatePicker,
        draggable,
        CardAward
    },
    props: ['promoType', 'formMode'],
    data() {
        return {
            radio: jwt_decode(localStorage.getItem('APP_01')).radio,
            user: jwt_decode(localStorage.getItem('SET_APP')).user[0],
            activeName: "promocao",
            activeTableValidation: "first",
            imageUrl: '',
            giftId: null,
            gift: {
                title: '',
                startDate: '',
                endDate: '',
                startTime: '',
                endTime: '',
                award: [],
                description: '',
                question: '',
                imageUrl: '',
                termsOfUse: ''
            },
            rowsGift: [],
            editionMode: false,
            disabledInputs: false,
            award: '',
            uploadFile: null,
            dialogVisibleNewPromo: false,
            errorFields: [],
            errorInvalidDate: false,
            errorInvalidTime: false,
            userChangedOriginalDateInfo: false,
            imageUrlGift: '',
            uploadFileGift: '',
            activeStep: 0,
            inputEditGift: false,
            msgComponentImage: ''
        }
    },
    methods: {
        openModal(payload) {
            if (payload.mode === 'creation') {
                this.editionMode = false
                if(this.$props.promoType==='1'){
                    this.gift.startDate = new Date(moment())
                }
            } else if (payload.mode === 'edition') {
                this.editionMode = true
                this.getGiftData(payload.id)
            }else if(payload.mode === 'view'){
                this.editionMode = false
                this.disabledInputs = true
                this.getGiftData(payload.id)
            }
            this.dialogVisibleNewPromo = true
        },

        backToPreviousStep() {
            this.errorFields = []
            this.activeStep--
        },

        validateInputAndGoToNextStep() {
            if(this.disabledInputs){
                this.activeStep++
                return
            }
            this.errorFields = []
            switch (this.activeStep) {
                case 0:
                    this.validateGeneralInfo()
                    break;
                case 1:
                    this.validateGiftSchedule()
                    break;
                case 2:
                    this.validateTerms()
                    break;
                case 3:
                    this.validateAwards()
                    break;
                default:
                    break;
            }
            if (this.errorFields.length === 0) {
                this.activeStep++
            }
        },
        validateGeneralInfo(){
            if (!this.gift.title) {
                this.errorFields.push(`Preencha o campo " Nome ${promoType==='1'?'Prêmio da hora':'Promoção'}"`)
            }
            if (!this.gift.question && this.$props.promoType==='2') {
                this.errorFields.push('Preencha o campo "Pergunta do concurso cultural"')
            }
            if (!this.gift.description) {
                this.errorFields.push(`Preencha o campo "Descrição ${this.$props.promoType==='1'?'do prêmio da hora':'da promoção'}"`)
            }
            if (!this.imageUrl) {
                this.errorFields.push('Preencha o campo "Imagem da promoção"')
            }
        },
        validateGiftSchedule(){
            if (this.gift.startDate == '') {
                this.errorFields.push('Preencha o campo "Data inicial"')
            }
            if (this.gift.endDate == '' && this.$props.promoType === '2') {
                this.errorFields.push('Preencha o campo "Data final"')
            }
            if (this.gift.startTime == '') {
                this.errorFields.push('Preencha o campo "Horário inicial"')
            }
            if (this.gift.endTime == '') {
                this.errorFields.push('Preencha o campo "Horário final"')
            }
                    
            this.validateDates()
            this.validateHours()
            
        },
        
        validateDates() {
            if(this.user.level === 0){
                this.errorInvalidDate = false;
                return;
            }
			this.errorInvalidDate = false;

			const today = moment().startOf('day')
			const startDate = moment(this.gift.startDate)
            //Se a promoção a ser validada for um prêmio da hora, a data final é igual a inicial.
			const endDate =  moment(this.$props.promoType==='1'?this.gift.startDate:this.gift.endDate)

			if (startDate < today) {
				this.errorFields.push(`Não se pode cadastrar um${this.$props.promoType==='1'?' prêmio da hora':'a promoção'} anterior à hoje.`)
                this.errorInvalidDate = true
			}
			else if (startDate> endDate) {
				this.errorFields.push("A data de término é anterior à data de início")
                this.errorInvalidDate = true
			}
		},
		
		validateHours() {
            if(this.user.level === 0){
                this.errorInvalidTime = false;
                return;
            }
			this.errorInvalidTime = false;

			let today = moment()
			const currentTimeInSeconds = today.hours() * 3600 + today.minutes() * 60
            today = moment().startOf('day')

			const startDate = moment(this.gift.startDate).startOf('day')
			const endDate = moment(this.$props.promoType==='1'?this.gift.startDate:this.gift.endDate).startOf('day')

			const startHours = moment(this.gift.startTime).hours()
			const startMinutes = moment(this.gift.startTime).minutes()
			const startTimeInSeconds = startHours * 3600 + startMinutes * 60

			const endHours = moment(this.gift.endTime).hours()
			const endMinutes = moment(this.gift.endTime).minutes()
			const endTimeInSeconds = endHours * 3600 + endMinutes * 60

			if (
				startDate.isSame(today) &&
				startTimeInSeconds < currentTimeInSeconds
			) {
				this.errorFields.push(`Não se pode criar ${this.$props.promoType==='1'?'um prêmio da hora':'uma promoção'} com o horário de início anterior ao de agora`)
                this.errorInvalidTime = true
			}
			if (
				startDate.isSame(endDate) &&
				endTimeInSeconds < startTimeInSeconds
			) {
                this.errorFields.push(`Não se pode criar uma ${this.$props.promoType==='1'?'um prêmio da hora':'uma promoção'} com o horário de término anterior ao de início`)
                this.errorInvalidTime = true
			}
		},

        validateTerms(){
            if (this.gift.termsOfUse == '') {
                this.errorFields.push('Preencha o campo "Regulamento"')
            }
        },

        validateAwards(){
            if (this.rowsGift.length === 0) {
                this.errorFields.push('Insira prêmios para validar o cadastro')
            }
        },

        validateImageSizeAndResolution(file) {
            this.msgComponentImage = ''
            
            if (file.size >= 250000) {
                this.msgComponentImage = 'O tamanho da imagem não pode ser maior que 250kb'
                return
            } else {
                this.checkImageResolution(file).then(()=>{
                    this.uploadFile = file.raw
                    this.imageUrl = URL.createObjectURL(file.raw)
                }).catch(()=>{
                    this.msgComponentImage = 'A imagem deve ter tamanho 512px por 512px'
                    this.uploadFile = null
                    this.imageUrl = null
                    return
                })
            }
            
        },

        checkImageResolution(file){
            return new Promise((resolve, reject) => {
                const image = new Image();
                image.src = window.URL.createObjectURL(file.raw)
                
                image.onload = () => {
                    if (image.width === 512 && image.height === 512) {
                        resolve()
                    } else {
                        reject()
                    }
                };
            });
        },

        addGift() {
            if (this.award != '') {
                this.rowsGift.push({
                    award: this.award.replace(/"/g, '\'').replace(/#/g, '').trim(),
                    fileSizeError: false
                })
            }
            this.award = ''
            this.imageUrlGift = ''
            this.uploadFileGift = ''
        },

        clearForm() {
            this.activeName = "promocao",
            this.imageUrl = '',
            this.giftId = null,
            this.gift.title = '',
            this.gift.startDate = '',
            this.gift.endDate = '',
            this.gift.startTime = '',
            this.gift.endTime = '',
            this.gift.description = '',
            this.gift.imageUrl = '',
            this.gift.question = ''
            this.gift.termsOfUse = ''
            this.rowsGift = [],
            this.award = '',
            this.disabledInputs = false
            this.uploadFile = null
            this.errorFields = []
            this.activeStep = 0
        },

        closeModal() {
            if(this.disabledInputs){
                this.clearForm()
                this.dialogVisibleNewPromo = false
                return
            }
            Swal.fire({
                title: 'Atenção',
                text: "Você está prestes a cancelar o preenchimento do formulário e todas as informações serão perdidas. Deseja continuar?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.value) {
                    this.clearForm()
                    this.dialogVisibleNewPromo = false
                    this.activeStep = 0
                }
            });
        },
        deleteGift(index) {
            this.rowsGift.splice(index, 1)
        },
        async getEditGifts(id) {
            const URL = `gift/getGift?id=${id}`
            return await models().get(URL)
                .then(response => {
                    return response
                })
                .catch(err => {return err})
        },

        async getEditGiftById(id) {
            const URL = `gift/getGiftsById?id=${id}`
            return await models().get(URL)
                .then(response => {return response})
                .catch(err => {return err})
        },

        async createNewGift() {
            loadRequest('Aguarde', `Estamos criando ${this.$props.promoType==="1"?'o prêmio da hora':'a promoção'}`)
            const URL = 'gift/newGift'
            if(this.$props.promoType==='1'){
                this.gift.endDate = this.gift.startDate
            }
            let startDate = moment(this.gift.startDate).format("YYYY-MM-DD")
            let endDate = moment(this.gift.endDate).format("YYYY-MM-DD")
            let startTime = moment(this.gift.startTime).format("HH:mm:00")
            let endTime = moment(this.gift.endTime).format("HH:mm:00")

            let stringGifts = []
            for (let item of this.rowsGift) {
                stringGifts.push(`${encodeURIComponent(item.award)}#${item.imageUrl}`)
            }

            const aux_gifts = stringGifts.join('|')

            let url_image = (await uploadCloudinary(this.radio.key, this.uploadFile)).secure_url
            if(!url_image){
                closeRequest()
                swalMessageError('Erro', 'Ocorreu um erro ao fazer o upload da imagem, por favor, entre em contato com nosso suporte.')
                console.log(err) 
            }
            const dataForm = {
                appKey: this.radio.key,
                name: encodeURIComponent(this.gift.title),
                description: encodeURIComponent(this.gift.description),
                question: encodeURIComponent(this.gift.question),
                imageUrl: '',
                startDate: `${startDate} ${startTime}`,
                endDate: `${endDate} ${endTime}`,
                isClosed: '0',
                gifts: aux_gifts,
                giftPromoType: this.$props.promoType,
                terms: encodeURIComponent(this.gift.termsOfUse),
                qtdWinner: '1',
                userId: this.user.id
            }
            dataForm.imageUrl = url_image

            return await models().post(URL, dataForm)
                .then(async response => {
                    if (response.data[0]['_insertedId']) {
                        this.giftId = response.data[0]['_insertedId']
                        try{
                            await this.updateGiftAwards()
                        }catch{

                        }
                        closeRequest()
                        this.clearForm()
                        this.dialogVisibleNewPromo = false
                        this.sendMixPanelEvent(`gift_created`)
                        this.$root.$emit("getGifts")
                    } else {
                        closeRequest()
                        swalMessageError('Erro', 'Ocorreu um erro ao cadastrar as informações, por favor, entre em contato com nosso suporte.')
                        console.log(response)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    closeRequest()
                    destroyImageFromCloudinary(dataForm.secure_url)
                    this.dialogVisibleNewPromo = true
                    swalMessageError('Ops', `Já existe  ${this.$props.promoType==="1"?'um prêmio da hora':'uma promoção'} cadastrado no período selecionado, por favor, revise antes de prosseguir`)
                    console.log(err)
                })
        },
        formatDateToSQL(date, time){
            const year = date.getFullYear()
            const month = date.getMonth() + 1
            const day = date.getDate()
            return year + '-' + month + '-' + day + ' ' + time + ':00'
        },
        async updateGiftAwards(){
            await deleteAllGifts(this.giftId);
            for(let item of this.rowsGift){
                let url = item.imageSrc
                if(item.uploadFile){
                    url = (await uploadCloudinary(this.radio.key, item.uploadFile)).secure_url
                }
                await insertGiftsByAppKey(this.radio.key, item.award, url, this.giftId)
            }
        },
        async updateGift() {
            loadRequest('Aguarde', `Estamos editando ${this.$props.promoType==="1"?'o prêmio da hora':'a promoção'}`)
            const URL = 'gift/updateGift'

            let startDate = moment(this.gift.startDate).format('YYYY-MM-DD')
            let endDate = moment(this.$props.promoType==='1'?this.gift.startDate:this.gift.endDate).format('YYYY-MM-DD')
            let startTime = moment(this.gift.startTime).format("HH:mm")
            let endTime = moment(this.gift.endTime).format("HH:mm")
            
            
            let url_image = this.gift.imageUrl;
            if(this.uploadFile){
                try {
                    url_image = (await uploadCloudinary(this.radio.key, this.uploadFile)).secure_url
                }catch {
                    closeRequest()
                    swalMessageError('Erro', 'Ocorreu um erro ao fazer o upload da imagem, por favor, entre em contato com nosso suporte.')
                    return
                }
            }
            clearAllCache(null, this.radio.key, "getGiftPromoFeaturedImage")
            const dataForm = {
                id: this.giftId,
                name: encodeURIComponent(this.gift.title),
                description: encodeURIComponent(this.gift.description),
                question: encodeURIComponent(this.gift.question),
                imageUrl: '',
                startDate: `${startDate} ${startTime}`,
                endDate: `${endDate} ${endTime}`,
                isClosed: '0',
                giftPromoType: this.$props.promoType,
                terms: encodeURIComponent(this.gift.termsOfUse),
                qtdWinner: '1',
                userId: this.user.id
            }
            dataForm.imageUrl = url_image
            return await models().put(URL, dataForm)
                .then(async () => {
                        await this.updateGiftAwards()
                        closeRequest()
                        this.clearForm()
                        this.dialogVisibleNewPromo = false
                        this.sendMixPanelEvent(`gift_updated`)
                        this.$root.$emit("getGifts")
    
                })
                .catch((err) => {
                    console.log(err)
                    closeRequest()
                    destroyImageFromCloudinary(dataForm.secure_url)
                    this.dialogVisibleNewPromo = true
                    swalMessageError('Erro', 'Ocorreu um erro ao editar as informações, por favor, entre em contato com nosso suporte.')
            })
        },

        async getGiftData(id){
            loadRequest('Aguarde', `Buscando informações ${this.$props.promoType==="1"?'do prêmio da hora':'da promoção'}`)
            this.giftId = id
            this.activeName = 'promocao'
            Promise.all([this.getEditGifts(id), this.getEditGiftById(id)])
                .then((response) => {
                    try {
                        // INFORMAÇÕES GERAIS DO PREMIO
                        if(response[0].data.length > 0) {
                            let startDateTime = new Date(moment(response[0].data[0].start_date))
                            let endDateTime = new Date(moment(response[0].data[0].end_date))

                            this.gift.title = decodeURIComponent(response[0].data[0].name)
                            this.gift.startDate = startDateTime
                            this.gift.endDate = endDateTime
                            this.gift.imageUrl = response[0].data[0].image_url
                            this.imageUrl = response[0].data[0].image_url
                            this.gift.startTime = startDateTime
                            this.gift.endTime = endDateTime
                            this.gift.description = decodeURIComponent(response[0].data[0].description)
                            this.gift.question = decodeURIComponent(response[0].data[0].question),
                            this.gift.termsOfUse = decodeURIComponent(response[0].data[0].terms)
                        }
                        // PRÊMIOS DISPONÍVEIS
                        if(response[1].status === 200 && response[1].data.length > 0) {
                            for(let item of response[1].data) {
                                this.rowsGift.push({
                                        id: item.id,
                                        award: decodeURIComponent(item.title).replace(/"/g, '\'').replace(/#/g, '').trim(),
                                        imageSrc: item.imageUrl === "undefined" || item.imageUrl === "null" ? null : item.imageUrl
                                    }
                                )
                            }
                        }

                        closeRequest()
                        this.dialogVisibleNewPromo = true

                    } catch (error) {
                        closeRequest()
                        Swal(
                            'Erro',
                            'Não foi possível buscar as informações do prêmio. Por favor entre em contato com nosso suporte.',
                            'error'
                        )
                        console.log(error)
                    }

                })
                .catch((err) => {
                    closeRequest()
                    Swal(
                        'Erro',
                        'Não foi possível buscar as informações do prêmio. Por favor entre em contato com nosso suporte.',
                        'error'
                    )
                    console.log(err)
                })
        },
        updateFile(payload) {
            const index = payload.index
            const imageSrc = payload.awardImage
            const uploadFile = payload.uploadFile
            this.rowsGift[index].imageSrc = imageSrc
            this.rowsGift[index].uploadFile = uploadFile
        },

        updateAwardName(payload){
            const index = payload.index
            const awardName = payload.awardName
            this.rowsGift[index].award = awardName
        },
        checkIfStartDateIsTodayDate(){
            const now = new Date();
            const startDate = new Date(this.gift.startDate);
            return (
                startDate.getDate() === now.getDate() &&
                startDate.getMonth() === now.getMonth() &&
                startDate.getFullYear() === now.getFullYear()
            );
        },
        sendMixPanelEvent(eventString, params){
			mixpanelAdapter.track(eventString, {
				...params,
				screen_name: this.$route.name
			})
		}
    }, 
    mounted() {
        this.$parent.$on("openGiftForm", (payload) => {
            this.openModal(payload)
        })

        this.$root.$on("updateFile", (payload) => {
            this.updateFile(payload)
        })

        this.$root.$on("deleteGift", (index)=>{
            this.deleteGift(index)
        })

        this.$root.$on("updateAwardName", (payload) => {
            this.updateAwardName(payload)
        })

        selectRadio.$on('selectRadio', (payload) => {
            this.radio = payload
        })
    }
}

</script>

<style>
.swal2-container, .swal2-center, .swal2-fade, .swal2-shown {
    z-index: 999999 !important;
}

.main-gift-container {
    margin-top: 10px;
    min-height: 570px;
}
.main-validation-container {
    min-height: 930px;
}

.el-step__title {
    font-size: 16px;
    margin-top: 10px;
    line-height: 25px;
}

.el-steps {
    padding: 0 15px;
}

.divDescription {
    padding: 0;
}

.container-bottom-actions {
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px;
    height: 178px;
    margin-top: 15px;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.input-edit-gift {
    margin-bottom: 0px;
    margin-left: -2px;
    color: #000000;
    font-size: 15px;
}

.div-avatar {
    text-align: center;
}

.image-upload>input {
    display: none;
}

.border-row {
    border: 1px solid;
    padding: 6px;
    border-radius: 2px;
    border-color: #a9a9a936;
    cursor: pointer;
}

.content-tab {
    padding: 10px;
}

.div-premio {
    margin-top: -8px;
}

.div-btn-plus {
    margin-top: 8px;
}

.md-table-cell-selection {
    width: 66px !important;
}

.dialog-gifts {
    min-height: 60vh !important;
}

.div-remove {
    display: flex;
    align-items: baseline;
}

.btn-remove {
    margin-left: 30px;
}

.aviso-periodo {
    margin-top: 20px;
    background-color: gold;
    padding: 10px;
    border-radius: 10px;
}

.info-span {
    color: #000000;
    font-weight: 800;
    display: block;
    margin: 20px 0 ;
}

.table-custom {
    border-spacing: 0;
    margin-bottom: 50px;
}

.table-custom tbody tr td {
    height: 60px;
}

.table-custom thead {
    text-align: left;
}

.table-custom thead tr {
    height: 50px;
    color: #74bf72;
}

.tbl-participantes {
    overflow: auto;
    position: relative;
}

.tbl-participantes p {
    position: absolute;
    bottom: 0;
}

.center-datepicker {
    margin-top: -8px;
}

.btns-edit {
    height: 36px !important;
    min-width: 36px !important;
    width: 36px !important;
}

/* .container-btn-edit {
    display: flex;
} */

.row-btn-edit-gift {
    width: 15%;
}

p>button {
    margin-right: 10px;
    height: 25px;
    border-radius: 10px;
}

p>button:hover {
    cursor: pointer;
}

tbody .row_custom td {
    border-top: 1px solid;
    border-top-color: #e1e1e1;
}

.div-img {
    margin-top: 16px;
}

/* MEDIA QUERIES */
@media (max-width: 960px) {
    .div-img {
        margin-top: 16px;
        text-align: center;
    }

    .tbl-participantes {
        overflow: auto;
        position: unset;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        margin-top: 15px;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    button.md-button.md-warning.md-block.md-theme-default {
        min-width: 100%;
        min-height: 35px;
    }

    #pane-promocao {
        min-height: 1080px;
    }

    .row-btn-edit-gift {
        margin-right: 50%;
    }

    .btns-edit {
        height: 28px !important;
        min-width: 28px !important;
        width: 28px !important;
    }
}

@media (max-width: 320px) {
    .div-img {
        text-align: center;
    }
    
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 218px;
        height: 200px;
        margin-top: 15px;
    }
}

@media (max-width: 500px) {
    .main-validation-container {
        min-height: 1100px;
    }
}

@media (min-width: 321px) and (max-width: 360px) {
    .div-img {
        text-align: center;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 254px;
        height: 200px;
        margin-top: 15px;
    }
}

@media (min-width: 361px) and (max-width: 375px) {
    .div-img {
        text-align: center;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 254px;
        height: 200px;
        margin-top: 15px;
    }
}

@media (min-width: 376px) and (max-width: 414px) {
    .div-img {
        text-align: center;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 300px;
        height: 200px;
        margin-top: 15px;
    }
}

.row-btn-edit {
    width: 15%;
}

</style>