var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar",
      attrs: {
        "data-color": _vm.activeColor,
        "data-image": _vm.backgroundImage,
        "data-background-color": _vm.backgroundColor,
      },
    },
    [
      _c("div", { staticClass: "logo" }, [
        _vm._m(0),
        _c(
          "a",
          {
            staticClass: "simple-text logo-normal",
            attrs: { href: "javascript:void(0)", target: "_blank" },
          },
          [
            _vm.$route.meta.rtlActive
              ? [_vm._v(_vm._s(_vm.rtlTitle))]
              : [_vm._v(_vm._s(_vm.title))],
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "navbar-minimize" },
          [
            _c(
              "md-button",
              {
                staticClass: "md-round md-just-icon md-transparent",
                attrs: { id: "minimizeSidebar" },
                on: { click: _vm.minimizeSidebar },
              },
              [
                _c(
                  "i",
                  {
                    staticClass:
                      "material-icons text_align-center visible-on-sidebar-regular",
                    staticStyle: { "margin-left": "25px" },
                  },
                  [_vm._v("more_vert")]
                ),
                _c(
                  "i",
                  {
                    staticClass:
                      "material-icons design_bullet-list-67 visible-on-sidebar-mini",
                    staticStyle: { "margin-left": "25px" },
                  },
                  [_vm._v("view_list")]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { ref: "sidebarScrollArea", staticClass: "sidebar-wrapper" },
        [
          _vm._t("default"),
          _c(
            "md-list",
            { staticClass: "nav" },
            [
              _vm._t("links", function () {
                return _vm._l(_vm.sidebarLinks, function (link, index) {
                  return _c(
                    "sidebar-item",
                    { key: link.name + index, attrs: { link: link } },
                    _vm._l(link.children, function (subLink, index) {
                      return _c("sidebar-item", {
                        key: subLink.name + index,
                        attrs: { link: subLink },
                      })
                    }),
                    1
                  )
                })
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "md-layout md-alignment-center-center",
              staticStyle: { color: "#ffffff" },
            },
            [
              _c("p", { staticClass: "md-layout-item md-size-100 version" }, [
                _vm._v(" " + _vm._s(_vm.getVersion) + "v "),
              ]),
            ]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { staticClass: "simple-text logo-mini", attrs: { href: "#" } },
      [
        _c("div", { staticClass: "logo-img" }, [
          _c("img", { attrs: { src: require("@/assets/mobradio.png") } }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }