var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: `Formulário de ${
              _vm.promoType === "1" ? "Prêmio da hora" : "Promoção"
            }`,
            visible: _vm.dialogVisibleNewPromo,
            "before-close": _vm.closeModal,
            "close-on-click-modal": false,
            center: true,
            "lock-scroll": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibleNewPromo = $event
            },
          },
        },
        [
          _c(
            "el-steps",
            { attrs: { active: _vm.activeStep, "finish-status": "success" } },
            [
              _c("el-step", { attrs: { title: "Geral" } }),
              _c("el-step", { attrs: { title: "Período" } }),
              _c("el-step", { attrs: { title: "Regulamento" } }),
              _c("el-step", { attrs: { title: "Prêmios" } }),
              _c("el-step", { attrs: { title: "Validação" } }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep === 0,
                  expression: "activeStep === 0",
                },
              ],
              staticClass: "main-gift-container",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                },
                [
                  _c("div", { staticClass: "md-layout divDescription" }, [
                    _c("span", { staticClass: "info-span" }, [
                      _vm._v(
                        " Olá, aqui você irá preencher os campos que descreverão " +
                          _vm._s(
                            _vm.promoType === "1"
                              ? "o prêmio da hora"
                              : "a promoção"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: `Nome ${
                        _vm.promoType === "1"
                          ? "do prêmio da hora"
                          : "da promoção"
                      }`,
                      disabled: _vm.disabledInputs,
                    },
                    model: {
                      value: _vm.gift.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.gift, "title", $$v)
                      },
                      expression: "gift.title",
                    },
                  }),
                ],
                1
              ),
              _vm.promoType === "2"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "margin-top": "15px" },
                        attrs: {
                          disabled: _vm.disabledInputs,
                          placeholder: "Pergunta do concurso cultural",
                        },
                        model: {
                          value: _vm.gift.question,
                          callback: function ($$v) {
                            _vm.$set(_vm.gift, "question", $$v)
                          },
                          expression: "gift.question",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: "5",
                      placeholder: `Descrição ${
                        _vm.promoType === "1"
                          ? "do prêmio da hora"
                          : "da promoção"
                      }`,
                      disabled: _vm.disabledInputs,
                    },
                    model: {
                      value: _vm.gift.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.gift, "description", $$v)
                      },
                      expression: "gift.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-70 md-large-size-60 md-xlarge-size-60 div-img",
                },
                [
                  _c("span", { staticStyle: { color: "#c8cbd2" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.promoType === "1"
                          ? "Imagem do prêmio da hora"
                          : "Imagem da promoção"
                      )
                    ),
                  ]),
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader avatar-gifts",
                      attrs: {
                        action: "#",
                        accept: "image/jpeg,image/gif,image/png",
                        "auto-upload": false,
                        "show-file-list": false,
                        "on-change": _vm.validateImageSizeAndResolution,
                        disabled: _vm.disabledInputs,
                      },
                    },
                    [
                      _vm.imageUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imageUrl },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                  _vm.msgComponentImage !== ""
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(_vm._s(_vm.msgComponentImage)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-size-90 md-small-size-95 md-xlarge-size-95 container-bottom-actions",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "12px" },
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.closeModal()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.disabledInputs ? "Cancelar" : "Fechar"))]
                  ),
                  _c(
                    "el-button",
                    {
                      ref: "next-button",
                      staticStyle: { "margin-top": "12px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.validateInputAndGoToNextStep()
                        },
                      },
                    },
                    [_vm._v("Próximo")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "md-layout" }, [
                _vm.errorFields.length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                      },
                      [
                        _c("p", [
                          _c("b", [
                            _vm._v(
                              "Os campos abaixo são obrigatórios e não foram preenchidos:"
                            ),
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errorFields, function (error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error)),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep === 1,
                  expression: "activeStep === 1",
                },
              ],
              staticClass: "main-gift-container",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                },
                [
                  _c("div", { staticClass: "md-layout divDescription" }, [
                    _c("span", { staticClass: "info-span" }, [
                      _vm._v(
                        " Aqui você irá definir o período em que " +
                          _vm._s(
                            _vm.promoType === "1"
                              ? "o Prêmio da hora"
                              : "a Promoção"
                          ) +
                          " ficará ativ" +
                          _vm._s(_vm.promoType === "1" ? "o" : "a") +
                          " no aplicativo "
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-xsmall-size-100",
                    class: _vm.promoType === "1" ? "md-size-100" : "md-size-50",
                  },
                  [
                    _c("el-date-picker", {
                      staticStyle: {
                        width: "100% !important",
                        "margin-bottom": "15px",
                      },
                      attrs: {
                        type: "date",
                        placeholder: "Data de início",
                        format: "dd/MM/yyyy",
                        id: "input_new_date_start",
                        disabled: _vm.disabledInputs,
                        "picker-options": {
                          start: "00:00",
                          step: "01:00",
                          end: "23:00",
                        },
                      },
                      model: {
                        value: _vm.gift.startDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.gift, "startDate", $$v)
                        },
                        expression: "gift.startDate",
                      },
                    }),
                  ],
                  1
                ),
                _vm.promoType === "2"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-size-50 md-xsmall-size-100",
                        class:
                          _vm.promoType === "1" ? "md-size-100" : "md-size-50",
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: {
                            width: "100% !important",
                            "margin-bottom": "15px",
                          },
                          attrs: {
                            type: "date",
                            placeholder: "Data de término",
                            format: "dd/MM/yyyy",
                            id: "input_new_date_start",
                            disabled: _vm.disabledInputs,
                            "picker-options": {
                              start: "00:00",
                              step: "01:00",
                              end: "23:00",
                            },
                          },
                          model: {
                            value: _vm.gift.endDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.gift, "endDate", $$v)
                            },
                            expression: "gift.endDate",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-xsmall-size-100",
                  },
                  [
                    _c("el-time-picker", {
                      staticStyle: {
                        width: "100% !important",
                        "margin-bottom": "15px !important",
                      },
                      attrs: {
                        "picker-options": {
                          selectableRange: "00:00:00 - 23:59:00",
                        },
                        placeholder: "Hora de início",
                        format: "HH:mm",
                        disabled: _vm.disabledInputs,
                      },
                      model: {
                        value: _vm.gift.startTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.gift, "startTime", $$v)
                        },
                        expression: "gift.startTime",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-xsmall-size-100",
                  },
                  [
                    _c("el-time-picker", {
                      staticStyle: {
                        width: "100% !important",
                        "margin-bottom": "15px !important",
                      },
                      attrs: {
                        "picker-options": {
                          selectableRange: "00:00:00 - 23:59:00",
                        },
                        placeholder: "Hora de término",
                        format: "HH:mm",
                        disabled: _vm.disabledInputs,
                      },
                      model: {
                        value: _vm.gift.endTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.gift, "endTime", $$v)
                        },
                        expression: "gift.endTime",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-size-90 md-small-size-95 md-xlarge-size-95 container-bottom-actions",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "12px" },
                      attrs: { type: "default" },
                      on: {
                        click: function ($event) {
                          return _vm.backToPreviousStep()
                        },
                      },
                    },
                    [_vm._v("Voltar")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "12px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.validateInputAndGoToNextStep()
                        },
                      },
                    },
                    [_vm._v("Próximo")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "md-layout" }, [
                _vm.errorFields.length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                      },
                      [
                        _c("p", [
                          _c("b", [
                            _vm._v(
                              "Houveram alguns erros ao preencher os dados:"
                            ),
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errorFields, function (error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error)),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep === 2,
                  expression: "activeStep === 2",
                },
              ],
              staticClass: "main-gift-container",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                },
                [
                  _c("div", { staticClass: "md-layout divDescription" }, [
                    _c("span", { staticClass: "info-span" }, [
                      _vm._v(
                        " Nessa etapa é importante fazer o uso de um bom regulamento, com um texto revisado, pois é uma medida que traz segurança para a rádio e para o ouvinte. "
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        id: "input_terms",
                        type: "textarea",
                        rows: 15,
                        placeholder: "Descreva o regulamento da promoção",
                        disabled: _vm.disabledInputs,
                      },
                      model: {
                        value: _vm.gift.termsOfUse,
                        callback: function ($$v) {
                          _vm.$set(_vm.gift, "termsOfUse", $$v)
                        },
                        expression: "gift.termsOfUse",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-x-small-70 md-size-90 md-small-size-95 md-xlarge-size-95 container-bottom-actions",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "12px" },
                      attrs: { type: "default" },
                      on: {
                        click: function ($event) {
                          return _vm.backToPreviousStep()
                        },
                      },
                    },
                    [_vm._v("Voltar")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "12px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.validateInputAndGoToNextStep()
                        },
                      },
                    },
                    [_vm._v("Próximo")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "md-layout" }, [
                _vm.errorFields.length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                      },
                      [
                        _c("p", [
                          _c("b", [
                            _vm._v(
                              "Os campos abaixo são obrigatórios e não foram preenchidos:"
                            ),
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errorFields, function (error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error)),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep === 3,
                  expression: "activeStep === 3",
                },
              ],
              staticClass: "main-gift-container",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                },
                [
                  _c("div", { staticClass: "md-layout divDescription" }, [
                    _c("span", { staticClass: "info-span" }, [
                      _vm._v(
                        " Nessa etapa você irá incluir os prêmios da promoção. "
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-medium-size-80 md-large-size-60 md-xlarge-size-60",
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        disabled: _vm.disabledInputs,
                        autosize: "",
                        type: "textarea",
                        placeholder: "Nome do Prêmio",
                      },
                      model: {
                        value: _vm.award,
                        callback: function ($$v) {
                          _vm.award = $$v
                        },
                        expression: "award",
                      },
                    }),
                    _c(
                      "span",
                      {
                        style:
                          _vm.award.length > 150
                            ? "color: #FF0606"
                            : "color: #707070",
                      },
                      [
                        _vm._v(" (" + _vm._s(_vm.award.length) + "/150) "),
                        _vm.award.length > 150
                          ? _c("span", [
                              _vm._v(
                                "O nome do prêmio passa de 150 caracteres"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20",
                    staticStyle: { "margin-top": "2px" },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-top": "12px" },
                        attrs: {
                          type: "primary",
                          disabled:
                            _vm.award.length > 150 || _vm.disabledInputs,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.addGift()
                          },
                        },
                      },
                      [_vm._v("+")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "draggable",
                _vm._b(
                  {
                    staticClass:
                      "md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                    staticStyle: { "padding-top": "20px" },
                    model: {
                      value: _vm.rowsGift,
                      callback: function ($$v) {
                        _vm.rowsGift = $$v
                      },
                      expression: "rowsGift",
                    },
                  },
                  "draggable",
                  _vm.dragOptions,
                  false
                ),
                [
                  _c(
                    "transition-group",
                    {
                      attrs: { type: "transition", name: "flip-list" },
                      on: {
                        start: function ($event) {
                          _vm.isDragging = true
                        },
                        end: function ($event) {
                          _vm.isDragging = false
                        },
                      },
                    },
                    _vm._l(_vm.rowsGift, function (element, index) {
                      return _c("card-award", {
                        key: element.award,
                        attrs: {
                          awardNameDefault: element.award,
                          awardImageDefault: element.imageSrc,
                          index: index,
                          viewOnly: _vm.disabledInputs,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-size-90 md-small-size-95 md-xlarge-size-95 container-bottom-actions",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "12px" },
                      attrs: { type: "default" },
                      on: {
                        click: function ($event) {
                          return _vm.backToPreviousStep()
                        },
                      },
                    },
                    [_vm._v("Voltar")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "12px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.validateInputAndGoToNextStep()
                        },
                      },
                    },
                    [_vm._v("Próximo")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "md-layout" }, [
                _vm.errorFields.length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                      },
                      [
                        _c("p", [
                          _c("b", [
                            _vm._v(
                              "Os campos abaixo são obrigatórios e não foram preenchidos:"
                            ),
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errorFields, function (error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error)),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep === 4,
                  expression: "activeStep === 4",
                },
              ],
              staticClass: "main-validation-container",
              staticStyle: { "margin-top": "20px" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                },
                [
                  _c("div", { staticClass: "md-layout divDescription" }, [
                    _c("span", { staticClass: "info-span" }, [
                      _vm._v(
                        " Pronto, agora que você já inseriu todas as informações da promoção, é hora de revisar e garantir que tudo esteja correto. "
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "el-tabs",
                {
                  staticStyle: { padding: "0 15px" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeTableValidation,
                    callback: function ($$v) {
                      _vm.activeTableValidation = $$v
                    },
                    expression: "activeTableValidation",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Informações Gerais", name: "first" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          staticStyle: { "margin-bottom": "15px" },
                        },
                        [
                          _c("span", { staticStyle: { color: "#c8cbd2" } }, [
                            _vm._v(
                              "Nome " +
                                _vm._s(
                                  _vm.promoType === "1"
                                    ? "do prêmio da hora"
                                    : "da promoção"
                                )
                            ),
                          ]),
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.gift.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.gift, "title", $$v)
                              },
                              expression: "gift.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.promoType === "2"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                              staticStyle: { "margin-bottom": "15px" },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { color: "#c8cbd2" } },
                                [_vm._v("Pergunta do concurso cultural")]
                              ),
                              _c("el-input", {
                                attrs: {
                                  "aria-disabled": "",
                                  disabled: "",
                                  placeholder: "Pergunta do concurso cultural",
                                },
                                model: {
                                  value: _vm.gift.question,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.gift, "question", $$v)
                                  },
                                  expression: "gift.question",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          staticStyle: { "margin-bottom": "15px" },
                        },
                        [
                          _c("span", { staticStyle: { color: "#c8cbd2" } }, [
                            _vm._v("Descrição da promoção"),
                          ]),
                          _c("el-input", {
                            staticStyle: { "margin-top": "-15px" },
                            attrs: {
                              type: "textarea",
                              rows: "5",
                              placeholder: "Descrição da promoção",
                              disabled: "",
                            },
                            model: {
                              value: _vm.gift.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.gift, "description", $$v)
                              },
                              expression: "gift.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "md-layout" }, [
                        _c(
                          "span",
                          {
                            staticClass: "md-layout-item md-size-100",
                            staticStyle: { color: "#c8cbd2" },
                          },
                          [_vm._v("Período da promoção")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-medium-size-50",
                            class:
                              _vm.promoType === "2"
                                ? "md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50"
                                : "md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [
                            _c("el-date-picker", {
                              staticStyle: {
                                width: "100% !important",
                                margin: "7px 0",
                              },
                              attrs: {
                                disabled: "",
                                type: "date",
                                placeholder: "Data inicial",
                                format: "dd/MM/yyyy",
                                id: "input_new_date_start",
                              },
                              model: {
                                value: _vm.gift.startDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.gift, "startDate", $$v)
                                },
                                expression: "gift.startDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.promoType === "2"
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: {
                                    width: "100% !important",
                                    margin: "7px 0",
                                  },
                                  attrs: {
                                    disabled: "",
                                    type: "date",
                                    placeholder: "Data inicial",
                                    format: "dd/MM/yyyy",
                                    id: "input_new_date_start",
                                  },
                                  model: {
                                    value: _vm.gift.endDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.gift, "endDate", $$v)
                                    },
                                    expression: "gift.endDate",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "md-layout" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                          },
                          [
                            _c("el-time-picker", {
                              staticStyle: {
                                width: "100% !important",
                                margin: "7px 0",
                              },
                              attrs: {
                                disabled: "",
                                "value-format": "HH:mm",
                                format: "HH:mm",
                                placeholder: "Horário inicial",
                              },
                              model: {
                                value: _vm.gift.startTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.gift, "startTime", $$v)
                                },
                                expression: "gift.startTime",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                          },
                          [
                            _c("el-time-picker", {
                              staticStyle: {
                                width: "100% !important",
                                margin: "7px 0",
                              },
                              attrs: {
                                disabled: "",
                                "value-format": "HH:mm",
                                format: "HH:mm",
                                placeholder: "Horário final",
                              },
                              model: {
                                value: _vm.gift.endTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.gift, "endTime", $$v)
                                },
                                expression: "gift.endTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          staticStyle: { "margin-top": "10px" },
                        },
                        [
                          _c("span", { staticStyle: { color: "#c8cbd2" } }, [
                            _vm._v(
                              "Imagem " +
                                _vm._s(
                                  _vm.promoType === "1"
                                    ? "do prêmio da hora"
                                    : "da promoção"
                                )
                            ),
                          ]),
                          _vm.imageUrl
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.imageUrl },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Prêmios", name: "second" } },
                    [
                      _c("md-table", {
                        staticClass:
                          "paginated-table table-striped table-hover",
                        staticStyle: { padding: "15px" },
                        attrs: { value: _vm.rowsGift },
                        scopedSlots: _vm._u([
                          {
                            key: "md-table-row",
                            fn: function ({ item }) {
                              return _c(
                                "md-table-row",
                                {},
                                [
                                  item.imageSrc
                                    ? _c(
                                        "md-table-cell",
                                        { attrs: { "md-label": "Imagem" } },
                                        [
                                          _c("img", {
                                            attrs: {
                                              width: "64px;",
                                              src: item.imageSrc,
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  !item.imageSrc
                                    ? _c(
                                        "md-table-cell",
                                        { attrs: { "md-label": "Imagem" } },
                                        [
                                          _c("img", {
                                            attrs: {
                                              width: "64px;",
                                              src: "https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png",
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "md-table-cell",
                                    { attrs: { "md-label": "Nome do Prêmio" } },
                                    [_vm._v(_vm._s(item.award))]
                                  ),
                                ],
                                1
                              )
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Regulamento", name: "third" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              id: "input_terms",
                              type: "textarea",
                              rows: 15,
                              placeholder: "Descreva o regulamento da promoção",
                            },
                            model: {
                              value: _vm.gift.termsOfUse,
                              callback: function ($$v) {
                                _vm.$set(_vm.gift, "termsOfUse", $$v)
                              },
                              expression: "gift.termsOfUse",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.gift.startDate &&
                        _vm.gift.startTime &&
                        _vm.gift.endTime,
                      expression:
                        "gift.startDate && gift.startTime && gift.endTime",
                    },
                  ],
                  staticClass:
                    "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                  staticStyle: { "margin-top": "20px" },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "info-span",
                      staticStyle: { color: "red", "font-size": "14px" },
                    },
                    [
                      _vm._v(
                        " Atenção: É importante verificar e ter certeza do período que está definindo. Uma vez que a promoção iniciar não será possível realizar nenhuma alteração! "
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-size-90 md-small-size-95 md-xlarge-size-95 container-bottom-actions",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "12px" },
                      attrs: { type: "default" },
                      on: {
                        click: function ($event) {
                          return _vm.backToPreviousStep()
                        },
                      },
                    },
                    [_vm._v("Voltar")]
                  ),
                  !_vm.disabledInputs
                    ? [
                        !_vm.editionMode
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "12px" },
                                attrs: { type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.createNewGift()
                                  },
                                },
                              },
                              [_vm._v("Finalizar")]
                            )
                          : _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "12px" },
                                attrs: { type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.updateGift()
                                  },
                                },
                              },
                              [_vm._v("Finalizar")]
                            ),
                      ]
                    : _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "12px" },
                          attrs: { type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.closeModal()
                            },
                          },
                        },
                        [_vm._v("Fechar")]
                      ),
                ],
                2
              ),
              _c("div", { staticClass: "md-layout" }, [
                _vm.errorFields.length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                      },
                      [
                        _c("p", [
                          _c("b", [
                            _vm._v(
                              "Os campos abaixo são obrigatórios e não foram preenchidos:"
                            ),
                          ]),
                          _c(
                            "ul",
                            _vm._l(_vm.errorFields, function (error) {
                              return _c("li", { key: error }, [
                                _vm._v(_vm._s(error)),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }