<template>
    <div>
        <div
            style="display: flex; align-items: center; justify-content: space-around"
        >
            <div>
                <h1>Suas coordenadas:</h1>
                <p>
                    {{ myCoordinates.lat }}, Latitude
                    {{ myCoordinates.lng }} Longitude
                </p>
            </div>
            <div>
                <h1>Mapa coordenadas:</h1>
                <p>
                    {{ mapCoordinates.lat }}, Latitude
                    {{ mapCoordinates.lng }} Longitude
                </p>
            </div>
        </div>
		<div style="display: flex; align-items: center; justify-content: space-around">
			<div id="map"></div>
		</div>
    </div>
</template>

<script>
let map;
const initMap = () => {
	map = new google.maps.Map(document.getElementById("map"), {
		center: { lat: -34.397, lng: 150.644 },
		zoom: 8
	});

	const drawingManager = new google.maps.drawing.DrawingManager({
		drawingMode: google.maps.drawing.OverlayType.MARKER,
		drawingControl: true,
		drawingControlOptions: {
			position: google.maps.ControlPosition.TOP_CENTER,
			drawingModes: [
				google.maps.drawing.OverlayType.CIRCLE,
			]
		},
		circleOptions: {
			fillColor: "#ffff00",
			fillOpacity: 5,
			strokeWeight: 5,
			clickable: false,
			editable: true,
			zIndex: 1
		}
	});
	drawingManager.setMap(map);
};
export default {
	data() {
		return {
			myCoordinates: {
				lat: 0,
				lng: 0
			}
		}
	},

	created() {
		navigator.geolocation.getCurrentPosition((data) => {
			this.myCoordinates = {
				lat: data.coords.latitude.toFixed(4),
				lng: data.coords.longitude.toFixed(4)
			}

			map.setCenter({ lat: data.coords.latitude, lng: data.coords.longitude })
		})
	},

	mounted() {
		initMap()
	},

	methods: {
	},

	computed: {
		mapCoordinates() {
			if (!map) {
				return {
					lat: 0,
					lng: 0
				};
			}
			return {
				lat: map
					.getCenter()
					.lat()
					.toFixed(4),
				lng: map
					.getCenter()
					.lng()
					.toFixed(4)
			};
		}
	}
};
</script>

<style>
	#map {
		height: 600px;
	}
</style>
