<template>
    <div class="md-layout">
        <div v-show="loading" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>

        <div v-show="!loading && blockStatus == 1" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <i class="material-icons">bookmarks</i>
                    </div>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout">
                        <div class="md-layout-item md-small-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20">
                            <md-field>
                                <label for="movie">Assunto</label>
                                <md-select v-model="subject">
                                    <md-option value="5">Família</md-option>
                                    <md-option value="2">Finanças</md-option>
                                    <md-option value="7">Outros</md-option>
                                    <md-option value="4">Relacionamento</md-option>
                                    <md-option value="1">Saúde</md-option>
                                    <md-option value="3">Trabalho/Emprego</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20">
                            <md-field>
                                <label for="movie">Status</label>
                                <md-select v-model="status">
                                    <md-option value="1">Respondido</md-option>
                                    <md-option value="0">Não respondido</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20">
                            <md-button class="md-success md-block" @click="()=>{
                                    sendMixPanelEvent(`prayer_requests_searched`, {
                                        prayer_subject: getPrayerSubject(subject),
                                        prayer_status: status==='1'? 'Respondido': 'Não respondido'
                                    })
                                    searchPrayer()
                                }" :disabled="disabledSearch"><i class="fas fa-search"></i>&nbsp; Buscar</md-button>
                        </div>
                        <div class="md-layout-item md-small-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20">
                            <md-button class="md-warning md-block" @click="dialogVisible = true; message = ''" :disabled="status == 1 || prayerData.length == 0"><i class="fas fa-paper-plane"></i>&nbsp; Responder pedidos</md-button>
                        </div>
                    </div>
                </md-card-content>
            </md-card>

            <md-card v-if="prayerData.length > 0">
                <md-card-header class="md-card-header-icon md-card-header-blue">
                    <div class="card-icon">
                        <md-icon>assignment</md-icon>
                    </div>
                </md-card-header>
                <md-card-content>
                    <md-table :value="queriedData" class="paginated-table table-striped table-hover">
                        <md-table-toolbar>
                            <md-field>
                                <label for="pages">Por página</label>
                                <md-select v-model="pagination.perPage" name="pages">
                                    <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                                        {{ item }}
                                    </md-option>
                                </md-select>
                            </md-field>
                        </md-table-toolbar>

                        <md-table-row slot="md-table-row" slot-scope="{ item }">
                            <md-table-cell md-label="Nome"> {{ item.listener_name }}</md-table-cell>
                            <md-table-cell md-label="Pedido">{{ item.prayer }}</md-table-cell>
                            <md-table-cell md-label="Data">{{ new Date(item.created).toLocaleString("pt-BR") }}</md-table-cell>
                        </md-table-row>
                    </md-table>
                </md-card-content>
                <md-card-actions md-alignment="space-between">
                    <div class="">
                        <p class="card-category">{{from + pageStart}} à {{to}} de {{total}} itens</p>
                    </div>
                    <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total">
                    </pagination>
                </md-card-actions>
            </md-card>

            <md-card style="z-index:0;text-align:center;" v-show="showError">
                <md-card-content>
                    <label for="">Sem informações pra serem exibidas.</label>
                </md-card-content>
            </md-card>
        </div>

        <el-dialog
            title="Responder pedidos"
            :visible.sync="dialogVisible">
            <div class="md-layout-item md-size-100 md-small-size-100">
                <el-input 
                    type="textarea"
                    :rows="3"
                    placeholder="Mensagem"
                    maxlength="300"
                    v-model="message">
                </el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <div class="md-layout">
                    <div class="md-layout-item md-size-50 md-small-size-100">
                        <md-button class="md-default md-block" @click="dialogVisible = false">Cancelar</md-button>
                    </div>
                    <div class="md-layout-item md-size-50 md-small-size-100">
                        <md-button class="md-success md-block" @click="dialogVisible = false; registerPushNotification()" :disabled="valideForm"><i class="fas fa-check"></i> &nbsp; Enviar</md-button>
                    </div>
                </div>
            </span>
        </el-dialog>

        <block v-show="blockStatus == 0"></block>
    </div>

</template>

<script>
    import Vue from 'vue'
    import Swal from 'sweetalert2'
    import axios from 'axios'
    import models from '../../routes/models.js'
    import ServiceBlock from '../../routes/service-block.js'
    import services from '../../routes/services'
    import block from './Block.vue'
    import {
        Pagination
    } from '@/components'
    const numeral = require('numeral')
    const locale = require('numeral/locales')
    import jwt_decode from "jwt-decode"
    import mixpanelAdapter from '../../utils/events/mixpanelAdapter'

    numeral.locale('pt-br')
    Vue.filter("formatNumber", function (value) {
        return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
    })

    import {
        Dialog,
        Button,
        Input,
        Tabs,
        TabPane,
        Upload,
        TimeSelect
    } from 'element-ui'
    import selectRadio from '../../utils/events/select-radio.js'
    export default {
        components: {
            [Dialog.name]: Dialog,
            [Button.name]: Button,
            [Input.name]: Input,
            [Tabs.name]: Tabs,
            [TabPane.name]: TabPane,
            [Upload.name]: Upload,
            [TimeSelect.name]: TimeSelect,
            Pagination,
            block
        },
        computed: {
            queriedData() {
                let result = this.prayerData;
                if (this.searchedData.length > 0) {
                    result = this.searchedData;
                }
                return result.slice(this.from, this.to)
            },
            to() {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from() {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total() {
                return this.searchedData.length > 0 ? this.searchedData.length : this.prayerData.length;
            },
            validateForm () {
                return [this.message].join()
            }
        },
        data() {
            return {
                appKey: jwt_decode(localStorage.getItem('APP_01')).radio.key,
                serverKey: jwt_decode(localStorage.getItem('APP_01')).radio.server_key,
                loading: false,
                disabledSearch: true,
                disabledSend: true,
                subject: null,
                status: null,
                showError: false,
                prayerData: [],
                searchedData: '',
                pagination: {
                    perPage: 50,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                blockStatus: 1,
                pageStart: 0,
                deviceTokens: [],
                ids: null,
                idPrayers: [],
                message: '',
                dialogVisible: false,
                valideForm: true,
                title: "Pedido de Oração"
            }
        },
        methods: {
            searchPrayer() {
                this.loading = true
                this.prayerData = []
                this.idPrayers = []
                this.deviceTokens = []
                this.pagination.currentPage = 1
                models().get(`prayer/getPrayerRequested?appKey=${this.appKey}&subject=${parseInt(this.subject)}&status=${parseInt(this.status)}`)
                .then((resp) => {
                    this.loading = false
                    if(resp.data.length > 0) {
                        this.showError = false
                        this.disabledSend = false
                        this.prayerData = resp.data
                        
                        this.prayerData.forEach(item => {
                            this.deviceTokens.push(item.device_token)
                            this.idPrayers.push(item.id)
                        }); 
                    } else {
                        this.disabledSend = true
                        this.showError = true
                    }
                })
                .catch((e) => {
                    Swal(
                        'Erro',
                        'Não foi possível obter as informações, por favor, tente novamente.'
                    )
                    console.log(e)
                })
            },
            blockPage() {
                const menu = jwt_decode(localStorage.getItem('APP_02')).services
                let service = null
                for(let obj of menu) {
                    if(obj.subitens === null) {
                        const key = Object.keys(obj.item)[Object.values(obj.item).indexOf(this.$route.path.replace("/", ""))]
                        if(key) {
                            service = obj.item
                        }
                    } else {
                        const key = Object.keys(obj.subitens)[Object.values(obj.subitens).indexOf(this.$route.path.replace("/", ""))]
                        if(key) {
                            service = obj.subitens
                        }
                    }
                }
                
                if(service) {
                    this.blockStatus = service.has_permission
                } else {
                    this.$router.push("dashboard")
                }
            },
            findObjectByKey(array, key, value) {
                for (var i = 0; i < array.length; i++) {
                    if (array[i][key] === value) {
                        return array[i];
                    }
                }
                return null;
            },
            registerPushNotification(){
                const typeId = 3
                const sender = jwt_decode(localStorage.getItem('SET_APP')).user[0].email
                let date = new Date()
                let time = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds()
                date = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate() + ' ' + time
                let dataForm = {
                    pushNotificationTypeId: typeId,
                    appKey: this.appKey,
                    title: this.title,
                    message: this.message,
                    date: date,
                    sender: sender,
                    status: 'enviando'
                }
                Swal.fire({
                    title: "Enviando alertas...",
                    onOpen: () => {
                        Swal.showLoading()
                            models().post('notification/Register', dataForm)
                            .then((resp) => {
                                if (resp.data[0] && resp.data[0].lastInsertedId) {
                                    this.ids = resp.data[0].lastInsertedId
                                    this.getCountListener(resp.data[0].service_account)
                                } else {
                                    Swal.close();
                                }
                            })
                            .catch((e) => {
                                Swal(
                                    'Falha',
                                    'Erro ao cadastrar notificação. Por favor, tente novamente.',
                                    'error'
                                )
                                console.log(e)
                            })
                    }
                })
            },
            async getCountListener(serviceAccount) {
                const serverKey = serviceAccount ? serviceAccount: this.serverKey;

                this.sendMessages(serverKey, this.deviceTokens, this.title, this.message, this.idPrayers).then(()=>{
                    Swal(
                        'Pronto',
                        'As mensagens foram enviados com sucesso!',
                        'success'
                    )
                    this.status = 1
                    this.sendMixPanelEvent(`prayer_requests_answered`, {
                        prayer_subject: this.getPrayerSubject(this.subject)
                    })
                    this.searchPrayer()
                })
                .catch((e) => {
                    Swal(
                        'Erro',
                        'Tivemos uma falha ao enviar os alertas, por favor, tente novamente.',
                        'error'
                    )
                    console.log(e)
                })
            },
             sendMessages(serverKey, tokens, title, message, idsPrayer) {
                return services().post('prayer/sendResponse', {
                    serverKey: serverKey,
                    tokens: tokens,
                    title: title,
                    message: message,
                    ids: this.idPrayers
                })
                .then((resp) => {
                    let promiseUpdateTokens = this.updateAlert(this.deviceTokens.length, this.ids)
                    let promiseUpdateStatus = this.updateStatusPrayer(idsPrayer)
                    Promise.all([promiseUpdateTokens, promiseUpdateStatus]).then(()=>{
                        return Promise.resolve()
                    })
                    .catch(()=>{
                        return Promise.reject()
                    })
                })
                .catch((e) => {
                    console.log(`sendMessages error: ${JSON.stringify(e)}`)
                    return Promise.reject()
                })
            },
            async updateAlert(count, ids){
                let dataForm = {
                    count: count,
                    ids: ids
                }
                await
                models().post('notification/StatusSent', dataForm)
                .then((resp) => {
                })
                .catch((e) => {
                    console.log(e)
                })
            },
            async updateStatusPrayer(idsPrayer) {
                let dataForm = {
                    ids: idsPrayer
                }
                await
                models().put("prayer/updateStatusPrayer", dataForm)
                .then(() => {
                
                })
                .catch((e) => {
                    Swal(
                        'Erro',
                        'Falha ao atualizar o status do pedido de oração',
                        'error'
                    )
                    console.log(e)
                })
            },
            getPrayerSubject(subject){
                switch(subject){
                    case "1":
                        return "Saúde"
                    case "2":
                        return "Finanças"
                    case "3":
                        return "Trabalho/Emprego"
                    case "4":
                        return "Relacionamento"
                    case "5":
                        return "Família"
                    case "7":
                        return "Outros"
                }
            },
            sendMixPanelEvent(eventString, params){
                mixpanelAdapter.track(eventString, {
                    ...params,
                    screen_name: this.$route.name
                })
            }
        },
        mounted() {
            let self = this
            if (jwt_decode(localStorage.getItem('APP_01'))) {
                self.appKey = jwt_decode(localStorage.getItem('APP_01')).radio.key
                self.serverKey = jwt_decode(localStorage.getItem('APP_01')).radio.server_key
                self.blockPage()
            }
            selectRadio.$on('selectRadio', (payload) => {
                self.appKey = payload.key
                self.serverKey = payload.server_key                
                self.prayerData = []
                self.msgError = false
                self.blockPage()
            })
        },
        watch: {
            subject() {
                if(this.subject !== null && this.status !== null) {
                    this.disabledSearch = false
                }
            },
            status() {
                if(this.subject !== null && this.status !== null) {
                    this.disabledSearch = false
                }
            },
            validateForm() {
                if(this.message != '') {
                    this.valideForm = false
                } else {
                    this.valideForm = true
                }
            }
        }
    }

</script>
<style>
</style>
