<template functional>
    <header class="main-header">
		<img
			class="mobradio-logo"
			src="../assets/logos/mobradio-white.png"
		/>
		<h1 class="radio-name">{{ props.RadioName }}</h1>
		<img class="radio-logo" :src='props.RadioLogo'/>
	</header>
</template>

<script>
    export default {
        name: "HeaderReport",
        props: ['RadioName', 'RadioLogo']
    }
</script>

<style>
@import url("../assets/background/background-header.css");

.main-header {
	background-image: var(--background-header);
	background-size: cover;
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 10rem;
	padding: 0 2rem 3rem 2rem;
	background-repeat: no-repeat;
}
.main-header .mobradio-logo {
	width: 172px;
	height: 26px;
}
.main-header .radio-name {
	font-weight: normal;
	color: #fff !important;
	font-size: 27px;
	line-height: 100%;
	width: 80%;
	display: inline-block;
	text-align: right;
	white-space: nowrap;
}
.main-header .radio-logo {
	height: 69px;
	width: 69px;
	margin-left: 20px;
	border-radius: 50%;
}
</style>