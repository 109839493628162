<template>
	<div class="md-layout">
		<div
			v-show="blockStatus == 1"
			class="md-layout-item md-small-size-100 principal"
		>
			<FilterSelector
				@searchData="setNewDate()"
				:buttonsToDisplay="[
					buttonEnum.TODAY,
					buttonEnum.YESTERDAY_PLUS_TODAY,
					buttonEnum.SEVEN_DAYS,
					buttonEnum.LAST_30_DAYS,
					buttonEnum.LAST_MONTH,
					buttonEnum.CUSTOM,
				]"
				@extraFunction="redirectToReport()"
				:extraFunction="true"
				:extraFunctionLabel="'VER RELATÓRIO'"
				:disableExtraFunction="tableData.length === 0"
			>
				<template #filters>
					<div class="md-layout">
						<div
							class="md-layout-item md-large-size-20 md-xlarge-size-20"
							style="padding: 0"
						>
							<h3
								class="md-layout-item md-size-100"
								style="
									font-size: 1rem;
									text-align: left;
									margin: 25px 0 10px 0;
									padding: 0;
									font-weight: 100;
								"
							>
								Artista (opcional)
							</h3>
							<el-input v-model="artist" placeholder="Digite o nome do artista">
							</el-input>
						</div>
						<div class="md-layout-item md-large-size-20 md-xlarge-size-20">
							<h3
								class="md-layout-item md-size-100"
								style="
									font-size: 1rem;
									text-align: left;
									margin: 25px 0 10px 0;
									padding: 0;
									font-weight: 100;
								"
							>
								Música (opcional)
							</h3>
							<el-input v-model="title" placeholder="Digite o nome da música">
							</el-input>
						</div>
					</div>
				</template>
			</FilterSelector>
		</div>
		<div
			v-show="loading"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
		>
			<Spinner />
		</div>
		<div
			v-show="!loading"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
		>
			<md-card v-if="this.tableData.length > 0">
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<div class="div-periodo">
						<span>{{ periodo }}</span>
					</div>
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						class="paginated-table table-striped table-hover"
					>
						<md-table-toolbar>
							<md-field>
								<label for="pages">Por página</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item"
									>
										{{ item }}
									</md-option>
								</md-select>
							</md-field>
						</md-table-toolbar>

						<md-table-row slot="md-table-row" slot-scope="{ item }" height="10">
							<md-table-cell md-label="Título">{{ item.title }}</md-table-cell>
							<md-table-cell md-label="Artista">{{
								item.artist
							}}</md-table-cell>
							<md-table-cell md-label="Curti" md-sort-by="percentual_likes"
								>{{ item.total_likes | formatNumber }} ({{
									Math.round(item.percentual_likes)
								}}%)</md-table-cell
							>
							<md-table-cell
								md-label="Não curti"
								md-sort-by="percentual_dislikes"
								>{{ item.total_dislikes | formatNumber }} ({{
									Math.round(item.percentual_dislikes)
								}}%)</md-table-cell
							>
							<md-table-cell md-label="Total" md-sort-by="total">{{
								item.total | formatNumber
							}}</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ from + 1 }} à {{ to }} de {{ total }} itens
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total"
					>
					</pagination>
				</md-card-actions>
			</md-card>
			<md-card v-else style="text-align: center">
				<md-card-content>
					<label for="">Sem informações pra serem exibidas.</label>
				</md-card-content>
			</md-card>
		</div>

		<!-- IMPRESSÃO -->
		<div class="titleLeft impressao">
			<img src="../../../public/img/logo-mobradio-black.png" alt="" />
			<h1>Relatório de Votação Musical</h1>
		</div>
		<div class="titleRight impressao">
			<b class="nameRadio">{{
				nameRadioReport ? nameRadioReport : "MobRadio"
			}}</b>
			&nbsp;
			<span><img class="logoRadio" :src="logo" alt="" /></span>
		</div>
		<div class="impressao periodo">
			<span>Período: {{ periodStart }} até: {{ periodEnd }} </span>
		</div>
		<div style="width: 100%">&nbsp;</div>
		<div class="contentBody impressao">
			<div class="divTable">
				<h4>Votação</h4>
				<div
					class="md-content md-table md-theme-default"
					table-header-color="blue"
				>
					<div
						class="md-content md-table-content md-scrollbar md-theme-default"
					>
						<table>
							<thead>
								<tr>
									<th class="md-table-head" width="40">
										<div class="md-table-head-container md-ripple md-disabled">
											<div class="md-table-head-label">Título</div>
										</div>
									</th>
									<th class="md-table-head" width="40">
										<div class="md-table-head-container md-ripple md-disabled">
											<div class="md-table-head-label">Artista</div>
										</div>
									</th>
									<th class="md-table-head" width="40">
										<div class="md-table-head-container md-ripple md-disabled">
											<div class="md-table-head-label">Curti</div>
										</div>
									</th>
									<th class="md-table-head" width="40">
										<div class="md-table-head-container md-ripple md-disabled">
											<div class="md-table-head-label">Não Curti</div>
										</div>
									</th>
									<th class="md-table-head" width="40">
										<div class="md-table-head-container md-ripple md-disabled">
											<div class="md-table-head-label">Total</div>
										</div>
									</th>
								</tr>
							</thead>
							<tfoot class="report-footer">
								<tr>
									<td colspan="6">
										Fonte de dados: MobRadio Analytics &nbsp;
										<img
											class="footerIcon"
											src="../../../public/img/favicon.png"
										/>
									</td>
								</tr>
							</tfoot>
							<tbody>
								<tr
									class="md-table-row"
									v-for="(item, index) in this.tableData"
									:key="index"
								>
									<td class="md-table-cell">
										<div class="md-table-cell-container">{{ item.title }}</div>
									</td>
									<td class="md-table-cell">
										<div class="md-table-cell-container">{{ item.artist }}</div>
									</td>
									<td class="md-table-cell">
										<div class="md-table-cell-container">
											{{ item.total_likes | formatNumber }} ({{
												Math.round(item.percentual_likes)
											}}%)
										</div>
									</td>
									<td class="md-table-cell">
										<div class="md-table-cell-container">
											{{ item.total_dislikes | formatNumber }} ({{
												Math.round(item.percentual_dislikes)
											}}%)
										</div>
									</td>
									<td class="md-table-cell">
										<div class="md-table-cell-container">
											{{ item.total | formatNumber }}
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<block v-show="blockStatus == 0"></block>
	</div>
</template>

<script>
import Vue from "vue";
import Swal from "sweetalert2";
import models from "../../routes/models.js";
import Fuse from "fuse.js";
import selectRadio from "../../utils/events/select-radio.js";
import { Pagination } from "@/components";
import block from "./Block.vue";
import jwt_decode from "jwt-decode";
import buttonEnum from "./Components/FilterSelector/buttonEnum";
import filters from "./Components/FilterSelector/filters";
import FilterSelector from "./Components/FilterSelector/FilterSelector.vue";
import Spinner from "../../components/Icons/Spinner.vue";
import mixpanelAdapter from "../../utils/events/mixpanelAdapter";

const numeral = require("numeral");

numeral.locale("pt-br");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

export default {
	components: {
		Pagination,
		block,
		Spinner,
		FilterSelector,
	},
	computed: {
		queriedData() {
			let result = this.tableData;
			if (this.searchedData.length > 0) {
				result = this.searchedData;
			}
			return result.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0
				? this.searchedData.length
				: this.tableData.length;
		},
	},
	data() {
		return {
			buttonEnum: buttonEnum,
			appKey: jwt_decode(localStorage.getItem("APP_01")).radio.key,
			nameRadioReport: jwt_decode(localStorage.getItem("APP_01")).radio.name,
			logo: jwt_decode(localStorage.getItem("APP_01")).radio.logo,
			tableData: [],
			searchedData: [],
			showDialog: false,
			time1: "",
			time2: "",
			widthPicker: 100 + "%",
			currentSort: "total",
			currentSortOrder: "asc",
			pagination: {
				perPage: 50,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50],
				total: 0,
			},
			propsToSearch: ["title", "artist"],
			loading: false,
			disabledButton: true,
			disabledButton1: false,
			disabledButton2: false,
			disabledButton3: false,
			disabledButton4: false,
			disabledButton5: false,
			periodo: "Hoje",
			blockStatus: 1,
			periodStart: "",
			periodEnd: "",
			searchQuery: "",
			fuseSearch: null,
			artist: "",
			title: "",
			blackList: 0,
			timerSearch: null,
		};
	},
	methods: {
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "asc") {
					return b[sortBy] - a[sortBy];
				}
				return b[sortBy] - a[sortBy];
			});
		},
		setNewDate(id) {
			this.tableData = [];
			this.loading = true;
			(this.disabledButton1 = false),
				(this.disabledButton2 = false),
				(this.disabledButton3 = false),
				(this.disabledButton4 = false),
				(this.disabledButton5 = false);
			this.getBlacklist();
			let dateStartFormatted = null;
			let dateEndFormatted = null;

			dateStartFormatted =
				filters.startDate.getFullYear() +
				"-" +
				(filters.startDate.getMonth() + 1) +
				"-" +
				filters.startDate.getDate() +
				" 00:00:00";
			dateEndFormatted =
				filters.endDate.getFullYear() +
				"-" +
				(filters.endDate.getMonth() + 1) +
				"-" +
				filters.endDate.getDate() +
				" 23:59:59";
			this.disabledButton = false;
			this.disabledButton5 = true;
			this.periodo = `${filters.startDate.toLocaleDateString(
				"pt-BR"
			)} a ${filters.endDate.toLocaleDateString("pt-BR")}`;

			this.currentSort = "total";

			models()
				.get(
					`music/getVotedSongs?appKey=${this.appKey}&startDate=${dateStartFormatted}&endDate=${dateEndFormatted}&title=${this.title}&artist=${this.artist}&offset=0&rows=999999`
				)
				.then((resp) => {
					this.tableData = resp.data;
					for (var i = this.tableData.length - 1; i >= 0; i--) {
						for (var j = 0; j < this.blackList.length; j++) {
							if (
								this.tableData[i] &&
								(this.tableData[i].title === this.blackList[j].name ||
									this.tableData[i].artist === this.blackList[j].name)
							) {
								this.tableData.splice(i, 1);
							}
						}
					}
					this.fuseSearch = new Fuse(this.tableData, {
						keys: ["title", "artist"],
						threshold: 0.3,
					});
					this.loading = false;
				})
				.catch((e) => {
					Swal(
						"Erro",
						"Falha ao buscar as informações, por favor tente novamente.",
						"error"
					);
					console.log(e);
				});
		},
		getVotedSongs() {
			this.loading = true;
			this.tableData = [];
			models()
				.get(
					`music/getVotedSongs${this.appKey}&startDate=${this.time1}&endDate=${this.time2}&title=${this.title}&artist=${this.artist}&offset=0&rows=999999999`
				)
				.then((resp) => {
					this.tableData = resp.data;
					for (var i = this.tableData.length - 1; i >= 0; i--) {
						for (var j = 0; j < this.blackList.length; j++) {
							if (
								this.tableData[i] &&
								(this.tableData[i].title === this.blackList[j].name ||
									this.tableData[i].artist === this.blackList[j].name)
							) {
								this.tableData.splice(i, 1);
							}
						}
					}
					this.loading = false;
				})
				.catch((e) => {
					Swal(
						"Erro",
						"Falha ao buscar as informações, por favor tente novamente.",
						"error"
					);
					console.log(e);
				});
		},
		blockPage() {
			const menu = jwt_decode(localStorage.getItem("APP_02")).services;
			let service = null;
			for (let obj of menu) {
				if (obj.subitens === null) {
					const key = Object.keys(obj.item)[
						Object.values(obj.item).indexOf(this.$route.path.replace("/", ""))
					];
					if (key) {
						service = obj.item;
					}
				} else {
					const key = Object.keys(obj.subitens)[
						Object.values(obj.subitens).indexOf(
							this.$route.path.replace("/", "")
						)
					];
					if (key) {
						service = obj.subitens;
					}
				}
			}

			if (service) {
				this.blockStatus = service.has_permission;
			} else {
				this.$router.push("dashboard");
			}
		},
		findObjectByKey(array, key, value) {
			for (var i = 0; i < array.length; i++) {
				if (array[i][key] === value) {
					return array[i];
				}
			}
			return null;
		},
		print() {
			this.periodStart = new Date(this.time1).toLocaleDateString("pt-BR");
			this.periodEnd = new Date(this.time2).toLocaleDateString("pt-BR");
			setTimeout(() => {
				window.print();
			}, 200);
		},
		redirectToReport() {
			this.$router.push("report-voted-song");
		},
		getBlacklist() {
			models()
				.get(`radios/getBlacklist?appKey=${this.appKey}`)
				.then((resp) => {
					this.blackList = resp.data;
				});
		}
	},
	mounted() {
		this.getBlacklist();
		if (jwt_decode(localStorage.getItem("APP_01"))) {
			this.appKey = jwt_decode(localStorage.getItem("APP_01")).radio.key;
			this.blockPage();
			this.getBlacklist();
		}
		selectRadio.$on("selectRadio", (payload) => {
			this.appKey = payload.key;
			this.tableData = [];
			this.disabledButton1 = false;
			this.disabledButton2 = false;
			this.disabledButton3 = false;
			this.disabledButton4 = false;
			this.disabledButton5 = false;
			(this.nameRadioReport = jwt_decode(
				localStorage.getItem("APP_01")
			).radio.name),
				(this.logo = jwt_decode(localStorage.getItem("APP_01")).radio.logo),
				this.blockPage();
			this.getBlacklist();
			this.setNewDate();
		});
		document.addEventListener("keyup", function (e) {
			if (e.keyCode == 13) {
				if (this.disabledButton == false) {
					this.getVotedSongs();
				}
			}
		});
	},
	watch: {
		searchQuery(value) {
			let result = this.tableData;
			if (value !== "") {
				result = this.fuseSearch.search(this.searchQuery);
			}
			this.searchedData = result;
		},
		time1() {
			if (this.time1 !== "" && this.time2 !== "") {
				this.disabledButton = false;
			} else {
				this.disabledButton = true;
			}
		},
		time2() {
			if (this.time1 !== "" && this.time2 !== "") {
				this.disabledButton = false;
			} else {
				this.disabledButton = true;
			}
		},
		artist() {
			clearTimeout(this.timerSearch);

			this.timerSearch = setTimeout(() => {
				this.setNewDate();
			}, 1000);
		},
		title() {
			clearTimeout(this.timerSearch);

			this.timerSearch = setTimeout(() => {
				this.setNewDate();
			}, 1000);
		},
	},
};
</script>
<style>
.impressao {
	display: none;
}
.border-icon-graph {
	display: inline-flex;
	padding: 20px;
	border-radius: 5px;
	position: absolute;
	box-shadow: 0 12px 20px -10px rgba(0, 188, 212, 0.28),
		0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 188, 212, 0.2);
	background: linear-gradient(60deg, #26c6da, #00acc1);
}
.icon-graph {
	background-color: transparent !important;
	color: #ffffff;
}
.container-graph {
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
}
.div-periodo {
	float: right;
	padding-top: 20px;
	font-size: 15px;
	font-weight: 500;
	font-style: italic;
	font-variant: all-small-caps;
}
.mobile-off {
	display: block;
}
.mobile-on {
	display: none;
}
.md-card.main-rodape.md-theme-default {
	margin-top: 0px !important;
}
.md-table-row > td {
	vertical-align: inherit;
}
canvas {
	padding: 60px 40px 60px 60px;
	border-radius: 10px;
	box-shadow: 0px 0px 8px 0px #d9d9d9;
	background-color: #ffffff;
}
@page {
	size: auto;
	margin: 10mm 10mm 10mm 10mm;
}
@media print {
	tr {
		page-break-inside: avoid;
	}
	table.report-container {
		page-break-after: always !important;
	}
	thead.report-header {
		display: table-header-group !important;
	}
	tfoot.report-footer {
		display: table-footer-group !important;
		text-align: center;
		font-size: 12pt;
	}
	canvas {
		height: 300px !important;
		padding: 10px;
		border: 1px solid #d9d9d9;
		border-radius: 0px;
		box-shadow: 0px 0px 0px 0px;
		background-color: #ffffff;
		margin-top: -30px;
		margin-bottom: -40px;
	}
	img {
		width: 160px;
		height: 160px;
	}

	h1 {
		font-size: 18pt;
	}

	span {
		font-size: 12pt;
	}

	footer {
		text-align: center;
		font-size: 12pt;
	}

	.principal {
		display: none;
	}

	.impressao {
		display: block;
		margin-top: -30px;
	}

	.periodo {
		margin-top: 10px;
		width: 100%;
	}

	.md-toolbar-section-start {
		display: none;
	}

	.titleLeft {
		width: 100%;
	}

	.titleRight {
		float: right;
		width: 100%;
		text-align: right;
		top: 50px;
	}

	.nameRadio {
		font-size: 18pt;
	}

	.logoRadio {
		width: 48px;
		height: 48px;
		border-radius: 25px;
		margin-top: -10px;
	}

	.contentBody {
		width: 100%;
		margin-top: 50px;
	}

	.divTable {
		border: 1px solid #d2d2d2;
		padding: 10px;
		margin-bottom: 10px;
		width: 100%;
	}

	.card-icon {
		display: none;
	}

	.footerIcon {
		width: 16px;
		height: 16px;
		margin-top: -5px;
	}

	.container-graph {
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
	}

	.md-card.main-rodape.md-theme-default {
		margin-top: -300px;
	}
}
@media screen and (max-width: 601px) {
	.mobile-off {
		display: none;
	}
	.mobile-on {
		display: block;
	}
}
</style>
