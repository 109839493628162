<template>
    <div class="md-layout">
        <md-card class="main-cabecalho">
            <div class="md-layout-item md-small-size-100 md-medium-size-100 cabecalho">
                <div class="md-layout-item md-small-size-100 md-medium-size-25 md-large-size-25 md-xlarge-size-25 img-logo">
                    <img src="../../../../public/img/logo-mobradio-black.png" alt="" style="width:240px;">
                </div>
                <div class="md-layout-item md-small-size-100 md-medium-size-50 md-medium-size-50 md-large-size-50">
                    <h3 class="nomeRadio">{{nameRadio}} <span><img class="logoRadio" :src="logo" style="width: 48px;border-radius: 26px;"></span></h3>
                </div>
            </div>
            <div class="md-layout-item md-small-size-100 md-medium-size-100 cabecalho">
                <div v-if="periodEnd !== null" class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50">
                    <h4>Período: <small>{{periodStart}}</small> até: <small>{{periodEnd}}</small> </h4>
                </div>
                <div v-if="periodEnd === null" class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50">
                    <h4>{{periodStart}}</h4>
                </div>
                <div class="md-layout-item md-small-size-100 md-medium-size-25 md-large-size-20 md-xlarge-size-15">
                    <md-button class="md-info md-block btn-imprimir" @click="actionPrint()" :disabled="imprimir"><i class="fas fa-print"></i> &nbsp; Imprimir</md-button>
                </div>
            </div>
        </md-card>
    </div>
</template>

<script>
import selectRadio from '../../../utils/events/select-radio.js'
import jwt_decode from "jwt-decode"
export default {
    name: 'cabecalho',
    props: ['periodStart', 'periodEnd', 'imprimir'],
    data() {
        return {
            nameRadio: jwt_decode(localStorage.getItem('APP_01')).radio.name,
            logo: jwt_decode(localStorage.getItem('APP_01')).radio.logo
        }
    },

    methods: {
        actionPrint() {
            let tempTitle = document.title;
            if(this.periodEnd !== null) {
                document.title = `MobRadio_-_${this.$route.name.replace(/ /g,'_')}(${this.periodStart}-${this.periodEnd}).pdf`;
            } else {
                document.title = `MobRadio_-_${this.$route.name.replace(/ /g,'_')}(${this.periodStart}).pdf`;
            }
            window.print();
            document.title = tempTitle;
        }
    },
    mounted() {
        selectRadio.$on('selectRadio', (payload) => {
            this.nameRadio = payload.name
            this.logo = jwt_decode(localStorage.getItem('APP_01')).radio.logo
        })
    }
}
</script>

<style>
    .main-cabecalho {
        padding: 20px;
    }
    .btn-imprimir {
        float: right;
    }
    h4 {
        font-weight: 500;
    }
    @media (min-width: 960px) {
        .cabecalho {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        .nomeRadio {
            float: right;
        }
    }
    @media (max-width: 960px) {
        .nomeRadio {
            float: left;
        }
    }

    @page {
        margin: 0 0 0 0 !important;
    }

    @media print {
        * {
            text-shadow:none !important;
            -ms-filter:none !important;
        }
        body {
            margin:0;
            padding:0;
            line-height: 1.4em;
            color: #000;
        }
        img {
            max-width: 240px;
            max-height: 240px;
        }
        h1 {
            font-size: 24pt;
        }

        h2 {
            font-size: 18pt;
        }

        h3 {
            font-size: 14pt;
        }
        .md-button, .md-toolbar, .card-icon, .display-center-container, nav, video, audio, object, embed {
            display: none;
        }
        .md-card {
            margin: 1rem auto;
        }
    }
</style>

