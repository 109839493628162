<template>
    <div>
        <NewPopUp />
    </div>
</template>

<script>
    import NewPopUp from "./New.vue"

    export default {
        components: {
            NewPopUp
        }
    }
</script>