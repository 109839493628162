<template>
    <div class="sidebar" :data-color="activeColor" :data-image="backgroundImage" :data-background-color="backgroundColor">
        <!-- :style="sidebarStyle" -->

        <div class="logo">
            <a href="#" class="simple-text logo-mini">
                <div class="logo-img">
                    <img src="@/assets/mobradio.png">
                </div>
            </a>
            <a href="javascript:void(0)" class="simple-text logo-normal" target="_blank">
                <template v-if="$route.meta.rtlActive">{{ rtlTitle }}</template>
                <template v-else>{{ title }}</template>
            </a>
            <div class="navbar-minimize">
                <md-button id="minimizeSidebar" class="md-round md-just-icon md-transparent" @click="minimizeSidebar">
                    <i class="material-icons text_align-center visible-on-sidebar-regular"
                        style="margin-left:25px;">more_vert</i>
                    <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini"
                        style="margin-left:25px;">view_list</i>
                </md-button>
            </div>
        </div>
        <div class="sidebar-wrapper" ref="sidebarScrollArea">
            <slot></slot>
            <md-list class="nav">
                <slot name="links">
                    <sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">
                        <sidebar-item v-for="(subLink, index) in link.children" :key="subLink.name + index" :link="subLink">
                        </sidebar-item>
                    </sidebar-item>
                </slot>
            </md-list>
            <div class="md-layout md-alignment-center-center" style="color: #ffffff">
                <p class="md-layout-item md-size-100 version">
                    {{ getVersion }}v
                </p>
            </div>
        </div>
        
    </div>
</template>
<script>

import package_json from '../../../package.json'

export default {
    name: 'sidebar',
    props: {
        title: {
            type: String,
            default: 'Painel MobRadio'
        },
        activeColor: {
            type: String,
            default: 'azure',
            validator: (value) => {
                let acceptedValues = ['', 'primary', 'azure', 'green', 'orange', 'danger', 'rose']
                return acceptedValues.indexOf(value) !== -1
            }
        },
        backgroundImage: {
            type: String,
            default: './img/sidebar-2.jpg'
        },
        backgroundColor: {
            type: String,
            default: 'black',
            validator: (value) => {
                let acceptedValues = ['', 'black', 'white', 'red']
                return acceptedValues.indexOf(value) !== -1
            }
        },
        logo: {
            type: String,
            default: '../../../public/img/mobrdio.png'
        },
        sidebarLinks: {
            type: Array,
            default: () => []
        },
        autoClose: {
            type: Boolean,
            default: true
        }
    },
    provide() {
        return {
            autoClose: this.autoClose
        }
    },
    methods: {
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize()
            }
        }
    },
    computed: {
        sidebarStyle() {
            return {
                backgroundImage: `url(${this.backgroundImage})`
            }
        },

		getVersion() {
			return package_json.version;
		}
    },
    beforeDestroy() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false
        }
    }
}
</script>
<style>
@media (min-width: 992px) {

    .navbar-search-form-mobile,
    .nav-mobile-menu {
        display: none;
    }
}

.logo {
    background-color: #009ddb !important;
}

.logo-img {
    background-color: #009ddb !important;
}

.logo-img>img {
    width: 34px !important;
    height: 34px !important;
    top: 0px !important;
}

.simple-text {
    text-transform: capitalize !important;
}

.version {
    font-size: 12px;
    font-family: 'Roboto';
    font-weight: 300;
    margin-top: 12px !important;
    margin-bottom: 4px !important;
    padding-left: 8px !important;
}
</style>