const buttonEnum = {
	TODAY: "1",
	YESTERDAY: "2",
	SEVEN_DAYS: "3",
	FIFTEEN_DAYS: "4",
	MONTH_TO_DATE: "5",
	CUSTOM: "6",
	YESTERDAY_PLUS_TODAY: "7",
	LAST_MONTH: "8",
	LAST_30_DAYS: "9",
};

export default buttonEnum;
