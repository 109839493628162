var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout", attrs: { id: "filter-selector" } },
    [
      _c(
        "div",
        {
          staticClass: "md-layout-item md-size-100",
          staticStyle: { margin: "0 !important", padding: "0 !important" },
        },
        [
          _c(
            "div",
            { staticClass: "md-layout" },
            [
              _c(
                "md-card",
                {
                  staticClass: "md-layout-item md-size-100",
                  staticStyle: { "margin-top": "0" },
                },
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "md-layout" }, [
                      _c(
                        "div",
                        {
                          staticClass: "md-layout-item md-size-100",
                          staticStyle: { padding: "0" },
                        },
                        [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass: "md-layout-item md-size-100",
                                staticStyle: { padding: "0" },
                              },
                              [_vm._t("filters")],
                              2
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout-item md-size-100",
                          staticStyle: { padding: "0" },
                        },
                        [
                          _c("h3", { staticClass: "title-period" }, [
                            _vm._v("Período"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "md-layout" },
                            [
                              _vm._l(
                                _vm.buttonsToDisplay,
                                function (periodOption) {
                                  return [
                                    periodOption != _vm.buttonEnum.CUSTOM
                                      ? _c(
                                          "button",
                                          {
                                            ref: `${periodOption}-period-option`,
                                            refInFor: true,
                                            staticClass:
                                              "md-layout-item md-xsmall-size-100 md-small-size-20 md-medium-size-20 md-large-size-15 md-xlarge-size-15 period-button",
                                            class: {
                                              "period-button-selected":
                                                _vm.buttonSelected ===
                                                periodOption,
                                              "period-button-disabled":
                                                _vm.disableSearch,
                                            },
                                            attrs: {
                                              disabled: _vm.disableSearch,
                                            },
                                            on: {
                                              click: () => {
                                                _vm.setPeriod(periodOption)
                                                _vm.sendMixPanelEvent({
                                                  period:
                                                    _vm.buttonLabels[
                                                      periodOption
                                                    ],
                                                })
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.buttonLabels[periodOption]
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "button",
                                          {
                                            ref: `${periodOption}-period-option`,
                                            refInFor: true,
                                            staticClass:
                                              "md-layout-item md-xsmall-size-100 md-small-size-40 md-medium-size-40 md-large-size-25 md-xlarge-size-25 period-button",
                                            class: {
                                              "period-button-selected":
                                                _vm.buttonSelected ===
                                                _vm.buttonEnum.CUSTOM,
                                              "period-button-disabled":
                                                _vm.disableSearch,
                                              "md-large-size-35 md-xlarge-size-35":
                                                _vm.buttonSelected ===
                                                _vm.buttonEnum.CUSTOM,
                                            },
                                            staticStyle: {
                                              "padding-right": "2px",
                                              "padding-left": "2px",
                                            },
                                            attrs: {
                                              disabled: _vm.disableSearch,
                                            },
                                            on: {
                                              click: () => {
                                                _vm.buttonSelected =
                                                  _vm.buttonEnum.CUSTOM
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Personalizado"),
                                            ]),
                                            _vm.buttonSelected ===
                                            _vm.buttonEnum.CUSTOM
                                              ? _c("el-date-picker", {
                                                  staticClass: "remove-margin",
                                                  staticStyle: {
                                                    "max-width": "400px",
                                                    margin: "0 !important",
                                                  },
                                                  attrs: {
                                                    format: "dd/MM/yyyy",
                                                    type: "daterange",
                                                    "range-separator": "a",
                                                    "unlink-panels": "",
                                                    "start-placeholder":
                                                      "Data inicial",
                                                    "end-placeholder":
                                                      "Data final",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.handleCustomPeriodChange(
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.period,
                                                    callback: function ($$v) {
                                                      _vm.period = $$v
                                                    },
                                                    expression: "period",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                  ]
                                }
                              ),
                            ],
                            2
                          ),
                          this.checkDifferenceBetweenDates()
                            ? _c(
                                "h5",
                                {
                                  ref: "period-error-message",
                                  staticStyle: {
                                    color: "#dd0000",
                                    margin: "0",
                                    "margin-top": "10px",
                                    "font-size": "95%",
                                  },
                                },
                                [
                                  _vm._v(
                                    " O período personalizado não pode ultrapassar 90 dias "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "md-layout-item md-size-100",
                          staticStyle: { "margin-top": "25px", padding: "0" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "md-layout md-alignment-center-right",
                            },
                            [
                              this.radio.parent_id && _vm.catalogReportAvailable
                                ? _c(
                                    "h3",
                                    {
                                      ref: "radios-consolidated-counter",
                                      staticStyle: { "margin-right": "auto" },
                                    },
                                    [
                                      _vm._v(
                                        " Rádios Consolidadas: " +
                                          _vm._s(
                                            _vm.radiosConsolidated.length
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-xsmall-size-100 md-small-size-20 md-medium-size-25 md-large-size-15 md-xlarge-size-15",
                                  staticStyle: { "padding-right": "0" },
                                },
                                [
                                  this.radio.parent_id &&
                                  _vm.catalogReportAvailable
                                    ? _c(
                                        "md-button",
                                        {
                                          ref: "consolidate-radios-button",
                                          staticClass: "md-success",
                                          staticStyle: { "padding-right": "0" },
                                          on: {
                                            click: () => {
                                              this.$emit(
                                                "showConsolidatePopup",
                                                this.radiosConsolidated
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Consolidar rádios ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "md-layout-item md-xsmall-size-100 md-small-size-25 md-medium-size-20 md-large-size-10 md-xlarge-size-10",
                                  staticStyle: { padding: "0" },
                                },
                                [
                                  _vm.extraFunction
                                    ? _c(
                                        "md-button",
                                        {
                                          ref: "extra-function-button",
                                          staticClass: "md-info",
                                          attrs: {
                                            disabled: _vm.disableExtraFunction,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.triggerExtraFunctionEvent()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.extraFunctionLabel) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("PopupConsolidateRadios"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }