<template>
	<div class="md-layout">
		<SwitchPopup />
		<div v-if="loading" style="display:contents" class="md-layout">
			<div
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
			>
				<div class="spinner">
					<div class="rect1"></div>
					<div class="rect2"></div>
					<div class="rect3"></div>
					<div class="rect4"></div>
					<div class="rect5"></div>
				</div>
			</div>
		</div>

		<div
			v-if="!loading"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-15 md-xlarge-size-15"
			style="display: flex; flex-direction: column; align-items: center; justify-content: center;"
		>
			<img :src="radio_current.logo" alt="Logo da rádio" class="logo-radio" />
			<h4 style="font-weight: light;">{{ radio_current.name }}</h4>
		</div>

		<div
			v-if="!loading"
			class="md-layout-item md-xsmall-size-100  md-medium-size-100 md-large-size-80 md-xlarge-size-70"
		>
			<md-card>
				<md-card-header>
					<h4 style="text-align: center; margin-bottom: 10px;">
						Sessões e usuários únicos
					</h4>
				</md-card-header>

				<md-card-content class="remove-margin-and-padding">
					<div class="custom-div-card-content md-layout">
						<div
							class="md-layout-item md-xsmall-size-100 md-small-size-40 md-medium-size-45 md-large-size-30 md-xlarge-size-30"
							style="margin: auto;"
						>
							<h4 style="text-align: center;">Hoje</h4>
							<div style="display: flex; justify-content: space-around;">
								<div>
									<p class="category" style="text-align:center">
										Sessões iniciadas
									</p>
									<h3 class="title">
										<div
											class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-50 md-large-size-100 md-xlarge-size-100"
										>
											{{ this.sessionsToday | formatNumber }}
										</div>
										<span style="font-size: 15px;">&nbsp;</span>
									</h3>
								</div>
								<div>
									<p class="category" style="text-align:center">
										Usuários únicos
									</p>
									<h3 class="title">
										<div
											class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
										>
											{{ this.uniqueUsersToday | formatNumber }}
										</div>
									</h3>
								</div>
							</div>
						</div>
						<div
							class="md-layout-item md-xsmall-size-100 md-small-size-40 md-medium-size-45 md-large-size-30 md-xlarge-size-30 div-border"
							style="margin: auto;"
						>
							<h4 style="text-align: center;">Ontem</h4>
							<div style="display: flex; justify-content: space-around;">
								<div>
									<p class="category" style="text-align:center">
										Sessões iniciadas
									</p>
									<h3 class="title">
										<div
											class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
										>
											{{ this.sessionsYesterday | formatNumber }}
										</div>
										<span
											v-if="this.percentSessionsYesterday > 0"
											class="text-success"
											style="font-size: 14px;"
										>
											<i class="fas fa-caret-up"></i>
											<span>{{ this.percentSessionsYesterday }}</span
											>%
										</span>
										<span
											v-else-if="this.percentSessionsYesterday < 0"
											class="text-danger"
											style="font-size: 14px;"
										>
											<i class="fas fa-caret-down custom-icon-content"></i>
											<span>{{ this.percentSessionsYesterday }}</span
											>%
										</span>
										<span v-else style="font-size: 14px; white-space: nowrap;"
											>Sem percentual</span
										>
									</h3>
								</div>
								<div>
									<p class="category" style="text-align:center">
										Usuários únicos
									</p>
									<h3 class="title">
										<div
											class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
										>
											{{ this.uniqueUsersYesterday | formatNumber }}
										</div>
										<span
											v-if="this.percentUniqueUsersYesterday > 0"
											class="text-success"
											style="font-size: 14px;"
										>
											<i class="fas fa-caret-up"></i>
											<span>{{ this.percentUniqueUsersYesterday }}</span
											>%
										</span>
										<span
											v-else-if="this.percentUniqueUsersYesterday < 0"
											class="text-danger"
											style="font-size: 14px;"
										>
											<i class="fas fa-caret-down custom-icon-content"></i>
											<span>{{ this.percentUniqueUsersYesterday }}</span
											>%
										</span>
										<span v-else style="font-size: 14px; white-space: nowrap;"
											>Sem percentual</span
										>
									</h3>
								</div>
							</div>
						</div>
						<div
							class="md-layout-item md-xsmall-size-100 md-small-size-70 md-medium-size-70 md-large-size-30 md-xlarge-size-30"
							style="margin: auto;"
						>
							<h4 style="text-align: center">Mês</h4>
							<div style="display: flex; justify-content: space-around;">
								<div>
									<p class="category" style="text-align:center">
										Sessões iniciadas
									</p>
									<h3 class="title">
										<div
											class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-30 md-large-size-100 md-xlarge-size-100"
										>
											{{ this.sessionsMonth | formatNumber }}
										</div>
										<span
											v-if="this.percentSessionsMonth > 0"
											class="text-success"
											style="font-size: 15px;"
										>
											<i class="fas fa-caret-up"></i>
											<span>{{ this.percentSessionsMonth }}</span
											>%
										</span>
										<span
											v-else-if="this.percentSessionsMonth < 0"
											class="text-danger"
											style="font-size: 15px;"
										>
											<i class="fas fa-caret-down custom-icon-content"></i>
											<span>{{ this.percentSessionsMonth }}</span
											>%
										</span>
										<span v-else style="font-size: 15px; white-space: nowrap;"
											>Sem percentual</span
										>
									</h3>
								</div>
								<div>
									<p class="category" style="text-align:center">
										Média de usuários únicos
									</p>
									<h3 class="title">
										<div
											class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
										>
											{{ this.uniqueUsersMonth | formatNumber }}/dia
										</div>
										<span
											v-if="this.percentUniqueUsersMonth > 0"
											class="text-success"
											style="font-size: 15px;"
										>
											<i class="fas fa-caret-up"></i>
											<span>{{ this.percentUniqueUsersMonth }}</span
											>%
										</span>
										<span
											v-else-if="this.percentUniqueUsersMonth < 0"
											class="text-danger"
											style="font-size: 15px;"
										>
											<i class="fas fa-caret-down custom-icon-content"></i>
											<span>{{ this.percentUniqueUsersMonth }}</span
											>%
										</span>
										<span v-else style="font-size: 15px; white-space: nowrap;"
											>Sem percentual</span
										>
									</h3>
								</div>
							</div>
						</div>
					</div>
				</md-card-content>
				<md-card-footer class="remove-margin-and-padding">
					<div style="text-align: center;">
						<router-link
							to="/report-sessions"
							style="display: block; margin: 2.5rem; font-size: 1rem; color: #4caf50"
							@click.native="sendMixPanelEvent(`dashboard_button_report_clicked`)"
							>VER RELATÓRIO
						</router-link>
					</div>
				</md-card-footer>
				<div class="md-card-actions md-alignment-left">
					<div class="stats">
						<i class="md-icon md-icon-font md-theme-default">access_time</i>
						Última atualização ({{
							new Date().toLocaleTimeString("pt-BR").substring(0, 2)
						}}h)
					</div>
				</div>
			</md-card>
		</div>

		<div
			v-if="!loading"
			class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-95 md-xlarge-size-85"
			style="margin-left: 10px;"
		>
			<md-card>
				<md-card-header>
					<h4 style="margin: 10px 0 0 5px !important; text-align: left;">
						Sessões iniciadas nos últimos 7 dias
					</h4>
				</md-card-header>
				<md-card-content class="md-content-dashboard">
					<div v-show="this.arrAccessRadio.length === 0" class="error-message">
						<h3>
							Sem informações disponíveis. Aguarde até que a base de dados seja
							alimentada.
						</h3>
					</div>
					<div v-show="this.arrAccessRadio.length !== 0" class="md-layout">
						<div
							class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-200 md-xlarge-size-200 row-icon-dashboard platform-data-container"
							id="chart-sessions-per-platform"
						>
						
						</div>
						<div
							class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-25 md-xlarge-size-25"
							style="background-color: #e9e9e9; padding: 2rem; margin: 1rem; height: 70%; border-radius: 1rem;"
						>
							<div class="md-layout">
								<div class="md-layout-item md-size-30 row-icon-dashboard">
									<i
										class="fab fa-android"
										title="Android"
										style="color: #5BB643"
									></i>
									<p>{{ this.totalAndroid | formatNumber }}</p>
								</div>
								<div
									class="md-layout-item md-size-30 row-icon-dashboard"
									style="border-left: 1px solid #e3e3e3; border-right: 1px solid #e3e3e3;"
								>
									<i
										class="fab fa-apple"
										title="iOS"
										style="color: #000000"
									></i>
									<p>{{ this.totalIos | formatNumber }}</p>
								</div>
								<div class="md-layout-item md-size-30 row-icon-dashboard">
									<i
										class="fa fa-desktop"
										title="Web"
										style="color: #FFA100"
									></i>
									<p>{{ this.totalWeb | formatNumber }}</p>
								</div>
								<div class="md-layout-item md-size-30 row-icon-dashboard">
									<img
										src="../../../public/img/alexa_icon.png"
										alt="alexa_icon"
										style="width: 38px;"
									/>
									<p>{{ this.totalAlexa | formatNumber }}</p>
								</div>
								<div
									class="md-layout-item md-size-30 row-icon-dashboard"
									style="border-left: 1px solid #e3e3e3; border-right: 1px solid #e3e3e3;"
								>
									<i
										class="fas fa-hdd"
										title="Apple TV"
										style="color: #61FFD1;"
									></i>
									<p>{{ this.totalAppleTV | formatNumber }}</p>
								</div>
								<div class="md-layout-item md-size-30 row-icon-dashboard">
									<p style="font-weight: bold; margin: 0;">TOTAL</p>
									<p>{{ this.totalSessions | formatNumber }}</p>
								</div>
							</div>
						</div>
					</div>
				</md-card-content>
			</md-card>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import Chart from "chart.js";
import selectRadio from "../../utils/events/select-radio.js";
import models from "../../routes/models.js";
import jwt_decode from "jwt-decode";
import SwitchPopup from "./PopupWarnings/SwitchPopup";
import {
	StatsCard,
	ChartCard,
	ProductCard,
	AnimatedNumber,
	GlobalSalesCard,
	GlobalSalesTable,
	Tabs,
	Collapse
} from "@/components";
import { DatePicker } from "element-ui";
import mixpanelAdapter from "../../utils/events/mixpanelAdapter";
import {} from "q";

const numeral = require("numeral");
numeral.locale("pt-br");
Vue.filter("formatNumber", function(value) {
	return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

export default {
	components: {
		StatsCard,
		ChartCard,
		AnimatedNumber,
		ProductCard,
		GlobalSalesCard,
		GlobalSalesTable,
		Tabs,
		Collapse,
		[DatePicker.name]: DatePicker,
		SwitchPopup
	},
	data() {
		return {
			loading: true,
			appKey: "",
			uniqueUsersToday: 0,
			uniqueUsersYesterday: 0,
			percentUniqueUsersYesterday: 0,
			uniqueUsersMonth: 0,
			percentUniqueUsersMonth: 0,

			sessionsToday: 0,
			sessionsYesterday: 0,
			percentSessionsYesterday: 0,
			sessionsMonth: 0,
			percentSessionsMonth: 0,

			totalAndroid: 0,
			totalIos: 0,
			totalWeb: 0,
			totalAlexa: 0,
			totalAppleTV: 0,
			totalSessions: 0,

			arrAccessRadio: [],
			radio_current: jwt_decode(localStorage.getItem("APP_01")).radio,
			dialogSelectChildrenRadio: true,
			catalogFirstSelect: [],
			mailto: ""
		};
	},

	methods: {
		async getSessionsToday(appKey) {
			models()
				.get(`/dashboard/getCurrentAccessIndex?appKey=${appKey}`)
				.then(res => {
					this.sessionsToday = res.data.total;
				});
		},

		async getSessionsYesterday(appKey) {
			models()
				.get(`dashboard/getCompareYesterdayAccessIndex?appKey=${appKey}`)
				.then(res => {
					this.sessionsYesterday = res.data.total;
					this.percentSessionsYesterday = res.data.percentual;
				});
		},

		async getSessionsMonth(appKey) {
			models()
				.get(`dashboard/getCompareMonthToDateAccessIndex?appKey=${appKey}`)
				.then(res => {
					this.sessionsMonth = res.data.total;
					this.percentSessionsMonth = res.data.percentual;
				});
		},

		async getUniqueUsersToday(appKey) {
			models()
				.get(`dashboard/getUniqueUser?appKey=${appKey}`)
				.then(res => {
					this.uniqueUsersToday = res.data.total;
				});
		},

		async getUniqueUsersYesterday(appKey) {
			models()
				.get(`dashboard/getUniqueUserYesterday?appKey=${appKey}`)
				.then(res => {
					this.uniqueUsersYesterday = res.data.total;
					this.percentUniqueUsersYesterday = res.data.percentual;
				});
		},

		async getUniqueUsersMonth(appKey) {
			models()
				.get(`dashboard/getUniqueUserMonth?appKey=${appKey}`)
				.then(res => {
					this.uniqueUsersMonth = Math.floor(res.data.media);
					this.percentUniqueUsersMonth = res.data.percentual;
				});
		},

		async acessFromLastSevenDays(appKey) {
			let dateStart = new Date();
			let dateEnd = new Date();
			dateStart.setDate(dateStart.getDate() - 7);
			dateEnd.setDate(dateEnd.getDate() - 1);
			dateStart =
				dateStart.getFullYear() +
				"-" +
				(dateStart.getMonth() + 1) +
				"-" +
				dateStart.getDate() +
				" 00:00:00";

			dateEnd =
				dateEnd.getFullYear() +
				"-" +
				(dateEnd.getMonth() + 1) +
				"-" +
				dateEnd.getDate() +
				" 23:59:59";

			console.log("dateStart:" + dateStart);
			console.log("dateEnd:" + dateEnd);
			models()
				.get(
					`music/accessRadio?appKey=${appKey}&startDate=${dateStart}&endDate=${dateEnd}`
				)
				.then(res => {
					this.arrAccessRadio = res.data;
					let chart = document.getElementById("chart-access-radio");
					if (chart) {
						chart.remove();
					}
					if (this.arrAccessRadio.length === 0) {
						return;
					}
					this.totalAndroid = res.data
						.map(element => element.total_count_android)
						.reduce((sum, current) => sum + current);
					this.totalAlexa = res.data
						.map(element => element.total_count_alexa)
						.reduce((sum, current) => sum + current);
					this.totalAppleTV = res.data
						.map(element => element.total_count_apple_tv)
						.reduce((sum, current) => sum + current);
					this.totalWeb = res.data
						.map(element => element.total_count_web)
						.reduce((sum, current) => sum + current);
					this.totalIos = res.data
						.map(element => element.total_count_iphone)
						.reduce((sum, current) => sum + current);
					this.totalIos += res.data
						.map(element => element.total_count_ipad)
						.reduce((sum, current) => sum + current);
					this.totalIos += res.data
						.map(element => element.total_count_ipod)
						.reduce((sum, current) => sum + current);
					this.totalSessions =
						(this.totalAndroid || 0) +
						(this.totalIos || 0) +
						(this.totalWeb || 0) +
						(this.totalAlexa || 0) +
						(this.totalAppleTV || 0);
					this.buildChartAcessPerPlatform();
				});
		},

		buildChartAcessPerPlatform() {
			const platforms = ["Android", "iOS", "Web", "Alexa", "AppleTV"];
			const lineColors = [
				"#5BB643",
				"#000000",
				"#FFA100",
				"#00C9FD",
				"#61FFD1"
			];

			let dataPerPlatform = {
				Android: [],
				iOS: [],
				Web: [],
				Alexa: [],
				AppleTV: []
			};

			this.arrAccessRadio.forEach(element => {
				dataPerPlatform.Android.push(
					element["total_count_android"]
						.toLocaleString("pt-br", {
							minimumFractionDigits: 2
						})
						.replace(".", "")
						.replace(",", ".")
				);
			});
			this.arrAccessRadio.forEach(element => {
				dataPerPlatform.iOS.push(
					element["total_count_iphone"]
						.toLocaleString("pt-br", {
							minimumFractionDigits: 2
						})
						.replace(".", "")
						.replace(",", ".")
				);
			});
			this.arrAccessRadio.forEach(element => {
				dataPerPlatform.Alexa.push(
					element["total_count_alexa"]
						.toLocaleString("pt-br", {
							minimumFractionDigits: 2
						})
						.replace(".", "")
						.replace(",", ".")
				);
			});

			this.arrAccessRadio.forEach(element => {
				dataPerPlatform.AppleTV.push(
					element["total_count_apple_tv"]
						.toLocaleString("pt-br", {
							minimumFractionDigits: 2
						})
						.replace(".", "")
						.replace(",", ".")
				);
			});

			let dataSets = platforms.map((platform, index) => {
				return {
					data: dataPerPlatform[platform],
					label: platform,
					borderColor: lineColors[index],
					backgroundColor: lineColors[index],
					fill: false,
					pointRadius: 3,
					pointHoverRadius: 3
				};
			});

			const chartAccessRadioContent = document.getElementById(
				"chart-sessions-per-platform"
			);
			let chart = document.getElementById("chart-access-radio");
			if (chart) {
				chart.remove();
			}

			chartAccessRadioContent.innerHTML +=
				'<canvas id="chart-access-radio" class="chart-dashboard"></canvas>';
			chart = document.getElementById("chart-access-radio");
			const ctx = chart.getContext("2d");
			this.dataCompletedTasksChart = new Chart(ctx, {
				type: "line",
				data: {
					labels: this.arrAccessRadio.map(element =>
						element.date
							.split("-")
							.reverse()
							.join("/")
					),
					datasets: dataSets
				},
				options: {
					responsive: true,
					maintainAspectRatio: false,
					legend: {
						position: "bottom",
						display: true,
						labels: {
							boxWidth: 15,
							padding: 20
						}
					},
					tooltips: {
						mode: "index",
						intersect: false
					},
					scales: {
						xAxes: [
							{
								gridLines: {
									borderDash: [5, 5]
								}
							}
						],
						yAxes: [
							{
								gridLines: {
									borderDash: [5, 5]
								},
								ticks: {
									beginAtZero: true
								}
							}
						]
					}
				}
			});
		},

		async getAllData(appKey) {
			this.loading = true;

			Promise.all([
				this.getSessionsToday(appKey),
				this.getSessionsYesterday(appKey),
				this.getSessionsMonth(appKey),
				this.getUniqueUsersToday(appKey),
				this.getUniqueUsersYesterday(appKey),
				this.getUniqueUsersMonth(appKey),
				this.acessFromLastSevenDays(appKey)
			]).finally(() => {
				this.loading = false;
			});
		},
		sendMixPanelEvent(eventString){
            mixpanelAdapter.track(eventString, {
                screen_name: this.$route.name
            })
        }
	},
	mounted() {
		const user = jwt_decode(localStorage.getItem("SET_APP")).user;

		const currentRadio = localStorage.getItem("SET_APP");
		if (currentRadio.is_parent == 0) {
			this.dialogSelectChildrenRadio = true;
		}
		this.appKey = this.radio_current.key;
		this.getAllData(this.appKey);
		this.mailto = `mailto:comercial@mobradio.com.br?subject=[PAINEL] Nova funcionalidade no app: Audiência
			&body=Me chamo ${user[0].firstname} e falo em nome da rádio ${this.radioName}.
			<br>Gostaria de saber mais detalhes sobre a funcionalidade de Audiência do Painel.
			<br><br>Att<br>${user[0].firstname}`;

		selectRadio.$on("selectRadio", payload => {
			this.radio_current = jwt_decode(localStorage.getItem("APP_01")).radio;
			this.appKey = payload.key;
		});
	},
	watch: {
		appKey(newKey) {
			this.getAllData(newKey);
		},
		time1() {
			this.disabledButton = !(this.time1 !== null && this.time2 !== null);
		},
		time2() {
			this.disabledButton = !(this.time1 !== null && this.time2 !== null);
		}
	}
};
</script>

<style>
p.category {
	font-size: 14px;
}

.div-border{
	border-left: 1px solid #e3e3e3;
	border-right: 1px solid #e3e3e3;
}

.logo-radio {
	width: 12rem;
	height: 12rem;
	border-radius: 1.5rem;
	box-shadow: 0px 5px 5px #ccc;
}

.container-select-first-radio {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(3px);
}

.remove-margin-and-padding {
	margin: 0 !important;
	padding: 0 !important;
}

#chart-sessions-per-platform {
	width: 100%;
}
.absolute-values-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	border-radius: 1rem;
	padding: 1rem;
	background-color: #f5f5f5;

	box-shadow: 0px 5px 6px #ccc;
	height: 60%;
	width: 100%;
}

table {
	width: 100%;
}

td {
	vertical-align: top;
}

.impressao {
	display: none;
}

.row-icon-dashboard > span {
	font-size: 12px;
}

.row-icon-dashboard > i {
	display: block !important;
	font-size: xx-large !important;
}

.row-icon-dashboard-downloads {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.row-icon-dashboard {
	margin: auto;
	text-align: center !important;
}

.md-card-tops {
	min-height: 409px;
	margin-bottom: -20px;
}

.ct-series-a .ct-line {
	stroke: #5eb561;
}

.ct-series-b .ct-line {
	stroke: #000000;
}

.ct-series-c .ct-line {
	stroke: #039be5;
}

.ct-series-a .ct-point {
	stroke: #5eb561;
}

.ct-series-b .ct-point {
	stroke: #000000;
}

.ct-series-c .ct-point {
	stroke: #039be5;
}

.cell-right {
	font-size: 11px;
	width: 20%;
}

.cell-right + .cell-right {
	padding-right: 5px;
}

.card-icon.card-blue {
	background-color: #0ca5eb !important;
	box-shadow: 0 12px 20px -10px rgba(0, 149, 255, 0.28),
		0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 149, 255, 0.2);
}

.card-icon.card-red {
	background-color: #eb0c0c !important;
	box-shadow: 0 12px 20px -10px rgba(255, 0, 0, 0.28),
		0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 0, 0, 0.2);
}

.card-icon.card-lightseagreen {
	background-color: #20b2aa !important;
	box-shadow: 0 12px 20px -10px rgba(80, 184, 204, 0.28),
		0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(80, 184, 204, 0.28);
}

.card-icon.card-darkorange {
	background-color: #ff8c00 !important;
	box-shadow: 0 12px 20px -10px rgba(255, 118, 0, 0.28),
		0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 177, 0, 0.2);
}

.card-icon.card-black {
	background-color: #000000 !important;
	box-shadow: 0 12px 20px -10px rgba(0, 0, 0, 0.28),
		0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(0, 0, 0, 0.2);
}

.card-icon.card-light-blue {
	box-shadow: 0 12px 20px -10px rgba(12, 165, 235, 0.28),
		0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(15, 166, 236, 0.28);
}

.card-icon.card-date {
	width: 63px;
	height: 63px;
	margin-left: -5px;
	box-shadow: 0 12px 20px -10px rgba(153, 153, 153, 0.28),
		0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(153, 153, 153, 0.28);
}

.card-500 {
	display: flex;
	justify-content: center;
	align-items: center;
}

i.md-icon.md-icon-font.card-icon-date.md-theme-default.card-icon-date {
	margin-top: 5px;
	margin-left: 5px;
}

i.far.fa-thumbs-up {
	color: #0da5eb;
}

i.far.fa-thumbs-down {
	color: #eb0d0c;
}

.mx-calendar-content {
	width: 100%;
}

.text-dispositivos {
	font-size: 20px;
}

.box-filter {
	display: flex;
	align-items: flex-start;
	align-items: flex-end;
}

.box-buttons-filter {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.view-filter {
	font-size: 15px;
	font-weight: 500;
	font-style: italic;
	font-variant: all-small-caps;
}

.spinner {
	margin: 100px auto;
	width: 100%;
	height: 40px;
	text-align: center;
	font-size: 10px;
}

.spinner > div {
	background-color: #333;
	height: 50px;
	margin-right: 5px;
	width: 6px;
	display: inline-block;
	-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
	animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.box-link-report {
	text-align: center;
}

.spinner .rect2 {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.spinner .rect3 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

.spinner .rect4 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.spinner .rect5 {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

.chart-dashboard {
	padding: 10px 10px 10px 10px !important;
	overflow-y: scroll;
}

.card-chart-dashboard {
	top: 0px;
	padding-top: 0px !important;
	margin-top: -25px !important;
	border-radius: 15px;
	box-shadow: 1px 1px 9px -4px #000000;
}

.card-chart-dashboard-hover {
	top: 0px;
	padding-top: 0px !important;
	margin-top: -25px !important;
	border-radius: 15px;
	box-shadow: 1px 1px 9px -4px #000000;
	transition: top 0.2s;
}

.content-chart-dashboard {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	min-height: 400px !important;
}

.content-chart-dashboard-downloads {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	min-height: 440px !important;
}

.content-chart-dashboard:hover .card-chart-dashboard-hover {
	top: -50px;
}

.box-buttons-filter {
	padding: 20px 20px !important;
}

.stats-chart {
	margin: 0 15px 10px;
	padding: 10px 0 0 0;
	border-top: 1px solid #eeeeee;
	line-height: 22px;
	color: #999999;
	font-size: 12px;
}

.stats-chart > .md-icon {
	line-height: 22px !important;
	color: #999999 !important;
	font-size: 16px !important;
}

.stats-chart-downloads {
	/* position: absolute;
			  bottom: 0; */
	width: 85%;
	margin: 0 15px 10px;
	padding: 10px 0 0 0;
	border-top: 1px solid #eeeeee;
	line-height: 22px;
	color: #999999;
	font-size: 12px;
}

.stats-chart-downloads > .md-icon {
	line-height: 22px !important;
	color: #999999 !important;
	font-size: 16px !important;
}

.stats-chart-bottom {
	margin: 0 15px 10px;
	padding: 10px 0 0 0;
	border-top: 1px solid #eeeeee;
	line-height: 22px;
	color: #999999;
	font-size: 12px;
	bottom: 0;
	/* position: absolute; */
	width: 80%;
}

.stats-chart-bottom > .md-icon {
	line-height: 22px !important;
	color: #999999 !important;
	font-size: 16px !important;
}

.stats-chart-top {
	margin: 24px 15px 10px;
	padding: 10px 0 0 0;
	border-top: 1px solid #eeeeee;
	line-height: 22px;
	color: #999999;
	font-size: 12px;
}

.chart-label-total {
	color: #999999;
	margin-left: 20px;
}

.chart-title {
	margin-left: 20px;
	display: block !important;
	position: initial !important;
	text-align: inherit !important;
	margin-top: -10px !important;
}

.btn-report-hidden {
	display: flex;
	justify-content: space-evenly;
	position: absolute;
	margin-top: -70px;
	cursor: pointer;
}

.btn-filter-dashboard-disabled {
	background: lightblue !important;
}

.btn-filter-dashboard-disabled:hover,
.btn-filter-dashboard-disabled:hover i,
.btn-filter-dashboard-disabled:focus,
.btn-filter-dashboard-disabled:focus i {
	background: #469ddb !important;
}

.btn-filter-dashboard-enabled {
	background: #469ddb !important;
}

.btn-filter-dashboard-enabled,
.btn-filter-dashboard-enabled i,
.btn-filter-dashboard-enabled:hover,
.btn-filter-dashboard-enabled:hover i,
.btn-filter-dashboard-enabled:focus,
.btn-filter-dashboard-enabled:focus i,
.btn-filter-dashboard-enabled:active,
.btn-filter-dashboard-enabled:active i,
.btn-filter-dashboard-enabled.active,
.btn-filter-dashboard-enabled.active i,
.btn-filter-dashboard-enabled:active:focus,
.btn-filter-dashboard-enabled:active:focus i,
.btn-filter-dashboard-enabled:active:hover,
.btn-filter-dashboard-enabled:active:hover i,
.btn-filter-dashboard-enabled.active:focus,
.btn-filter-dashboard-enabled.active:focus i,
.btn-filter-dashboard-enabled.active:hover,
.btn-filter-dashboard-enabled.active:hover i {
	background: #469ddb !important;
}

.icons-chart-dashboard-disabled {
	filter: grayscale(100%) !important;
	color: gray !important;
}

.div-display-rotate {
	visibility: hidden;
	text-align: center;
	z-index: 999999;
	position: absolute;
	width: 100%;
}

.div-display-rotate > img {
	width: 100px;
}

#icons-chart-downloads {
	margin-top: 75px;
}

.card-index {
	display: flex !important;
	justify-content: center;
	margin-top: 66px;
}

.span-index {
	margin-top: -15px;
}

.md-card-actions.md-alignment-left {
	position: absolute;
	bottom: 0;
	width: 90%;
}

.info-total-hint {
	font-size: 15px !important;
	width: 10px !important;
	height: 0px !important;
	margin-top: -15px !important;
	cursor: pointer !important;
}

.title-card {
	text-align: right !important;
	margin-top: 0px !important;
	font-size: 15px !important;
	color: #999999 !important;
}

.custom-card-content {
	margin-top: -15px !important;
	margin-bottom: 35px !important;
	min-height: 145px !important;
	padding: 15px 20px;
}

.custom-div-percentual {
	margin-top: -15px !important;
}

.error-message {
	grid-column: span 2;
	display: flex;
	text-align: center;
	justify-content: center;
	padding: 8%;
}

.custom-icon-content {
	height: 10px !important;
	width: 10px !important;
}

.center-date-filter {
	margin-top: -10px;
}

#chart-access-radio {
	min-height: 285px !important;
}

@-webkit-keyframes sk-stretchdelay {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(0.4);
	}

	20% {
		-webkit-transform: scaleY(1);
	}
}

@keyframes sk-stretchdelay {
	0%,
	40%,
	100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}

	20% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
	}
}

@media (max-width: 320px) {
	p.category {
		font-size: 11px;
	}
	.apple-tv {
		max-width: unset !important;
		width: 24px !important;
		margin-bottom: 2px;
	}

	.div-display-rotate {
		visibility: visible;
	}

	.div-display-rotate > img {
		width: 70px;
	}
}

@media (min-width: 321px) and (max-width: 416px) {
	.apple-tv {
		max-width: unset !important;
		width: 24px !important;
		margin-bottom: 2px;
	}

	.div-display-rotate {
		visibility: visible;
	}
}

@media (min-width: 415px) and (max-width: 768px) {
	.apple-tv {
		max-width: unset !important;
		width: 24px !important;
		margin-bottom: 2px;
	}
}

@media (min-width: 599px) {
	
	
	.break-line-card {
		display: none;
	}

	.btn-filter {
		margin-top: -10px;
	}
	.custom-div-card-content {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
	}
}

@media (min-width: 960px) {
	.box-buttons-filter {
		display: flex;
		align-items: flex-start;
		height: 89px;
	}

	.box-dispositivos {
		display: flex;
		justify-content: space-around;
		align-items: flex-end;
	}

	.apple-tv {
		width: 52px !important;
	}

	.box-downloadas {
		display: flex;
		justify-content: space-between;
	}

	.card-box-downloads {
		min-width: 25% !important;
		max-width: 33% !important;
		flex-grow: 1 !important;
	}
}

@media only screen and (min-width: 960px) and (max-width: 1280px) {
	
	.box-buttons-filter {
		height: 150px;
	}
}

@media (max-width: 960px) {
	/* .md-card-tops {
				  min-height: 100px;
			  } */

	.box-dispositivos {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		margin-left: 8px !important;
	}

	.box-dispositivos > div {
		margin-right: 15px;
	}

	.box-dispositivos > div > i {
		font-size: 20px !important;
		margin-bottom: 0;
	}

	.box-dispositivos > div > h5 {
		font-size: 15px !important;
		margin-bottom: 0;
	}

	span.text-dispositivos {
		font-size: 15px;
	}
}

@media (max-width: 1460px) {
	.break-line-card {
		display: none;
	}
}

@media (max-width: 1300px) {
	.div-border{
		border: none;
	}
}

@media (min-width: 1903px) {
	.ghost-box {
		display: none;
	}
}

@page {
	size: auto;
}

@media print {
	tr {
		page-break-inside: avoid;
	}

	table.report-container {
		page-break-after: always !important;
	}

	thead.report-header {
		display: table-header-group !important;
	}

	tfoot.report-footer {
		display: table-footer-group !important;
		text-align: center;
		font-size: 12pt;
	}

	canvas {
		height: 300px !important;
		padding: 10px;
		border: 1px solid #d9d9d9;
		border-radius: 0px;
		box-shadow: 0px 0px 0px 0px;
		background-color: #ffffff;
		margin-top: -30px;
		margin-bottom: -40px;
	}

	img {
		width: 160px;
		height: 160px;
	}

	h1 {
		font-size: 18pt;
	}

	span {
		font-size: 12pt;
	}

	footer {
		text-align: center;
		font-size: 12pt;
	}

	.principal {
		display: none;
	}

	.impressao {
		display: block;
		/* margin-top: -30px; */
	}

	.periodo {
		margin-top: 10px;
		width: 100%;
	}

	.md-toolbar-section-start {
		display: none;
	}

	.titleLeft {
		width: 50%;
	}

	.titleRight {
		float: right;
		width: 50%;
		text-align: right;
	}

	.nameRadio {
		font-size: 18pt;
	}

	.logoRadio {
		width: 48px;
		height: 48px;
		border-radius: 25px;
		margin-top: -10px;
	}

	.contentBody {
		width: 100%;
		margin-top: 50px;
	}

	.divTable {
		border: 1px solid #d2d2d2;
		padding: 10px;
		margin-bottom: 10px;
		width: 100%;
	}

	.card-icon {
		display: none;
	}

	.footerIcon {
		width: 16px;
		height: 16px;
		margin-top: -5px;
	}

	.container-graph {
		width: 100%;
		padding-left: 0px;
		padding-right: 0px;
	}

	.md-card.main-rodape.md-theme-default {
		margin-top: -300px;
	}
}
</style>
