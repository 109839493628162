import axios from "axios";

export default () => {
    return axios.create({
        baseURL: "https://us-central1-api-mobradio.cloudfunctions.net/app/"
    });
    //LOCAL
    // return axios.create({
    //     baseURL: "http://localhost:5000/api-mobradio/us-central1/app"
    // });
};
