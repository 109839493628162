var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "listener-search-container" },
    [
      _c(
        "md-card",
        [
          _c(
            "md-card-header",
            { staticClass: "md-card-header-icon md-card-header-blue" },
            [
              _c(
                "div",
                { staticClass: "card-icon" },
                [_c("md-icon", [_vm._v("search")])],
                1
              ),
              _c("h4", { staticClass: "title" }, [_vm._v("Buscar Ouvintes")]),
            ]
          ),
          _c("md-card-content", [
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                { staticClass: "md-layout-item md-xsmall-size-100 md-size-20" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Nome")]),
                      _c("md-input", {
                        on: { input: _vm.onSearchInput },
                        model: {
                          value: _vm.filters.nameQuery,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "nameQuery", $$v)
                          },
                          expression: "filters.nameQuery",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-xsmall-size-100 md-size-20" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("E-mail")]),
                      _c("md-input", {
                        on: { input: _vm.onSearchInput },
                        model: {
                          value: _vm.filters.emailQuery,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "emailQuery", $$v)
                          },
                          expression: "filters.emailQuery",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-xsmall-size-100 md-size-20" },
                [
                  _c(
                    "md-field",
                    [
                      _c("label", [_vm._v("Telefone")]),
                      _c("md-input", {
                        on: { input: _vm.onSearchInput },
                        model: {
                          value: _vm.filters.phoneQuery,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "phoneQuery", $$v)
                          },
                          expression: "filters.phoneQuery",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm.loadingListeners
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "md-layout-item md-size-100" },
                    [_c("Spinner")],
                    1
                  ),
                ])
              : _vm.listeners.length > 0
              ? _c(
                  "div",
                  { staticClass: "results-table" },
                  [
                    _c("md-table", {
                      attrs: { "md-sort": "name", "md-card": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "md-table-row",
                          fn: function ({ item }) {
                            return _c(
                              "md-table-row",
                              {},
                              [
                                _c(
                                  "md-table-cell",
                                  {
                                    attrs: {
                                      "md-label": "Nome",
                                      "md-sort-by": "name",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _c(
                                  "md-table-cell",
                                  {
                                    attrs: {
                                      "md-label": "E-mail",
                                      "md-sort-by": "email",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.email))]
                                ),
                                _c(
                                  "md-table-cell",
                                  { attrs: { "md-label": "Telefone" } },
                                  [_vm._v(_vm._s(item.mobile_phone))]
                                ),
                                _c(
                                  "md-table-cell",
                                  {
                                    attrs: { "md-label": "Data de nascimento" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.birth_date
                                          ? new Date(
                                              item.birth_date
                                            ).toLocaleDateString("pt-BR")
                                          : "Não informado"
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "md-table-cell",
                                  { attrs: { "md-label": "Gênero" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        item.gender === "male"
                                          ? "Masculino"
                                          : item.gender === "female"
                                          ? "Feminino"
                                          : "Não Informado"
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "md-table-cell",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openEditDialog(item)
                                          },
                                        },
                                      },
                                      [_vm._v(" Editar ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "md-table-cell",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini", type: "danger" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.confirmDeleteListener(
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Remover ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          },
                        },
                      ]),
                      model: {
                        value: _vm.listeners,
                        callback: function ($$v) {
                          _vm.listeners = $$v
                        },
                        expression: "listeners",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.searchExecuted &&
            _vm.listeners.length === 0 &&
            !_vm.loadingListeners
              ? _c("div", { staticClass: "no-results" }, [
                  _c("p", [
                    _vm._v(
                      "Nenhum ouvinte encontrado com os critérios informados."
                    ),
                  ]),
                ])
              : _vm.listeners.length === 0 &&
                !_vm.loadingListeners &&
                !_vm.searchExecuted
              ? _c("div", { staticClass: "no-results" }, [
                  _c("p", [
                    _vm._v(
                      "Digite um nome, e-mail ou telefone para buscar um ouvinte."
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Editar Usuário",
            visible: _vm.editDialogVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: {
                model: _vm.editForm,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Nome", prop: "first_name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editForm.first_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "first_name", $$v)
                      },
                      expression: "editForm.first_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Sobrenome", prop: "last_name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editForm.last_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "last_name", $$v)
                      },
                      expression: "editForm.last_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "E-mail", prop: "email" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.editForm.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "email", $$v)
                      },
                      expression: "editForm.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Celular",
                    type: "number",
                    prop: "mobile_phone",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "11", "show-word-limit": "" },
                    model: {
                      value: _vm.editForm.mobile_phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "mobile_phone", $$v)
                      },
                      expression: "editForm.mobile_phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Data de Nascimento", prop: "birth_date" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Data de Nascimento",
                      format: "dd/MM/yyyy",
                    },
                    model: {
                      value: _vm.editForm.birth_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "birth_date", $$v)
                      },
                      expression: "editForm.birth_date",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Gênero", prop: "gender" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "Selecione o gênero" },
                      model: {
                        value: _vm.editForm.gender,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "gender", $$v)
                        },
                        expression: "editForm.gender",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Masculino", value: "male" },
                      }),
                      _c("el-option", {
                        attrs: { label: "Feminino", value: "female" },
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "Prefiro não informar",
                          value: "undefined",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Senha", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { type: "password", "show-password": "" },
                    model: {
                      value: _vm.editForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "password", $$v)
                      },
                      expression: "editForm.password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "Confirmar senha", prop: "confirmPassword" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "password", "show-password": "" },
                    model: {
                      value: _vm.editForm.confirmPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "confirmPassword", $$v)
                      },
                      expression: "editForm.confirmPassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.errorEmptyField
            ? _c("p", { staticClass: "form-error-title" }, [
                _vm._v(
                  "Existem campos com dados não preenchidos ou inválidos. Verifique e tente novamente."
                ),
              ])
            : _vm._e(),
          _vm.errorPasswordMismatch
            ? _c("p", { staticClass: "form-error-title" }, [
                _vm._v(
                  "As senhas não conferem. Por favor, verifique e tente novamente."
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editDialogVisible = false
                    },
                  },
                },
                [_vm._v("Cancelar")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updateListener },
                },
                [_vm._v("Salvar")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }