var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Novo Canal",
            visible: _vm.dialogNewPost,
            width: "30%",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogNewPost = $event
            },
          },
        },
        [
          _c("el-form", { attrs: { model: _vm.form } }, [
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [_c("span", [_vm._v("Campos com (*) são obrigatórios.")])]
              ),
            ]),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      autocomplete: "off",
                      placeholder: "Título*",
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autocomplete: "off",
                      placeholder: "Descrição*",
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "Idioma*" },
                      model: {
                        value: _vm.form.language,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "language", $$v)
                        },
                        expression: "form.language",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: "DE" } }, [
                        _vm._v("Alemão"),
                      ]),
                      _c("el-option", { attrs: { value: "ZH" } }, [
                        _vm._v("Chinês (Mandarin)"),
                      ]),
                      _c("el-option", { attrs: { value: "ES" } }, [
                        _vm._v("Espanhol"),
                      ]),
                      _c("el-option", { attrs: { value: "FR" } }, [
                        _vm._v("Francês"),
                      ]),
                      _c("el-option", { attrs: { value: "EN" } }, [
                        _vm._v("Inglês"),
                      ]),
                      _c("el-option", { attrs: { value: "IT" } }, [
                        _vm._v("Italiano"),
                      ]),
                      _c("el-option", { attrs: { value: "JA" } }, [
                        _vm._v("Japonês"),
                      ]),
                      _c("el-option", { attrs: { value: "PT" } }, [
                        _vm._v("Português"),
                      ]),
                      _c("el-option", { attrs: { value: "RU" } }, [
                        _vm._v("Russo"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "email",
                      autocomplete: "off",
                      placeholder: "Email*",
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      autocomplete: "off",
                      placeholder: "Autor*",
                    },
                    model: {
                      value: _vm.form.author,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "author", $$v)
                      },
                      expression: "form.author",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      autocomplete: "off",
                      placeholder: "Direitos Autorais*",
                    },
                    model: {
                      value: _vm.form.copyright,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "copyright", $$v)
                      },
                      expression: "form.copyright",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        multiple: "",
                        "multiple-limit": 3,
                        placeholder: "Selecione até 3 categorias",
                      },
                      model: {
                        value: _vm.form.category,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "category", $$v)
                        },
                        expression: "form.category",
                      },
                    },
                    _vm._l(_vm.options, function (group) {
                      return _c(
                        "el-option-group",
                        { key: group.label, attrs: { label: group.label } },
                        _vm._l(group.options, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                  staticStyle: { "padding-top": "20px" },
                },
                [
                  _c(
                    "span",
                    { ref: "msgImg", staticStyle: { color: "#c1c5cd" } },
                    [_vm._v("Capa do podcast (1400x1400 px)")]
                  ),
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader avatar-gifts",
                      attrs: {
                        action: "#",
                        accept: "image/jpeg, image/png",
                        "auto-upload": false,
                        "show-file-list": false,
                        "on-change": _vm.getFileImage,
                      },
                    },
                    [
                      _vm.urlPreviewImage
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.urlPreviewImage },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                  _c("span", { ref: "msgImg" }),
                ],
                1
              ),
            ]),
            _c("br"),
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70",
                },
                [
                  _c("el-checkbox", {
                    attrs: { type: "checkbox" },
                    model: {
                      value: _vm.form.explicit,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "explicit", $$v)
                      },
                      expression: "form.explicit",
                    },
                  }),
                  _vm._v("  "),
                  _c("span", { staticStyle: { color: "#a2a4a7" } }, [
                    _vm._v("Conteúdo explícito"),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogNewPost = false
                    },
                  },
                },
                [_vm._v("Cancelar")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.propertyBtnConfirm },
                  on: {
                    click: function ($event) {
                      return _vm.newChannel()
                    },
                  },
                },
                [_vm._v("Confirmar")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }