var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.blockStatus == 1,
              expression: "blockStatus == 1",
            },
          ],
          staticClass: "md-layout-item md-small-size-100",
        },
        [
          _c("FilterSelector", {
            attrs: {
              filterForReport: false,
              buttonsToDisplay: [
                _vm.buttonEnum.TODAY,
                _vm.buttonEnum.SEVEN_DAYS,
                _vm.buttonEnum.MONTH_TO_DATE,
                _vm.buttonEnum.CUSTOM,
              ],
            },
            on: {
              searchData: function ($event) {
                return _vm.musicPlayed()
              },
            },
            scopedSlots: _vm._u([
              {
                key: "filters",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "md-layout" },
                      [
                        _c(
                          "h3",
                          {
                            staticClass: "md-layout-item md-size-100",
                            staticStyle: {
                              "font-size": "1rem",
                              "text-align": "left",
                              margin: "25px 0 10px 0",
                              padding: "0",
                              "font-weight": "100",
                            },
                          },
                          [_vm._v(" Digite sua busca ")]
                        ),
                        _c("el-input", {
                          staticClass:
                            "md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-20 md-xlarge-size-20",
                          staticStyle: { margin: "0", padding: "0" },
                          attrs: { label: "Busca", type: "text" },
                          model: {
                            value: _vm.searchTitle,
                            callback: function ($$v) {
                              _vm.searchTitle = $$v
                            },
                            expression: "searchTitle",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        },
        [_c("Spinner")],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        },
        [
          _vm.tableData.length > 0
            ? _c(
                "md-card",
                [
                  _c(
                    "md-card-header",
                    { staticClass: "md-card-header-icon md-card-header-green" },
                    [
                      _c(
                        "div",
                        { staticClass: "card-icon" },
                        [_c("md-icon", [_vm._v("assignment")])],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "md-card-content",
                    [
                      _c(
                        "md-table",
                        {
                          staticClass:
                            "paginated-table table-striped table-hover",
                          attrs: {
                            value: _vm.queriedData,
                            "md-sort": _vm.currentSort,
                            "md-sort-order": _vm.currentSortOrder,
                            "md-sort-fn": _vm.customSort,
                          },
                          on: {
                            "update:mdSort": function ($event) {
                              _vm.currentSort = $event
                            },
                            "update:md-sort": function ($event) {
                              _vm.currentSort = $event
                            },
                            "update:mdSortOrder": function ($event) {
                              _vm.currentSortOrder = $event
                            },
                            "update:md-sort-order": function ($event) {
                              _vm.currentSortOrder = $event
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "md-table-row",
                                fn: function ({ item }) {
                                  return _c(
                                    "md-table-row",
                                    {},
                                    [
                                      _c(
                                        "md-table-cell",
                                        { attrs: { "md-label": "Título" } },
                                        [_vm._v(_vm._s(item.title))]
                                      ),
                                      _c(
                                        "md-table-cell",
                                        { attrs: { "md-label": "Artista" } },
                                        [_vm._v(_vm._s(item.artist))]
                                      ),
                                      _c(
                                        "md-table-cell",
                                        {
                                          attrs: {
                                            "md-label": "Total",
                                            "md-sort-by": "total",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.total))]
                                      ),
                                    ],
                                    1
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            3257137756
                          ),
                        },
                        [
                          _c(
                            "md-table-toolbar",
                            [
                              _c(
                                "md-field",
                                [
                                  _c("label", { attrs: { for: "pages" } }, [
                                    _vm._v("Por página"),
                                  ]),
                                  _c(
                                    "md-select",
                                    {
                                      attrs: { name: "pages" },
                                      model: {
                                        value: _vm.pagination.perPage,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.pagination,
                                            "perPage",
                                            $$v
                                          )
                                        },
                                        expression: "pagination.perPage",
                                      },
                                    },
                                    _vm._l(
                                      _vm.pagination.perPageOptions,
                                      function (item) {
                                        return _c(
                                          "md-option",
                                          {
                                            key: item,
                                            attrs: { label: item, value: item },
                                          },
                                          [_vm._v(" " + _vm._s(item) + " ")]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "md-card-actions",
                    { attrs: { "md-alignment": "space-between" } },
                    [
                      _c("div", {}, [
                        _c("p", { staticClass: "card-category" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.from + 1) +
                              " à " +
                              _vm._s(_vm.to) +
                              " de " +
                              _vm._s(_vm.total) +
                              " itens "
                          ),
                        ]),
                      ]),
                      _c("pagination", {
                        staticClass: "pagination-no-border pagination-success",
                        attrs: {
                          "per-page": _vm.pagination.perPage,
                          total: _vm.total,
                        },
                        model: {
                          value: _vm.pagination.currentPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.pagination, "currentPage", $$v)
                          },
                          expression: "pagination.currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "md-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.msgError,
                  expression: "msgError",
                },
              ],
              staticStyle: { "text-align": "center" },
            },
            [
              _c("md-card-content", [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Sem informações pra serem exibidas."),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("block", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.blockStatus == 0,
            expression: "blockStatus == 0",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }