var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        title: this.editingMode ? "Editar capa especial" : "Nova capa especial",
        "close-on-press-escape": false,
        "close-on-click-modal": false,
        "before-close": _vm.showAlertToCancelNewCover,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "md-layout" }, [
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          [
            _c("el-input", {
              attrs: { placeholder: "Nome da programação" },
              model: {
                value: _vm.title,
                callback: function ($$v) {
                  _vm.title = $$v
                },
                expression: "title",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          [
            _c("el-input", {
              attrs: { placeholder: "Subtítulo (Opcional)" },
              model: {
                value: _vm.subtitle,
                callback: function ($$v) {
                  _vm.subtitle = $$v
                },
                expression: "subtitle",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "md-layout-item md-size-100" }, [
          _c("div", { staticClass: "md-layout" }, [
            _c(
              "div",
              {
                staticClass: "md-layout-item md-size-50 md-small-size-100",
                staticStyle: { "padding-left": "0" },
              },
              [
                _c(
                  "el-date-picker",
                  {
                    staticStyle: { width: "100% !important" },
                    attrs: {
                      type: "date",
                      placeholder: "Data de início",
                      format: "dd/MM/yyyy",
                      "picker-options": {
                        disabledDate: (date) =>
                          date < new Date().setDate(new Date().getDate() - 1),
                      },
                    },
                    model: {
                      value: _vm.startDate,
                      callback: function ($$v) {
                        _vm.startDate = $$v
                      },
                      expression: "startDate",
                    },
                  },
                  [_vm._v(" >")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "md-layout-item md-size-50 md-small-size-100",
                staticStyle: { "padding-right": "0" },
              },
              [
                _c(
                  "el-date-picker",
                  {
                    staticStyle: { width: "100% !important" },
                    attrs: {
                      type: "date",
                      placeholder: "Data final",
                      format: "dd/MM/yyyy",
                      "picker-options": {
                        disabledDate: (date) =>
                          date < new Date().setDate(new Date().getDate() - 1),
                      },
                      disabled: !this.startDate,
                    },
                    model: {
                      value: _vm.endDate,
                      callback: function ($$v) {
                        _vm.endDate = $$v
                      },
                      expression: "endDate",
                    },
                  },
                  [_vm._v(" >")]
                ),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "md-layout-item md-size-100" }, [
          _c("div", { staticClass: "md-layout" }, [
            _c(
              "div",
              {
                staticClass: "md-layout-item md-size-50 md-small-size-100",
                staticStyle: { "padding-left": "0" },
              },
              [
                _c("el-time-picker", {
                  staticStyle: { width: "100% !important" },
                  attrs: {
                    format: "HH:mm",
                    disabled: !this.startDate,
                    "picker-options": _vm.startTimeRange,
                    placeholder: "Hora inicial",
                  },
                  model: {
                    value: _vm.startTime,
                    callback: function ($$v) {
                      _vm.startTime = $$v
                    },
                    expression: "startTime",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "md-layout-item md-size-50 md-small-size-100",
                staticStyle: { "padding-right": "0" },
              },
              [
                _c("el-time-picker", {
                  staticStyle: { width: "100% !important" },
                  attrs: {
                    format: "HH:mm",
                    disabled: !this.endDate,
                    "picker-options": {
                      selectableRange: `00:00:00 - 23:59:59`,
                    },
                    placeholder: "Hora final",
                  },
                  model: {
                    value: _vm.endTime,
                    callback: function ($$v) {
                      _vm.endTime = $$v
                    },
                    expression: "endTime",
                  },
                }),
              ],
              1
            ),
            _vm.dateInvalid
              ? _c("p", { staticStyle: { color: "#FF0000" } }, [
                  _vm._v(
                    " A data e a hora de término deverá ser posterior à data e a hora de início "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "md-layout-item md-size-100" },
          [
            _c("el-input", {
              attrs: { placeholder: "URL da imagem" },
              model: {
                value: _vm.imageURL,
                callback: function ($$v) {
                  _vm.imageURL = $$v
                },
                expression: "imageURL",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("div", { staticClass: "md-layout md-alignment-center-right" }, [
            _c(
              "div",
              { staticClass: "md-layout-item md-size-20 md-small-size-100" },
              [
                _c(
                  "md-button",
                  {
                    staticClass: "md-danger md-block",
                    on: {
                      click: function ($event) {
                        return _vm.showAlertToCancelNewCover()
                      },
                    },
                  },
                  [_vm._v("Cancelar")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "md-layout-item md-size-20 md-small-size-100" },
              [
                this.editingMode
                  ? _c(
                      "md-button",
                      {
                        staticClass: "md-info md-block",
                        attrs: { disabled: this.checkIfFieldsAreEmpty() },
                        on: {
                          click: function ($event) {
                            return _vm.editCover()
                          },
                        },
                      },
                      [_vm._v("Editar Capa")]
                    )
                  : _c(
                      "md-button",
                      {
                        staticClass: "md-success md-block",
                        attrs: { disabled: this.checkIfFieldsAreEmpty() },
                        on: {
                          click: function ($event) {
                            return _vm.createNewCover()
                          },
                        },
                      },
                      [_vm._v("Criar Capa")]
                    ),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }