<template>
	<div id="box-popup" class="box-message">
		<div class="alert alert-warning alert-with-icon" data-notify="container">
			<button type="button" aria-hidden="true" class="close" @click="closePopup()">×</button>
			<i data-notify="icon" class="material-icons">warning</i>
			<h3 class="title-popup">{{title}}</h3>
			<span data-notify="message">{{message}}</span>
		</div>
	</div>
</template>

<script>
import models from '../../../routes/models';
	export default {
		name: 'popup',

		data() {
			return {
				title: '',
				message: ''
			}
		},

		methods: {
			getPopupWarning() {
				models().get("/popups/getPopupWarning")
					.then((popups) => {
						popups.data.forEach(element => {
							if(element.status === 1) {
								this.title = element.title
								this.message = element.message
							}
						});
					})
			},
			closePopup() {
				document.getElementById("box-popup").remove();
			}
		},

		mounted() {
			this.getPopupWarning()
		}
	}

</script>

<style>
	.title-popup {
		margin-right: 50px;
		text-align: center;
		color: #ffffff;
	}

    .close-box {
        cursor: pointer;
        color: #ffffff;
        float: right;
    }

	.box-message {
		text-align: center;
		min-width: 30%;
		position: absolute;
		left: 50%;
		top: 5%;
		transform: translate(-50%, -50%);
		z-index: 999999;
	}

	.span-message {
		color: white;
	}

	.button-popup {
		text-align: center;
	}

	@media (max-width: 960px) {
		.box-message {
			width: 95%;
			position: absolute;
			left: 50%;
			top: 85px;
			transform: translate(-50%, -50%);
			z-index: 999999;
		}
	}

</style>
