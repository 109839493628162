var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass:
            "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        },
        [_vm._m(0)]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading && _vm.blockStatus == 1,
              expression: "!loading && blockStatus == 1",
            },
          ],
          staticClass: "md-layout-item md-size-100",
        },
        [
          _c(
            "md-card",
            [
              _c(
                "md-card-header",
                { staticClass: "md-card-header-icon md-card-header-blue" },
                [
                  _c("div", { staticClass: "card-icon" }, [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("library_books"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "md-card-content",
                [
                  _c(
                    "el-tabs",
                    {
                      on: {
                        "tab-click": function ($event) {
                          _vm.sendMixPanelEvent(`schedule_tab_filter_clicked`, {
                            day_of_week_filtered: _vm.getActiveNameDayOfWeek(
                              _vm.activeName
                            ),
                          })
                        },
                      },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "legend",
                          staticStyle: { "text-align": "right" },
                        },
                        [
                          _c("b", [_vm._v("Legenda:")]),
                          _vm._v("   "),
                          _c("i", {
                            staticClass: "fas fa-circle",
                            staticStyle: { color: "#ef6b6c" },
                          }),
                          _vm._v(" Finalizado "),
                          _c("i", {
                            staticClass: "fas fa-circle",
                            staticStyle: { color: "#4baf51" },
                          }),
                          _vm._v(" Em andamento "),
                          _c("i", {
                            staticClass: "fas fa-circle",
                            staticStyle: { color: "#c9cacb" },
                          }),
                          _vm._v(" Próximos "),
                        ]
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "SEGUNDA-FEIRA", name: "first" } },
                        [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-25 md-large-size-20 md-xlarge-size-15",
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-success md-block",
                                    on: {
                                      click: function ($event) {
                                        _vm.emptyScheduleModel()
                                        _vm.dialogVisibleNewProgram = true
                                        this.msgComponentImage = ""
                                      },
                                    },
                                  },
                                  [_vm._v("Adicionar")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                              },
                              [
                                _c("md-table", {
                                  attrs: { "table-header-color": "green" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "md-table-row",
                                      fn: function ({ item }) {
                                        return _c(
                                          "md-table-row",
                                          {
                                            style:
                                              item.status == 2 &&
                                              item.currentDay === 1
                                                ? "background-color: lightyellow;"
                                                : "",
                                          },
                                          [
                                            item.currentDay > 1
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#ef6b6c",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : item.currentDay === 1 &&
                                                item.status === 1
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#ef6b6c",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : item.currentDay === 1 &&
                                                item.status === 2
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#4baf51",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#c9cacb",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                staticClass: "img-schedule",
                                                staticStyle: { width: "80px" },
                                                attrs: { "md-label": "" },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "40px",
                                                    height: "40px",
                                                    "border-radius": "5px",
                                                  },
                                                  attrs: {
                                                    src: item.image_url,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: { "md-label": "Início" },
                                              },
                                              [_vm._v(_vm._s(item.start))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              { attrs: { "md-label": "Fim" } },
                                              [_vm._v(_vm._s(item.end))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: {
                                                  "md-label": "Programa",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.program))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: {
                                                  "md-label": "Locutor",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.speaker))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                staticStyle: { width: "250px" },
                                                attrs: { "md-label": "Ações" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "container-btn-edit",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-btn-edit",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-edit",
                                                            circle: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.getScheduleById(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-btn-edit",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-delete",
                                                            circle: "",
                                                            id: "btn-action-times",
                                                          },
                                                          on: {
                                                            click: () => {
                                                              _vm.sendMixPanelEvent(
                                                                `delete_scheduled_popup_opened`
                                                              )
                                                              _vm.deleteSchedule(
                                                                item.id,
                                                                item.url
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.tableDataSegunda,
                                    callback: function ($$v) {
                                      _vm.tableDataSegunda = $$v
                                    },
                                    expression: "tableDataSegunda",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "TERÇA-FEIRA", name: "second" } },
                        [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-25 md-large-size-20 md-xlarge-size-15",
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-success md-block",
                                    on: {
                                      click: function ($event) {
                                        _vm.dialogVisibleNewProgram = true
                                      },
                                    },
                                  },
                                  [_vm._v("Adicionar")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                              },
                              [
                                _c("md-table", {
                                  attrs: { "table-header-color": "green" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "md-table-row",
                                      fn: function ({ item }) {
                                        return _c(
                                          "md-table-row",
                                          {
                                            style:
                                              item.status == 2 &&
                                              item.currentDay === 2
                                                ? "background-color: lightyellow;"
                                                : "",
                                          },
                                          [
                                            item.currentDay > 2
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#ef6b6c",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : item.currentDay === 2 &&
                                                item.status === 1
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#ef6b6c",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : item.status === 2 &&
                                                item.currentDay === 2
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#4baf51",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#c9cacb",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                staticClass: "img-schedule",
                                                staticStyle: { width: "80px" },
                                                attrs: { "md-label": "" },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "40px",
                                                    height: "40px",
                                                    "border-radius": "5px",
                                                  },
                                                  attrs: {
                                                    src: item.image_url,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: { "md-label": "Início" },
                                              },
                                              [_vm._v(_vm._s(item.start))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              { attrs: { "md-label": "Fim" } },
                                              [_vm._v(_vm._s(item.end))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: {
                                                  "md-label": "Programa",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.program))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: {
                                                  "md-label": "Locutor",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.speaker))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                staticStyle: { width: "250px" },
                                                attrs: { "md-label": "Ações" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "container-btn-edit",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-btn-edit",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-edit",
                                                            circle: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.getScheduleById(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-btn-edit",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-delete",
                                                            circle: "",
                                                            id: "btn-action-times",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteSchedule(
                                                                item.id,
                                                                item.url
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.tableDataTerca,
                                    callback: function ($$v) {
                                      _vm.tableDataTerca = $$v
                                    },
                                    expression: "tableDataTerca",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "QUARTA-FEIRA", name: "third" } },
                        [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-25 md-large-size-20 md-xlarge-size-15",
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-success md-block",
                                    on: {
                                      click: function ($event) {
                                        _vm.dialogVisibleNewProgram = true
                                      },
                                    },
                                  },
                                  [_vm._v("Adicionar")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                              },
                              [
                                _c("md-table", {
                                  attrs: { "table-header-color": "green" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "md-table-row",
                                      fn: function ({ item }) {
                                        return _c(
                                          "md-table-row",
                                          {
                                            style:
                                              item.status == 2 &&
                                              item.currentDay === 3
                                                ? "background-color: lightyellow;"
                                                : "",
                                          },
                                          [
                                            item.currentDay > 3
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#ef6b6c",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : item.currentDay === 3 &&
                                                item.status === 1
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#ef6b6c",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : item.currentDay === 3 &&
                                                item.status === 2
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#4baf51",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#c9cacb",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                staticClass: "img-schedule",
                                                staticStyle: { width: "80px" },
                                                attrs: { "md-label": "" },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "40px",
                                                    height: "40px",
                                                    "border-radius": "5px",
                                                  },
                                                  attrs: {
                                                    src: item.image_url,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: { "md-label": "Início" },
                                              },
                                              [_vm._v(_vm._s(item.start))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              { attrs: { "md-label": "Fim" } },
                                              [_vm._v(_vm._s(item.end))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: {
                                                  "md-label": "Programa",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.program))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: {
                                                  "md-label": "Locutor",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.speaker))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                staticStyle: { width: "250px" },
                                                attrs: { "md-label": "Ações" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "container-btn-edit",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-btn-edit",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-edit",
                                                            circle: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.getScheduleById(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-btn-edit",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-delete",
                                                            circle: "",
                                                            id: "btn-action-times",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteSchedule(
                                                                item.id,
                                                                item.url
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.tableDataQuarta,
                                    callback: function ($$v) {
                                      _vm.tableDataQuarta = $$v
                                    },
                                    expression: "tableDataQuarta",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "QUINTA-FEIRA", name: "fourth" } },
                        [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-25 md-large-size-20 md-xlarge-size-15",
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-success md-block",
                                    on: {
                                      click: function ($event) {
                                        _vm.dialogVisibleNewProgram = true
                                      },
                                    },
                                  },
                                  [_vm._v("Adicionar")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                              },
                              [
                                _c("md-table", {
                                  attrs: { "table-header-color": "green" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "md-table-row",
                                      fn: function ({ item }) {
                                        return _c(
                                          "md-table-row",
                                          {
                                            style:
                                              item.status == 2 &&
                                              item.currentDay === 4
                                                ? "background-color: lightyellow;"
                                                : "",
                                          },
                                          [
                                            item.currentDay > 4
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#ef6b6c",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : item.currentDay === 4 &&
                                                item.status === 1
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#ef6b6c",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : item.currentDay === 4 &&
                                                item.status === 2
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#4baf51",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#c9cacb",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                staticClass: "img-schedule",
                                                staticStyle: { width: "80px" },
                                                attrs: { "md-label": "" },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "40px",
                                                    height: "40px",
                                                    "border-radius": "5px",
                                                  },
                                                  attrs: {
                                                    src: item.image_url,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: { "md-label": "Início" },
                                              },
                                              [_vm._v(_vm._s(item.start))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              { attrs: { "md-label": "Fim" } },
                                              [_vm._v(_vm._s(item.end))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: {
                                                  "md-label": "Programa",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.program))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: {
                                                  "md-label": "Locutor",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.speaker))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                staticStyle: { width: "250px" },
                                                attrs: { "md-label": "Ações" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "container-btn-edit",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-btn-edit",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-edit",
                                                            circle: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.getScheduleById(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-btn-edit",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-delete",
                                                            circle: "",
                                                            id: "btn-action-times",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteSchedule(
                                                                item.id,
                                                                item.url
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.tableDataQuinta,
                                    callback: function ($$v) {
                                      _vm.tableDataQuinta = $$v
                                    },
                                    expression: "tableDataQuinta",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "SEXTA-FEIRA", name: "six" } },
                        [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-25 md-large-size-20 md-xlarge-size-15",
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-success md-block",
                                    on: {
                                      click: function ($event) {
                                        _vm.dialogVisibleNewProgram = true
                                      },
                                    },
                                  },
                                  [_vm._v("Adicionar")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                              },
                              [
                                _c("md-table", {
                                  attrs: { "table-header-color": "green" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "md-table-row",
                                      fn: function ({ item }) {
                                        return _c(
                                          "md-table-row",
                                          {
                                            style:
                                              item.status == 2 &&
                                              item.currentDay === 5
                                                ? "background-color: lightyellow;"
                                                : "",
                                          },
                                          [
                                            item.currentDay > 5
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#ef6b6c",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : item.currentDay === 5 &&
                                                item.status === 1
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#ef6b6c",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : item.currentDay === 5 &&
                                                item.status === 2
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#4baf51",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#c9cacb",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                staticClass: "img-schedule",
                                                staticStyle: { width: "80px" },
                                                attrs: { "md-label": "" },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "40px",
                                                    height: "40px",
                                                    "border-radius": "5px",
                                                  },
                                                  attrs: {
                                                    src: item.image_url,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: { "md-label": "Início" },
                                              },
                                              [_vm._v(_vm._s(item.start))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              { attrs: { "md-label": "Fim" } },
                                              [_vm._v(_vm._s(item.end))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: {
                                                  "md-label": "Programa",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.program))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: {
                                                  "md-label": "Locutor",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.speaker))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                staticStyle: { width: "250px" },
                                                attrs: { "md-label": "Ações" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "container-btn-edit",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-btn-edit",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-edit",
                                                            circle: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.getScheduleById(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-btn-edit",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-delete",
                                                            circle: "",
                                                            id: "btn-action-times",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteSchedule(
                                                                item.id,
                                                                item.url
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.tableDataSexta,
                                    callback: function ($$v) {
                                      _vm.tableDataSexta = $$v
                                    },
                                    expression: "tableDataSexta",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "SÁBADO", name: "seven" } },
                        [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-25 md-large-size-20 md-xlarge-size-15",
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-success md-block",
                                    on: {
                                      click: function ($event) {
                                        _vm.dialogVisibleNewProgram = true
                                      },
                                    },
                                  },
                                  [_vm._v("Adicionar")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                              },
                              [
                                _c("md-table", {
                                  attrs: { "table-header-color": "green" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "md-table-row",
                                      fn: function ({ item }) {
                                        return _c(
                                          "md-table-row",
                                          {
                                            style:
                                              item.status == 2 &&
                                              item.currentDay === 6
                                                ? "background-color: lightyellow;"
                                                : "",
                                          },
                                          [
                                            item.currentDay > 6
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#ef6b6c",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : item.currentDay === 6 &&
                                                item.status === 1
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#ef6b6c",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : item.currentDay === 6 &&
                                                item.status === 2
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#4baf51",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#c9cacb",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                staticClass: "img-schedule",
                                                staticStyle: { width: "80px" },
                                                attrs: { "md-label": "" },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "40px",
                                                    height: "40px",
                                                    "border-radius": "5px",
                                                  },
                                                  attrs: {
                                                    src: item.image_url,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: { "md-label": "Início" },
                                              },
                                              [_vm._v(_vm._s(item.start))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              { attrs: { "md-label": "Fim" } },
                                              [_vm._v(_vm._s(item.end))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: {
                                                  "md-label": "Programa",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.program))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: {
                                                  "md-label": "Locutor",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.speaker))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                staticStyle: { width: "250px" },
                                                attrs: { "md-label": "Ações" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "container-btn-edit",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-btn-edit",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-edit",
                                                            circle: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.getScheduleById(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-btn-edit",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-delete",
                                                            circle: "",
                                                            id: "btn-action-times",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteSchedule(
                                                                item.id,
                                                                item.url
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.tableDataSabado,
                                    callback: function ($$v) {
                                      _vm.tableDataSabado = $$v
                                    },
                                    expression: "tableDataSabado",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "DOMINGO", name: "eight" } },
                        [
                          _c("div", { staticClass: "md-layout" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-25 md-large-size-20 md-xlarge-size-15",
                              },
                              [
                                _c(
                                  "md-button",
                                  {
                                    staticClass: "md-success md-block",
                                    on: {
                                      click: function ($event) {
                                        _vm.dialogVisibleNewProgram = true
                                      },
                                    },
                                  },
                                  [_vm._v("Adicionar")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                              },
                              [
                                _c("md-table", {
                                  attrs: { "table-header-color": "green" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "md-table-row",
                                      fn: function ({ item }) {
                                        return _c(
                                          "md-table-row",
                                          {
                                            style:
                                              item.status == 2 &&
                                              item.currentDay === 0
                                                ? "background-color: lightyellow;"
                                                : "",
                                          },
                                          [
                                            item.currentDay === 0 &&
                                            item.status === 1
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#ef6b6c",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : item.currentDay === 0 &&
                                                item.status === 2
                                              ? _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#4baf51",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "md-table-cell",
                                                  {
                                                    staticStyle: {
                                                      width: "5px",
                                                      color: "#c9cacb",
                                                    },
                                                    attrs: { "md-label": "" },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-circle",
                                                    }),
                                                  ]
                                                ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                staticClass: "img-schedule",
                                                staticStyle: { width: "80px" },
                                                attrs: { "md-label": "" },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "40px",
                                                    height: "40px",
                                                    "border-radius": "5px",
                                                  },
                                                  attrs: {
                                                    src: item.image_url,
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: { "md-label": "Início" },
                                              },
                                              [_vm._v(_vm._s(item.start))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              { attrs: { "md-label": "Fim" } },
                                              [_vm._v(_vm._s(item.end))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: {
                                                  "md-label": "Programa",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.program))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                attrs: {
                                                  "md-label": "Locutor",
                                                },
                                              },
                                              [_vm._v(_vm._s(item.speaker))]
                                            ),
                                            _c(
                                              "md-table-cell",
                                              {
                                                staticStyle: { width: "250px" },
                                                attrs: { "md-label": "Ações" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "container-btn-edit",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-btn-edit",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-edit",
                                                            circle: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.getScheduleById(
                                                                item.id
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "row-btn-edit",
                                                      },
                                                      [
                                                        _c("el-button", {
                                                          attrs: {
                                                            icon: "el-icon-delete",
                                                            circle: "",
                                                            id: "btn-action-times",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteSchedule(
                                                                item.id,
                                                                item.url
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.tableDataDomingo,
                                    callback: function ($$v) {
                                      _vm.tableDataDomingo = $$v
                                    },
                                    expression: "tableDataDomingo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "Novo programa",
                visible: _vm.dialogVisibleNewProgram,
              },
              on: {
                open: function ($event) {
                  return _vm.sendMixPanelEvent(`new_schedule_form_opened`)
                },
                close: function ($event) {
                  return _vm.sendMixPanelEvent(`new_schedule_form_closed`)
                },
                "update:visible": function ($event) {
                  _vm.dialogVisibleNewProgram = $event
                },
              },
            },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.newName,
                    callback: function ($$v) {
                      _vm.newName = $$v
                    },
                    expression: "newName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Programa", name: "programa" } },
                    [
                      _c("div", { staticClass: "md-layout" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [
                            _c("el-input", {
                              staticStyle: { "margin-bottom": "12px" },
                              attrs: { id: "input_name", placeholder: "Nome" },
                              model: {
                                value: _vm.schedule.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.schedule, "name", $$v)
                                },
                                expression: "schedule.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                id: "input_broadcaster",
                                placeholder: "Locutor",
                              },
                              model: {
                                value: _vm.schedule.broadcaster,
                                callback: function ($$v) {
                                  _vm.$set(_vm.schedule, "broadcaster", $$v)
                                },
                                expression: "schedule.broadcaster",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                id: "input_description",
                                type: "textarea",
                                rows: 6,
                                placeholder: "Descrição",
                              },
                              model: {
                                value: _vm.schedule.description,
                                callback: function ($$v) {
                                  _vm.$set(_vm.schedule, "description", $$v)
                                },
                                expression: "schedule.description",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-60 md-xlarge-size-60",
                          },
                          [
                            _c("el-time-picker", {
                              staticStyle: {
                                width: "100% !important",
                                "margin-top": "12px",
                              },
                              attrs: {
                                "is-range": "",
                                "range-separator": "-",
                                "start-placeholder": "Horário inicial",
                                "end-placeholder": "Horário final",
                                format: "HH:mm",
                              },
                              model: {
                                value: _vm.schedule.time,
                                callback: function ($$v) {
                                  _vm.$set(_vm.schedule, "time", $$v)
                                },
                                expression: "schedule.time",
                              },
                            }),
                            _c("span", { staticClass: "span-obs" }, [
                              _vm._v(
                                "Obs.: O horário final pode limitar o horário inicial, por exemplo, para definir o horário inicial para 14:00hrs, o horário final deve estar no mínimo com 15:00hrs."
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 check-items",
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { indeterminate: _vm.isIndeterminate },
                                on: { change: _vm.handleCheckAllChange },
                                model: {
                                  value: _vm.checkAll,
                                  callback: function ($$v) {
                                    _vm.checkAll = $$v
                                  },
                                  expression: "checkAll",
                                },
                              },
                              [_vm._v("Marcar todos")]
                            ),
                            _c("div", { staticStyle: { margin: "15px 0" } }),
                            _c(
                              "el-checkbox-group",
                              {
                                on: { change: _vm.handlecheckedDaysChange },
                                model: {
                                  value: _vm.checkedDays,
                                  callback: function ($$v) {
                                    _vm.checkedDays = $$v
                                  },
                                  expression: "checkedDays",
                                },
                              },
                              _vm._l(_vm.days, function (day) {
                                return _c(
                                  "el-checkbox",
                                  { key: day, attrs: { label: day } },
                                  [_vm._v(_vm._s(day))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "md-layout-item md-size-100 div-img" },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader avatar-gifts",
                                attrs: {
                                  action: "#",
                                  accept: "image/jpeg,image/gif,image/png",
                                  "auto-upload": false,
                                  "show-file-list": false,
                                  "before-upload": _vm.getLoadImage,
                                  "on-change": _vm.handleProgress,
                                  "on-success": _vm.handleAvatarSuccess,
                                },
                              },
                              [
                                _vm.imageUrl
                                  ? _c("img", {
                                      staticClass: "avatar",
                                      attrs: { src: _vm.imageUrl },
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                    }),
                              ]
                            ),
                            _vm.msgComponentImage !== ""
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(_vm._s(_vm.msgComponentImage)),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        this.errorsForm.length
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                              },
                              [
                                _c("p", [
                                  _c("b", [
                                    _vm._v(
                                      "Por favor, corrija o(s) seguinte(s) erro(s):"
                                    ),
                                  ]),
                                  _c(
                                    "ul",
                                    _vm._l(this.errorsForm, function (error) {
                                      return _c("li", { key: error }, [
                                        _vm._v(_vm._s(error)),
                                      ])
                                    }),
                                    0
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-30 md-xlarge-size-30",
                          },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-info md-block",
                                on: {
                                  click: function ($event) {
                                    return _vm.newSchedule()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-save" }),
                                _vm._v(" Salvar"),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-30 md-xlarge-size-30",
                          },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-danger md-block",
                                on: {
                                  click: function ($event) {
                                    _vm.dialogVisibleNewProgram = false
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-times" }),
                                _vm._v(" Cancelar"),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [_vm._v("   ")]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              staticStyle: { width: "100%" },
              attrs: {
                title: "Atualizar programa",
                visible: _vm.dialogVisibleViewProgram,
              },
              on: {
                open: function ($event) {
                  return _vm.sendMixPanelEvent(`update_schedule_form_opened`)
                },
                close: function ($event) {
                  return _vm.sendMixPanelEvent(`update_schedule_form_closed`)
                },
                "update:visible": function ($event) {
                  _vm.dialogVisibleViewProgram = $event
                },
              },
            },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.updateName,
                    callback: function ($$v) {
                      _vm.updateName = $$v
                    },
                    expression: "updateName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Programa", name: "programa" } },
                    [
                      _c("div", { staticClass: "md-layout" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [
                            _c("el-input", {
                              staticStyle: { "margin-bottom": "12px" },
                              attrs: {
                                id: "input_update_name",
                                placeholder: "Nome",
                              },
                              model: {
                                value: _vm.scheduleUpdate.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scheduleUpdate, "name", $$v)
                                },
                                expression: "scheduleUpdate.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                id: "input_update_broadcaster",
                                placeholder: "Locutor",
                              },
                              model: {
                                value: _vm.scheduleUpdate.broadcaster,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.scheduleUpdate,
                                    "broadcaster",
                                    $$v
                                  )
                                },
                                expression: "scheduleUpdate.broadcaster",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                            staticStyle: { "margin-bottom": "12px" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                id: "input_update_description",
                                type: "textarea",
                                rows: 6,
                                placeholder: "Descrição",
                              },
                              model: {
                                value: _vm.scheduleUpdate.description,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.scheduleUpdate,
                                    "description",
                                    $$v
                                  )
                                },
                                expression: "scheduleUpdate.description",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-60 md-xlarge-size-60",
                          },
                          [
                            _c("el-time-picker", {
                              staticStyle: {
                                width: "100% !important",
                                "margin-botton": "12px",
                              },
                              attrs: {
                                "is-range": "",
                                "range-separator": "-",
                                "start-placeholder": "Horário inicial",
                                "end-placeholder": "Horário final",
                                format: "HH:mm",
                              },
                              model: {
                                value: _vm.scheduleUpdate.time,
                                callback: function ($$v) {
                                  _vm.$set(_vm.scheduleUpdate, "time", $$v)
                                },
                                expression: "scheduleUpdate.time",
                              },
                            }),
                            _c("span", { staticClass: "span-obs" }, [
                              _vm._v(
                                "Obs.: O horário final pode limitar o horário inicial, por exemplo, para definir o horário inicial para 14:00hrs, o horário final deve estar no mínimo com 15:00hrs."
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 check-items",
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { indeterminate: _vm.isIndeterminate },
                                on: { change: _vm.handleCheckAllChange },
                                model: {
                                  value: _vm.checkAll,
                                  callback: function ($$v) {
                                    _vm.checkAll = $$v
                                  },
                                  expression: "checkAll",
                                },
                              },
                              [_vm._v("Marcar todos")]
                            ),
                            _c("div", { staticStyle: { margin: "15px 0" } }),
                            _c(
                              "el-checkbox-group",
                              {
                                on: { change: _vm.handlecheckedDaysChange },
                                model: {
                                  value: _vm.checkedDays,
                                  callback: function ($$v) {
                                    _vm.checkedDays = $$v
                                  },
                                  expression: "checkedDays",
                                },
                              },
                              _vm._l(_vm.days, function (day) {
                                return _c(
                                  "el-checkbox",
                                  { key: day, attrs: { label: day } },
                                  [_vm._v(_vm._s(day))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 div-img",
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader avatar-gifts",
                                attrs: {
                                  action:
                                    "https://jsonplaceholder.typicode.com/posts/",
                                  "show-file-list": false,
                                  "before-upload": _vm.getLoadImageUpdate,
                                  "on-change": _vm.handleProgressUpdate,
                                  "on-success": _vm.handleAvatarSuccessUpdate,
                                },
                              },
                              [
                                _vm.imageUrlPreview
                                  ? _c("img", {
                                      staticClass: "avatar",
                                      attrs: { src: _vm.imageUrlPreview },
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                    }),
                              ]
                            ),
                            _vm.msgComponentImage !== ""
                              ? _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(_vm._s(_vm.msgComponentImage)),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.errorsEdit.length
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                              },
                              [
                                _c("p", [
                                  _c("b", [
                                    _vm._v(
                                      "Por favor, corrija o(s) seguinte(s) erro(s):"
                                    ),
                                  ]),
                                  _c(
                                    "ul",
                                    _vm._l(_vm.errorsEdit, function (error) {
                                      return _c("li", { key: error }, [
                                        _vm._v(_vm._s(error)),
                                      ])
                                    }),
                                    0
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-30 md-xlarge-size-30",
                          },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-info md-block",
                                on: {
                                  click: function ($event) {
                                    return _vm.updateSchedule()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-save" }),
                                _vm._v(" Atualizar"),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-30 md-xlarge-size-30",
                          },
                          [
                            _c(
                              "md-button",
                              {
                                staticClass: "md-danger md-block",
                                on: {
                                  click: function ($event) {
                                    _vm.dialogVisibleViewProgram = false
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-times" }),
                                _vm._v(" Cancelar"),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                          },
                          [_vm._v("   ")]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("block", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.blockStatus == 0,
            expression: "blockStatus == 0",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "spinner" }, [
      _c("div", { staticClass: "rect1" }),
      _c("div", { staticClass: "rect2" }),
      _c("div", { staticClass: "rect3" }),
      _c("div", { staticClass: "rect4" }),
      _c("div", { staticClass: "rect5" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }