<template>
    <div>
        <div v-if="this.switch === 1 && this.expiration_date > new Date().toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'})">
            <PopupInfo />
        </div>
        <div v-else-if="this.switch === 2  && this.expiration_date > new Date().toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'})">
            <PopupSuccess />
        </div>
        <div v-else-if="this.switch === 3  && this.expiration_date > new Date().toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'})">
            <PopupWarning />
        </div>
        <div v-else-if="this.switch === 4  && this.expiration_date > new Date().toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'})">
            <PopupDanger />
        </div>
    </div>
</template>

<script>
    import PopupInfo from "./PopupInfo.vue"
    import PopupSuccess from "./PopupSuccess.vue"
    import PopupWarning from "./PopupWarning.vue"
    import PopupDanger from "./PopupDanger.vue"
    import models from '../../../routes/models'

    export default {
        name: "SwitchPopup",
        components: {
            PopupInfo,
            PopupSuccess,
            PopupWarning,
            PopupDanger
        },
        data() {
            return {
                switch: '',
                expiration_date: ''
            }
        },
        methods: {
            SwitchPopup() {
                models().get("/popups/getPopupWarning")
					.then((popups) => {
						popups.data.forEach(element => {
							if(element.status === 1) {
								this.switch = parseInt(element.type)
								this.expiration_date = element.expiration_date
							}
						});
					})
            }
        },
        mounted() {
            this.SwitchPopup()
        }
    }
</script>