var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "text-align": "center" } }, [
    _c("img", {
      staticStyle: {
        width: "7rem",
        "border-radius": "3.5rem",
        border: "1px solid #707070",
      },
      attrs: { src: _vm.logo, alt: _vm.name },
    }),
    _c(
      "h5",
      {
        staticStyle: {
          "text-align": "center",
          margin: "10px 0 30px 0",
          color: "#707070",
        },
      },
      [_vm._v(" " + _vm._s(_vm.name) + " ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }