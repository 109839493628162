const Guard = {
    login(to, from, next) {
        if(localStorage.getItem('SET_APP')) {
            next()
        }
        else {
            next('/login')
        }
    }
}

export default Guard