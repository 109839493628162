var render = function render(_c, _vm) {
  return _vm._m(0)
}
var staticRenderFns = [
  function (_c, _vm) {
    return _c("footer", { staticClass: "final-footer" }, [
      _c("img", {
        attrs: { src: require("../assets/logos/mobradio-white.png") },
      }),
      _c("h5", [_vm._v("Fonte de dados: MobRadio Analytics")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }