<template>
    <div class="md-layout">
        <el-dialog
            title="Editar Episódio"
            :visible.sync="dialogEditChannel"
            width="30%">
            
            <el-form :model="form">
                <div class="md-layout">
                    <div class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70">
                        <span>Campos com (*) são obrigatórios.</span>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70">
                        <el-input type="text" v-model="form.title" autocomplete="off" placeholder="Título*"></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70">
                        <el-input type="textarea" v-model="form.description" autocomplete="off" placeholder="Descrição*"></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div style="padding-top:20px;" class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                        <span style="color:#c1c5cd;" ref="msgImg">Capa do podcast (1400x1400 px)</span>
                        <el-upload class="avatar-uploader avatar-gifts" 
                            action="#"
                            accept="image/jpeg,image/png" 
                            :auto-upload="false" 
                            :show-file-list="false"
                            :on-change="getFileImage">

                            <img v-if="form.image" :src="form.image" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <span ref="msgImg"></span>
                    </div>
                </div>
                <br>
                <div class="md-layout">
                    <div style="padding-top:20px;" class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                        <el-upload
                            ref="uploadDirectory"
                            class="upload-demo"
                            accept=".mp3,audio/*"
                            action="#"
                            :limit="1"
                            :multiple="false"
                            :auto-upload="false"
                            :on-change="getAudio">
                            <el-button type="info">Arquivo de áudio</el-button>
                        </el-upload>
                    </div>
                </div>
                <br>
                <div class="md-layout">
                    <div class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70">
                        <el-checkbox type="checkbox" v-model="form.explicit"></el-checkbox> &nbsp; <span style="color:#a2a4a7;">Conteúdo explícito</span>
                    </div>
                </div>
                <br>
                <div class="md-layout" style="color: #a5a7aa;">
                    <div style="border: 1px dotted" class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70">
                        Publicado: {{ this.form.created }} <br>
                        Última atualização: {{ this.updated ? this.updated : '------' }} <br>
                        RSS: http://seu-link-feed-rsss/123456789.xml
                    </div>
                </div>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogEditChannel = false">Cancelar</el-button>
                <el-button type="primary" @click="updateEpisode()" :disabled="propertyBtnConfirm">Confirmar</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { Dialog, Button, Form, Input, Select, Option, Upload, Checkbox, Tabs, TabPane, OptionGroup } from 'element-ui'
import Swal from 'sweetalert2'
import categoryChannel from './category'
import ApiFirebase from '../../../../routes/api-firebase'
import  firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import jwt_decode from "jwt-decode"

export default {
    components: {
        [Dialog.name]: Dialog,
        [Button.name]: Button,
        [Form.name]: Form,
        [Input.name]: Input,
        [Select.name]: Select,
        [Option.name]: Option,
        [Upload.name]: Upload,
        [Checkbox.name]: Checkbox,
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
        [OptionGroup.name]: OptionGroup

    },
    data() {
        return {
            radio: jwt_decode(localStorage.getItem('APP_01')).radio,
            dialogEditChannel: false,
            form: {
                id: '',
                title: '',
                description: '',
                audio: '',
                image: '',
                explicit: false,
                created: ''
            },
            value: [],
            options: categoryChannel,
            uploadedImage: undefined,
            imageUrl: undefined,
            propertyBtnConfirm: false,
            fileImage: null,
            fileAudio: null,
            idChannel: null,
            episodesUpdate: null,
            updated: ''
        }
    },

    methods: {
        openNewEpisode() {
            this.dialogNewPost = true
        },
        getFileImage(file) {
            let vm = this
            let span = this.$refs.msgImg
            let img = new Image;
            this.uploadedImage = file.raw
            this.form.image = URL.createObjectURL(file.raw)
            this.fileImage = file
            img.onload = function() {
                if(img.width < 1400 || img.height < 1400) {
                    span.textContent = "A imagem não atende os requisitos minimos de resolução (1400 x 1400 pixels)."
                    span.style.color = "#ff0000"
                    vm.propertyBtnConfirm = true
                } else {
                    span.textContent = ""
                    vm.propertyBtnConfirm = false
                }
            }
            img.src = this.form.image
        },
        getEdit(idEpisode, idChannel, show) {
            Swal.showLoading()
            ApiFirebase().get(`channelById/${idChannel}`)
                .then((resp) => {
                    this.episodesUpdate = resp.data[0].episodes
                    resp.data[0].episodes.forEach(element => {
                        if(element.id === idEpisode) {
                            this.form.id = element.id,
                            this.form.title = element.title,
                            this.form.description = element.description,
                            this.form.audio = element.audio,
                            this.form.image = element.image,
                            this.form.explicit = element.explicit,
                            this.form.created = element.created
                            this.updated = element.updated
                        }
                    });
                    this.dialogEditChannel = true
                    Swal.close()
                 })
                .catch((err) => { console.log(err )})
        
        },
        getAudio(res, file){
            this.fileAudio = file[0]
        },
        async getEpisodes(idChannel) {
            return await ApiFirebase().get(`channelById/${idChannel}`)
                .then((resp) => {
                    return resp.data[0].episodes
                })
                .catch((err) => { console.log(err )})
        },
        updateEpisode() {
            this.dialogEditChannel = false
            // ATUALIZA SEM UPLOAD
            if(!this.fileImage && !this.fileAudio) {
                let formUpdate = {
                    id: this.form.id,
                    title: this.form.title,
                    description: this.form.description,
                    audio: this.form.audio,
                    image: this.form.image,
                    explicit: this.form.explicit,
                    created: this.form.created
                }
                let index = this.episodesUpdate.findIndex((item) => item.id == this.form.id)
                if (index !== -1) {
                    this.episodesUpdate[index] = formUpdate;
                }
                Swal({
                    title: "Atualizando episódio...",
                    onOpen: () => {
                        Swal.showLoading()
                        ApiFirebase().post("episodeUpdate", {episodes: this.episodesUpdate, idChannel: this.idChannel })
                            .then((resp) => {
                                Swal(
                                    'Pronto',
                                    'Episódio atualizado com sucesso!',
                                    'success'
                                )
                                this.fileImage = null
                                this.fileAudio = null
                                this.$root.$emit("reloadEpisodes")
                            })
                            .catch(() => {
                                Swal(
                                    'Erro',
                                    'Não foi possível atualizar o episódio, por favor, tente novamente.',
                                    'error'
                                )
                            })
                    }
                })
            }
            // ATUALIZA COM UPLOAD DE IMAGEM
            if(this.fileImage && !this.fileAudio) {
                Swal({
                    title: "Atualizando episódio",
                    html: "Aguarde, isso pode levar um tempo...",
                    onOpen: () => {
                        Swal.showLoading()
                        this.uploadFirebase(this.fileImage, "Images").then((uri) => {
                            let formUpdate = {
                                id: this.form.id,
                                title: this.form.title,
                                description: this.form.description,
                                audio: this.form.audio,
                                image: uri,
                                explicit: this.form.explicit,
                                created: this.form.created,
                                updated: new Date().toLocaleString("pt-BR", {
                                    timeZone: 'America/Belem'
                                })
                            }
                            let index = this.episodesUpdate.findIndex((item) => item.id == this.form.id)
                            if (index !== -1) {
                                this.episodesUpdate[index] = formUpdate;
                            }
                            ApiFirebase().post("episodeUpdate", { episodes: this.episodesUpdate, idChannel: this.idChannel })
                                .then((resp) => {
                                    Swal(
                                        'Pronto',
                                        'Episódio atualizado com sucesso!',
                                        'success'
                                    )
                                    this.fileImage = null
                                    this.fileAudio = null
                                    this.$root.$emit("reloadEpisodes")
                                })
                                .catch(() => {
                                    Swal(
                                        'Erro',
                                        'Não foi possível atualizar o episódio, por favor, tente novamente.',
                                        'error'
                                    )
                                })
                        })
                    }
                })
            }
            // ATUALIZA COM UPLOAD DE AUDIO
            if(!this.fileImage && this.fileAudio) {
                Swal({
                    title: "Atualizando episódio",
                    html: "Aguarde, isso pode levar um tempo...",
                    onOpen: () => {
                        Swal.showLoading()
                        this.uploadFirebase(this.fileAudio, "Audio").then((uri) => {
                            let formUpdate = {
                                id: this.form.id,
                                title: this.form.title,
                                description: this.form.description,
                                audio: uri,
                                image: this.form.image,
                                explicit: this.form.explicit,
                                created: this.form.created,
                                updated: new Date().toLocaleString("pt-BR", {
                                    timeZone: 'America/Belem'
                                })
                            }
                            let index = this.episodesUpdate.findIndex((item) => item.id == this.form.id)
                            if (index !== -1) {
                                this.episodesUpdate[index] = formUpdate;
                            }
                            ApiFirebase().post("episodeUpdate", { episodes: this.episodesUpdate, idChannel: this.idChannel })
                                .then((resp) => {
                                    Swal(
                                        'Pronto',
                                        'Episódio atualizado com sucesso!',
                                        'success'
                                    )
                                    this.fileImage = null
                                    this.fileAudio = null
                                    this.$root.$emit("reloadEpisodes")
                                })
                                .catch(() => {
                                    Swal(
                                        'Erro',
                                        'Não foi possível atualizar o episódio, por favor, tente novamente.',
                                        'error'
                                    )
                                })
                        })
                    }
                })
            }
            // ATUALIZA COM UPLOAD DE IMAGEM E AUDIO
            if(this.fileImage && this.fileAudio) {
                let formUpdate = {
                    id: this.form.id,
                    title: this.form.title,
                    description: this.form.description,
                    audio: '',
                    image: '',
                    explicit: this.form.explicit,
                    created: this.form.created,
                    updated: new Date().toLocaleString("pt-BR", {
                        timeZone: 'America/Belem'
                    })
                }
                Swal({
                    title: "Atualizando episódio",
                    html: "Aguarde, isso pode levar um tempo...",
                    onOpen: () => {
                        Swal.showLoading()
                        this.uploadFirebase(this.fileImage, "Images").then((uri) => {
                            formUpdate.image = uri
                                this.uploadFirebase(this.fileAudio, "Audio").then((uri) => {
                                    formUpdate.audio = uri
                                    let index = this.episodesUpdate.findIndex((item) => item.id == this.form.id)
                                    if (index !== -1) {
                                        this.episodesUpdate[index] = formUpdate;
                                    }
                                    ApiFirebase().post("episodeUpdate", { episodes: this.episodesUpdate, idChannel: this.idChannel })
                                        .then((resp) => {
                                            Swal(
                                                'Pronto',
                                                'Episódio atualizado com sucesso!',
                                                'success'
                                            )
                                            this.fileImage = null
                                            this.fileAudio = null
                                            this.$root.$emit("reloadEpisodes")
                                        })
                                        .catch(() => {
                                            Swal(
                                                'Erro',
                                                'Não foi possível atualizar o episódio, por favor, tente novamente.',
                                                'error'
                                            )
                                        })
                                    })
                                })
                    }
                })
            }
        },
        deleteEpisode(ep, idChannel) {
            this.getEpisodes(idChannel)
                .then((episodes) => {
                    let self = this
                    let index = episodes.findIndex((item) => item.id == ep.id)
                    if (index !== -1) {
                        episodes.splice(index, 1);
                    }
                    Swal.fire({
                        title: 'Apagar Episódio',
                        text: "Deseja mesmo fazer isso? O  episódio será excluído e o processo não poderá ser revertido",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: 'Confirmar'
                    })
                    .then((result) => {
                        if (result.value) {
                            Swal.showLoading()
                            ApiFirebase().post("deleteEpisode", { episodes: episodes, idChannel: this.idChannel })
                            .then((resp) => {
                                Swal(
                                    'Pronto',
                                    'Episódio excluído com sucesso!',
                                    'success'
                                )
                                this.$root.$emit("updateListDelete", { id: ep.id })
                            })
                            .catch(() => {
                                Swal(
                                    'Erro',
                                    'Não foi possível excluir o episódio, por favor, tente novamente.',
                                    'error'
                                )
                            })
                    }})
                })
        },
        async uploadFirebase(file, folder) {
            const storage = firebase.storage().ref(`Podcasts/${folder}/`)
            const name = this.radio.master_key + '_' + file.name;
            const metadata = {
                contentType: file.raw.type
            };
            const task = storage.child(name).put(file.raw, metadata)
            return await task
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {
                return url;
            })
            .catch(console.error)
        }
    },
    mounted() {
        let self = this
        self.$root.$on("editEpisode", (payload) => {
            self.getEdit(payload.idEpisode, payload.idChannel, payload.show)
            this.idChannel = payload.idChannel
        })
        self.$root.$on("deleteEpisode", (payload) => {
            self.deleteEpisode(payload.episode, payload.idChannel)
            this.idChannel = payload.idChannel
        })
    }
}
</script>

<style>
    .el-dialog__header {
        font-weight: 400;
    }
    .el-dialog__body {
        font-weight: 400;
    }
    .el-dialog__footer {
        text-align: left;
        padding: 10px 35px 20px;
    }
</style>