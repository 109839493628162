<template>
	<md-card md-with-hover>
		<md-card-header>
			<div
				class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
				style="margin-top: 1rem;"
			>
				<template v-if="this.$props.cover.active">
					<i style="color:#4baf51" class="fas fa-circle"></i>&nbsp;
					<span style="color:#4baf51">ATIVA</span>
				</template>
				<template v-else-if="this.$props.cover.ended">
					<i style="color:#ef6b6c" class="fas fa-circle"></i>&nbsp;
					<span style="color:#ef6b6c">FINALIZADA</span>
				</template>
				<template v-else>
					<i style="color:#c9cacb" class="fas fa-circle"></i>&nbsp;
					<span style="color:#c9cacb">PRÓXIMA</span>
				</template>
			</div>
		</md-card-header>
		<md-card-content>
			<div class="md-layout md-alignment-top-center">
				<div
					class="md-layout-item md-small-size-100 md-medium-size-60 md-large-size-15 md-xlarge-size-15"
				>
					<img class="cover-image" :src="this.$props.cover.image_url" />
				</div>
				<div
					class="md-layout-item md-small-size-100 md-medium-size-60 md-large-size-60 md-xlarge-size-60"
				>
					<h3 style="text-align: left">{{ cover.title }}</h3>
					<h4>{{ cover.subtitle }}</h4>
					<span>{{
						this.formatDate(cover.date_start)
					}}</span>
					&nbsp;
					<i class="fa fa-circle fa-xs" style="color: #707070"></i>
					&nbsp;
					<span>{{
						this.formatDate(cover.date_end)
					}}</span>
				</div>
				<div
					class="md-layout-item md-small-size-100 md-medium-size-35 md-large-size-20 md-xlarge-size-20"
				>
					<md-button class="md-success md-block"
						:disabled="(this.$props.cover.active||this.$props.cover.ended)&&this.user.level!==0"
						@click="openModalToEditing()"
						><i class="fas fa-edit"></i>&nbsp;Editar</md-button
					>
					<p v-if="(this.$props.cover.active||this.$props.cover.ended)&&this.user.level!==0" 
						align="center"
						style="color: #4baf51;"
						>
						Não é possível editar capas {{cover.ended?'finalizadas':'ativas'}}.
					</p>
					<md-button class="md-info md-block"
						@click="showMore()"
						><i class="fas fa-eye"></i>&nbsp;Ver mais</md-button
					>
					<md-button class="md-danger md-block" 
						:disabled="(this.$props.cover.active||this.$props.cover.ended)&&this.user.level!==0"
						@click="confirmDeleteCover()"
						><i class="fas fa-trash"></i>&nbsp;Excluir</md-button
					>
					<p v-if="(this.$props.cover.active||this.$props.cover.ended)&&this.user.level!==0" 
						align="center"
						style="color: #ef6b6c;"
						>
						Não é possível excluir capas {{cover.ended?'finalizadas':'ativas'}}.
					</p>
				</div>
			</div>
		</md-card-content>
	</md-card>
</template>

<script>
import Swal from "sweetalert2";
import jwt_decode from 'jwt-decode'
import models from '../../../routes/models'

export default {
	name: "CardCampaign",
	props: ["cover"],
	data() {
		return {
			user: jwt_decode(localStorage.getItem('SET_APP')).user[0],
			dialogEditForm: false
		};
	},
	methods: {
		formatDate(dateString){
			const dateObject = new Date(Date.parse(dateString))
			
			return dateObject.toLocaleDateString("pt-BR") +' às '+ dateObject.getHours().toString().padStart(2,'0')+':'+dateObject.getMinutes().toString().padStart(2,'0')
		},
		openModalToEditing() {
			this.$root.$emit("openCoverFormModalEditing", this.$props.cover);
		},
		confirmDeleteCover() {
			Swal.fire({
				title: "Deseja mesmo fazer isso?",
				text:
					"Você está prestes a cancelar o cadastro de uma capa especial e perderá todas as informações. Tem certeza que deseja continuar?",
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				cancelButtonText: "Cancelar",
				confirmButtonText: "Confirmar"
			}).then(result => {
				if (result.value) {
					this.deleteCover();
				}
			});
		},

		deleteCover() {
			Swal.fire({
				allowOutsideClick: false,
				title: "Deletando Capa...",
				onOpen: () => {
					Swal.showLoading();
					models().delete('cover/removeSpecialCover', {
						data: { id: this.$props.cover.id }
					}).then((async ()=>{
						await Swal("Pronto", "Capa deletada com sucesso!", "success");
						this.$root.$emit("getAllSpecialCovers");
					})).catch(async err=>{
						console.log(err)
						await Swal("Ops!", "Houve algum erro ao tentar deletar sua capa. Caso persista, entre em contato com o suporte.", "error");
						this.$root.$emit("getAllSpecialCovers");
					})
					
				}
			});
			
		},

		showMore(){
			Swal.fire({
				title: "Visualização de banner",
				html: `
					<h3>${this.$props.cover.title}</h3>
					<h5>${this.$props.cover.subtitle}</h5>
					<h6>De ${this.formatDate(this.$props.cover.date_start)} até ${this.formatDate(this.$props.cover.date_end)}</h6>
					<div style="background: #e3e3e3">
						<img src=${this.$props.cover.image_url} width=300>
					</div>
				    `,
				showCloseButton: true
			});
		}
	}
};
</script>

<style>
.cover-image {
	max-width: 200px;
}
</style>
