var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.dataChannels, function (channel, key) {
        return _c("div", { key: key, staticClass: "row-channel" }, [
          _c(
            "div",
            {
              staticClass: "row-child",
              staticStyle: {
                width: "17%",
                display: "flex",
                "justify-content": "center",
              },
            },
            [
              _c("img", {
                staticStyle: { width: "200px", "border-radius": "10px" },
                attrs: { src: channel.image, alt: "" },
              }),
            ]
          ),
          _c(
            "div",
            { staticClass: "row-child", staticStyle: { width: "50%" } },
            [
              _c(
                "h4",
                { staticStyle: { "font-weight": "400", margin: "auto" } },
                [_vm._v(" " + _vm._s(channel.title) + " ")]
              ),
              _c("br"),
              _vm._v(" " + _vm._s(channel.episodes.length) + " Episódio(s) "),
              _c("br"),
              _c(
                "md-button",
                {
                  staticClass: "md-success md-sm",
                  attrs: { value: channel.id },
                  on: {
                    click: function ($event) {
                      return _vm.copyToClipBoard(channel.id)
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-rss" }), _vm._v(" Feed RSS ")]
              ),
              _vm._v("  "),
              _c(
                "md-button",
                {
                  staticClass: "md-warning md-sm",
                  on: {
                    click: function ($event) {
                      return _vm.editChannel(channel.id)
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-edit" }), _vm._v(" Editar ")]
              ),
              _vm._v("  "),
              _c(
                "md-button",
                {
                  staticClass: "md-danger md-sm",
                  on: {
                    click: function ($event) {
                      return _vm.deleteChannels(channel.id)
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-trash" }), _vm._v(" Excluir ")]
              ),
              _c("br"),
              _c("div", { staticStyle: { "margin-top": "25px" } }, [
                _c(
                  "span",
                  {
                    staticClass: "pub-date",
                    staticStyle: {
                      "margin-top": "50px",
                      "font-style": "italic",
                    },
                  },
                  [
                    _vm._v(
                      "Criado em: " +
                        _vm._s(
                          new Date(channel.created).toLocaleString("pt-BR", {
                            timeZone: "America/Belem",
                          })
                        )
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ])
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.dataChannels.length <= 0,
              expression: "this.dataChannels.length <= 0",
            },
          ],
          staticClass: "row-channel",
        },
        [_c("span", [_vm._v("Nenhum canal foi cadastrado.")])]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }