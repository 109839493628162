<template>
    <Map />
</template>

<script>
import Map from "./Map";
export default {
    components: {
        Map
    }
};
</script>

<style lang="css"></style>
