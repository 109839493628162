var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-card",
    { attrs: { "md-with-hover": "" } },
    [
      _c("md-card-header", [
        _c(
          "div",
          {
            staticClass:
              "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
            staticStyle: { "margin-top": "1rem" },
          },
          [
            this.$props.cover.active
              ? [
                  _c("i", {
                    staticClass: "fas fa-circle",
                    staticStyle: { color: "#4baf51" },
                  }),
                  _vm._v("  "),
                  _c("span", { staticStyle: { color: "#4baf51" } }, [
                    _vm._v("ATIVA"),
                  ]),
                ]
              : this.$props.cover.ended
              ? [
                  _c("i", {
                    staticClass: "fas fa-circle",
                    staticStyle: { color: "#ef6b6c" },
                  }),
                  _vm._v("  "),
                  _c("span", { staticStyle: { color: "#ef6b6c" } }, [
                    _vm._v("FINALIZADA"),
                  ]),
                ]
              : [
                  _c("i", {
                    staticClass: "fas fa-circle",
                    staticStyle: { color: "#c9cacb" },
                  }),
                  _vm._v("  "),
                  _c("span", { staticStyle: { color: "#c9cacb" } }, [
                    _vm._v("PRÓXIMA"),
                  ]),
                ],
          ],
          2
        ),
      ]),
      _c("md-card-content", [
        _c("div", { staticClass: "md-layout md-alignment-top-center" }, [
          _c(
            "div",
            {
              staticClass:
                "md-layout-item md-small-size-100 md-medium-size-60 md-large-size-15 md-xlarge-size-15",
            },
            [
              _c("img", {
                staticClass: "cover-image",
                attrs: { src: this.$props.cover.image_url },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "md-layout-item md-small-size-100 md-medium-size-60 md-large-size-60 md-xlarge-size-60",
            },
            [
              _c("h3", { staticStyle: { "text-align": "left" } }, [
                _vm._v(_vm._s(_vm.cover.title)),
              ]),
              _c("h4", [_vm._v(_vm._s(_vm.cover.subtitle))]),
              _c("span", [
                _vm._v(_vm._s(this.formatDate(_vm.cover.date_start))),
              ]),
              _vm._v("   "),
              _c("i", {
                staticClass: "fa fa-circle fa-xs",
                staticStyle: { color: "#707070" },
              }),
              _vm._v("   "),
              _c("span", [_vm._v(_vm._s(this.formatDate(_vm.cover.date_end)))]),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "md-layout-item md-small-size-100 md-medium-size-35 md-large-size-20 md-xlarge-size-20",
            },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-success md-block",
                  attrs: {
                    disabled:
                      (this.$props.cover.active || this.$props.cover.ended) &&
                      this.user.level !== 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openModalToEditing()
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-edit" }), _vm._v(" Editar")]
              ),
              (this.$props.cover.active || this.$props.cover.ended) &&
              this.user.level !== 0
                ? _c(
                    "p",
                    {
                      staticStyle: { color: "#4baf51" },
                      attrs: { align: "center" },
                    },
                    [
                      _vm._v(
                        " Não é possível editar capas " +
                          _vm._s(_vm.cover.ended ? "finalizadas" : "ativas") +
                          ". "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "md-button",
                {
                  staticClass: "md-info md-block",
                  on: {
                    click: function ($event) {
                      return _vm.showMore()
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-eye" }), _vm._v(" Ver mais")]
              ),
              _c(
                "md-button",
                {
                  staticClass: "md-danger md-block",
                  attrs: {
                    disabled:
                      (this.$props.cover.active || this.$props.cover.ended) &&
                      this.user.level !== 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.confirmDeleteCover()
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-trash" }), _vm._v(" Excluir")]
              ),
              (this.$props.cover.active || this.$props.cover.ended) &&
              this.user.level !== 0
                ? _c(
                    "p",
                    {
                      staticStyle: { color: "#ef6b6c" },
                      attrs: { align: "center" },
                    },
                    [
                      _vm._v(
                        " Não é possível excluir capas " +
                          _vm._s(_vm.cover.ended ? "finalizadas" : "ativas") +
                          ". "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }