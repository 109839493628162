<template>
	<div class="report-layout" align="center">
		<div class="page">
			<HeaderReport
				:RadioName="radioName" 
				:RadioLogo="radioLogo"
			/>
			<div class="md-layout" style="padding: 10% 0 0 0; width: 100%;">
                <div class="md-layout-item md-size-95" style="margin: 1rem auto;">
					<div class="md-layout md-alignment-center-space-between">
						<div class="md-layout-item md-size-40">
							<h1 class="report-main-title">{{ reportName }}</h1>
							<h5 class="report-main-title">{{ sublabel }}</h5>
						</div>
						<div class="report-period-container md-layout-item md-size-40">
							<h5 class="report-main-title">Período de apuração</h5>
							<h3 class="period">{{periodStart}} a {{periodEnd}}</h3>
						</div>
					</div>
				</div>
				<div class="md-layout-item md-size-95" style="margin: 0 auto;">
                    <div class="md-layout" style="margin: 0 auto;">
				        <slot name="page-1"></slot>
                    </div>
                </div>
			</div>
		</div>
        <div class="page" v-for="pageNumber in (numberOfPages-1)" :key="pageNumber">
            <header class="second-page-header">
                <h3>{{ reportName }} {{sublabel? " - "+sublabel : "" }}</h3>
                <h3>{{ periodStart }} a {{ periodEnd }}</h3>
            </header>
            <div class="md-layout-item md-size-95" style="margin: 0 auto;">
                <div class="md-layout">
                    <slot :name="`page-${pageNumber+1}`"></slot>
                </div>
            </div>
            <FooterReport v-if="pageNumber + 1 === numberOfPages"/>
        </div>
	</div>
</template>

<script>
import HeaderReport from "./HeaderReport.vue"
import FooterReport from "./FooterReport.vue"

export default {
	name: "TemplateReport",
	components: {
		HeaderReport,
		FooterReport
	},
	props: [
        "radioName", 
        "radioLogo",
        "reportName",
        "sublabel",
        "periodStart", 
        "periodEnd", 
        "numberOfPages"
    ]
};
</script>

<style scoped>
@page {
	size: auto;
	margin: 0 0 0 0;
}

@media print{
	.page {
		width: 210mm;
		height: 296mm;
	}

	.report-layout {
		width: 210mm;
	}
}

.report-main-title {
	margin: 0; 
	text-align: left; 
	font-weight: bold;
}

.report-period-container *{
	text-align: right;
}

.report-period-container .period {
	font-style: italic;
	margin: 0;
	padding: 0;
}

.platform-label {
	text-align: left;
	margin-right: 20px;
	display: inline-block;
}

.platform-label img {
	height: 15px;
	width: 15px;
	margin-right: 5px;
	margin-top: -5px;
}

.platform-label span {
	font-size: 100%;
	white-space: nowrap;
}

.report-layout td {
	text-align: center;
}

.report-layout {
	print-color-adjust: exact !important;
	-webkit-print-color-adjust: exact !important;
	background-color: #ffffff;
}

.page {
	position: relative;
}

.report-layout * {
	color: #707070;
	font-family: "Roboto" !important;
}

.second-page-header{
	width: 100%;
	height: 5rem;
	padding-top: 0.5rem;
	margin-bottom: 2rem;
	background: linear-gradient(90deg, #485ef3, #49b6ea);
}
.second-page-header h3{
	color: #FFF;
	font-weight: bold;
	margin: 0;
}
.report-table-second-page {
	margin: auto;
	background: #fff;
	width: 90%;
	padding: 16px;
	padding-top: 30px;
	border-radius: 16px;
	border: 1px solid #009ddb;
}

.report-table-second-page .title-table-second-page {
	margin: 0;
	padding: 0;
	color: #495161;
	margin-bottom: 30px;
}

.report-table-second-page  h4 {
	font-weight: bold;
	font-size: 22px;
}
.report-table-second-page  * {
	color: #495161;
}
.report-table-second-page  img {
	width: 16px;
}

.report-table-second-page  table {
	border-collapse: collapse;
	width: 100%;
	margin-bottom: 1rem;
}


.report-table-second-page th {
	text-align: center;
	font-size: 14px;
	padding-bottom: 16px;
}

.report-table-second-page td {
	border-bottom: 1px solid #e0e0e0;
	text-align: center;
	font-size: 12px;
	padding: 10px 0;
}

.report-table-second-page table tr:last-child td {
    border-bottom: 1px solid #FFFFFF;
}

.first-col {
	font-size: 13px;
	font-weight: bold;
}
</style>