<template>
    <el-dialog :visible.sync="dialogVisible" :title="this.editingMode?'Editar capa especial': 'Nova capa especial'" :close-on-press-escape="false"
        :close-on-click-modal="false" :before-close="showAlertToCancelNewCover">
        <div class="md-layout">
            <div class="md-layout-item md-size-100">
                <el-input placeholder="Nome da programação" v-model="title"></el-input>
            </div>
            <div class="md-layout-item md-size-100">
                <el-input placeholder="Subtítulo (Opcional)" v-model="subtitle"></el-input>
            </div>
            <div class="md-layout-item md-size-100">
                <div class="md-layout">
                    <div class="md-layout-item md-size-50 md-small-size-100" style="padding-left: 0;">
                        <el-date-picker v-model="startDate"
                            type="date" placeholder="Data de início"
                            style="width:100% !important" format="dd/MM/yyyy"
                            :picker-options="{ disabledDate: (date) => date < (new Date()).setDate((new Date()).getDate()-1) }">
                        ></el-date-picker>
                    </div>
                    <div class="md-layout-item md-size-50 md-small-size-100" style="padding-right: 0;">
                        <el-date-picker v-model="endDate" 
                            type="date" placeholder="Data final" style="width:100% !important"
                            format="dd/MM/yyyy"
                            :picker-options="{ disabledDate: (date) => date < (new Date()).setDate((new Date()).getDate()-1) }"
                            :disabled="!this.startDate">
                            ></el-date-picker>
                    </div>
                </div>
            </div>
            <div class="md-layout-item md-size-100">
                <div class="md-layout">
                    <div class="md-layout-item md-size-50 md-small-size-100" style="padding-left: 0;">
                        <el-time-picker v-model="startTime" style="width: 100% !important;"
                            format="HH:mm"
                            :disabled="!this.startDate"
                            :picker-options="startTimeRange" placeholder="Hora inicial">
                        </el-time-picker>
                    </div>
                    <div class="md-layout-item md-size-50 md-small-size-100" style="padding-right: 0;">
                        <el-time-picker v-model="endTime" style="width: 100% !important;" 
                            format="HH:mm"
                            :disabled="!this.endDate"
                            :picker-options="{
                                selectableRange: `00:00:00 - 23:59:59`
                            }" placeholder="Hora final">
                        </el-time-picker>
                    </div>
                    <p v-if="dateInvalid" style="color: #FF0000;">
                        A data e a hora de término deverá ser posterior à data e a hora de início
                    </p>
                </div>
            </div>
            <div class="md-layout-item md-size-100">
                <el-input placeholder="URL da imagem" v-model="imageURL"></el-input>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <div class="md-layout md-alignment-center-right">
                <div class="md-layout-item md-size-20 md-small-size-100">
                    <md-button class="md-danger md-block" @click="showAlertToCancelNewCover()">Cancelar</md-button>
                </div>
                <div class="md-layout-item md-size-20 md-small-size-100">
                    <md-button class="md-info md-block" 
                        v-if="this.editingMode"
                        @click="editCover()"
                        :disabled="this.checkIfFieldsAreEmpty()">Editar Capa</md-button>
                    <md-button class="md-success md-block" 
                        v-else
                        @click="createNewCover()"
                        :disabled="this.checkIfFieldsAreEmpty()">Criar Capa</md-button>
                </div>
            </div>
        </span>
    </el-dialog>
</template>

<script>
import Swal from 'sweetalert2'
import models from '../../../routes/models'
import jwt_decode from 'jwt-decode'

export default {
    props: ['cover'],
    data() {
        return {
            dialogVisible: false,
            editingMode: false,
            title: '',
            coverId: '',
            subtitle: '',
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
            imageURL: '',
            dateInvalid: false
        }
    },
    computed: {
        startTimeRange() {
            const currentDate = new Date();
            const selectedDate = new Date(this.startDate);
            
            if (selectedDate.toDateString() === currentDate.toDateString()) {
      
            const currentHour = currentDate.getHours();
            const currentMinute = currentDate.getMinutes();
            const currentSecond = currentDate.getSeconds();
            
            const startTime = `${currentHour}:${currentMinute}:${currentSecond}`;
            
            return {
                selectableRange: `${startTime} - 23:59:59`
            };
            } else {
            return {
                selectableRange: `00:00:00 - 23:59:59`
            };
            }
        }
    },
    methods: {
        openModal() {
            this.dialogVisible = true
        },
        setDefaultValues(cover){
            const startDate = new Date(cover.date_start)
            const endDate = new Date(cover.date_end)
            const startTime = new Date(cover.date_start)
            const endTime = new Date(cover.date_end)
            startDate.setHours(0,0,0)
            endDate.setHours(0,0,0)
            this.coverId = cover.id
            this.title = cover.title
            this.subtitle = cover.subtitle
            this.startTime = startTime
            this.endTime = endTime
            this.startDate = startDate
            this.endDate = endDate
            this.imageURL = cover.image_url
        },

        checkIfFieldsAreEmpty() {
            return !this.title || !this.startDate || !this.endDate || !this.startTime || !this.endTime || !this.imageURL
        },
        checkIfAllFieldsAreEmpty(){
            return !this.title && !this.startDate && !this.endDate && !this.startTime && !this.endTime && !this.imageURL
        },

        checkIfDateIsValid() {
            const startDate = this.startDate
            const endDate = this.endDate

            startDate.setHours(0,0,0)
            endDate.setHours(0,0,0)

            const startTime = this.startTime.getHours() * 3600 + this.startTime.getMinutes()*60;
            const endTime = this.endTime.getHours() * 3600 + this.endTime.getMinutes() * 60;

            return (startDate.getTime() < endDate.getTime()) || (startDate.getTime() === endDate.getTime() && startTime <= endTime)
        },

        showAlertToCancelNewCover() { 
            if(this.checkIfAllFieldsAreEmpty()){
                this.clearForm();
                this.dialogVisible = false;
                return;
            }

            Swal.fire({
                title: 'Deseja mesmo fazer isso?',
                text: `Você está prestes a cancelar ${this.editingMode?'a edição': 'o cadastro'} de uma capa especial e perderá todas as informações. Tem certeza que deseja continuar?`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar'

            }).then(result=>{
                if(!result.value){
                    return    
                }
                this.clearForm()
                this.dialogVisible = false
            })
        },

        formatDateToMYSQL(date, hour) {
            const dateObject = new Date(date)
            const hourObject = new Date(hour)

            let dateFormated = dateObject.getFullYear() + '-' + (dateObject.getMonth() + 1) + '-' + dateObject.getDate() 
            let hourFormated = `${hourObject.getHours().toString().padStart(2, '0')}:${hourObject.getMinutes().toString().padStart(2, '0')}`

            return dateFormated +' '+ hourFormated
        },

        clearForm() {
            this.dialogVisible = false
            this.title = ''
            this.subtitle = ''
            this.startDate = new Date()
            this.endDate = ''
            this.startTime = ''
            this.endTime = ''
            this.imageURL = ''
            this.dateInvalid = false
        },
        createNewCover() {
            if (!this.checkIfDateIsValid()) {
                this.dateInvalid = true;
                return;
            }

            const appKey = jwt_decode(localStorage.getItem("APP_01")).radio.key;

            const cover = {
                app_key: appKey,
                title: this.title,
                subtitle: this.subtitle,
                date_start: this.formatDateToMYSQL(this.startDate, this.startTime),
                date_end: this.formatDateToMYSQL(this.endDate, this.endTime),
                image_url: this.imageURL
            }

            Swal.showLoading()
            models().post('cover/createSpecialCover', cover).then(()=>{
                Swal.fire({
                    title: 'Pronto!',
                    text: "Capa especial criada com sucesso.",
                    type: 'success',
                }).then(()=>{
                    Swal.close()
                    this.clearForm()
                    this.dialogVisible = false
                    this.$root.$emit('getAllSpecialCovers')     
                })
            }).catch(err=>{
                if(err.response.data.sqlMessage){
                    Swal.fire({
                    title: 'Ops!',
                    text: "Existe alguma capa especial já programada para o período informado.",
                    type: 'error',
                    }).then(()=>{
                        Swal.close()
                    })
                }else{
                    Swal.fire({
                        title: 'Ops!',
                        text: "Houve algum erro ao tentar cadastrar sua capa especial. Caso persista, entre em contato com o suporte.",
                        type: 'error',
                    }).then(()=>{
                        Swal.close()
                        this.clearForm()
                        this.dialogVisible = false
                        this.$root.$emit('getAllSpecialCovers')
                    })
                }
            })
        },
        editCover(){
            if (!this.checkIfDateIsValid()) {
                this.dateInvalid = true;
                return;
            }
            const cover = {
                id: this.coverId,
                title: this.title,
                subtitle: this.subtitle,
                date_start: this.formatDateToMYSQL(this.startDate, this.startTime),
                date_end: this.formatDateToMYSQL(this.endDate, this.endTime),
                image_url: this.imageURL
            }
            models().put('cover/updateSpecialCover', cover).then(()=>{
                Swal.fire({
                    title: 'Pronto!',
                    text: `Capa especial ${this.editingMode?'editada':'criada'} com sucesso.`,
                    type: 'success',
                }).then(()=>{
                    Swal.close()
                    this.dialogVisible = false
                    this.clearForm()
                    this.$root.$emit('getAllSpecialCovers')    
                })
            }).catch(err=>{
                console.log(err)
                Swal.fire({
                    title: 'Ops!',
                    text: "Houve algum erro ao tentar cadastrar sua capa especial. Caso persista, entre em contato com o suporte.",
                    type: 'error',
                }).then(()=>{
                    Swal.close()
                    this.dialogVisible = false
                    this.clearForm()
                    this.$root.$emit('getAllSpecialCovers')
                })
            })
            
        }
    },
    mounted() {
        this.$root.$on('openCoverFormModal', () => {
            this.editingMode = false
            this.openModal()
        })

        this.$root.$on('openCoverFormModalEditing', (payload) => {
            this.editingMode = true
            this.setDefaultValues(payload)
            this.openModal()
        })
    }
}
</script>

<style></style>