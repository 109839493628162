var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "full-page",
      class: { "nav-open": _vm.$sidebar.showSidebar },
    },
    [
      _c(
        "md-toolbar",
        {
          staticClass: "md-transparent md-toolbar-absolute",
          attrs: { "md-elevation": "0" },
        },
        [
          _c("div", { staticClass: "md-toolbar-row md-offset" }, [
            _c("div", { staticClass: "md-toolbar-section-start" }),
            _c(
              "div",
              { staticClass: "md-toolbar-section-end" },
              [
                _c(
                  "md-button",
                  {
                    staticClass:
                      "md-just-icon md-simple md-round md-toolbar-toggle",
                    class: { toggled: _vm.$sidebar.showSidebar },
                    on: { click: _vm.toggleSidebar },
                  },
                  [
                    _c("span", { staticClass: "icon-bar" }),
                    _c("span", { staticClass: "icon-bar" }),
                    _c("span", { staticClass: "icon-bar" }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-collapse",
                    class: { "off-canvas-sidebar": _vm.responsive },
                  },
                  [
                    _c(
                      "md-list",
                      [
                        _vm.$route.name !== "Login"
                          ? _c(
                              "md-list-item",
                              {
                                attrs: { to: "/login" },
                                on: { click: _vm.linkClick },
                              },
                              [
                                _c("md-icon", [_vm._v("lock_open")]),
                                _vm._v(" Login "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "wrapper wrapper-full-page",
          on: { click: _vm.toggleSidebarPage },
        },
        [
          _c(
            "div",
            {
              staticClass: "page-header header-filter",
              class: _vm.setPageClass,
              style: _vm.setBgImage,
              attrs: { "filter-color": "black" },
            },
            [
              _c(
                "div",
                { staticClass: "container md-offset" },
                [
                  _c(
                    "zoom-center-transition",
                    {
                      attrs: {
                        duration: _vm.pageTransitionDuration,
                        mode: "out-in",
                      },
                    },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
              _vm._m(0),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("footer", { staticClass: "footer" }, [
      _c("div", { staticClass: "container md-offset" }, [
        _c("div", { staticClass: "copyright text-center" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }