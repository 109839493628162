import Vue from "vue";
import VueRouter from "vue-router";
import DashboardPlugin from "./material-dashboard";
import VueSession, { key } from "vue-session";
// Plugins
import App from "./App.vue";
import Chartist from "chartist";
import vueTimePicker from '@dmuy/timepicker/vue-timepicker'
import Autocomplete from "v-autocomplete";
import "v-autocomplete/dist/v-autocomplete.css";
import VueAnalytics from "vue-analytics";
import VueGeolocation from "vue-browser-geolocation";

import ElementUI from "element-ui";
import locale from 'element-ui/lib/locale/lang/pt-br'
import numeral from 'numeral'
import mixpanelAdapter from "./utils/events/mixpanelAdapter";

// router setup
import routes from "./routes/routes";

//setting timezone
import moment from 'moment-timezone'
moment.tz.setDefault('America/Belem')

// plugin setup
Vue.use(VueRouter);
Vue.use(ElementUI, {locale})
Vue.use(DashboardPlugin);
Vue.use("vue-moment");
Vue.use(VueSession);
Vue.use(Autocomplete);
Vue.use(VueGeolocation);
Vue.use(vueTimePicker, { is24hour: true, format:'hh:mm', clearBtn: true })

Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
  });

const router = new VueRouter({
	mode: "history",
	routes, // short for routes: routes
	linkExactActiveClass: "nav-item active",
});

router.beforeEach((to, from, next)=>{
	mixpanelAdapter.track(`page_view`, {
		screen_name: to.name,
		previous_screen: from.name
	});
	next()
})

Vue.use(VueAnalytics, {
    id: "UA-137998466-2",
    router
});

// global library setup
Object.defineProperty(Vue.prototype, "$Chartist", {
    get() {
        return this.$root.Chartist;
    }
});

/* eslint-disable no-new */
new Vue({
    el: "#app",
    render: h => h(App),
    router,
    data: {
        Chartist: Chartist
    }
});
