import axios from 'axios'

//http://localhost:3014/brokes
//https://server.mobradio.com.br/brokers

function clearAllCache(id, token, service) {
    let formData = {
        token: id ? id : token,
        service: service
    }
    
    axios.post('https://server.mobradio.com.br/brokers/delete-cache', formData)
    .then((resp) => {
        return resp.data;
    })
    .catch((error) => {
        console.log(`error clear cache service`);
        return error;
    })
}

export default clearAllCache;