<template>
	<div class="md-layout">
		<div
			v-show="blockStatus == 1"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 print-hide"
		>
			<FilterSelector
				@searchData="getArtistCollection()"
				:buttonsToDisplay="[
					buttonEnum.TODAY,
					buttonEnum.SEVEN_DAYS,
					buttonEnum.MONTH_TO_DATE,
					buttonEnum.CUSTOM,
				]"
				:extraFunction="true"
				@extraFunction="print()"
				:extraFunctionLabel="'IMPRIMIR'"
				:disableExtraFunction="tableData.length === 0"
				:disableSearch="this.artistsSelected.length === 0"
			>
				<template #filters>
					<div class="md-layout-item md-size-100" style="padding: 0">
						<h3
							class="md-layout-item md-size-100"
							style="
								font-size: 1rem;
								text-align: left;
								padding: 0;
								font-weight: 100;
							"
						>
							Artista
						</h3>
					</div>
					<div
						class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-30 md-large-size-20 md-xlarge-size-20"
						style="padding: 0"
					>
						<md-button @click="openDialogSelectArtist()" class="md-success">
							<i class="fa fa-plus"></i>&nbsp; Selecionar artistas
						</md-button>
					</div>
					<div
						class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-70 md-large-size-75 md-xlarge-size-75"
					>
						<md-chips
							class="md-primary"
							v-model="chips"
							id="input-chips"
							@md-click="deleteHandler"
						></md-chips>
					</div>
				</template>
			</FilterSelector>
		</div>

		<block v-show="blockStatus == 0"></block>
		<div
			v-show="loading"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 print-hide"
		>
			<Spinner />
		</div>
		<div
			v-show="!loading && blockStatus == 1"
			class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100 print-hide"
		>
			<md-card
				style="z-index: 0"
				v-if="tableData.length > 0"
				class="print-hide"
			>
				<md-card-header class="md-card-header-icon md-card-header">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						class="paginated-table table-striped table-hover"
					>
						<md-table-toolbar>
							<md-field>
								<label for="pages">Por página</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option
										v-for="item in pagination.perPageOptions"
										:key="item"
										:label="item"
										:value="item"
									>
										{{ item }}
									</md-option>
								</md-select>
							</md-field>
						</md-table-toolbar>

						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell md-label="Artista">{{
								item.artist
							}}</md-table-cell>
							<md-table-cell md-label="Música">{{ item.title }}</md-table-cell>
							<md-table-cell md-label="Execução">{{
								item.total_played
							}}</md-table-cell>
							<md-table-cell md-label="Pedidos">{{
								item.total_req ? item.total_req : 0
							}}</md-table-cell>
							<md-table-cell md-label="Curti">{{ item.likes }}</md-table-cell>
							<md-table-cell md-label="Não Curti">{{
								item.dislikes
							}}</md-table-cell>
							<md-table-cell md-label="Total" md-sort-by="total">{{
								item.total
							}}</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">
							{{ from + 1 }} à {{ to }} de {{ total }} itens
						</p>
					</div>
					<pagination
						class="pagination-no-border pagination-success"
						v-model="pagination.currentPage"
						:per-page="pagination.perPage"
						:total="total"
					>
					</pagination>
				</md-card-actions>
			</md-card>

			<md-card
				style="z-index: 0; text-align: center"
				v-show="showError && !loading"
			>
				<md-card-content>
					<label for="">Sem informações pra serem exibidas.</label>
				</md-card-content>
			</md-card>
		</div>
		<!-- PRINT PAGE-->
		<div class="md-layout print">
			<div>
				<img src="./../../../public/img/logo-mobradio-black.png" alt="" />
				<h1>Extrato por artista</h1>
			</div>
			<div class="titleRight">
				<b class="nameRadio">{{
					RadioLocalStorage.name ? RadioLocalStorage.name : "MobRadio"
				}}</b>
				&nbsp;
				<img class="logoRadio" :src="RadioLocalStorage.logo" alt="" />
			</div>
			<div>
				<span>Período: {{ periodStart }} até: {{ periodEnd }} </span>
			</div>

			<div v-if="this.tableData.length > 0" class="contentBody">
				<div class="divTable">
					<div
						class="md-content md-table md-theme-default"
						table-header-color="blue"
					>
						<div
							class="md-content md-table-content md-scrollbar md-theme-default"
						>
							<table>
								<thead>
									<tr>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Artista</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Música</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Execução</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Pedidos</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Curti</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Não Curti</div>
											</div>
										</th>
										<th class="md-table-head">
											<div
												class="md-table-head-container md-ripple md-disabled"
											>
												<div class="md-table-head-label">Total</div>
											</div>
										</th>
									</tr>
								</thead>
								<tfoot class="report-footer">
									<tr>
										<td colspan="6">
											Fonte de dados: MobRadio Analytics &nbsp;
											<img
												class="footerIcon"
												src="../../../public/img/favicon.png"
											/>
										</td>
									</tr>
								</tfoot>
								<tbody>
									<tr
										class="md-table-row"
										v-for="(item, index) in tableData"
										:key="index"
									>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.artist }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.title }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.total_played }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.total_req ? item.total_req : 0 }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.likes }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.dislikes }}
											</div>
										</td>
										<td class="md-table-cell">
											<div class="md-table-cell-container">
												{{ item.total }}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="Seleção de artistas" :visible.sync="dialogSelectArtist">
			<div class="md-layout">
				<div
					class="md-layout-item md-xsmall-size-100 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
					style="padding: 0; margin-bottom: 10px"
				>
					<span>Artista</span>
					<el-input
						aria-placeholder="Busque pelo artista"
						v-model="searchQuery"
					></el-input>
				</div>
				<div
					class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-30 md-xlarge-size-30"
					style="margin-top: 10px; padding-right: 0"
				>
					<md-button class="md-success" @click="updateItems()">
						Buscar
					</md-button>
				</div>
				<template v-if="loadingArtists">
					<div
						class="md-layout-item md-size-100"
						style="margin: 10px 0; padding: 0"
					>
						<Spinner />
					</div>
				</template>
				<template v-else-if="messageSearchArtists">
					<div class="md-layout-item md-size-100" style="padding: 0">
						<h4>{{ messageSearchArtists }}</h4>
					</div>
				</template>
				<template v-else>
					<div
						class="md-layout-item md-size-100"
						v-if="artistsSelected.length !== 0"
						style="margin: 10px 0; padding: 0"
					>
						<h5 style="margin: 0">Artistas selecionados</h5>
						<el-checkbox-group v-model="artistsSelected">
							<div class="md-layout" style="padding: 0; margin: 0">
								<template v-for="artist in artistsSelected">
									<div
										class="md-layout-item md-xsmall-size-100 md-medium-size-50 md-large-size-25 md-xlarge-size-25"
										style="padding: 0; margin: 0"
									>
										<el-checkbox
											:label="artist"
											class="checkbox-breakline"
										></el-checkbox>
									</div>
								</template>
							</div>
						</el-checkbox-group>
					</div>
					<div class="md-layout-item md-size-100" style="padding: 0; margin: 0">
						<el-checkbox-group v-model="artistsSelected">
							<div class="md-layout" style="padding: 0; margin: 0">
								<template v-for="item in artistsSearched">
									<div
										class="md-layout-item md-xsmall-size-100 md-medium-size-50 md-large-size-25 md-xlarge-size-25"
										style="padding: 0; margin: 0"
									>
										<el-checkbox
											:label="item.artist"
											:value="item"
											class="checkbox-breakline"
										></el-checkbox>
									</div>
								</template>
							</div>
						</el-checkbox-group>
					</div>
				</template>
				<div class="md-layout-item md-size-100" style="padding: 0; margin: 0">
					<div class="md-layout md-alignment-center-right">
						<md-button
							@click="closeDialogSelectArtist()"
							class="md-success md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-20 md-xlarge-size-20"
							style="margin-right: 10px !important"
						>
							Confirmar
						</md-button>
						<md-button
							@click="cancelArtistSelection()"
							class="md-danger md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-20 md-xlarge-size-20"
						>
							Cancelar
						</md-button>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import models from "../../routes/models.js";
import selectRadio from "../../utils/events/select-radio.js";
import Cabecalho from "./Reports/Cabecalho.vue";
import { Pagination } from "@/components";
import block from "./Block.vue";
import ItemTemplate from "../../components/template-autocomplete/extract-artist.vue";
import Spinner from "../../components/Icons/Spinner.vue";
import FilterSelector from "./Components/FilterSelector/FilterSelector.vue";
import filters from "./Components/FilterSelector/filters";
import buttonEnum from "./Components/FilterSelector/buttonEnum";
import jwt_decode from "jwt-decode";
import { DatePicker, Checkbox, CheckboxGroup } from "element-ui";
import mixpanelAdapter from "../../utils/events/mixpanelAdapter";

export default {
	components: {
		Pagination,
		block,
		Cabecalho,
		[DatePicker.name]: DatePicker,
		[Checkbox.name]: Checkbox,
		[CheckboxGroup.name]: CheckboxGroup,
		FilterSelector,
		Spinner,
	},
	data() {
		return {
			buttonEnum: buttonEnum,
			appKey: jwt_decode(localStorage.getItem("APP_01")).radio.key,
			dialogSelectArtist: false,
			selectedEmployee: null,
			search: "",
			time1: null,
			time2: null,
			artists: [],
			artistsSearched: [],
			artistsSelected: [],
			loadingArtists: false,
			messageSearchArtists: "Busque por algum artista para gerar o extrato",
			chips: [],
			disabled: true,
			loading: false,
			tableData: [],
			timeout: null,
			currentSort: "total",
			currentSortOrder: "desc",
			pagination: {
				perPage: 50,
				currentPage: 1,
				perPageOptions: [5, 10, 25, 50],
				total: 0,
			},
			searchQuery: "",
			propsToSearch: [],
			searchedData: [],
			disabledSearch: true,
			blockStatus: 1,
			loadArtist: false,
			showError: false,
			searchTimeout: 0,
			item: "",
			items: [],
			template: ItemTemplate,
			periodStart: "",
			periodEnd: "",
			RadioLocalStorage: "",
			disabledButton: true,
		};
	},
	computed: {
		queriedData() {
			let result = this.tableData;
			if (this.searchedData.length > 0) {
				result = this.searchedData;
			}
			return result.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData.length > 0
				? this.searchedData.length
				: this.tableData.length;
		},
	},
	methods: {
		openDialogSelectArtist() {
			this.dialogSelectArtist = true;
		},
		closeDialogSelectArtist() {
			this.dialogSelectArtist = false;
		},
		cancelArtistSelection() {
			this.artistsSelected = [];
			this.closeDialogSelectArtist();
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === "desc") {
					return b[sortBy] - a[sortBy];
				}
				return a[sortBy] - b[sortBy];
			});
		},
		setArtist(val) {
			this.disabled = false;
		},
		getArtistCollection() {
			this.tableData = [];
			this.loading = true;
			let aux = [];
			for (let i of this.chips) {
				aux.push(`\\"${i}\\"`);
			}
			let dateStart = filters.startDate;
			let dateEnd = filters.endDate;
			dateStart =
				dateStart.getFullYear() +
				"-" +
				(dateStart.getMonth() + 1) +
				"-" +
				dateStart.getDate() +
				" 00:00:00";
			dateEnd =
				dateEnd.getFullYear() +
				"-" +
				(dateEnd.getMonth() + 1) +
				"-" +
				dateEnd.getDate() +
				" 23:59:59";
			models()
				.get(
					`music/getArtistCollection?appKey=${this.appKey}&aux=${aux.join(
						","
					)}&startDate=${dateStart}&endDate=${dateEnd}`
				)
				.then((resp) => {
					if (resp.data.length > 0) {
						for (let item of resp.data) {
							let likes = item.total_likes ? item.total_likes : 0;
							let dislikes = item.total_dislikes ? item.total_dislikes : 0;
							let total = likes + dislikes;
							let likePercent = "(0%)";
							let dislikePercent = `(0%)`;
							if (likes != 0) {
								likePercent = `(${Math.round((likes * 100) / total)}%)`;
							}
							if (dislikes != 0) {
								dislikePercent = `(${Math.round((dislikes * 100) / total)}%)`;
							}

							this.tableData.push({
								artist: item.artist,
								title: item.title,
								total_played: item.total_played,
								total_req: item.total_req,
								likes: `${likes} ${likePercent}`,
								dislikes: `${dislikes} ${dislikePercent}`,
								total: total,
							});
						}
						this.disabledButton = false;
						this.showError = false;
					} else {
						this.showError = true;
						this.disabledButton = true;
					}
					this.loading = false;
				})
				.catch((e) => {
					console.log(e);
				});
		},
		async getArtists(searchTerm) {
			this.loadArtist = true;
			models()
				.get(`music/getArtist?appKey=${this.appKey}&search=${searchTerm}`)
				.then((res) => {
					this.artistsSearched = res.data;
				});
		},
		blockPage() {
			const menu = jwt_decode(localStorage.getItem("APP_02")).services;
			let service = null;
			for (let obj of menu) {
				if (obj.subitens === null) {
					const key = Object.keys(obj.item)[
						Object.values(obj.item).indexOf(this.$route.path.replace("/", ""))
					];
					if (key) {
						service = obj.item;
					}
				} else {
					const key = Object.keys(obj.subitens)[
						Object.values(obj.subitens).indexOf(
							this.$route.path.replace("/", "")
						)
					];
					if (key) {
						service = obj.subitens;
					}
				}
			}

			if (service) {
				this.blockStatus = service.has_permission;
			} else {
				this.$router.push("dashboard");
			}
		},
		findObjectByKey(array, key, value) {
			for (var i = 0; i < array.length; i++) {
				if (array[i][key] === value) {
					return array[i];
				}
			}
			return null;
		},
		print() {
			window.print();
		},
		selectArtist(obj) {
			if (obj.artist !== "Sem resultados.") {
				this.item = obj.artist;
				if (!this.chips.includes(obj.artist.toUpperCase())) {
					this.chips.push(obj.artist.toUpperCase());
					this.disabled = true;
				}
			}
		},
		getLabel(item) {
			if (item.artist && item.artist !== "Sem resultados.") return item.artist;
		},
		updateItems() {
			this.loadingArtists = true;
			models()
				.get(
					`music/searchArtist?appKey=${this.appKey}&searchTerm=${this.searchQuery}`
				)
				.then((resp) => {
					if (resp.data.length == 0) {
						this.artistsSearched = [];
						this.messageSearchArtists = `Sem resultados para a busca '${this.searchQuery}'`;
					} else {
						this.messageSearchArtists = null;
						this.artistsSearched = resp.data;
					}
				})
				.catch((e) => {
					Swal(
						"Ops!",
						"Houve algum erro ao tentar recuperar os artistas para o extrato musical. Caso o erro persista, entre em contato com o suporte.",
						"error"
					);
					console.log(e);
				})
				.finally(() => {
					this.loadingArtists = false;
				});
		},
		deleteHandler(text, index) {
			this.chips.splice(index, 1);
		}
	},
	mounted() {
		if (jwt_decode(localStorage.getItem("APP_01"))) {
			this.RadioLocalStorage = jwt_decode(localStorage.getItem("APP_01")).radio;
			this.appKey = this.RadioLocalStorage.key;
			this.blockPage();
		}
		selectRadio.$on("selectRadio", (payload) => {
			this.appKey = payload.key;
			this.logo = payload.logo;
			this.disabledButton = true;
			this.tableData = [];
			this.artistsSelected = [];
			this.artistsSearched = [];
			this.searchQuery = "";
			this.chips = [];
			this.blockPage();
			this.disabledSearch = false;
		});
	},
	watch: {
		artistsSelected(val) {
			this.chips = val;
		},
		time1: function () {
			this.periodStart = new Date(this.time1).toLocaleDateString("pt-BR");
			if (this.chips.length > 0 && this.time1 !== null && this.time2 !== null) {
				this.disabledSearch = false;
			} else {
				this.disabledSearch = true;
			}
		},
		time2: function () {
			this.periodEnd = new Date(this.time2).toLocaleDateString("pt-BR");
			if (this.chips.length > 0 && this.time1 !== null && this.time2 !== null) {
				this.disabledSearch = false;
			} else {
				this.disabledSearch = true;
			}
		},
		chips: function () {
			if (this.chips.length > 0 && this.time1 !== null && this.time2 !== null) {
				this.disabledSearch = false;
			} else {
				this.disabledSearch = true;
			}
		},
	},
};
</script>

<style lang="scss">
#input-chips {
	display: none;
}

.checkbox-breakline .el-checkbox__label {
	text-wrap: initial;
}

.load-data {
	display: none;
	width: 32px;
	height: 32px;
	position: absolute;
	left: 0;
	z-index: 999;
}

.shake-on-error .md-duplicated {
	animation-name: shake;
	animation-duration: 0.5s;
}

.md-chip .md-ripple {
	font-size: 1em;
}

.loader,
.loader:before,
.loader:after {
	background: #000000;
	-webkit-animation: load1 1s infinite ease-in-out;
	animation: load1 1s infinite ease-in-out;
	width: 1em;
	height: 4em;
}

.loader {
	color: #000000;
	text-indent: -9999em;
	margin: 6px -15px;
	position: relative;
	font-size: 3px;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

.loader:before,
.loader:after {
	position: absolute;
	top: 0;
	content: "";
}

.loader:before {
	left: -1.5em;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.loader:after {
	left: 1.5em;
}

.autocomplete__box {
	border-top: 0 !important;
	border-right: 0 !important;
	border-left: 0 !important;
	border-radius: 0 !important;
}

.autocomplete__box > img {
	width: 18px;
}

.autocomplete--clear img {
	width: 12px;
}

.autocomplete__no-results {
	display: none !important;
}

.msgError {
	display: none;
}

@-webkit-keyframes load1 {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}

	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}

@keyframes load1 {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}

	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}

@keyframes shake {
	0% {
		transform: translate(15px);
	}

	20% {
		transform: translate(-15px);
	}

	40% {
		transform: translate(7px);
	}

	60% {
		transform: translate(-7px);
	}

	80% {
		transform: translate(3px);
	}

	100% {
		transform: translate(0px);
	}
}

.not-found {
	display: none;
	color: red;
	font-weight: 800;
}

.v-autocomplete > .v-autocomplete-input-group > .v-autocomplete-input {
	font-size: 1.1em !important;
	padding: 10px !important;
	box-shadow: none !important;
	border: #c0c0c0 solid 1px !important;
	border-radius: 5px;
	width: 100% !important;
	outline: none !important;
	background-color: #ffffff !important;
}

.v-autocomplete-selected > .v-autocomplete-input::placeholder {
	color: #c0c0c0 !important;
}

.v-autocomplete-selected > .v-autocomplete-input {
	color: green !important;
	background-color: #f2fff2 !important;
}

.v-autocomplete-list {
	width: 100% !important;
	text-align: left !important;
	border: none !important;
	border-top: none !important;
	max-height: 400px !important;
	overflow-y: auto !important;
	z-index: 999 !important;
	border-bottom: 1px solid #157977 !important;
}

.v-autocomplete-list-item {
	cursor: pointer !important;
	background-color: #fff !important;
	padding: 10px !important;
	border-bottom: 1px solid #157977 !important;
	border-left: 1px solid #157977 !important;
	border-right: 1px solid #157977 !important;
}

.print {
	display: none;
}

@page {
	size: auto;
	margin: 10mm 10mm 10mm 10mm;
}

@media print {
	.print-hide {
		display: none;
	}

	.print {
		display: block;
	}

	tr {
		page-break-inside: avoid;
	}

	table.report-container {
		page-break-after: always !important;
	}

	thead.report-header {
		display: table-header-group !important;
	}

	tfoot.report-footer {
		display: table-footer-group !important;
		text-align: center;
		font-size: 12pt;
	}

	body {
		margin: 0;
		padding: 0;
		font-size: 8pt;
	}

	img {
		width: 160px;
		height: 160px;
	}

	h1 {
		font-size: 18pt;
	}

	.md-table-head-label {
		font-weight: 800;
	}

	span {
		font-size: 12pt;
	}

	footer {
		text-align: center;
		font-size: 12pt;
	}

	.md-toolbar-section-start {
		display: none;
	}

	.titleRight {
		float: right;
		margin-top: -80px;
	}

	.nameRadio {
		font-size: 18pt;
	}

	.logoRadio {
		width: 48px;
		height: 48px;
		border-radius: 25px;
		margin-top: -10px;
	}

	.contentBody {
		margin-top: 20px;
	}

	.divTable {
		border: 1px solid #d2d2d2;
		padding: 10px;
		margin-bottom: 20px;
	}

	.footerIcon {
		width: 16px;
		height: 16px;
		margin-top: -5px;
	}
}
</style>
