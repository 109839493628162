var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
        staticStyle: { "text-align": "center" },
      },
      [
        _c("h1", [_vm._v("Erro 404")]),
        _c("h3", [
          _vm._v(
            "Desculpe, não foi possível localizar o endereço solicitado, por favor, tente novamente."
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }