<template>
    <el-dialog title="Participantes" :visible.sync="showSort" :close-on-press-escape="false" :close-on-click-modal="false" width="50">
        <h5 style="margin: -16px 0 16px 0; font-weight: 500; color: #999999;" v-if="tableTotalParticipants.length!==0 && giftPromoType===2">
            <em>Pergunta: {{ giftPromoQuestion }}</em>
        </h5>
        <el-tabs v-if="tableTotalParticipants.length!==0" v-model="activeNameSort">
            <el-tab-pane label="Participantes" name="participantes" class="tbl-participantes">
                <div class="md-layout" style="padding: 0;">
                    <div
                        class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-50 md-xlarge-size-30">
                        <md-button class="md-success md-block" @click="sort()"
                            :disabled="tableTotalParticipants.length === 0">
                            <i class="fas fa-random"></i> Sortear
                        </md-button>
                    </div>
                    <div
                        class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-50 md-xlarge-size-30">
                        <md-button class="md-info md-block" @click="exportExcel()"
                            :disabled="tableTotalParticipants.length === 0"><i class="fas fa-table"></i>
                            Exportar
                        </md-button>
                    </div>
                </div>
                <div class="md-layout dialog-gifts">
                    <div class="md-layout-item md-size-100 md-small-size-100" style="overflow-x: auto;">
                        <table class="table-custom">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Email</th>
                                    <th>Prêmio</th>
                                    <th>Telefone</th>
                                    <th
                                        v-if="giftPromoType===2"
                                    >Resposta</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="row_custom" v-for="(key, index) in tableParticipants" :key="index">
                                    <td style="padding: 15px 15px 15px 0px;">{{key.name}}</td>
                                    <td style="padding: 15px 15px 15px 0px;">{{key.email}}</td>
                                    <td style="padding: 15px 15px 15px 0px;">{{decodeURIComponent(key.gift_name)}}</td>
                                    <td style="padding: 15px 15px 15px 0px;">{{key.mobile_phone}}</td>
                                    <td style="padding: 15px 15px 15px 0px;"
                                        v-if="giftPromoType===2"
                                    >{{key.answer}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    v-if="totalParticipants>10" 
                    class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                    <div class="md-layout md-alignment-center-space-between">
                        <div class="md-layout-item md-size-50" style="padding: 0;">
                            <h5 
                            style="font-style: italic; color: #999999;"
                            >Página {{this.currentPage}} de {{Math.ceil(totalParticipants/10)}}</h5>
                        </div>

                        <div class="md-layout-item md-xsmall-size-100 md-medium-size-45 md-large-size-35 md-xlarge-size-30" 
                        style="padding: 0; display: flex; justify-content: flex-end;">
                            <md-button 
                                class="md-outlined"
                                v-if="this.currentPage!==1"
                                style="width: 48%; border-color: #1565c0; color: #1565c0 !important; background-color: #fff !important;"
                                @click="prevPage()">Anterior</md-button>
                            <md-button
                                v-if="this.currentPage < Math.ceil(totalParticipants/10)"
                                style="width: 48%; margin-left: 4% !important;"
                                class="md-info"
                                @click="nextPage()">Próxima</md-button>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Ganhadores" name="ganhadores">
                <h4 style="text-align: center;" v-if="tableWinners.length===0">
                    Nenhum ganhador sorteado até o momento.
                </h4>
                <div v-else class="md-layout-item md-size-100 md-small-size-100" style="overflow-x: auto;">
                    <table class="table-custom">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Email</th>
                                <th>Prêmio</th>
                                <th>Telefone</th>
                                <th v-if="giftPromoType===2">Resposta</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="row_custom" v-for="(item, index) in tableWinners" :key="index">
                                <td
                                style="padding: 15px 15px 15px 0px;"
                                >{{item.radio_listener_first_name}}</td>
                                <td
                                    style="padding: 15px 15px 15px 0px;"
                                >{{item.radio_listener_email}}</td>
                                <td
                                    style="padding: 15px 15px 15px 0px;"
                                >{{item.gifts_promo_name}}</td>
                                <td
                                    style="padding: 15px 15px 15px 0px;"
                                >{{item.radio_listener_mobile_phone}}</td>
                                <td
                                    style="padding: 15px 15px 15px 0px;"
                                    v-if="giftPromoType===2"
                                >{{item.gift_promo_answer}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </el-tab-pane>
        </el-tabs>
        <div v-else>
            <h4 style="text-align: center;">
                Est{{ giftPromoType === 1 ? 'e prêmio da hora':'a promoção' }} ainda não possui participantes.
                Aguarde os ouvintes se cadastrarem e tente novamente.
            </h4>
        </div>
    </el-dialog>
</template>
<script>
import ExcelJS from 'exceljs'
import Swal from 'sweetalert2'
import saveExcelTable from '../../../utils/events/saveExcelTable'
import selectRadio from '../../../utils/events/select-radio'
import models from '../../../routes/models'
import jwt_decode from 'jwt-decode'
import mixpanelAdapter from '../../../utils/events/mixpanelAdapter'

async function confirmWinner(appKey, listenerId, listenerName, listenerUuid, listenerEmail, listenerPhone, listenerProfileUrl, giftId, giftName, giftDescription, giftStartDate, giftEndDate, giftPromoAnswer) {
        let formData = {
            appKey: appKey,
            listenerId: listenerId,
            listenerName: listenerName,
            listenerUuid: listenerUuid,
            listenerEmail: listenerEmail,
            listenerPhone: listenerPhone,
            listenerProfileUrl: listenerProfileUrl,
            giftId: giftId,
            giftName: giftName,
            giftDescription: giftDescription,
            giftStartDate: giftStartDate,
            giftEndDate: giftEndDate,
            giftPromoAnswer: giftPromoAnswer
        }
        const URL = `gift/insertWinner`
        return await models().post(URL, formData)
            .then(response => {return response})
            .catch(err => {return err})
    }

async function getTotalParticipants(id) {
    const URL = `gift/getTotalParticipants?giftId=${id}`
    return await models().get(URL)
        .then(response => {return response})
        .catch(err => {return err})
}

async function getWinnerByPromoId(promoId) {
    const URL = `gift/getWinnerByPromoId?promoId=${promoId}`
    return await models().get(URL)
        .then(response => {return response})
        .catch(err => {return err})
}


async function getParticipants(id, appKey) {
    const URL = `gift/getParticipants?giftId=${id}&appKey=${appKey}`
    return await models().get(URL)
        .then(response => {return response})
        .catch(err => {return err})
}

function addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}


export default {
    name: "TableOfParticipants",
    data(){
        return {
            radio: jwt_decode(localStorage.getItem('APP_01')).radio,
            user: jwt_decode(localStorage.getItem('SET_APP')).user[0],
            activeNameSort: 'participantes',
            showSort: false,
            tableParticipants: [],
            tableWinners: [],
            tableTotalParticipants: [],
            totalParticipants: [],
            currentPage: 1,
            giftPromoType: null,
            giftPromoQuestion: null,
            currentUpdateId: null
        }
    },
    methods: {
        sort() {
            if(!this.tableTotalParticipants.length) {
                this.showParticipantsModal = false
                Swal(
                    'Atenção',
                    'Não existem participantes nessa promoção.',
                    'warning'
                )
            } else {
                //this.showSort = false
                let random = this.tableTotalParticipants[Math.floor(Math.random() * this.tableTotalParticipants.length)]
                Swal.fire({
                    title: 'Sorteio realizado!',
                    html: `<div style="text-align: left">
                                Informações do vencedor: <p>
                                <b>Nome:</b> ${random.name} <br>
                                <b>Email:</b> ${random.email} <br>
                                <b>Telefone:</b> ${random.mobile_phone} <br>
                                <b>Prêmio:</b> ${decodeURIComponent(random.gift_name)} <br>
                                <b>Resposta:</b> ${random.answer} <br>
                            </div>
                            `,
                    showConfirmButton: true,
                    showCancelButton: true,
                    showCloseButton: true,
                    confirmButtonColor: '#47af4c',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Sortear novamente',
                    allowOutsideClick: true,
                    type: 'success'

                })
                .then((result) => {
                    if (result.value) {
                        let d1 = new Date(random.promo_start_date)
                        let d2 = new Date(random.promo_end_date)
                        let auxDate1 = d1.getFullYear() + "-" + (d1.getMonth()+1) + "-" + d1.getDate() + " " + addZero(d1.getHours()) + ":" + addZero(d1.getMinutes()) + ":" + addZero(d1.getSeconds())
                        let auxDate2 = d2.getFullYear() + "-" + (d2.getMonth()+1) + "-" + d2.getDate() + " " + addZero(d2.getHours()) + ":" + addZero(d2.getMinutes()) + ":" + addZero(d2.getSeconds())
                        confirmWinner(this.radio.key, random.radio_listener_id, random.name, random.uuid, random.email, random.mobile_phone, "", this.currentUpdateId, random.gift_name, random.promo_description, auxDate1, auxDate2, random.answer)
                            .then(() => {
                                this.$root.$emit("updateGift", "winner")
                                this.openSort(this.currentUpdateId)
                                this.activeNameSort = "ganhadores"
                                this.sendMixPanelEvent(`gift_participants_winner_sorted`)
                            })
                            .catch((err) => {
                                console.log(err)
                                swalMessageError('Erro', 'Não foi possível confirmar o vencedor, por favor, tente novamente ou entre em contato com nosso suporte.')
                            })
                    } else if (result.dismiss == 'cancel') {
                        this.sort()
                    }
                })
            }
        },
        openSort(id) {
            this.tableParticipants = []
            this.totalParticipants = []
            this.tableWinners = []
            this.tableTotalParticipants = []
            this.activeNameSort = "participantes"
            Promise.all([getTotalParticipants(id), getWinnerByPromoId(id), getParticipants(id, this.radio.key)])
                .then((response) => {
                    if(response[0].status === 200 && response[0].data.length > 0) {
                        this.totalParticipants = response[0].data[0].total
                    }
                    if(response[1].status === 200 && response[1].data.length > 0) {
                        this.tableWinners = response[1].data
                    }
                    if(response[2].status === 200 && response[2].data.length > 0) {
                        this.tableTotalParticipants = response[2].data
                    }
                    this.tableParticipants = this.tableTotalParticipants.slice(0, 10)
                    this.showSort = true
                })
        },
        nextPage() {
            if(this.currentPage<Math.ceil(this.totalParticipants/10)){
                this.currentPage++;
                const startIndex = (this.currentPage-1)*10
                const endIndex = (this.currentPage)*10
                this.tableParticipants = this.tableTotalParticipants.slice(startIndex, endIndex)
            }
        },
        prevPage() {
            if(this.currentPage>1){
                this.currentPage--;
                const startIndex = (this.currentPage-1)*10
                const endIndex = (this.currentPage)*10
                this.tableParticipants = this.tableTotalParticipants.slice(startIndex, endIndex)
            }
        },
        exportExcel() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Participantes');

            let columns = [
                { header: 'Data', key: 'date', width: 40 },
                { header: 'Nome', key: 'name', width: 40 },
                { header: 'E-mail', key: 'email', width: 40 },
                { header: 'Telefone', key: 'phone', width: 40 },
                { header: 'Prêmio', key: 'gift', width: 40 }
            ];

            if (this.giftPromoType === 2) {
                columns.push({ header: 'Resposta', key: 'answer', width: 40 });
            }

            worksheet.columns = columns;

            for (let i = 0; i < this.tableTotalParticipants.length; i++) {
                let rowData = {
                    name: this.tableTotalParticipants[i].name,
                    email: this.tableTotalParticipants[i].email,
                    gift: decodeURIComponent(this.tableTotalParticipants[i].gift_name),
                    phone: this.tableTotalParticipants[i].mobile_phone,
                    date: new Date(this.tableTotalParticipants[i].inscricao).toLocaleString('pt-BR', { hour12: false })
                };

                if (this.giftPromoType === 2) {
                    rowData.answer = this.tableTotalParticipants[i].answer;
                }

                worksheet.addRow(rowData);
            }

            saveExcelTable(`${this.radio.name} - ${decodeURIComponent(this.tableTotalParticipants[0].promo_name)}.xlsx`, workbook);
            this.sendMixPanelEvent(`gift_participants_export_excel`);
        },


        sendMixPanelEvent(eventString){
			mixpanelAdapter.track(eventString, {
				screen_name: this.$route.name
			})
		}
    },
    mounted(){
        selectRadio.$on('selectRadio', (payload) => {
            this.radio = payload
        })
        this.$parent.$on("showSortGifts", (payload)=>{
            this.giftPromoType = payload.promoType
            this.giftPromoQuestion = payload.giftPromoQuestion
            this.currentUpdateId = payload.id
            this.openSort(payload.id)
        })
    }
}

</script>
<style>

.md-outlined {
	border: 1px solid;
	background-color: #ffff !important;
    color: #1565c0 !important; 
	font-weight: 600 !important;
	box-shadow: none;
}

.md-outlined:disabled{
    color: #dadada !important;
    background-color: #d8d8d8 !important;
}

.md-outlined:hover{
	background: #fff !important;
    color: #1565c0 !important; 
}


</style>