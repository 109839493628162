var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "wrapper",
      class: [
        { "nav-open": _vm.$sidebar.showSidebar },
        { rtl: _vm.$route.meta.rtlActive },
      ],
    },
    [
      this.$route.path !== "/forms/wizard" && !this.dialogSelectChildrenRadio
        ? _c(
            "side-bar",
            [
              _c("user-menu"),
              _c(
                "template",
                { slot: "links" },
                [
                  _vm._l(_vm.services, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "menu-dashboard" },
                      [
                        item.item != null &&
                        item.menu_label !== "Status Streaming"
                          ? _c("sidebar-item", {
                              key: "A" + index,
                              attrs: {
                                link: {
                                  name: item.menu_label,
                                  icon: item.item.vue_icon,
                                  path: "/" + item.item.service_url,
                                },
                              },
                            })
                          : _c(
                              "sidebar-item",
                              {
                                attrs: {
                                  link: {
                                    name: item.menu_label,
                                    icon: item.vue_icon,
                                  },
                                },
                              },
                              _vm._l(item.subitens, function (sub, index) {
                                return _c("sidebar-item", {
                                  key: "B" + index,
                                  attrs: {
                                    link: {
                                      name: sub.label,
                                      path: "/" + sub.service_url,
                                    },
                                  },
                                })
                              }),
                              1
                            ),
                      ],
                      1
                    )
                  }),
                  _c("sidebar-item", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: this.user[0].level === 0,
                        expression: "this.user[0].level === 0",
                      },
                    ],
                    staticClass: "menu-dashboard",
                    attrs: {
                      link: {
                        name: "Áreas do dispositivo",
                        path: "/advertising-campaign-zones",
                        icon: "screenshot",
                      },
                    },
                  }),
                  _c("sidebar-item", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: this.user[0].level === 0,
                        expression: "this.user[0].level === 0",
                      },
                    ],
                    staticClass: "menu-dashboard",
                    attrs: {
                      link: {
                        name: "Popup de Avisos",
                        path: "/popup-warnings",
                        icon: "dvr",
                      },
                    },
                  }),
                  _c("sidebar-item", {
                    staticClass: "menu-dashboard",
                    attrs: {
                      link: {
                        name: "Configurações",
                        path: "/configuration/edit",
                        icon: "settings",
                      },
                    },
                  }),
                  _c("li", { staticClass: "menu-dashboard" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        on: {
                          click: () => {
                            _vm.sendMixPanelEvent(
                              `logout_confirmation_popup_opened`
                            )
                            _vm.logout()
                          },
                        },
                      },
                      [
                        _c("md-icon", [_vm._v(_vm._s("logout"))]),
                        _c("p", [_vm._v("Sair")]),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "main-panel" },
        [
          _c("top-navbar"),
          _c(
            "div",
            {
              class: { content: !_vm.$route.meta.hideContent },
              on: { click: _vm.toggleSidebar },
            },
            [
              _c(
                "zoom-center-transition",
                { attrs: { duration: 200, mode: "out-in" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          ),
          !_vm.$route.meta.hideFooter ? _c("content-footer") : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogSelectChildrenRadio,
            "close-on-click-modal": false,
            "show-close": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSelectChildrenRadio = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "align-items": "center",
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../../../public/img/logo-mobradio-black.png"),
                },
              }),
              _c("h3", { staticStyle: { "margin-bottom": "2rem" } }, [
                _vm._v(" Selecione um das rádios para prosseguir: "),
              ]),
              _c(
                "div",
                { staticClass: "md-layout" },
                _vm._l(this.catalogFirstSelect, function (radio) {
                  return _c(
                    "div",
                    {
                      key: radio,
                      staticClass:
                        "md-layout-item md-xsmall-size-50 md-small-size-25 md-medium-size-25 md-large-size-20 md-xlarge-size-20",
                      staticStyle: { margin: "auto" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "md-layout-item md-size-20",
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.selectRadio(radio)
                            },
                          },
                        },
                        [
                          _c("radio-catalog-item", {
                            attrs: { logo: radio.logo, name: radio.subName },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }