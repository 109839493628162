<template>
  <div class="md-layout">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
            <i class="material-icons">list</i>
        </div>
      </md-card-header>

      <md-card-content>
        <div style="margin-left: -25px;" class="md-layout-item md-small-size-100 md-medium-size-15 md-large-size-15 md-xlarge-size-15">
            <md-button style="margin-top:13px" class="md-info md-block" @click="newZone()"><i class="fas fa-plus"></i>&nbsp; Adicionar</md-button>
          </div>
        <md-table :value="listZones" class="paginated-table table-striped table-hover">
          <md-table-row class="row-table" slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Nome da área">{{item.name}}</md-table-cell>
            <md-table-cell md-label="Descrição">{{item.description ? item.description : '---'}}</md-table-cell>
            <md-table-cell md-label="Width">{{item.width}}</md-table-cell>
            <md-table-cell md-label="Height">{{item.height}}</md-table-cell>
            <md-table-cell md-label="Tempo de rotação do banner">{{item.banner_rotation_time}}</md-table-cell>
            <md-table-cell md-label="Hash">{{item.hash}}</md-table-cell>
            <md-table-cell md-label="Ações">
              <div class="container-btn-edit">
                  <div class="row-btn-edit-general">
                    <el-button icon="el-icon-edit" circle @click="editZone(item)"></el-button>
                  </div>
                  <div class="row-btn-edit-general">
                    <el-button icon="el-icon-delete" circle @click="deleteZone(item)"></el-button>
                  </div>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <span v-show="this.messageListZone === null">Nenhuma campanha foi encontrada para o período selecionado</span>
      </md-card-content>
    </md-card>

    <md-card v-show="this.messageListZone === null">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
            <i class="material-icons">list</i>
        </div>
      </md-card-header>

      <md-card-content>
        <span >Nenhuma campanha foi encontrada para o período selecionado</span>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode'
import { Button, DatePicker } from 'element-ui'
import models from '../../../../routes/models'
import Swal from 'sweetalert2'

export default {
  name: 'Get',
  components: {
    [DatePicker.name]: DatePicker,
    [Button.name]: Button
  },
  data() {
    return {
      radio: jwt_decode(localStorage.getItem('APP_01')).radio,
      listZones: [],
      messageListZone: false
    }
  },
  methods: {
    getZones(appKey) {
      models().get(`mobads/getZones?appKey=${appKey}`)
        .then((zones) => {
          if(zones.data.length > 0) {
            this.listZones = zones.data
          } else {
            this.messageListZone = true
          }
        })
    },
    newZone() {
      this.$root.$emit('openNewZone')
    },
    editZone(item) {
      this.$root.$emit('openEditZone', item)
    },
    deleteZone(itemToRemove) {
      Swal({
          title: 'Atenção',
          html: 'Deseja realmente excluir essa área?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          type: 'warning'
      }).then((result) => {
        if(result.value) {
          let formData = {
            appKey: this.radio.key,
            id: itemToRemove.id
          }
          models().post('mobads/deleteZone', formData)
            .then(() => {
              this.listZones = this.listZones.filter(function(item) {
                return item !== itemToRemove
              })
              Swal(
                '',
                '',
                'success'
              )
            })
        }
      })
    }
  },
  mounted() {
    this.getZones(this.radio.key)
    this.$root.$on('reloadGetZone', () => {
      this.getZones(this.radio.key)
    })
  }
}
</script>

<style>
  .date-picker {
    width: 100% !important;
  }
  .row-table {
    height: 60px;
  }
  .container-btn-edit {
    display: flex;
  }
  .row-btn-edit-general {
      margin-left: 20px !important;
    }
  @media (max-width: 320px) {
    .row-btn-edit-general {
      margin-left: 30px;
    }
  }
  @media (max-width: 1024px) {
    .row-btn-edit-general {
      margin-left: 20px !important;
    }
  }
  @media (min-width: 1360px) {
    .row-btn-edit-general {
      margin-left: 10px !important;
    }
  }
</style>
