import services from '../../routes/services'

async function uploadCloudinary(key, file) {
    const URL = 'services/uploadCloudImage'
    const formData = new FormData()
    const config = {headers: {'content-type': 'multipart/form-data'}}

    formData.append('key', key)
    formData.append('attachments', file)

    return await services().post(URL, formData, config).then(res => {
        return res.data
    })
}

export default uploadCloudinary