// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import "./polyfills";
// Notifications plugin. Used on Notifications page
import Notifications from "@/components/NotificationPlugin";
// Validation plugin used to validate forms
import VeeValidate from "vee-validate";
// A plugin file where you could register global components used across the app
import GlobalComponents from "./globalComponents";
// A plugin file where you could register global directives
import GlobalDirectives from "./globalDirectives";
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from "./components/SidebarPlugin";
// Tabs plugin. Used on Panels page.

import VueMaterial from "vue-material";

// asset imports
import "vue-material/dist/vue-material.min.css";
import "./assets/scss/material-dashboard.scss";
import "./assets/css/demo.css";

// library auto imports
import "es6-promise/auto";

import './base/index.scss'
import MdReactive from "../src/core/utils/MdReactive";

const init = () => {
	let material = new MdReactive({
		ripple: false,
		//     // theming: {},
		locale: {
			startYear: 1900,
			endYear: 2099,
			dateFormat: "dd/MM/yyyy",
			days: [
				"Domingo",
				"Segunda",
				"Terça",
				"Quarta",
				"Quinta",
				"Sexta",
				"Sábado",
			],
			shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
			shorterDays: ["D", "S", "T", "Q", "Q", "S", "S"],
			months: [
				"Janeiro",
				"Fevereiro",
				"Março",
				"Abril",
				"Maio",
				"Junho",
				"Julho",
				"Agosto",
				"Setembro",
				"Outubro",
				"Novembro",
				"Dezembro",
			],
			shortMonths: [
				"Jan",
				"Fev",
				"Mar",
				"Abr",
				"Mai",
				"Jun",
				"Jul",
				"Ago",
				"Set",
				"Out",
				"Nov",
				"Dez",
			],
			shorterMonths: [
				"J",
				"F",
				"M",
				"A",
				"M",
				"Ju",
				"Ju",
				"A",
				"Se",
				"O",
				"N",
				"D",
			],
			firstDayOfAWeek: 0,
      cancel: 'Cancelar',
      confirm: 'Confirmar'
		},
	});

	return material;
};

export default {
	install(Vue) {
		Vue.use(GlobalComponents);
		Vue.use(GlobalDirectives);
		Vue.use(VueMaterial);
		Vue.use(SideBar);
		Vue.use(Notifications);
		Vue.use(VeeValidate, { fieldsBagName: "veeFields" });
		Vue.material = init();
		Vue.prototype.$material = Vue.material;
	},
};
