<template>
	<div>
		<div class="user">
			<div class="md-layout-item md-size-100" v-show="radios.length > 5">
				<md-field>
					<i class="material-icons md-suffix">search</i>
					&nbsp;
					<md-input
						placeholder="Buscar rádio..."
						class="md-accent"
						v-model="searchString"
					>
					</md-input>
				</md-field>
			</div>
			<div class="photo">
				<img :src="avatar" alt="avatar" />
			</div>
			<div class="user-info">
				<a
					data-toggle="collapse"
					:aria-expanded="!isClosed"
					@click.stop="toggleMenu"
					@click.capture="clicked"
				>
					<span v-if="$route.meta.rtlActive">
						{{ rtlTitle }}
						<b class="caret"></b>
					</span>
					<span v-else>
						{{ nameRadio }}
						<b class="caret" v-if="radios.length > 0"></b>
					</span>
				</a>

				<collapse-transition v-if="radios.length >= 1">
					<div v-show="!isClosed">
						<ul class="nav">
							<slot>
								<template
									v-if="
										radios.every(
											radio =>
												radio.name
													.toLowerCase()
													.indexOf(searchString.toLowerCase()) < 0
										)
									"
								>
									<div
										class="md-layout-item md-size-100"
										v-show="radios.length > 5"
									>
										<h5 style="color: white">
											Não foram encontradas rádios para a busca "{{
												this.searchString
											}}"
										</h5>
									</div>
								</template>
								<template v-else v-for="(itemRadios, index) in radios">
									<li
										v-if="
											itemRadios.name
												.toLowerCase()
												.indexOf(searchString.toLowerCase()) >= 0
										"
										:key="index"
									>
										<a
											v-if="itemRadios.parent == 0"
											@click="
												selectRadio(
													itemRadios.id,
													itemRadios.name,
													itemRadios.key,
													itemRadios.server_key,
													itemRadios.logo,
													itemRadios.cloud_name,
													itemRadios.cloud_api_key,
													itemRadios.cloud_api_secret,
													itemRadios.master_key
												)
											"
											style="display: -webkit-box"
										>
											<img
												:src="itemRadios.logo"
												style="width:28px;heigth:28px"
											/>
											&nbsp;
											<span class="sidebar-normal">{{ itemRadios.name }}</span>
										</a>
										<a
											v-if="itemRadios.parent == 1"
											style="display: -webkit-box"
											@click="showRadioChildren(index)"
										>
											<img
												:src="itemRadios.logo"
												style="width:28px; heigth:28px;"
											/>
											&nbsp;
											<span class="sidebar-normal">{{ itemRadios.name }}</span>
											<img
												:id="'icon' + index"
												:src="srcIcon"
												class="icon-radio-catalog"
												alt=""
											/>
										</a>
										<div :ref="'panel' + index" class="panel">
											<ul class="radio-parent">
												<li
													v-for="(itemParent, index2) in radiosParent"
													:key="index2"
													v-show="itemRadios.id == itemParent.parent_id"
												>
													<a
														@click="
															selectRadio(
																itemParent.id,
																itemParent.subName,
																itemParent.key,
																itemParent.server_key,
																itemParent.logo,
																itemParent.cloud_name,
																'',
																'',
																itemParent.master_key,
																itemRadios.id,
																itemRadios.name
															)
														"
														style="display: -webkit-box"
													>
														<img
															:src="itemParent.logo"
															style="width:28px;heigth:28px"
														/>
														&nbsp;
														<span class="sidebar-normal">{{
															itemParent.subName
														}}</span>
													</a>
												</li>
											</ul>
										</div>
									</li>
								</template>
							</slot>
						</ul>
					</div>
				</collapse-transition>
			</div>
		</div>
	</div>
</template>
<script>
import models from "../../../../routes/models.js";
import selectRadio from "../../../../utils/events/select-radio.js";
import Swal from "sweetalert2";
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import { CollapseTransition } from "vue2-transitions";

export default {
	components: {
		CollapseTransition
	},
	props: {
		title: {
			type: String,
			default: "Rádio"
		}
	},
	data() {
		return {
			radio_current: jwt_decode(localStorage.getItem("APP_01")).radio,
			user_current: jwt_decode(localStorage.getItem("SET_APP")).user[0],
			isClosed: true,
			nameRadio: null,
			logoRadio: null,
			avatar: "",
			srcIcon: require("../../../../assets/plus.png"),
			radios: new Array(),
			radiosParent: new Array(),
			searchString: ""
		};
	},
	methods: {
		clicked: function(e) {
			e.preventDefault();
		},
		toggleMenu: function() {
			this.isClosed = !this.isClosed;
		},
		getRadiosByUserId() {
			let vm = this;
			vm.radios = [];
			const id = this.user_current.id;
			models()
				.get(`users/getRadiosByUserId?userId=${id}`)
				.then(resp => {
					for (let radio of resp.data) {
						vm.radios.push({
							id: radio.id,
							name: radio.name,
							key: radio.key,
							server_key: radio.server_key,
							logo: radio.image_logo_url,
							parent: radio.is_parent,
							cloud_name: radio.cloudinary_cloud_name,
							cloud_api_key: radio.cloudinary_api_key,
							cloud_api_secret: radio.cloudinary_api_secret,
							master_key: radio.key
						});

						if (radio.is_parent == 1) {
							Promise.resolve(vm.getChildrenRadios(radio.id)).then(resp => {
								for (let radioParent of resp.data) {
									vm.radiosParent.push({
										parent_name: radio.name,
										parent_id: radio.id,
										name: radio.name,
										id: radioParent.id,
										subName: radioParent.name,
										key: radioParent.key,
										server_key: radio.server_key,
										logo: radioParent.image_logo_url,
										cloud_name: radio.cloudinary_cloud_name,
										master_key: radio.key
									});
								}
							});
						}
					}

					this.avatar = jwt_decode(localStorage.getItem("APP_01")).radio.logo;
					Swal.close();
				})
				.catch(e => {
					console.log(e);
				});
		},

		async getChildrenRadios(parentId) {
			return await models().get(`radios/getChildrenRadios/${parentId}`);
		},

		selectRadio(
			id,
			name,
			key,
			server_key,
			logo,
			cloud_name,
			cloud_key,
			cloud_secret,
			master_key,
			parent_id = null,
			parent_name = null
		) {
			this.getServicesRadio(id).then(res => {
				const radio = {
					id: id,
					name: name,
					key: key,
					server_key: server_key,
					logo: logo,
					cloud_name: cloud_name,
					cloud_key: cloud_key,
					cloud_secret: cloud_secret,
					master_key: master_key
				};

				if(parent_id){
					radio.parent_id = parent_id
					radio.parent_name = parent_name
				}
				const token = jwt.sign({ radio }, "12345", {
					expiresIn: 600
				});
				localStorage.setItem(
					"APP_01",
					JSON.stringify(token).replaceAll('"', "")
				);
				selectRadio.$emit("selectRadio", {
					id,
					name,
					key,
					server_key,
					logo: logo,
					cloud_name: cloud_name,
					cloud_key: cloud_key,
					cloud_secret: cloud_secret,
					master_key: master_key
				});
			});
			this.searchString = "";
			this.nameRadio = name;
			this.avatar = logo;
			this.isClosed = true;
			this.dialogSelectChildrenRadio = false;
		},

		async getServicesRadio(id) {
			await models()
				.get(`services/getAllRadioServices/${id}`)
				.then(resp => {
					let services = [];
					for (let item of resp.data) {
						let arrAux = { menu_label: "", item: null, subitens: null };
						if (item.menu_group === null || item.menu_group === "") {
							arrAux = { menu_label: item.label, item: item, subitens: null };
							services.push(arrAux);
						} else {
							let auxItem = services.find(
								each =>
									each.menu_label.toLowerCase() ===
									item.menu_group.toLowerCase()
							);
							if (auxItem !== undefined) {
								auxItem.subitens.push(item);
							} else {
								arrAux = {
									menu_label: item.menu_group,
									vue_icon: item.vue_icon,
									item: null,
									subitens: [item]
								};
								services.push(arrAux);
							}
						}
					}
					const serv = services.sort(this.dynamicSort("menu_label"));
					const token = jwt.sign({ services: serv }, "12345", {
						expiresIn: 600
					});
					localStorage.setItem(
						"APP_02",
						JSON.stringify(token).replaceAll('"', "")
					);
				})
				.catch(e => {
					console.log(e);
				});
		},

		showRadioChildren(index) {
			if (this.$refs["panel" + index][0].classList.contains("panel")) {
				this.$refs["panel" + index][0].classList.remove("panel");
				this.$refs["panel" + index][0].classList.add("panel-animation");
			} else {
				this.$refs["panel" + index][0].classList.remove("panel-animation");
				this.$refs["panel" + index][0].classList.add("panel");
			}
		},
		dynamicSort(property) {
			var sortOrder = 1;

			if (property[0] === "-") {
				sortOrder = -1;
				property = property.substr(1);
			}

			return function(a, b) {
				if (sortOrder == -1) {
					return b[property].localeCompare(a[property]);
				} else {
					return a[property].localeCompare(b[property]);
				}
			};
		},

		arrayBufferToBase64(ab) {
			let dView = new Uint8Array(ab); //Get a byte view
			let arr = Array.prototype.slice.call(dView); //Create a normal array
			let arr1 = arr.map(function(item) {
				return String.fromCharCode(item); //Convert
			});
			return window.btoa(arr1.join("")); //Form a string
		}
	},

	watch: {
		searchString: function() {
			if (this.searchString) {
				this.isClosed = false;
			}
		}
	},
	mounted() {
		this.$root.$on('reloadMenuServices', (payload)=>{
			this.radio_current = payload
			this.nameRadio = this.radio_current.name ? this.radio_current.name : null;
			this.logoRadio = this.radio_current.logo ? this.radio_current.logo : null;
            this.getServicesRadio(payload.id)
        })

		if (this.$route.path !== "/forms/wizard") {
			this.getRadiosByUserId();

			this.nameRadio = this.radio_current.name ? this.radio_current.name : null;
			this.logoRadio = this.radio_current.logo ? this.radio_current.logo : null;
		}
	}
};
</script>
<style>
.icon-radio-catalog {
	width: 16px;
	padding-left: 5px;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
	/* background-color: #eee; */
	color: #444;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	text-align: left;
	border: none;
	outline: none;
	transition: 0.4s;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
	display: none;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity 1s;
	transition: opacity 1s;
}
.panel-animation {
	display: block;
	visibility: visible;
	opacity: 1;
	animation: fade 1s;
}

.collapsed {
	transition: opacity 1s;
}

.input-search {
	display: flex;
	justify-content: center;
	padding-top: 20px;
}

.input-search > input {
	width: 90%;
	height: 40px;
	border-radius: 7px;
	font-size: 16px;
	text-align: center;
	background-color: #3b3b3b;
	border-color: #00000033;
	color: #ffffff;
}

.radio-parent > li {
	list-style-type: none;
}

@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
</style>
