import { saveAs } from "file-saver";

function saveExcelTable(fileName, workbook) {
	workbook.xlsx.writeBuffer({ base64: true }).then(buffer => {
		saveAs(
			new Blob([buffer], {
				type:
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			}),
			fileName
		);
	});
}

export default saveExcelTable;