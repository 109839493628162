<template>
	<div>
		<el-dialog
			width="70%"
			title="Visualização de campanha"
			:visible.sync="openPreviewInfo"
			:close-on-click-modal="false"
			:center="true"
			:lock-scroll="false"
		>
			<div class="main-container">
				
				<div class="md-layout">
					<div
						class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
					>
						<h2 style="margin: 0;">{{ campaign.name }}</h2>
					</div>
					<div
						class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
					>
						<h5 style="display: inline-block">
							{{ new Date(campaign.start_date).toLocaleDateString("pt-BR") }} às {{ campaign.start_time.slice(0,-3) }}
						</h5>
						&nbsp;
						<i class="fa fa-circle" style="display: inline-block;"></i>
						&nbsp;
						<h5 style="display: inline-block">
							{{ new Date(campaign.end_date).toLocaleDateString("pt-BR") }} às {{ campaign.end_time.slice(0,-3) }}
						</h5>
					</div>
					<div
						class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
					>
						<template v-if="campaign.expired === 1">
							<i style="color:#ef6b6c" class="fas fa-circle"></i>&nbsp;
							<span style="color:#ef6b6c">FINALIZADA</span>
						</template>
						<template v-else-if="campaign.current === 1">
							<i style="color:#4baf51" class="fas fa-circle"></i>&nbsp;
							<span style="color:#4baf51">ABERTA</span>
						</template>
						<template v-else-if="campaign.opened === 0">
							<i style="color:#c9cacb" class="fas fa-circle"></i>&nbsp;
							<span style="color:#c9cacb">PRÓXIMA</span>
						</template>
					</div>
					<div
						class="md-layout-item md-size-100"
					>
						<Spinner v-if="loading"/>
						<div class="md-layout" v-else>
							<h4 class="md-layout-item md-size-100" style="padding: 0;">Banners cadastrados</h4>
							<el-table
								style="padding: 0;"
								class="md-layout-item md-size-100"
								:data="banners"
								>
								<el-table-column
									prop="name"
									label="Nome"
								>
								</el-table-column>
								<el-table-column
									prop="position"
									label="Área"
								>
								</el-table-column>
								<el-table-column
									prop="url"
									label="URL"
								>
								</el-table-column>
								<el-table-column>
									<template slot-scope="scope">
										<el-button
											icon="el-icon-view"
											round
											type="primary"
											@click="showBannerPopup(scope.row)"
										>
											Visualizar banner
										</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { Dialog, Button } from "element-ui";
import Spinner from '../../../components/Icons/Spinner.vue'
import models from '../../../routes/models';
import Swal from "sweetalert2";
export default {
	name: "ViewInfo",
	components: {
		[Button.name]: Button,
		[Dialog.name]: Dialog,
		Spinner
	},
	data() {
		return {
			openPreviewInfo: false,
			campaign: {},
			banners: [],
			loading: false
		};
	},

	methods: {
		showBannerPopup(banner) {
			Swal.fire({
				title: "Visualização de banner",
				html: `
					<h3>Nome: ${banner.name}</h3>
					<h5>Posição: ${banner.position}</h5>
					<div style="background: #e3e3e3">
						<img src=${banner.image} width=300>
					</div>
				    `,
				showCloseButton: true
			});
		}
	},

	mounted() {
		this.$root.$on("openPreviewCampaign", payload => {
			this.campaign = payload;
			this.openPreviewInfo = true;
			this.loading = true
            models()
                .get(`mobads/getBanners?idMobAd=${payload.id}`)
                .then(banners => {
                    this.banners = []
                    for (let item of banners.data) {
                        this.banners.push({
                            idCampaign: item.campaign_id,
                            idBannerType: item.banner_type_id,
                            idZone: item.zone_id,
                            image: item.url_image,
                            name: item.name,
                            type: item.type,
                            url: item.url_image_click,
                            position: item.zone,
                            hash: item.hash ? item.hash : ""
                        });
                    }

					this.banners.sort((a, b) => {
						if (a.name < b.name) {
							return -1
						}
						if (a.name > b.name) {
							return 1
						}
						return 0
					})
					
					this.loading = false
                });
		});
	}
};
</script>

<style scoped>
.main-container {
	min-height: 750px;
}

.url-redirect-box {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
</style>
