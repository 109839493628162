var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Nova área",
            visible: _vm.openNewZone,
            "before-close": _vm.closeModal,
            "close-on-click-modal": false,
            center: true,
            "lock-scroll": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openNewZone = $event
            },
          },
        },
        [
          _c("el-steps", {
            attrs: { active: _vm.activeStep, "finish-status": "success" },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeStep === 0,
                  expression: "activeStep === 0",
                },
              ],
              staticClass: "main-container",
              staticStyle: { "margin-top": "20px" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                },
                [
                  _c("div", { staticClass: "md-layout divDescription" }, [
                    _c("span", { staticClass: "info-span" }, [
                      _vm._v(
                        " Olá, aqui você irá preencher as informações para cadastrar uma nova área no dispositivo "
                      ),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Nome da área*" },
                      model: {
                        value: _vm.zone.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.zone, "name", $$v)
                        },
                        expression: "zone.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                  },
                  [
                    _c("el-input", {
                      attrs: { type: "text-area", placeholder: "Descrição" },
                      model: {
                        value: _vm.zone.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.zone, "description", $$v)
                        },
                        expression: "zone.description",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Width*" },
                      model: {
                        value: _vm.zone.width,
                        callback: function ($$v) {
                          _vm.$set(_vm.zone, "width", $$v)
                        },
                        expression: "zone.width",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Heigth*" },
                      model: {
                        value: _vm.zone.height,
                        callback: function ($$v) {
                          _vm.$set(_vm.zone, "height", $$v)
                        },
                        expression: "zone.height",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50",
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder:
                          "Tempo de rotação do banner (em segundos)*",
                      },
                      model: {
                        value: _vm.zone.banner_rotation_time,
                        callback: function ($$v) {
                          _vm.$set(_vm.zone, "banner_rotation_time", $$v)
                        },
                        expression: "zone.banner_rotation_time",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.errors.length
                ? _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                    },
                    [
                      _c("p", [
                        _c("b", [
                          _vm._v(
                            "Por favor, corrija os campos conforme solicitado:"
                          ),
                        ]),
                        _c(
                          "ul",
                          _vm._l(_vm.errors, function (error) {
                            return _c(
                              "li",
                              { key: error, staticStyle: { color: "red" } },
                              [_vm._v(_vm._s(error))]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "md-layout",
                  staticStyle: { "margin-top": "2rem" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-80 md-large-size-80 md-xlarge-size-80",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "12px" },
                          attrs: { type: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.nextStep(1)
                            },
                          },
                        },
                        [_vm._v("Cadastrar")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }