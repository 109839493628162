<template>
    <div class="md-layout">
        <div v-show="loading" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
            <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>
        <div v-show="!loading && blockStatus == 1" class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size100">
            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size100">
                <md-card>
                    <md-card-header>
                        <div class="card-icon">
                            <md-icon>input</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content>
                        <div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
                            <md-field>
                                <label for="period">Escolher período</label>
                                <md-select v-model="period">
                                    <md-option value=0>Alcance do dia</md-option>
                                    <md-option value=1>Alcance da última hora</md-option>
                                    <md-option value=2>Alcance das últimas 4 horas</md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100" v-show="info">
                <md-card>
                    <md-card-header class="md-card-header md-card-header-blue md-card-header-icon">
                        <div class="card-icon">
                            <md-icon>timeline</md-icon>
                        </div>
                    </md-card-header>
                    <md-card-content>
                        <canvas id="getGraphicPerMinute"></canvas>
                    </md-card-content>
                </md-card>
            </div>
            <div class="md-layout" >
                <div class="md-layout-item md-small-size-100 md-large-size-50 md-xlarge-size-50" v-show="info">
                    <md-card>
                        <md-card-header>
                            <div class="card-icon card-red">
                                <md-icon>access_time</md-icon>
                            </div>
                            <h4>Últimos 20 minutos</h4>
                        </md-card-header>
                        <md-card-content class="heigthTable">
                            <md-table v-model="tableLastMinutes" table-header-color="green" ref="tableLastMinutes">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Hora">{{ item.hour }}</md-table-cell>
                                    <md-table-cell md-label="Ouvintes">{{ item.listeners | formatNumber }}</md-table-cell>
                                    <md-table-cell md-label="Tune In">{{ item.tunein | formatNumber }}</md-table-cell>
                                    <md-table-cell md-label="Tune Out">{{ item.tuneout | formatNumber }}</md-table-cell>
                                </md-table-row>
                            </md-table>
                        </md-card-content>
                    </md-card>
                </div>
                <div class="md-layout-item md-small-size-100 md-large-size-50 md-xlarge-size-50" v-show="info">
                    <md-card>
                        <md-card-header>
                            <div class="card-icon card-darkorange">
                                <md-icon>access_time</md-icon>
                            </div>
                            <h4>Alcance médio por hora</h4>
                        </md-card-header>
                        <md-card-content class="heigthTable">
                            <md-table v-model="tableDataAverage" table-header-color="green">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <!-- <md-table-cell md-label="">{{ item.id }}</md-table-cell> -->
                                    <md-table-cell md-label="Hora">{{ item.hora }}</md-table-cell>
                                    <md-table-cell md-label="Ouvintes/Min">{{ item.total_listeners | formatNumber }}</md-table-cell>
                                    <md-table-cell md-label="Tune In/Min">{{ item.total_tunein | formatNumber }}</md-table-cell>
                                    <md-table-cell md-label="Tune Out/Min">{{ item.total_tuneout | formatNumber }}</md-table-cell>
                                </md-table-row>
                            </md-table>
                            <!-- <md-card-header> -->
                            <h4>Hora com maior alcance médio</h4>
                            <!-- </md-card-header> -->
                            <md-table table-header-color="green">
                                <md-table-row slot="md-table-row">
                                    <md-table-cell md-label="Horário"><b>Horário: </b>{{hourMax}}</md-table-cell>
                                    <md-table-cell md-label="Ouvintes/Min"><b>Ouvintes/Min: </b>{{listenerMax | formatNumber}}</md-table-cell>
                                </md-table-row>
                            </md-table>
                        </md-card-content>
                    </md-card>
                </div>
            </div>
            <div class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100" v-show="!info">
                <md-card class="md-layout-item md-small-size-100 md-large-size-100 md-xlarge-size-100" style="text-align: center">
                    <h4>Sem informações no momento.</h4>
                </md-card>
            </div>
        </div>
        <block v-show="blockStatus == 0"></block>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Swal from 'sweetalert2'
    import models from '../../routes/models.js'
    import Chart from 'chart.js'
    import selectRadio from '../../utils/events/select-radio.js'
    import ServiceBlock from '../../routes/service-block.js'
    import block from './Block.vue'
    const numeral = require('numeral')
    const locale = require('numeral/locales')
    numeral.locale('pt-br')
    Vue.filter("formatNumber", function(value) {
        return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
    })
    import {
        ChartCard,
        AnimatedNumber,
    } from '@/components'

    export default {
        components: {
            ChartCard,
            AnimatedNumber,
            block
        },
        data() {
            return {
                period: 0,
                colouredLineChart: {
                    data: {
                        labels: ['00:00', '01:00', '01:45', '02:30', '03:00', '04:00', '05:25', '06:30', '07:00', '08:03', '08:43'],
                        series: [
                            [287, 385, 490, 554, 586, 698, 695, 752, 788, 846, 944]
                        ]
                    },
                    options: {
                        lineSmooth: this.$Chartist.Interpolation.cardinal({
                            tension: 10
                        }),
                        axisY: {
                            showGrid: true,
                            offset: 40
                        },
                        axisX: {
                            showGrid: false,
                        },
                        low: 0,
                        high: 1000,
                        showPoint: true,
                        height: '350px'
                    }
                },
                tableDataAverage: [],
                appKey: JSON.parse(localStorage.getItem('radio-default')).key,
                loading: false,
                tableLastMinutes: [],
                getGraphic: null,
                hourMax: 0,
                listenerMax: 0,
                info: false,
                blockStatus: 1
            }
        },
        methods: {
            async getGraphicPerMinute() {
                await models().get(`streaming/getGraphicPerMinute/${this.appKey}`)
                    .then((resp) => {
                        if (resp.data.length > 0) {
                            let labels = []
                            let data = []
                            for (let itens of resp.data) {
                                labels.push(itens.hora)
                                data.push(itens.total_listeners)
                            }
                            this.generateGraphic(labels, data)
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            },
            async getLastMinutes() {
                this.tableLastMinutes = []
                return await models().get(`streaming/lastMinutes/${this.appKey}`)
                    .then((resp) => {
                        if (resp.data.length > 0) {
                            for (let itens of resp.data) {
                                this.tableLastMinutes.push(itens)
                            }
                            return this.tableLastMinutes
                        }
                    })
            },
            async getAverageLastHour() {
                this.tableDataAverage = []
                return await models().get(`streaming/averageLastHour/${this.appKey}`)
                    .then((resp) => {
                        if (resp.data.length > 0) {
                            for (let itens of resp.data) {
                                this.tableDataAverage.push(itens)
                            }
                            this.getMaxListeners()
                            return this.tableDataAverage
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            },
            getLastHourPerMinute() {
                models().get(`streaming/getLastHourPerMinute/${this.appKey}`)
                    .then((resp) => {
                        let labels = []
                        let data = []
                        for (let itens of resp.data) {
                            labels.push(itens.hora)
                            data.push(itens.total_listeners)
                        }
                        this.generateGraphic(labels, data)
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            },
            getLastFourHour() {
                models().get(`streaming/getLastFourHour/${this.appKey}`)
                    .then((resp) => {
                        let labels = []
                        let data = []
                        for (let itens of resp.data) {
                            labels.push(itens.hora)
                            data.push(itens.total_listeners)
                        }
                        this.generateGraphic(labels, data)
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            },
            async getLastMinute() {
                return await models().get(`streaming/getLastMinute/${this.appKey}`)
            },
            generateGraphic(labels, data) {
                const chart = document.getElementById('getGraphicPerMinute')
                const ctx = chart.getContext("2d")
                const render = new Chart(ctx, {
                    type: 'line',
                    data: {
                        labels: labels,
                        datasets: [{
                            data: data,
                            label: false,
                            borderColor: "#5EB54A",
                            backgroundColor: "#5EB54A",
                            fill: false,
                            pointRadius: 3,
                            pointHoverRadius: 3,
                        }]
                    },
                    options: {
                        responsive: true,
                        intersect: true,
                        legend: {
                            display: false
                        },
                        tooltips: {
                            mode: 'x'
                        }
                    }
                })
                this.loading = false
                return render
            },
            removeLastChild() {
                const select = this.$refs.tableLastMinutes
                select.$el.querySelector("table>tbody").lastElementChild.remove()
            },
            getMaxListeners() {
                let maxListener = []
                for (let i of this.tableDataAverage) {
                    maxListener.push(parseInt(i.total_listeners))
                }
                var max = maxListener.reduce(function(a, b) {
                    return Math.max(a, b);
                })
                for (let i of this.tableDataAverage) {
                    if (i.total_listeners == max) {
                        this.hourMax = i.hora
                        this.listenerMax = max
                    }
                }
            },
            loadPage() {
                Promise.all([this.getLastMinutes(), this.getAverageLastHour()])
                    .then((resp) => {
                        console.log(resp)
                        if (resp[0] == undefined || resp[1] == undefined) {
                            this.info = false
                        } else {
                            this.getGraphicPerMinute()
                            window.setInterval(() => {
                                let select = this.$refs.tableLastMinutes
                                Promise.resolve(this.getLastMinute())
                                    .then((resp) => {
                                        let tr = document.createElement('tr')
                                        tr.className = "md-table-row"
                                        tr.innerHTML = `<td class="md-table-cell">
                                        <div class="md-table-cell-container">${resp.data[0].hour}</div>
                                    </td>
                                    <td class="md-table-cell">
                                        <div class="md-table-cell-container">${numeral(resp.data[0].listeners).format('0,0')}</div>
                                    </td>
                                    <td class="md-table-cell">
                                        <div class="md-table-cell-container">${numeral(resp.data[0].tunein).format('0,0')}</div>
                                    </td>
                                    <td class="md-table-cell">
                                        <div class="md-table-cell-container">${numeral(resp.data[0].tuneout).format('0,0')}</div>
                                    </td>`

                                        this.removeLastChild()
                                        select.$el.querySelector("table>tbody").prepend(tr)
                                    })

                                if (this.period == 0) {
                                    this.getGraphicPerMinute()
                                }
                                if (this.period == 1) {
                                    this.getLastHourPerMinute()
                                }
                                if (this.period == 2) {
                                    this.getLastFourHour()
                                }
                            }, 60 * 1000)
                            this.info = true
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            blockPage() {
                const menu = JSON.parse(localStorage.getItem('radio-services'))
                let service = null
                for(let obj of menu) {
                    if(obj.subitens === null) {
                        const key = Object.keys(obj.item)[Object.values(obj.item).indexOf(this.$route.path.replace("/", ""))]
                        if(key) {
                            service = obj.item
                        }
                    } else {
                        const key = Object.keys(obj.subitens)[Object.values(obj.subitens).indexOf(this.$route.path.replace("/", ""))]
                        if(key) {
                            service = obj.subitens
                        }
                    }
                }
                
                if(service) {
                    this.blockStatus = service.has_permission
                } else {
                    this.$router.push("dashboard")
                }
            },
            findObjectByKey(array, key, value) {
                for (var i = 0; i < array.length; i++) {
                    if (array[i][key] === value) {
                        return array[i];
                    }
                }
                return null;
            },
        },
        mounted() {
            this.loading = true
            if (localStorage.getItem('radio-default')) {
                this.appKey = JSON.parse(localStorage.getItem('radio-default')).key
                this.loadPage()
                this.getGraphicPerMinute()
                this.blockPage()
            }
            selectRadio.$on('selectRadio', (payload) => {
                this.appKey = payload.key
                this.loading = true
                this.blockPage()
            })
        },
        watch: {
            appKey() {
                this.loadPage()
            },
            period: function() {
                if (this.period == 0) {
                    this.getGraphicPerMinute()
                }
                if (this.period == 1) {
                    this.getLastHourPerMinute()
                }
                if (this.period == 2) {
                    this.getLastFourHour()
                }
            }
        }
    }
</script>

<style>
    @media (min-width: 960px) {
        .heigthTable {
            height: 1060px !important;
        }
    }
</style>