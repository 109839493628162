var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "pagination", class: _vm.paginationClass },
    [
      _c(
        "li",
        {
          staticClass: "page-item prev-page",
          class: { disabled: _vm.value === 1, "no-arrows": _vm.noArrows },
        },
        [
          _c(
            "a",
            {
              staticClass: "page-link",
              attrs: { "aria-label": "Previous" },
              on: { click: _vm.prevPage },
            },
            [_c("i", { staticClass: "fas fa-angle-double-left" })]
          ),
        ]
      ),
      _vm._l(_vm.range(_vm.minPage, _vm.maxPage), function (item) {
        return _c(
          "li",
          {
            key: item,
            staticClass: "page-item",
            class: { active: _vm.value === item },
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link",
                on: {
                  click: function ($event) {
                    return _vm.changePage(item)
                  },
                },
              },
              [_vm._v(_vm._s(item))]
            ),
          ]
        )
      }),
      _c(
        "li",
        {
          staticClass: "page-item page-pre next-page",
          class: {
            disabled: _vm.value === _vm.totalPages,
            "no-arrows": _vm.noArrows,
          },
        },
        [
          _c(
            "a",
            {
              staticClass: "page-link",
              attrs: { "aria-label": "Next" },
              on: { click: _vm.nextPage },
            },
            [_c("i", { staticClass: "fas fa-angle-double-right" })]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }