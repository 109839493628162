<template>
    <div>
        <el-dialog
            title="Editar Popup"
            :visible.sync="show"
            width="30%"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
        >
            <el-form :model="form">
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-80 md-large-size-80 md-xlarge-size-80"
                    >
                        <el-input
                            type="text"
                            v-model="form.title"
                            autocomplete="off"
                            placeholder="Título"
                        ></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-80 md-large-size-80 md-xlarge-size-80"
                    >
                        <el-input
                            type="textarea"
                            v-model="form.message"
                            autocomplete="off"
                            placeholder="Mensagem"
                        ></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-80 md-large-size-80 md-xlarge-size-80"
                    >
                        <el-select
                            v-model="form.type"
                            placeholder="Tipo"
                            style="width:100%;"
                        >
                            <el-option value="Informação">Informação <i style="color: #23a5d8" class="fas fa-circle"></i></el-option>
                            <el-option value="Atualização">Atualização <i style="color: #51ab55" class="fas fa-circle"></i></el-option>
                            <el-option value="Alerta">Alerta <i style="color: #ffa500" class="fas fa-circle"></i></el-option>
                            <el-option value="Manutenção">Manutenção <i style="color: #ff0000" class="fas fa-circle"></i></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-80 md-large-size-80 md-xlarge-size-80"
                    >
                        <el-date-picker
							v-model="form.expiration_date"
							type="datetime"
							format="dd-MM-yyyy HH:mm:ss"
							placeholder="Data de expiração"
							style="width:100% !important;"
						></el-date-picker>
                    </div>
                </div>
                <br>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-80 md-large-size-80 md-xlarge-size-80"
                    >
                        <span style="color: red">Atenção: Antes de alterar o status pra ativo, verifique se não existe nenhum Popup em atividade.</span>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-80 md-large-size-80 md-xlarge-size-80"
                    >
                        <el-select
                            v-model="form.status"
                            placeholder="Tipo"
                            style="width:100%;"
                        >
                            <el-option value="Ativo">Ativo</el-option>
                            <el-option value="Inativo">Inativo</el-option>
                        </el-select>
                    </div>
                </div>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button
                    type="primary"
                    @click="updatePopup()"
                    >Confirmar</el-button
                >
				<el-button @click="closePopupEdit()">Cancelar</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
  Dialog,
  Button,
  Form,
  Input,
  Select,
  Option,
  Upload,
  Checkbox,
  Tabs,
  TabPane,
  OptionGroup,
  DatePicker
} from "element-ui";
import models from '../../../routes/models'
import swal from 'sweetalert2';
export default {
    components: {
        [Dialog.name]: Dialog,
        [Button.name]: Button,
        [Form.name]: Form,
        [Input.name]: Input,
        [Select.name]: Select,
        [Option.name]: Option,
        [Upload.name]: Upload,
        [Checkbox.name]: Checkbox,
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
        [OptionGroup.name]: OptionGroup,
        [DatePicker.name]: DatePicker
    },
    data() {
        return {
            show: false,
            form: {
                id: '',
                title: '',
                message: '',
                type: '',
                expiration_date: ''
            }
        }
    },
    methods: {
        updatePopup() {
            this.show = false
            swal({
                html: "Atualizando...",
                type: "info",
                onOpen: () => {
                    swal.showLoading()
                    models().post("/popups/editPopup", this.form)
                        .then(() => {
                            swal.close()
                            swal(
                                'Pronto',
                                'Popup atualizado com sucesso',
                                'success'
                            )
                            this.$root.$emit("reloadPopupPage")
                        })  
                        .catch((error) => {
                            console.log(error)
                        })
                }
            })
        },
        closePopupEdit() {
            document.getElementById("box-popup").remove();
        }
    },
    mounted() {
        this.$parent.$on("openPopupEdit", (payload) => {
            this.form = {
                id: payload.id,
                title: payload.title,
                message: payload.message,
                type: (payload.type === 1) ? 'Informação' : (payload.type === 2) ? 'Atualização' : (payload.type === 3) ? 'Alerta' : (payload.type === 4) ? 'Manutenção' : '',
                status: (payload.status === 0) ? 'Inativo' : (payload.status === 1) ? 'Ativo' : 0,
                expiration_date: new Date(payload.expiration_date).toLocaleString('pt-BR')
            }
            this.show = true
        })
    }
}
</script>

