<template>
    <div style="text-align: center;">
        <img
            :src="logo"
            style="width: 7rem; border-radius: 3.5rem; border: 1px solid #707070;"
            :alt="name"
        />
        <h5
            style="text-align: center; margin: 10px 0 30px 0; color: #707070;"
        >
            {{ name }}
        </h5>
    </div>
</template>

<script>
    export default {
        name:'RadioCatalogItem',
        props: ['logo', 'name']

    }
</script>


<style>

</style>