var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.openDialog, title: "Nova Rede Social" },
          on: {
            "update:visible": function ($event) {
              _vm.openDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "md-layout md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-40 md-large-size-40 md-xlarge-size-40",
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "Selecione" },
                      model: {
                        value: _vm.selectedSocial,
                        callback: function ($$v) {
                          _vm.selectedSocial = $$v
                        },
                        expression: "selectedSocial",
                      },
                    },
                    _vm._l(_vm.socialNetworks, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: [item] },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-60 md-large-size-60 md-xlarge-size-60",
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "URL da rede social" },
                    model: {
                      value: _vm.uri,
                      callback: function ($$v) {
                        _vm.uri = $$v
                      },
                      expression: "uri",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                },
                [
                  _c("br"),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.newSocialNetwork()
                        },
                      },
                    },
                    [_vm._v("Cadastrar")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }