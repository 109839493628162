<template>
    <div class="md-layout">
        <el-dialog
            title="Novo Canal"
            :visible.sync="dialogNewPost"
            width="30%"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
        >
            <el-form :model="form">
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <span>Campos com (*) são obrigatórios.</span>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-input
                            type="text"
                            v-model="form.title"
                            autocomplete="off"
                            placeholder="Título*"
                        ></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-input
                            type="textarea"
                            v-model="form.description"
                            autocomplete="off"
                            placeholder="Descrição*"
                        ></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-select
                            v-model="form.language"
                            placeholder="Idioma*"
                            style="width:100%;"
                        >
                            <el-option value="DE">Alemão</el-option>
                            <el-option value="ZH">Chinês (Mandarin)</el-option>
                            <el-option value="ES">Espanhol</el-option>
                            <el-option value="FR">Francês</el-option>
                            <el-option value="EN">Inglês</el-option>
                            <el-option value="IT">Italiano</el-option>
                            <el-option value="JA">Japonês</el-option>
                            <el-option value="PT">Português</el-option>
                            <el-option value="RU">Russo</el-option>
                        </el-select>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-input
                            type="email"
                            v-model="form.email"
                            autocomplete="off"
                            placeholder="Email*"
                        ></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-input
                            type="text"
                            v-model="form.author"
                            autocomplete="off"
                            placeholder="Autor*"
                        ></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-input
                            type="text"
                            v-model="form.copyright"
                            autocomplete="off"
                            placeholder="Direitos Autorais*"
                        ></el-input>
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-select
                            v-model="form.category"
                            multiple
                            :multiple-limit="3"
                            placeholder="Selecione até 3 categorias"
                            style="width:100%;"
                        >
                            <el-option-group
                                v-for="group in options"
                                :key="group.label"
                                :label="group.label"
                            >
                                <el-option
                                    v-for="item in group.options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-option-group>
                        </el-select>
                        <!-- <el-input type="text" v-model="form.category" autocomplete="off" placeholder="Categorias"></el-input> -->
                    </div>
                </div>
                <div class="md-layout">
                    <div
                        style="padding-top:20px;"
                        class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
                    >
                        <span style="color:#c1c5cd;" ref="msgImg"
                            >Capa do podcast (1400x1400 px)</span
                        >
                        <el-upload
                            class="avatar-uploader avatar-gifts"
                            action="#"
                            accept="image/jpeg, image/png"
                            :auto-upload="false"
                            :show-file-list="false"
                            :on-change="getFileImage"
                        >
                            <img
                                v-if="urlPreviewImage"
                                :src="urlPreviewImage"
                                class="avatar"
                            />
                            <i
                                v-else
                                class="el-icon-plus avatar-uploader-icon"
                            ></i>
                        </el-upload>
                        <span ref="msgImg"></span>
                    </div>
                </div>
                <br />
                <div class="md-layout">
                    <div
                        class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
                    >
                        <el-checkbox
                            type="checkbox"
                            v-model="form.explicit"
                        ></el-checkbox
                        >&nbsp;
                        <span style="color:#a2a4a7;">Conteúdo explícito</span>
                    </div>
                </div>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogNewPost = false">Cancelar</el-button>
                <el-button
                    type="primary"
                    @click="newChannel()"
                    :disabled="propertyBtnConfirm"
                    >Confirmar</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
    Dialog,
    Button,
    Form,
    Input,
    Select,
    Option,
    Upload,
    Checkbox,
    Tabs,
    TabPane,
    OptionGroup
} from "element-ui";
import Swal from "sweetalert2";
import Axios from "axios";
import categoryChannel from "./category";
import ApiFirebase from "../../../../routes/api-firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import services from '../../../../routes/services'
import jwt_decode from "jwt-decode"

function handleFileSelect(file, self) {
    let f = file; // FileList object
    let reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function(theFile) {
        return function(e) {
            let binaryData = e.target.result;
            //Converting Binary Data to base 64
            let base64String = window.btoa(binaryData);
            //showing file converted to base64
            self.imgBase64String = base64String;
        };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
}

async function uploadCloudinary(key, file, service) {
    const URL = `https://api.painel.mobradio.com.br/services/${service}`;
    const formData = new FormData();
    const config = { headers: { "content-type": "multipart/form-data" } };

    formData.append("key", key);
    formData.append("attachments", file);

    return await services().post(`services/${service}`, formData, config).then(res => {
        return res.data;
    });
}

export default {
    components: {
        [Dialog.name]: Dialog,
        [Button.name]: Button,
        [Form.name]: Form,
        [Input.name]: Input,
        [Select.name]: Select,
        [Option.name]: Option,
        [Upload.name]: Upload,
        [Checkbox.name]: Checkbox,
        [Tabs.name]: Tabs,
        [TabPane.name]: TabPane,
        [OptionGroup.name]: OptionGroup
    },
    data() {
        return {
            radio: jwt_decode(localStorage.getItem('APP_01')).radio,
            dialogNewPost: false,
            form: {
                title: "",
                description: "",
                language: "",
                email: "",
                author: "",
                category: [],
                copyright: "",
                explicit: false,
                type: [],
                resource: "",
                desc: ""
            },
            value: [],
            options: categoryChannel,
            file: null,
            urlPreviewImage: null,
            propertyBtnConfirm: true,
            imgBase64String: ""
        };
    },

    methods: {
        openNewPodcast() {
            this.dialogNewPost = true;
        },
        getFileImage(file) {
            let self = this;
            let span = this.$refs.msgImg;
            let img = new Image();
            this.file = file;
            this.urlPreviewImage = URL.createObjectURL(file.raw);
            handleFileSelect(file.raw, self);
            img.onload = function() {
                if(img.width < 1400 || img.height < 1400) {
                    span.textContent = "A imagem não atende os requisitos minimos de resolução (1400 x 1400 pixels)."
                    span.style.color = "#ff0000"
                    self.propertyBtnConfirm = true
                } else {
                    span.textContent = ""
                    self.propertyBtnConfirm = false
                }
            };
            img.src = this.urlPreviewImage;
        },
        newChannel() {
            this.dialogNewPost = false;
            Swal({
                title: "Cadastrando canal...",
                onOpen: () => {
                    Swal.showLoading();
                    uploadCloudinary(
                        this.radio.key,
                        this.file.raw,
                        "uploadCloudImage"
                    ).then(data => {
                        let channel = {
                            title: this.form.title,
                            description: this.form.description,
                            language: this.form.language,
                            email: this.form.email,
                            author: this.form.author,
                            episodes: this.form.episodes
                                ? this.form.episodes
                                : [],
                            copyright: this.form.copyright,
                            category: this.form.category,
                            image: data.secure_url,
                            explicit: this.form.explicit,
                            key: this.radio.key
                        };
                        ApiFirebase()
                            .post("channels", channel)
                            .then(response => {
                                Swal(
                                    "Pronto",
                                    "Novo canal criado com sucesso, agora você já pode começar a adicionar novos episódios",
                                    "success"
                                );
                                this.clearForm();
                                this.$root.$emit("reloadGet", true);
                            })
                            .catch(erros => {
                                Swal(
                                    "Erro",
                                    "Não foi possível criar um novo canal no momento, por favor, tente novamente mais tarde.",
                                    "error"
                                );
                                this.dialogNewPost = true;
                            });
                    });
                }
            });
        },
        clearForm() {
            (this.form.title = ""),
                (this.form.description = ""),
                (this.form.language = ""),
                (this.form.email = ""),
                (this.form.author = ""),
                (this.form.episodes = []),
                (this.form.copyright = ""),
                (this.form.category = []),
                (this.form.image = ""),
                (this.urlPreviewImage = null),
                (this.form.explicit = false);
        }
    },
    mounted() {
        let self = this;
        self.$root.$on("openNewPodcast", payload => {
            self.dialogNewPost = payload;
        });
    },
    watch: {
        file(val) {
            this.propertyBtnConfirm = false
            console.log(this.propertyBtnConfirm)
        }
    }
};
</script>

<style>
.el-dialog__header {
    font-weight: 400;
}
.el-dialog__body {
    font-weight: 400;
}
.el-dialog__footer {
    text-align: left;
    padding: 10px 35px 20px;
}
</style>
