<template>
	<div class="wrapper" :class="[
		{ 'nav-open': $sidebar.showSidebar },
		{ rtl: $route.meta.rtlActive }
	]">
		<!-- <notifications></notifications> -->
		<side-bar v-if="this.$route.path !== '/forms/wizard' && !this.dialogSelectChildrenRadio">
			<user-menu></user-menu>
			<template slot="links">
				<div v-for="(item, index) in services" class="menu-dashboard" :key="index">
					<sidebar-item v-if="item.item != null && item.menu_label !== 'Status Streaming'" :link="{
						name: item.menu_label,
						icon: item.item.vue_icon,
						path: '/' + item.item.service_url
					}" :key="'A' + index">
					</sidebar-item>
					<sidebar-item v-else :link="{ name: item.menu_label, icon: item.vue_icon }">
						<sidebar-item v-for="(sub, index) in item.subitens"
							:link="{ name: sub.label, path: '/' + sub.service_url }" :key="'B' + index">
						</sidebar-item>
					</sidebar-item>
				</div>
				<sidebar-item v-show="this.user[0].level === 0" class="menu-dashboard" :link="{
					name: 'Áreas do dispositivo',
					path: '/advertising-campaign-zones',
					icon: 'screenshot'
				}"></sidebar-item>
				<sidebar-item v-show="this.user[0].level === 0" class="menu-dashboard" :link="{
					name: 'Popup de Avisos',
					path: '/popup-warnings',
					icon: 'dvr'
				}"></sidebar-item>
				<sidebar-item class="menu-dashboard" :link="{
					name: 'Configurações',
					path: '/configuration/edit',
					icon: 'settings'
				}"></sidebar-item>
				<li class="menu-dashboard">
					<a class="nav-link" @click="()=>{
							sendMixPanelEvent(`logout_confirmation_popup_opened`)
							logout()
						}">
						<md-icon>{{ 'logout' }}</md-icon>
						<p>Sair</p>
					</a>
				</li>

			</template>
		</side-bar>
		<div class="main-panel">
			<top-navbar></top-navbar>

			<div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
				<zoom-center-transition :duration="200" mode="out-in">
					<!-- your content here -->
					<router-view></router-view>
				</zoom-center-transition>
			</div>
			<content-footer v-if="!$route.meta.hideFooter"></content-footer>
		</div>

		<el-dialog :visible.sync="dialogSelectChildrenRadio" :close-on-click-modal="false" :show-close="false"
			:close-on-press-escape="false">
			<div style="display: flex; flex-direction: column; align-items: center;">
				<img src="../../../../public/img/logo-mobradio-black.png" />
				<h3 style="margin-bottom: 2rem;">
					Selecione um das rádios para prosseguir:
				</h3>
				<div class="md-layout">
					<div style="margin: auto;"
						class="md-layout-item md-xsmall-size-50 md-small-size-25 md-medium-size-25 md-large-size-20 md-xlarge-size-20"
						v-for="(radio) in this.catalogFirstSelect" :key="radio">
						<a @click="
							selectRadio(radio)
							" class="md-layout-item md-size-20" style="cursor: pointer;">
							<radio-catalog-item :logo="radio.logo" :name="radio.subName"></radio-catalog-item>
						</a>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import jwt_decode from "jwt-decode";
import { Dialog } from "element-ui";
import jwt from "jsonwebtoken";

function hasElement(className) {
	return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
	if (hasElement(className)) {
		new PerfectScrollbar(`.${className}`);
	} else {
		// try to init it later in case this component is loaded async
		setTimeout(() => {
			initScrollbar(className);
		}, 100);
	}
}

import TopNavbar from "./TopNavbar.vue";
import DashboardContent from "./Content.vue";
import UserMenu from "./Extra/UserMenu.vue";
import RadioCatalogItem from "../Components/RadioCatalogItem.vue";
import models from "../../../routes/models.js";
import selectRadio from "../../../utils/events/select-radio.js";
import mixpanelAdapter from "../../../utils/events/mixpanelAdapter";
import { SlideYDownTransition, ZoomCenterTransition } from "vue2-transitions";
import swal from "sweetalert2";

export default {
	components: {
		TopNavbar,
		DashboardContent,
		RadioCatalogItem,
		UserMenu,
		SlideYDownTransition,
		ZoomCenterTransition,
		[Dialog.name]: Dialog
	},
	data() {
		return {
			services: jwt_decode(localStorage.getItem('APP_02')).services,
			catalogFirstSelect: [],
			dialogSelectChildrenRadio: false,
			radio_current: jwt_decode(localStorage.getItem("APP_01")).radio,
			plan: jwt_decode(localStorage.getItem("SET_APP")).user.pagarme_id,
			user: jwt_decode(localStorage.getItem("SET_APP")).user
		};
	},
	methods: {
		toggleSidebar() {
			if (this.$sidebar.showSidebar) {
				this.$sidebar.displaySidebar(false);
			}
		},
		getServicesRadio() {
			this.services = [];
			const id = this.radio_current.id;
			models()
				.get(`services/getAllRadioServices/${id}`)
				.then(resp => {
					for (let item of resp.data) {
						let arrAux = { menu_label: "", item: null, subitens: null };
						if (item.menu_group === null || item.menu_group === "") {
							arrAux = { menu_label: item.label, item: item, subitens: null };
							this.services.push(arrAux);
						} else {
							let auxItem = this.services.find(
								each =>
									each.menu_label.toLowerCase() ===
									item.menu_group.toLowerCase()
							);
							if (auxItem !== undefined) {
								auxItem.subitens.push(item);
							} else {
								arrAux = {
									menu_label: item.menu_group,
									vue_icon: item.vue_icon,
									item: null,
									subitens: [item]
								};
								this.services.push(arrAux);
							}
						}
					}

					this.services.sort(this.dynamicSort("menu_label"));
				});
		},
		dynamicSort(property) {
			var sortOrder = 1;

			if (property[0] === "-") {
				sortOrder = -1;
				property = property.substr(1);
			}

			return function (a, b) {
				if (sortOrder == -1) {
					return b[property].localeCompare(a[property]);
				} else {
					return a[property].localeCompare(b[property]);
				}
			};
		},
		selectRadio(radio) {

			const token = jwt.sign({ radio }, "12345", {
				expiresIn: 600
			});
			localStorage.setItem(
				"APP_01",
				JSON.stringify(token).replaceAll('"', "")
			);

			this.radio_current = jwt_decode(localStorage.getItem("APP_01")).radio
			selectRadio.$emit("selectRadio", { ...this.radio_current });
			this.$root.$emit('reloadMenuServices', this.radio_current)
			this.dialogSelectChildrenRadio = false;
		},
		logout() {
			swal.fire({
				type: 'warning',
				title: 'Tem certeza?',
				text: 'Tem certeza de que deseja fazer logout?',
				showCancelButton: true,
				showConfirmButton: true,
				confirmButtonText: 'Fazer logout',
				cancelButtonText: 'Continuar no painel',
				confirmButtonColor: '#f44336',
				cancelButtonColor: '#3085d6'
			}).then(result => {
				const logoutConfirmed = result.value
				if (logoutConfirmed) {
					this.sendMixPanelEvent(`logout_confirmed`)
					sessionStorage.clear()
					localStorage.clear()
					this.$session.destroy()
					this.$router.push('/login')
				}else{
					this.sendMixPanelEvent(`logout_canceled`)
				}
			})
		},
		async getChildrenRadios() {
			return await models().get(
				`radios/getChildrenRadios/${this.radio_current.id}`
			);
		},
		sendMixPanelEvent(eventString){
			mixpanelAdapter.track(eventString, {
				screen_name: this.$route.name
			})
		}
	},
	mounted() {
		if (this.$route.path === "/forms/wizard") {
			return;
		}

		this.radio_current = jwt_decode(localStorage.getItem("APP_01")).radio;
		if (this.radio_current.is_parent) {
			this.dialogSelectChildrenRadio = true;
			Promise.resolve(this.getChildrenRadios()).then(res => {
				const childrenRadios = res.data;
				childrenRadios.forEach(radio => {
					this.catalogFirstSelect.push({
						name: radio.name,
						id: radio.id,
						subName: radio.name,
						key: radio.key,
						server_key: this.radio_current.server_key,
						logo: radio.image_logo_url,
						cloud_name: radio.cloudinary_cloud_name,
						master_key: radio.key,
						parent_id: this.radio_current.id,
						parent_name: this.radio_current.name
					});
				});
			});
		} else {
			this.getServicesRadio()
		}

		selectRadio.$on("selectRadio", () => {
			window.setTimeout(() => {
				this.services = [];
				this.services = jwt_decode(localStorage.getItem("APP_02")).services;
			}, 500);
		});
		let docClasses = document.body.classList;
		let isWindows = navigator.platform.startsWith("Win");
		if (isWindows) {
			// if we are on windows OS we activate the perfectScrollbar function
			initScrollbar("sidebar");
			initScrollbar("sidebar-wrapper");

			docClasses.add("perfect-scrollbar-on");
		} else {
			docClasses.add("perfect-scrollbar-off");
		}
	}
};
</script>
<style lang="scss">
$scaleSize: 0.95;

@keyframes zoomIn95 {
	from {
		opacity: 0;
		transform: scale3d($scaleSize, $scaleSize, $scaleSize);
	}

	to {
		opacity: 1;
	}
}

.main-panel .zoomIn {
	animation-name: zoomIn95;
}

@keyframes zoomOut95 {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: scale3d($scaleSize, $scaleSize, $scaleSize);
	}
}

.main-panel .zoomOut {
	animation-name: zoomOut95;
}

ul>li>a>.sidebar-mini {
	visibility: hidden;
}

ul>li>a>.sidebar-mini:after {
	content: " ";
	visibility: visible;
}

.menu-dashboard {
	margin-bottom: 10px !important;
}

.menu-dashboard>li>a>i {
	margin-right: 12px !important;
}

.menu-dashboard>a>i {
	margin-right: 12px !important;
}
</style>