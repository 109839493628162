<template>
  <div class="md-layout">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <i class="material-icons">list</i>
        </div>
      </md-card-header>

      <md-card-content>
        <el-tabs v-model="activeName">
          <el-tab-pane label="Todas" name="first">
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-medium-size-75 md-large-size-75">
                <md-field>
                  <label>Digite sua busca</label>
                  <md-input type="text" v-model="searchString"></md-input>
                </md-field>
              </div>
              <div
                class="md-layout-item md-small-size-100 md-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20">
                <md-button class="md-info md-block" @click="openCoverFormModal"><i class="fas fa-plus"></i> Nova capa
                  especial</md-button>
              </div>
              <div class="md-layout-item md-size-100">
                <div class="md-layout">
                  <CardSpecialCover v-for="cover in this.specialCoversSearched" :cover="cover" :key="cover.id" />
                  <h5 v-if="this.specialCoversSearched.length === 0 && this.searchString">Não há capas especiais para a busca
                    "{{ this.searchString }}".</h5>
                  <h5 v-if="this.specialCoversSearched.length === 0 && !this.searchString"> Não há capas especiais criadas.
                  </h5>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Ativas" name="second">
            <div class="md-layout">
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-medium-size-75 md-large-size-75">
                  <md-field>
                    <label>Digite sua busca</label>
                    <md-input type="text" v-model="searchString"></md-input>
                  </md-field>
                </div>
                <div
                  class="md-layout-item md-small-size-100 md-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20">
                  <md-button class="md-info md-block"
                    @click="openCoverFormModal"
                  ><i class="fas fa-plus"></i> Nova capa especial</md-button>
                </div>
                <div class="md-layout-item md-size-100">
                  <div class="md-layout">
                    <CardSpecialCover v-for="cover in this.specialCoversSearched.filter(cover => cover.active)"
                      :cover="cover" :key="cover.id" />
                    <h5 v-if="this.specialCoversSearched.filter(cover => cover.active).length === 0 && this.searchString">Não há capas especiais ativas para
                      a busca "{{ this.searchString }}".</h5>
                    <h5 v-if="this.specialCoversSearched.filter(cover => cover.active).length === 0 && !this.searchString"> Não há capas especiais ativas
                      criadas.</h5>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Próximas" name="third">
            <div class="md-layout">
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-medium-size-75 md-large-size-75">
                  <md-field>
                    <label>Digite sua busca</label>
                    <md-input type="text" v-model="searchString"></md-input>
                  </md-field>
                </div>
                <div
                  class="md-layout-item md-small-size-100 md-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20">
                  <md-button class="md-info md-block"
                    @click="openCoverFormModal"
                  ><i class="fas fa-plus"></i> Nova capa
                    especial</md-button>
                </div>
                <div class="md-layout-item md-size-100">
                  <div class="md-layout">
                    <CardSpecialCover v-for="cover in this.specialCoversSearched.filter(cover => !cover.active&&!cover.ended)"
                      :cover="cover" :key="cover.id" />
                    <h5 v-if="this.specialCoversSearched.filter(cover => !cover.active&&!cover.ended).length === 0 && this.searchString">Não há capas especiais próximas
                      para a busca "{{ this.searchString }}".</h5>
                    <h5 v-if="this.specialCoversSearched.filter(cover => !cover.active&&!cover.ended).length === 0 && !this.searchString"> Não há capas especiais próximas
                      criadas.</h5>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Finalizadas" name="fourth">
            <div class="md-layout">
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-medium-size-75 md-large-size-75">
                  <md-field>
                    <label>Digite sua busca</label>
                    <md-input type="text" v-model="searchString"></md-input>
                  </md-field>
                </div>
                <div
                  class="md-layout-item md-small-size-100 md-size-100 md-medium-size-20 md-large-size-20 md-xlarge-size-20">
                  <md-button class="md-info md-block"
                    @click="openCoverFormModal"
                  ><i class="fas fa-plus"></i> Nova capa
                    especial</md-button>
                </div>
                <div class="md-layout-item md-size-100">
                  <div class="md-layout">
                    <CardSpecialCover v-for="cover in this.specialCoversSearched.filter(cover => cover.ended)"
                      :cover="cover" :key="cover.id" />
                    <h5 v-if="this.specialCoversSearched.filter(cover => cover.ended).length === 0 && this.searchString">Não há capas especiais finalizadas
                      para a busca "{{ this.searchString }}".</h5>
                    <h5 v-if="this.specialCoversSearched.filter(cover => cover.ended).length === 0 && !this.searchString"> Não há capas especiais finalizadas
                      criadas.</h5>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>

import models from '../../../routes/models'
import jwt_decode from 'jwt-decode'
import CardSpecialCover from './CardSpecialCover.vue'
import Swal from 'sweetalert2'
import moment from "moment-timezone"

export default {
  name: 'CoverList',
  components: {
    CardSpecialCover
  },
  data() {
    return {
      activeName: 'first',
      searchString: '',
      specialCovers: [],
      specialCoversSearched: []
    }
  },
  methods: {
    sortCoversByDate(){
      this.specialCoversSearched.sort(function(a,b){
        return new Date(a.date_start) - new Date(b.date_start);
      });
    },
    getAllSpecialCovers() {
      this.specialCovers = []
      this.specialCoversSearched = []
      Swal.fire({
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })
      const appKey = jwt_decode(localStorage.getItem("APP_01")).radio.key;
      
      models().get(`cover/getAllCovers?appKey=${appKey}`).then(res=>{
        const covers = res.data
        for(let cover of covers){
          let today = new Date()

          let coverDateStart = new Date(cover.date_start)
          let coverDateEnd = new Date(cover.date_end)
          
          let coverObject =  {
            id: cover.id,
            title: cover.title,
            subtitle: cover.subtitle,
            date_start: moment(cover.date_start).tz('America/Sao_Paulo').format(),
            date_end: moment(cover.date_end).tz('America/Sao_Paulo').format(),
            image_url: cover.image_url,
            active: coverDateStart <= today && coverDateEnd >= today,
            ended: coverDateEnd <= today
          }
          this.specialCovers.push(coverObject)
        }
        Swal.close()
        this.specialCoversSearched = this.specialCovers
        this.sortCoversByDate()
      }).catch(err=>{
        console.log(err)
        Swal.fire({
            title: 'Ops!',
            text: "Houve um erro ao tentar recuperar as capas. Caso persista, entre em contato com o suporte.",
            type: 'error'
        })
      })
    },

    openCoverFormModal() {
      this.$root.$emit('openCoverFormModal')
    },

    searchSpecialCovers() {
      if (!this.searchString) {
        this.specialCoversSearched = this.specialCovers
        return;
      }
      this.specialCoversSearched = this.specialCoversSearched.filter(cover => cover.title.toLowerCase().includes(this.searchString.toLowerCase()))
    }


  },

  watch: {
    searchString: function () {
      this.searchSpecialCovers()
    }
  },

  mounted() {

    this.$root.$on('getAllSpecialCovers', ()=>{
      this.getAllSpecialCovers()
    })
    this.getAllSpecialCovers()
  }
}


</script>