var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.item.artist == "Sem resultados."
      ? _c("span", [_vm._v(_vm._s(_vm.item.artist))])
      : _c("span", [
          _vm._v(_vm._s(_vm.item.title) + " - " + _vm._s(_vm.item.artist)),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }