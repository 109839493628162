<template>
	<ReportLayout 
		:radioName="radioName" 
		:radioLogo="radioLogo" 
		reportName="Extrato musical" 
		:sublabel="title + ' - ' + artist"
		:periodStart="periodStart" 
		:periodEnd="periodEnd" 
		:numberOfPages="data.collectionPerHour.length > 9 ? 2 : 1">
		<template v-slot:page-1>
			<div class="md-layout-item md-size-100" 
				style="margin: 0 auto;">
				<ReportCard
					:title="'Estatísticas gerais'" 
					:labels="['Total de likes', 'Total de dislikes', 'Total de votos', 'Total de reproduções', 'Total de pedidos']"
					:keys="['totalLikes', 'totalDislikes', 'totalVotes', 'totalPlayed', 'totalRequested']" 
					:cardData="[{
						totalLikes: $options.filters.formatNumber(data.totalLikes),
						totalDislikes: $options.filters.formatNumber(data.totalDislikes),
						totalVotes: $options.filters.formatNumber(data.totalVotes),
						totalPlayed: $options.filters.formatNumber(data.totalPlayed),
						totalRequested: $options.filters.formatNumber(data.totalRequested),
					}]" 
					:dataDisplay="'big-columns'"
                />
			</div>
			<div class="md-layout-item md-size-100" style="margin: 0 auto;">
				<ReportCard 
					:title="'Extrato por hora'"
					:labels="['Hora', 'Likes', 'Dislikes', 'Votos', 'Reproduções', 'Pedidos']"
					:keys="['hour', 'total_likes', 'total_dislikes', 'total_votes', 'total_played', 'total_requests']"
					:cardData="data.collectionPerHour.slice(0, 7)"
					:dataDisplay="'long-table'">
				</ReportCard>
			</div>
		</template>
		<ReportCard
            class="md-layout-item md-size-100"
            style="margin: 0 auto;"
            v-if="data.collectionPerHour.length > 9"
            :slot="'page-2'"
			:title="'Extrato por hora'"
			:labels="['Hora', 'Likes', 'Dislikes', 'Votos', 'Reproduções', 'Pedidos']"
			:keys="['hour', 'total_likes', 'total_dislikes', 'total_votes', 'total_played', 'total_requests']"
			:cardData="data.collectionPerHour.slice(7, 24)"
			:dataDisplay="'long-table'">
		</ReportCard>
	</ReportLayout>
</template>

<script>
import Vue from "vue";
import ReportLayout from "./partials/ReportLayout.vue";
import ReportCard from "./partials/ReportCard.vue";
import numeral from "numeral";
numeral.locale("pt-br");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

export default {
	name: "TemplateReportExtractMusical",
	components: {
		ReportLayout,
		ReportCard
	},
	props: [
		"radioName",
		"radioLogo",
		"title",
        "artist",
		"periodStart",
		"periodEnd",
		"data"
	]
};
</script>

<style>

.platform-label {
	text-align: left;
	margin-right: 20px;
	display: inline-block;
}

.platform-label img {
	height: 15px;
	width: 15px;
	margin-right: 5px;
	margin-top: -5px;
}

.platform-label span {
	font-size: 100%;
	white-space: nowrap;
}

</style>