<template>
    <div class="md-layout hide-on-print">
        <template v-if="listOfMobAds.length === 0 && searchString === ''">
            <h5>Não foram encontrados MobAds {{ status }}dentro do período informado</h5>
        </template>
        <template v-else-if="listOfMobAds.length ===0 && searchString !== ''">
            <h5>Não foram encontrados MobAds {{status}}para a busca "{{searchString}}" dentro do período informado</h5>
        </template>
        <template v-else v-for="campaign in listOfMobAds">
            <div class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-33 md-xlarge-size-33 mobad-card">
                <MobAdCard :mobAd="campaign"/>
            </div>
        </template>
    </div>
    
</template>

<script>
import MobAdCard from './MobAdCard'

export default {
    name: "CampaignTab",
    components: {
        MobAdCard
    },
    props: ['listOfMobAds', 'searchString', 'status']
}
</script>

<style>

.mobad-card:nth-child(3n+1) {
    padding-left: 2px;
}

.mobad-card:nth-child(3n+3) {
    padding-right: 2px;
}


</style>