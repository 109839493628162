<template>
	<md-card style="margin-top: 0;">
		<md-content>
			<div
				class="md-layout md-alignment-top-center"
				style="background: #e3e3e3"
			>
				<div
					class="md-layout-item md-xsmall-size-100 md-small-size-30 md-medium-size-30 md-large-size-30 md-xlarge-size-20"
					style="position: relative;"
				>
					<div class="md-layout md-alignment-center-center">
						<img class="image-award" :src="this.awardImage?this.awardImage:this.errorImage"/>
						<span v-if="this.errorUpload" style="color: #FF0909; font-size: 90%; text-align: center">
							O tamanho da imagem ultrapassa 200kB
						</span>
						<template v-if="!this.$props.viewOnly">
							<label class="button-upload" :for="'upload_' + this.$props.index">
								{{this.awardImage?"Editar imagem":"Adicionar Imagem"}}
							</label>
							<input
								:id="'upload_' + this.$props.index"
								type="file"
								accept="image/*"
								style="display: none;" 
								@change="loadFile($event)"
							/>
						</template>
					</div>
				</div>
				<div
					class="md-layout-item md-xsmall-size-100 md-small-size-65 md-medium-size-65 md-large-size-60 md-xlarge-size-75"
				>
					<div class="md-layout md-layout md-alignment-top-left">
						<div class="md-layout-item md-size-100">
							<div class="md-layout md-alignment-top-space-between">
								<h6
									class="md-layout-item md-size-100"
									style="margin: 12px 0 0 0;"
								>
									Nome do prêmio
								</h6>
								<div
									class="md-layout-item md-xsmall-size-80 md-small-size-90 md-medium-size-90 md-large-size-90 md-xlarge-size-75"
								>
									<el-input
										autosize
										type="textarea"
										v-model="awardName"
										style="margin: 0 !important;"
										:disabled="!this.editionMode"
										:resize="!this.editionMode?'none':'vertical'"
									></el-input>
									<span :style="awardName.length > 150 || awardName.length === 0 ? 'color: #FF0606' : 'color: #707070'">
										({{ this.awardName.length }}/150) <span v-if="this.awardName.length > 150">O nome do prêmio passa de 150
											caracteres.</span>
											<span v-else-if="this.awardName.length === 0">O nome do prêmio não pode ser vazio.</span>
									</span>
								</div>
								<div
									v-if="!this.editionMode && !this.$props.viewOnly"
									class="md-layout-item md-xsmall-size-15 md-small-size-10 md-medium-size-10 md-large-size-10 md-xlarge-size-10"
									style="margin-top:15px; padding: 0 !important;"
								>
									<el-button
										type="primary"
										style="margin: 0 !important;"
										icon="el-icon-edit"
										circle
										@click="enableEditionMode()"
									>
									</el-button>
								</div>
								<div
									v-else-if="!this.$props.viewOnly"
									class="md-layout-item md-xsmall-size-15 md-small-size-10 md-medium-size-10 md-large-size-10 md-xlarge-size-10"
									style="margin-top:15px; padding: 0 !important;"
								>
									<el-button
										type="success"
										style="margin: 0 !important;"
										icon="el-icon-check"
										circle
										v-if="this.awardName.length <= 150&&this.awardName.length !== 0"
										@click="confirmNameChange()"
									>
									</el-button>
								</div>
							</div>
						</div>
						<div class="md-layout-item md-size-100">
							<div class="md-layout">
								<div
									class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-70  md-large-size-70"
									style="padding-left: 0 !important; padding-right: 0 !important;"
									v-if="!this.$props.viewOnly"
								>
									<div class="md-layout md-alignment-center-center">
										<div class="md-layout-item">
											<el-button
												@click="removeAwardFromList()"
												style="width: 100%; margin: 10px 0;"
												type="danger"
												icon="el-icon-delete"
												round
												>Remover</el-button
											>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</md-content>
	</md-card>
</template>

<script>
import { Button, Input } from "element-ui";
export default {
	name: "CardAward",
	components: {
		[Button.name]: Button,
		[Input.name]: Input
	},
	props: ["awardNameDefault", "awardImageDefault", "viewOnly", "index"],
	data() {
		return {
			awardName: "",
			awardImage: "",
			uploadFile: null,
			errorUpload: false,
			editionMode: false,
			errorImage: require("../../../assets/image-award.png"),
		};
	},
	methods: {
		enableEditionMode() {
			this.editionMode = true;
		},
		cancelEdition() {
			this.awardName = this.$props.awardNameDefault;
			this.$root.$emit("updateAwardName", {
				index: this.$props.index,
				awardName: this.awardName
			});
			this.editionMode = false;
		},
		removeAwardFromList() {
			this.$root.$emit("deleteGift", this.$props.index);
		},
		confirmNameChange() {
			this.$root.$emit("updateAwardName", {
				index: this.$props.index,
				awardName: this.awardName
			});
			this.editionMode = false;
		},
		loadFile(event) {
			let file = event.target.files[0];
			let fileSize = file.size / 1024;

			if (fileSize >= 200) {
				this.awardImage = "";
				this.uploadFile = "";
				this.errorUpload = true;
				this.buttonText = "Adicionar imagem"
			} else {
				const url = URL.createObjectURL(file);
				this.awardImage = url;
				this.uploadFile = file;
				this.errorUpload = false;
				this.buttonText = "Editar imagem"
			}

			this.$root.$emit("updateFile", {
				index: this.$props.index,
				awardImage: this.awardImage,
				uploadFile: this.uploadFile
			});
		}
	},
	mounted() {
		this.awardName = this.$props.awardNameDefault;
		this.awardImage = this.$props.awardImageDefault;
	}
};
</script>

<style>
.edit-image {
	position: absolute;
	top: 0;
	left: 0;
	background-color: #707070;
	z-index: 3;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: 0.2s all;
}

.image-award {
	width: 68px !important;
	height: 68px !important;
	margin: 10px;
	object-fit: contain;
}

.button-upload {
	display: block;
	padding: 5px;
	margin: 10px 0;
	width: 100%;
	height: 2.4rem;
	border-radius: 1.2rem;
	text-align: center;
	background-color: #ffffff;
	cursor: pointer;
	transition: 0.2s all;
}

.button-upload:hover {
	background-color: #a8a8a8;
}
</style>
