<template>
  <div class="md-layout">
    <el-dialog
      title="Novo Episódio"
      :visible.sync="dialogNewEpisode"
      width="30%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <el-form :model="form">
        <div class="md-layout">
          <div
            class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
          >
            <span>Campos com (*) são obrigatórios.</span>
          </div>
        </div>
        <div class="md-layout">
          <div
            class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
          >
            <el-select style="width:100%;" v-model="form.id" placeholder="Atribuir Canal">
              <el-option
                v-for="item in channels"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="md-layout">
          <div
            class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
          >
            <el-input type="text" v-model="form.title" autocomplete="off" placeholder="Título*"></el-input>
          </div>
        </div>
        <div class="md-layout">
          <div
            class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
          >
            <el-input
              type="textarea"
              v-model="form.description"
              autocomplete="off"
              placeholder="Descrição*"
            ></el-input>
          </div>
        </div>
        <div class="md-layout">
          <div
            class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
          >
            <el-date-picker
              v-model="form.datePub"
              type="datetime"
              format="dd-MM-yyyy HH:mm:ss"
              placeholder="Data de publicação (momento em que o episódio vai ao ar)"
              style="width:100% !important;"
            ></el-date-picker>
          </div>
        </div>
        <div class="md-layout">
          <div
            style="padding-top:20px;"
            class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
          >
            <span style="color:#c1c5cd;" ref="msgImg">
              Capa do episódio(Caso não cadastre imagem para o
              episódio, será usado a mesma imagem do canal).
            </span>
            <el-upload
              class="avatar-uploader avatar-gifts"
              action="#"
              accept="image/jpeg, image/png"
              :auto-upload="false"
              :show-file-list="false"
              :on-change="getFileImage"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <span ref="msgImg"></span>
          </div>
        </div>
        <br />
        <div class="md-layout">
          <div
            style="padding-top:20px;"
            class="md-layout-item md-xs-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100"
          >
            <el-upload
              ref="uploadDirectory"
              class="upload-demo"
              accept=".mp3, audio/*"
              action="#"
              :limit="1"
              :multiple="false"
              :auto-upload="false"
              :on-change="getAudio"
            >
              <el-button type="info">Arquivo de áudio</el-button>
            </el-upload>
          </div>
        </div>
        <br />
        <div class="md-layout">
          <div
            class="md-layout-item md-xs-size-70 md-small-size-70 md-medium-size-70 md-large-size-70 md-xlarge-size-70"
          >
            <el-checkbox type="checkbox" v-model="form.explicit" :value="false"></el-checkbox>&nbsp;
            <span style="color:#a2a4a7;">Conteúdo explícito</span>
          </div>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogNewEpisode = false">Cancelar</el-button>
        <el-button type="primary" @click="newEpisode()" :disabled="propertyBtnConfirm">Confirmar</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  Dialog,
  Button,
  Form,
  Input,
  Select,
  Option,
  Upload,
  Checkbox,
  Tabs,
  TabPane,
  OptionGroup,
  DatePicker
} from "element-ui";
import Swal from "sweetalert2";
import categoryChannel from "./category";
import Axios from "axios";
import ApiFirebase from "../../../../routes/api-firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import services from '../../../../routes/services'
import jwt_decode from "jwt-decode"

async function uploadCloudinary(key, file, service) {
  //const URL = `https://api.painel.mobradio.com.br/services/${service}`;
  const formData = new FormData();
  const config = { headers: { "content-type": "multipart/form-data" } };

  formData.append("key", key);
  formData.append("attachments", file);

  return await services().post(`services/${service}`, formData, config).then(res => {
    return res.data;
  });
}
function guidGenerator() {
  const S4 = function() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return S4() + S4() + S4();
}
export default {
  components: {
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Form.name]: Form,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Upload.name]: Upload,
    [Checkbox.name]: Checkbox,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [OptionGroup.name]: OptionGroup,
    [DatePicker.name]: DatePicker
  },
  data() {
    return {
      radio: jwt_decode(localStorage.getItem('APP_01')).radio,
      dialogNewEpisode: false,
      form: {
        id: "",
        idEpisode: "",
        title: "",
        description: "",
        image: "",
        audio: "",
        audioLength: 0,
        explicit: false,
        datePub: ""
      },
      value: [],
      options: categoryChannel,
      uploadedImage: undefined,
      imageUrl: undefined,
      propertyBtnConfirm: false,
      channels: [],
      fileImage: null,
      fileAudio: null
    };
  },

  methods: {
    newEpisode() {
      this.dialogNewEpisode = false;
      Swal({
        title: "Cadastrando episódio...",
        onOpen: () => {
          Swal.showLoading();
          // UPLOAD IMAGEM
          if(this.fileImage !== null && this.fileImage !== '') {
            uploadCloudinary(this.radio.key, this.fileImage.raw,"uploadCloudImage")
              .then(image => {
            this.form.image = image.secure_url;
            // UPLOAD AUDIO
            uploadCloudinary(
              this.radio.key,
              this.fileAudio.raw,
              "audioPodcast"
            ).then(audio => {
              this.form.audio = audio.secure_url;
              this.form.audioLength = this.fileAudio.size;
              this.form.audioType = this.fileAudio.type;
              this.form.idEpisode = guidGenerator();
              this.form.datePub = new Date(this.form.datePub).toGMTString();
              // CADASTRA O EPISÓDIO
              ApiFirebase()
                .post("episodes", this.form)
                .then(response => {
                  Swal(
                    "Pronto",
                    "Novo episódio criado com sucesso!",
                    "success"
                  );
                  this.form = {
                    id: "",
                    idEpisode: "",
                    title: "",
                    description: "",
                    image: "",
                    audio: "",
                    audioLength: 0,
                    explicit: false,
                    datePub: ""
                  },
                  this.fileImage = null
                  this.fileAudio = null
                  this.uploadedImage = undefined
                  this.imageUrl = undefined
                  this.$refs.uploadDirectory.clearFiles()
                  this.$root.$emit("reloadEpisodes", this.form.id);
                })
                .catch(erros => {
                  Swal(
                    "Erro",
                    "Não foi possível cadastrar um novo episódio no momento, por favor, tente novamente mais tarde.",
                    "error"
                  );
                  this.dialogNewPost = true;
                });
            });
          });
          } else {
            this.form.image = null;
            // UPLOAD AUDIO
            uploadCloudinary(
              this.radio.key,
              this.fileAudio.raw,
              "audioPodcast"
            ).then(audio => {
              this.form.audio = audio.url;
              this.form.audioLength = this.fileAudio.size;
              this.form.audioType = this.fileAudio.type;
              this.form.idEpisode = guidGenerator();
              this.form.datePub = new Date(this.form.datePub).toGMTString();
              // CADASTRA O EPISÓDIO
              ApiFirebase()
                .post("episodes", this.form)
                .then(response => {
                  Swal(
                    "Pronto",
                    "Novo episódio criado com sucesso!",
                    "success"
                  );
                  this.form = {
                    id: "",
                    idEpisode: "",
                    title: "",
                    description: "",
                    image: "",
                    audio: "",
                    audioLength: 0,
                    explicit: false,
                    datePub: ""
                  },
                  this.fileImage = null
                  this.fileAudio = null
                  this.uploadedImage = undefined
                  this.imageUrl = undefined
                  this.$refs.uploadDirectory.clearFiles()
                  this.$root.$emit("reloadGetEpisode", this.form.id);
                })
                .catch(erros => {
                  Swal(
                    "Erro",
                    "Não foi possível criar um novo canal no momento, por favor, tente novamente mais tarde.",
                    "error"
                  );
                  this.dialogNewPost = true;
                });
            });
          }
        }
      });
    },
    getFileImage(file) {
      let vm = this;
      let span = this.$refs.msgImg;
      let img = new Image();
      this.uploadedImage = file.raw;
      this.imageUrl = URL.createObjectURL(file.raw);
      this.fileImage = file;
      img.onload = function() {
        if(img.width < 1400 || img.height < 1400) {
            span.textContent = "A imagem não atende os requisitos minimos de resolução (1400 x 1400 pixels)."
            span.style.color = "#ff0000"
            vm.propertyBtnConfirm = true
        } else {
            span.textContent = ""
            vm.propertyBtnConfirm = false
        }
      };
      img.src = this.imageUrl;
    },
    getAudio(res, file) {
      this.fileAudio = file[0];
    },
    async uploadFirebase(file, folder) {
      const storage = firebase.storage().ref(`Podcasts/${folder}/`);
      const name = this.radio.master_key + "_" + file.name;
      const metadata = {
        contentType: file.raw.type
      };
      const task = storage.child(name).put(file.raw, metadata);
      return await task
        .then(snapshot => snapshot.ref.getDownloadURL())
        .then(url => {
          return url;
        })
        .catch(console.error);
    }
  },
  mounted() {
    let self = this;
    self.$root.$on("channels", payload => {
      self.channels = payload;
      self.dialogNewEpisode = true;
    });
  }
};
</script>

<style>
.el-dialog__header {
  font-weight: 400;
}
.el-dialog__body {
  font-weight: 400;
}
.el-dialog__footer {
  text-align: left;
  padding: 10px 35px 20px;
}
</style>
