<template>
    <div>
        <el-dialog :visible.sync="openDialog"
            title="Nova Rede Social">
                <div class="md-layout md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                    <div class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-40 md-large-size-40 md-xlarge-size-40">
                        <el-select v-model="selectedSocial" placeholder="Selecione" style="width:100%;">
                            <el-option
                                v-for="item in socialNetworks"
                                :key="item.id"
                                :label="item.name"
                                :value="[item]">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-60 md-large-size-60 md-xlarge-size-60">
                        <el-input placeholder="URL da rede social" v-model="uri"></el-input>
                    </div>
                    <div class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                        <br>
                        <el-button type="primary" @click="newSocialNetwork()">Cadastrar</el-button>
                    </div>
                </div>
        </el-dialog>
    </div>
</template>

<script>
    import selectRadio from '../../../utils/events/select-radio'
    import mixpanelAdapter from '../../../utils/events/mixpanelAdapter'
    import Swal from 'sweetalert2'
    import {
        Dialog,
        Button,
        Tabs,
        TabPane,
        Input,
        Step,
        Steps,
        Upload,
        Select,
        Option
    } from 'element-ui'
    import models from '../../../routes/models'
    import jwt_decode from "jwt-decode";

    export default {
        components: {
            [Button.name]: Button,
            [Dialog.name]: Dialog,
            [Tabs.name]: Tabs,
            [TabPane.name]: TabPane,
            [Input.name]: Input,
            [Steps.name]: Steps,
            [Step.name]: Step,
            [Upload.name]: Upload,
            [Select.name]: Select,
            [Option.name]: Option
        },
        data() {
            return {
                radio: jwt_decode(localStorage.getItem('APP_01')).radio,
                user: jwt_decode(localStorage.getItem('SET_APP')).user,
                openDialog: false,
                socialNetworks: [],
                selectedSocial: '',
                uri: '',
                ipAddress: ''
            }
        },
        methods: {
            sendMixPanelEvent(eventString, params){
                mixpanelAdapter.track(eventString, {
                    ...params,
                    screen_name: this.$route.name
                })
            },
            getSocialNetworls() {
                models().get("radios/getAllSocialNetworks")
                    .then((response) => {
                        if(response.data) {
                            for(let item of response.data) {
                                if(item.id !== 68) {
                                    this.socialNetworks.push(item)
                                }
                            }
                        } else {    
                           Swal(
                                'Erro',
                                'Não foi possível localizar as redes sociais, por favor, tente novamente.',
                                'error'
                           ) 
                        }
                    })
                    .catch((error) => {
                        
                    })
            },
            newSocialNetwork() {
                let form = {
                    id: this.radio.id, 
                    social_network_id: this.selectedSocial[0].id,
                    uri: this.uri
                }
                models().post("radios/insertSocialNetwork", form)
                    .then((response) => {
                        this.openDialog = false;
                        let obj = {
                            id: this.selectedSocial[0].id,
                            link: this.uri,
                            name: this.selectedSocial[0].name
                        }
                        this.$root.$emit("pushSocialNetwork", obj)
                        const radio_change_log_form = {
                            radio_key: this.radio.key,
                            user_id: this.user.id,
                            user_username: this.user.username,
                            user_first_name: this.user.name,
                            user_lastname: this.user.lastname,
                            user_email: this.user.email,
                            radio_data: JSON.stringify(obj).replace(/['"]+/g, "'"),
                            type: 0,
                            ip: this.ipAddress,
                            user_agent: navigator.userAgent
                        }
                        this.radioChangeLog(radio_change_log_form)
                        Swal('Pronto','Rede social cadastrada com sucesso!','success');
                        this.sendMixPanelEvent(`social_media_created`, {
                            social_media_name: obj.name
                        })
                        this.selectedSocial = '';
                        this.uri = '';
                    })
            },
            getIp() {
                fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(({ ip }) => {
                    this.ipAddress = ip;
                });
            },
            radioChangeLog(radio_change_log_form) {
                models().post('radios/radioChangeLog', radio_change_log_form)
            }
        },
        mounted() {
            this.getIp()
            this.$parent.$on("openNewSocialNetwork", (payload) => {
                this.openDialog = true;
            })
            this.getSocialNetworls();
        }
    }
</script>

<style lang="css">
    .el-upload {
        margin: 0 auto !important;
    }
</style>