var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "space-around",
        },
      },
      [
        _c("div", [
          _c("h1", [_vm._v("Suas coordenadas:")]),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.myCoordinates.lat) +
                ", Latitude " +
                _vm._s(_vm.myCoordinates.lng) +
                " Longitude "
            ),
          ]),
        ]),
        _c("div", [
          _c("h1", [_vm._v("Mapa coordenadas:")]),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.mapCoordinates.lat) +
                ", Latitude " +
                _vm._s(_vm.mapCoordinates.lng) +
                " Longitude "
            ),
          ]),
        ]),
      ]
    ),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "space-around",
        },
      },
      [_c("div", { attrs: { id: "map" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }