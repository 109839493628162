var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report-layout", attrs: { align: "center" } },
    [
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("HeaderReport", {
            attrs: { RadioName: _vm.radioName, RadioLogo: _vm.radioLogo },
          }),
          _c(
            "div",
            {
              staticClass: "md-layout",
              staticStyle: { padding: "10% 0 0 0", width: "100%" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "md-layout-item md-size-95",
                  staticStyle: { margin: "1rem auto" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "md-layout md-alignment-center-space-between",
                    },
                    [
                      _c("div", { staticClass: "md-layout-item md-size-40" }, [
                        _c("h1", { staticClass: "report-main-title" }, [
                          _vm._v(_vm._s(_vm.reportName)),
                        ]),
                        _c("h5", { staticClass: "report-main-title" }, [
                          _vm._v(_vm._s(_vm.sublabel)),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "report-period-container md-layout-item md-size-40",
                        },
                        [
                          _c("h5", { staticClass: "report-main-title" }, [
                            _vm._v("Período de apuração"),
                          ]),
                          _c("h3", { staticClass: "period" }, [
                            _vm._v(
                              _vm._s(_vm.periodStart) +
                                " a " +
                                _vm._s(_vm.periodEnd)
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "md-layout-item md-size-95",
                  staticStyle: { margin: "0 auto" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "md-layout",
                      staticStyle: { margin: "0 auto" },
                    },
                    [_vm._t("page-1")],
                    2
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._l(_vm.numberOfPages - 1, function (pageNumber) {
        return _c(
          "div",
          { key: pageNumber, staticClass: "page" },
          [
            _c("header", { staticClass: "second-page-header" }, [
              _c("h3", [
                _vm._v(
                  _vm._s(_vm.reportName) +
                    " " +
                    _vm._s(_vm.sublabel ? " - " + _vm.sublabel : "")
                ),
              ]),
              _c("h3", [
                _vm._v(_vm._s(_vm.periodStart) + " a " + _vm._s(_vm.periodEnd)),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "md-layout-item md-size-95",
                staticStyle: { margin: "0 auto" },
              },
              [
                _c(
                  "div",
                  { staticClass: "md-layout" },
                  [_vm._t(`page-${pageNumber + 1}`)],
                  2
                ),
              ]
            ),
            pageNumber + 1 === _vm.numberOfPages
              ? _c("FooterReport")
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }