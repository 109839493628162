<template>
  <div>
    <span v-if="item.artist == 'Sem resultados.'">{{ item.artist }}</span>
    <span v-else>{{item.title}} - {{ item.artist }}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
    searchText: { required: true }
  }
}
</script>