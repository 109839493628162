let category = [{
        label: 'Artes',
            options: [
                {
                    value: 'Arts:Books',
                    label: 'Livros'
                }, {
                    value: 'Arts:Design',
                    label: 'Projeto'
                },{
                    value: 'Arts:Fashion & Beauty',
                    label: 'Moda e Beleza'
                },{
                    value: 'Arts:Food',
                    label: 'Comida'
                },{
                    value: 'Arts:Performing Arts',
                    label: 'Artes performáticas'
                },{
                    value: 'Arts:Visual Arts',
                    label: 'Artes visuais'
                }
            ]
        }, {
            label: 'Negócios',
            options: [
                {
                    value: 'Business:Careers',
                    label: 'Carreiras'
                }, {
                    value: 'Business:Entrepreneurship',
                    label: 'Empreendedorismo'
                }, {
                    value: 'Business:Investing',
                    label: 'Investimento'
                }, {
                    value: 'Business:Management',
                    label: 'Gestão'
                }, {
                    value: 'Business:Marketing',
                    label: 'Marketing'
                }, {
                    value: 'Business:Non-Profit',
                    label: 'Sem fins lucrativos'
                }
            ]
        }, {
            label: 'Comédia',
            options: [
                {
                    value: 'Comedy:Comedy Interviews',
                    label: 'Entrevistas de comédia'
                }, {
                    value: 'Comedy:Improv',
                    label: 'Improviso'
                }, {
                    value: 'Comedy:Stand-Up',
                    label: 'Stand-Up'
                }
            ]
        }, {
            label: 'Educação',
            options: [
                {
                    value: 'Education:Courses',
                    label: 'Cursos'
                }, {
                    value: 'Education:How To',
                    label: 'Como fazer'
                }, {
                    value: 'Education:Language Learning',
                    label: 'Aprendizagem de línguas'
                }, {
                    value: 'Education:Self-Improvement',
                    label: 'Auto-aperfeiçoamento'
                }
            ]
        }
    ]

module.exports = category;