<template>
		<div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-100" id="configuration-new-user">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon card">
						<md-icon>group</md-icon>
					</div>
					<h4 class="title">Usuários</h4>
				</md-card-header>
				<md-card-content>
					<!-- <h5>Cadastrados</h5> -->
						<div>
							<md-table v-model="usersDataTable" md-sort="name" md-sort-order="asc" md-card>
								<md-table-toolbar>
									<!-- <h1 class="md-title">Usuários</h1> -->
								</md-table-toolbar>

								<md-table-row slot="md-table-row" slot-scope="{ item }">
									<md-table-cell md-label="Usuário" md-sort-by="username">{{ item.username }}</md-table-cell>
									<md-table-cell md-label="Nome" md-sort-by="firstname">{{ item.firstname }}</md-table-cell>
									<md-table-cell md-label="Sobrenome" md-sort-by="lastname">{{ item.lastname }}</md-table-cell>
									<md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
									<md-table-cell md-label="Telefone">{{ item.mobile }}</md-table-cell>
									<md-table-cell md-label="Nível">{{ item.level.name }}</md-table-cell>
									<md-table-cell md-label="Email Semanal"><md-checkbox disabled v-model="item.weekly_email">{{ item.weekly_email ? 'Sim' : 'Não' }}</md-checkbox></md-table-cell>
									<md-table-cell md-label="Ações">
										<div class="container-btn-edit">
											<div class="row-btn-edit">
												<el-button icon="el-icon-edit" circle @click="editUser(item)"></el-button>
											</div>
											<div class="row-btn-edit">
												<el-button icon="el-icon-delete" circle @click="deleteUser(item.id)"></el-button>
											</div>
										</div>
									</md-table-cell>
								</md-table-row>
							</md-table>
						</div>
					<h5>Adicionar</h5>
					<div class="md-layout">
						<div class="md-layout-item md-xsmall-size-100 md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
							<el-select
								v-model="selectUser"
								filterable
								remote
								reserve-keyword
								placeholder="Buscar usuário"
								:remote-method="remoteMethod"
								:loading="loading">
								<el-option
									v-for="item in options"
									:key="item.value"
									:label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
						<div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
							<md-field>
								<label>Usuário</label>
								<md-input v-model="user.username"></md-input>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
							<md-field>
								<label>Nome</label>
								<md-input v-model="user.firstname"></md-input>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
							<md-field>
								<label>Sobrenome</label>
								<md-input v-model="user.lastname"></md-input>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
							<md-field>
								<label>Email</label>
								<md-input type="email" v-model="user.email"></md-input>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
							<md-field>
								<label>Telefone</label>
								<md-input type="number" v-model="user.mobile"></md-input>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-100 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
							<md-field>
								<label>Nível</label>
								<md-select type="select" v-model="user.level">
									<md-option value="0">Administrador</md-option>
									<md-option value="1">Gerente MobRadio</md-option>
									<md-option value="2">Usuário do Painel</md-option>
								</md-select>
							</md-field>
						</div>
						<div class="md-layout-item md-small-size-25 md-medium-size-20 md-large-size-20 md-xlarge-size-20">
							<md-button class="md-warning md-block" @click="newUser()"> Adicionar</md-button>
						</div>
					</div>
				</md-card-content>
			</md-card>

			<EditUser />
		</div>
</template>

<script>
import models from "@/routes/models";
import selectRadio from "@/utils/events/select-radio";
import {Button, Select, Option} from 'element-ui'
import EditUser from "./EditUser";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

export default {
	components: {
		[Button.name]: Button,
		[Select.name]: Select,
		[Option.name]: Option,
		EditUser
	},

	data() {
		return{
			radio: jwt_decode(localStorage.getItem('APP_01')).radio,
			userCurrent: jwt_decode(localStorage.getItem('SET_APP')).user[0],
			user: {
				id: '',
				username: '',
				firstname: '',
				lastname: '',
				email: '',
				mobile: '',
				level: '',
				weekly_email: false
			},
			addUser: {
				userId: '',
				radioId: ''
			},
			usersDataTable: [],
			allUsers: [],
			selectUser: '',
			options: [],
			value: [],
			list: [],
			loading: false,
		}
	},

	methods: {
		getUsersByRadio() {
			this.usersDataTable = []
			models().get(`/users/getUsersByRadio?radioId=${this.radio.id}`)
				.then(resp => {
					resp.data.forEach((element) => {
						let level
						if(element.level === 0) { level = "Administrador" }
						if(element.level === 1) { level = "Gerente MobRadio" }
						if(element.level === 2) { level = "Usuário do Painel" }

						this.usersDataTable.push({
							id: element.id,
							username: element.username,
							firstname: element.firstname,
							lastname: element.lastname,
							email: element.email,
							mobile: element.mobile,
							level: {
								id: element.level,
								name: level
							},
							weekly_email: element.weekly_email ? true : false
						})
					})
				})
				.catch(err => console.log(err))
		},

		getAllUsers() {
			models().get('/users/getAllUsers')
				.then((resp) => {
					this.allUsers = resp.data

				})
				.catch(err => console.error(err))
		},
		remoteMethod(query) {
			if (query !== '') {
				this.loading = true;
				setTimeout(() => {
					this.loading = false;
					this.options = this.list.filter(item => {
						return item.label.toLowerCase()
							.indexOf(query.toLowerCase()) > -1;
					});
				}, 200);
			} else {
				this.options = [];
			}
		},

		newUser() {
			if(this.addUser.userId !== '') {
				models().post('/users/addUsersByRadio', this.addUser)
					.then((resp) => {
						this.getUsersByRadio()
						this.addUser.userId = ''
						this.addUser.radioId = ''
						this.user.username = ''
						this.user.firstname = ''
						this.user.lastname = ''
						this.user.email = ''
						this.user.mobile = ''
						this.user.level = ''
						this.selectUser = ''
					})
					.catch(err => console.log(err))
			} else {
				this.user.radioId = this.radio.id
				models().post('/users/NewUserByRadio', this.user)
					.then((resp) => {
						this.getUsersByRadio()
						this.user.username = ''
						this.user.firstname = ''
						this.user.lastname = ''
						this.user.email = ''
						this.user.mobile = ''
						this.user.level = ''
					})
					.catch(err => console.log(err))
			}
		},

		editUser(user) {
			this.$root.$emit('editUser', user)
		},

		deleteUser(userId) {
			let formData = {
				userId: userId,
				radioId: this.radio.id
			}
			Swal({
				title: 'Deseja remover este usuário?',
				text: "Clique em confirmar para excluir.",
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Cancelar',
				confirmButtonText: 'Confirmar'
			}).then((result) => {
				if (result.value) {
					models().post('/users/deleteUserByRadio', formData)
						.then(() => {
							this.getUsersByRadio()
							Swal('', 'Usuário removido', 'success')
						})
				}
			})
		}
	},

	mounted() {
		this.getUsersByRadio()
		this.getAllUsers()
		selectRadio.$on('selectRadio', (payload) => {
			this.radio = payload
		})
	},

	watch: {
		radio() {
			this.getUsersByRadio()
			this.getAllUsers()
		},
		allUsers() {
			this.list = this.allUsers.map(item => {
				return { value: item.id, label: `${item.username}` };
			});
		},
		selectUser(id) {
			if(id) {
				const user = this.allUsers.find(element => element.id === id)
				this.user = user
				this.addUser = {
					userId: user.id,
					radioId: this.radio.id
				}
			}
		}
	}
}
</script>

<style>
	.container-btn-edit {
		display: flex;
	}

	.row-btn-edit {
		width: 35% !important;
	}
</style>
