<template>
    <div>
        <CoverList/>
        <CoverForm/>
    </div>
</template>
<script>
import CoverList from './CoverList.vue'
import CoverForm from './CoverForm.vue'

export default {
    components: {
        CoverList,
        CoverForm
    }
}

</script>
<style>

</style>