var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "md-card",
    { staticClass: "md-card-pricing", class: _vm.cardClass },
    [
      _vm._t("category"),
      _c(
        "md-card-content",
        [
          _c(
            "div",
            { staticClass: "md-card-icon", class: _vm.iconColor },
            [_vm._t("icon")],
            2
          ),
          _vm._t("title"),
          _vm._t("description"),
        ],
        2
      ),
      _c(
        "md-card-actions",
        { staticClass: "text-center" },
        [_vm._t("footer")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }