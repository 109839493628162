<template>
	<el-dialog title="Editar Usuário" :visible.sync="show" :close-on-press-escape="false" :close-on-click-modal="false">
		<div>
			<div class="md-layout">
				<div class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
					<label>Usuario</label>
					<el-input class="form-input" v-model="user.username"></el-input>
				</div>
				<div class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
					<label>Nome</label>
					<el-input class="form-input" v-model="user.firstname"></el-input>
				</div>
				<div class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
					<label>Sobrenome</label>
					<el-input class="form-input" v-model="user.lastname"></el-input>
				</div>
				<div class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
					<label>Email</label>
					<el-input class="form-input" v-model="user.email"></el-input>
				</div>
				<div class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
					<label>Telefone</label>
					<el-input class="form-input" v-model="user.mobile"></el-input>
				</div>
				<div class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50">
					<label style="width: 100%">Nivel</label> <br>
					<el-select v-model="user.level.name" placeholder="Select" style="width: 100%;margin-top: -12px">
						<el-option
							v-for="item in levels"
							:key="item.id"
							:label="item.name"
							:value="item.name">
						</el-option>
					</el-select>
				</div>
				<div class="md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-50 md-xlarge-size-50 form-checkbox">
					<label>Email Semanal</label> &nbsp;
					<el-checkbox v-model="user.weekly_email"></el-checkbox>
				</div>
			</div>
			<div class="md-layout">
				<div
					class="md-layout-item md-small-size-100 md-medium-size-30 md-large-size-30 md-xlarge-size-20">
					<md-button class="md-info md-block" @click="updateUser()">
						Salvar</md-button>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import { Dialog, Tabs, TabPane, Input, Checkbox, Select, Option } from 'element-ui'
	import Swal from 'sweetalert2';
	import models from "@/routes/models";
	import jwt_decode from "jwt-decode";
	export default {
		components: {
			[Dialog.name]: Dialog,
			[Tabs.name]: Tabs,
			[TabPane.name]: TabPane,
			[Input.name]: Input,
			[Checkbox.name]: Checkbox,
			[Select.name]: Select,
			[Option.name]: Option

		},
		data() {
			name: 'edit-user'
			return {
				show: false,
				radio: jwt_decode(localStorage.getItem('APP_01')).radio,
				user: {
					id: '',
					username: '',
					firstname: '',
					lastname: '',
					email: '',
					mobile: '',
					level: {
						id: '',
						name: ''
					},
					weekly_email: false
				},
				select_level: '',
				levels: [
					{ id:0, name: 'Administrador' },
					{ id:1, name: 'Gerente MobRadio' },
					{ id:2, name: 'Usuário do Painel' }
				]
			}
		},
		methods: {
			openModal(user) {
				this.user = user
				this.show = true
			},
			updateUser() {
				let level
				if(this.user.level.name === "Administrador") { level = 0 }
				if(this.user.level.name === "Gerente MobRadio") { level = 1 }
				if(this.user.level.name === "Usuário do Painel") { level = 2 }
				let formData = {
					id: this.user.id,
					username: this.user.username,
					firstname: this.user.firstname,
					lastname: this.user.lastname,
					email: this.user.email,
					mobile: this.user.mobile,
					level: level,
					weekly_email: this.user.weekly_email,
					radioId: this.radio.id
				}
				models().post('/users/updateUserByRadio', formData)
					.then(() => {
						Swal('', 'Usuário atualizado', "success")
						this.show = false
					})
					.catch(() => {})
			}
		},
		mounted() {
			this.$root.$on("editUser", (payload) => {
				this.openModal(payload)
			})
		}
	}
</script>

<style>
	.form-input {
		margin-top: -12px;
	}
	.form-checkbox {
		margin-top: 12px;
	}
</style>
