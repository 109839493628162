<template>
    <div class="md-layout">
        <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100" v-for="(item, key) in services" :key="key">
                <md-card class="body-card-services" v-if="'/'+item.service_url == route">
                    <md-card-header class="md-card-header-icon md-card-header-blue">
                        <div class="card-icon">
                            <md-icon>{{item.vue_icon}}</md-icon>
                        </div>
                        <h4 class="title"> Descrição do serviço</h4>
                    </md-card-header>
                    <md-card-content>
                        {{item.description}}
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header>
                    <div class="card-icon card-date">
                        <md-icon class="card-icon-date">lock</md-icon>
                    </div>
                    <h4 class="title"></h4>
                </md-card-header>
                <div>
                    <md-card-content class="md-layout">
                        <div class="md-layout-item md-small-size-90 md-medium-size-100 md-large-size-100 md-xlarge-size-100">
                            <span>
                                <h4>Atualmente o seu plano não contempla essa funcionalidade, para adquirir e/ou obter informações, entre em contato conosco:</h4>
                                <p><b>Contato:</b> (31) 2528 2400</p>
                                <p><b>Email: </b> <a href="mailto:comercial@mobradio.com.br">Comercial (comercial@mobradio.com.br)</a></p>
                            </span>
                        </div>
                    </md-card-content>
                </div>
            </md-card>
        </div>
    </div>
</template>

<script>
export default {
    name: 'block',
    data() {
        return {
            services: JSON.parse(localStorage.getItem('radio-services')),
            route: ''   
        }
    },
    mounted() {
        this.route = this.$router.currentRoute.path
    }
}
</script>
