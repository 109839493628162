var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "70%",
            title: "Visualização de campanha",
            visible: _vm.openPreviewInfo,
            "close-on-click-modal": false,
            center: true,
            "lock-scroll": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openPreviewInfo = $event
            },
          },
        },
        [
          _c("div", { staticClass: "main-container" }, [
            _c("div", { staticClass: "md-layout" }, [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                },
                [
                  _c("h2", { staticStyle: { margin: "0" } }, [
                    _vm._v(_vm._s(_vm.campaign.name)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                },
                [
                  _c("h5", { staticStyle: { display: "inline-block" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          new Date(_vm.campaign.start_date).toLocaleDateString(
                            "pt-BR"
                          )
                        ) +
                        " às " +
                        _vm._s(_vm.campaign.start_time.slice(0, -3)) +
                        " "
                    ),
                  ]),
                  _vm._v("   "),
                  _c("i", {
                    staticClass: "fa fa-circle",
                    staticStyle: { display: "inline-block" },
                  }),
                  _vm._v("   "),
                  _c("h5", { staticStyle: { display: "inline-block" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          new Date(_vm.campaign.end_date).toLocaleDateString(
                            "pt-BR"
                          )
                        ) +
                        " às " +
                        _vm._s(_vm.campaign.end_time.slice(0, -3)) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-small-size-100 md-medium-size-100 md-large-size-100 md-xlarge-size-100",
                },
                [
                  _vm.campaign.expired === 1
                    ? [
                        _c("i", {
                          staticClass: "fas fa-circle",
                          staticStyle: { color: "#ef6b6c" },
                        }),
                        _vm._v("  "),
                        _c("span", { staticStyle: { color: "#ef6b6c" } }, [
                          _vm._v("FINALIZADA"),
                        ]),
                      ]
                    : _vm.campaign.current === 1
                    ? [
                        _c("i", {
                          staticClass: "fas fa-circle",
                          staticStyle: { color: "#4baf51" },
                        }),
                        _vm._v("  "),
                        _c("span", { staticStyle: { color: "#4baf51" } }, [
                          _vm._v("ABERTA"),
                        ]),
                      ]
                    : _vm.campaign.opened === 0
                    ? [
                        _c("i", {
                          staticClass: "fas fa-circle",
                          staticStyle: { color: "#c9cacb" },
                        }),
                        _vm._v("  "),
                        _c("span", { staticStyle: { color: "#c9cacb" } }, [
                          _vm._v("PRÓXIMA"),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "md-layout-item md-size-100" },
                [
                  _vm.loading
                    ? _c("Spinner")
                    : _c(
                        "div",
                        { staticClass: "md-layout" },
                        [
                          _c(
                            "h4",
                            {
                              staticClass: "md-layout-item md-size-100",
                              staticStyle: { padding: "0" },
                            },
                            [_vm._v("Banners cadastrados")]
                          ),
                          _c(
                            "el-table",
                            {
                              staticClass: "md-layout-item md-size-100",
                              staticStyle: { padding: "0" },
                              attrs: { data: _vm.banners },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { prop: "name", label: "Nome" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "position", label: "Área" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "url", label: "URL" },
                              }),
                              _c("el-table-column", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              icon: "el-icon-view",
                                              round: "",
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showBannerPopup(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Visualizar banner ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }