var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "md-layout hide-on-print" },
    [
      _vm.listOfMobAds.length === 0 && _vm.searchString === ""
        ? [
            _c("h5", [
              _vm._v(
                "Não foram encontrados MobAds " +
                  _vm._s(_vm.status) +
                  "dentro do período informado"
              ),
            ]),
          ]
        : _vm.listOfMobAds.length === 0 && _vm.searchString !== ""
        ? [
            _c("h5", [
              _vm._v(
                "Não foram encontrados MobAds " +
                  _vm._s(_vm.status) +
                  'para a busca "' +
                  _vm._s(_vm.searchString) +
                  '" dentro do período informado'
              ),
            ]),
          ]
        : _vm._l(_vm.listOfMobAds, function (campaign) {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "md-layout-item md-xsmall-size-100 md-small-size-50 md-medium-size-50 md-large-size-33 md-xlarge-size-33 mobad-card",
                },
                [_c("MobAdCard", { attrs: { mobAd: campaign } })],
                1
              ),
            ]
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }